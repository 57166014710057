/* eslint-disable no-param-reassign */
import { createReducer } from 'modules/redux-utils'
import t from './actionTypes'

// initialState
const initialState = []

export default createReducer(initialState, {
  [t.LOAD_PERSONS]: (state, payload) => payload,
  [t.SET_SELECTED_PERSONS]: (state, payload) =>
    state.map((person) => {
      if (payload.indexOf(person.Id) !== -1) {
        person.selected = true
      } else {
        person.selected = false
      }

      return person
    }),
})
