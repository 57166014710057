/* eslint-disable */
// TODO: Lint cleanup. If you are editing this file, it is your duty to fix all linting errors.
import { createReducer } from 'modules/redux-utils'
import t from './actionTypes'

// initialState
const initialState = {
  modalActive: false,
  View: null,
  data: {},
}

export default createReducer(initialState, {
  [t.SHOW_MODAL]: (state, payload) =>
    Object.assign({}, state, {
      modalActive: true,
    }),

  [t.HIDE_MODAL]: (state, payload) =>
    Object.assign({}, state, {
      modalActive: false,
      View: null,
      data: {},
    }),
  [t.SET_MODAL_VIEW]: (state, payload) =>
    Object.assign({}, state, {
      View: payload,
    }),
  [t.SET_MODAL_DATA]: (state, payload) =>
    Object.assign({}, state, {
      data: payload,
    }),
  [t.CLEAR_MODAL_VIEW]: (state, payload) =>
    Object.assign({}, state, {
      modalActive: false,
      View: null,
      data: {},
    }),
})
