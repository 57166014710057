import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

const HSPTooltip = ({ tooltipId }) => (
  <ReactTooltip id={tooltipId} effect="solid" place="right">
    <Text primary large thin>
      What is an <Text bold>HSP</Text>?
    </Text>
    <Container flex direction="column" padding="0 10px 0 10px" margin="10px 0 0 0" width="350px">
      <Text p>
        In a Health Service Plan (HSP), you must choose a Primary Care Physician (PCP) for all your
        routine check ups and to contact if you feel ill.
      </Text>
      <Text p>
        You can see any doctor, specialist or hospital within your provider network without a
        referral. (Certain services will require preauthorization and referrals depending on
        carrier).
      </Text>
      <Text p>
        HSP plans do not cover out-of-network services unless it is a medical emergency.
      </Text>
    </Container>
  </ReactTooltip>
)

HSPTooltip.propTypes = {
  tooltipId: PropTypes.string.isRequired,
}

HSPTooltip.defaultProps = {}

export default HSPTooltip
