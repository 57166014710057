/* eslint-disable */
// TODO: Lint cleanup. If you are editing this file, it is your duty to fix all linting errors.
import React from 'react'
import PropTypes from 'prop-types'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

const ProductCardDescription = ({ children }) => (
  <Container noPadding margin="20px 0" style={{ maxWidth: '450px' }}>
    <Text light thin fontSize="16px">
      {children}
    </Text>
  </Container>
)

ProductCardDescription.displayName = 'ProductCardDescription'
ProductCardDescription.propTypes = {
  children: PropTypes.node,
}

export default ProductCardDescription
