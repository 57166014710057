import { createRequest } from '@hixme/redux-gateway'

import notification from 'modules/notification-manager'
import personsModule, { selectors as personsSelectors } from 'store/modules/persons'
import session from 'store/modules/user-session'

import { GET_RESPONSES_ROUTE, POST_RESPONSES_ROUTE, DELETE_RESPONSES_ROUTE } from './constants'
import * as selectors from './selectors'
import t from './actionTypes'

// ======== Questionnaire ==========
export const initializeQuestionnaire = () => ({
  type: t.INIT_QUESTIONNAIRE,
})

export const resetQuestionnaire = () => ({
  type: t.RESET_QUESTIONNAIRE,
})

export const startQuestionnaire = () => ({
  type: t.START_QUESTIONNAIRE,
})

export const nextQuestion = () => ({
  type: t.NEXT_QUESTION,
})

export const prevQuestion = () => ({
  type: t.PREV_QUESTION,
})

// ======== Question responses action creators ==========
export const loadResponses = (responses) => ({
  type: t.LOAD_RESPONSES,
  payload: responses,
})

export const loadQuestionAnswers = (payload) => ({
  type: t.LOAD_QUESTIONANSWERS,
  payload,
})

// ======== Question responses async actions ==========
export const getResponses = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(
      createRequest({
        route: GET_RESPONSES_ROUTE,
        params: {
          personPublicKey: dispatch(session.selectors.getUserId()),
        },
      })
    ).then(
      (data) => {
        dispatch(loadResponses(data))
        resolve(data)
      },
      (error) => {
        dispatch(
          notification.actions.createNotification({
            type: 'error',
            message: 'Error retrieving medical responses. Please please reload the page.',
            autoClose: true,
          })
        )
        reject(error)
      }
    )
  })

export const deleteResponses = () => (dispatch, getState) => {
  const employeeKey = personsSelectors.getEmployeeKey(getState())

  return new Promise((resolve, reject) => {
    dispatch(
      createRequest({
        route: DELETE_RESPONSES_ROUTE,
        body: { EmployeePublicKey: employeeKey },
      })
    ).then(
      (data) => {
        dispatch(session.actions.loadEnrollmentResponses(data))
        resolve(data)
      },
      (error) => {
        dispatch(
          notification.actions.createNotification({
            type: 'error',
            message: 'There was an error deleting medical responses.',
            autoClose: true,
          })
        )
        reject(error)
      }
    )
  })
}

export const postResponses = () => (dispatch, getState) => {
  const responses = selectors.getResponses(getState())
  return new Promise((resolve, reject) => {
    dispatch(
      createRequest({
        route: POST_RESPONSES_ROUTE,
        body: responses,
      })
    ).then(
      (data) => {
        resolve(data)
      },
      (error) => {
        dispatch(
          notification.actions.createNotification({
            type: 'error',
            message: 'Error saving medical responses. Please reload the page.',
            autoClose: true,
          })
        )
        reject(error)
      }
    )
  })
}

export const postResponsesWithResponders = () => (dispatch, getState) => {
  const state = getState()
  const employeeKey = personsSelectors.getEmployeeKey(state)
  const responders = selectors.getRespondersList(state)
  const questionAnswers = selectors.getQuestionAnswers(state)
  const questionResponses = Object.keys(questionAnswers).reduce(
    (prev, cur, index) => [
      ...prev,
      {
        QuestionNumber: cur,
        Response: questionAnswers[cur],
        EmployeePublicKey: employeeKey,
        Responders: responders[index],
      },
    ],
    []
  )

  dispatch(loadResponses(questionResponses))
  dispatch(
    notification.actions.createNotification({
      message: 'Saving your answers...',
      autoClose: true,
      timeToClose: 1500,
    })
  )

  return new Promise((resolve, reject) => {
    dispatch(
      createRequest({
        route: POST_RESPONSES_ROUTE,
        body: questionResponses,
      })
    ).then(
      (data) => {
        dispatch(session.actions.loadEnrollmentResponses(data))
        resolve(data)
      },
      (error) => {
        dispatch(
          notification.actions.createNotification({
            type: 'error',
            message: 'There was an saving medical responses. Please try again.',
            autoClose: true,
          })
        )
        reject(error)
      }
    )
  })
}

// ======== Person selector action creators ==========
export const loadPersons = (persons) => ({
  type: t.LOAD_PERSONS,
  payload: persons,
})

export const updateRespondersByIndex = (responders, index) => ({
  type: t.UPDATE_RESPONDERS_BYINDEX,
  payload: { responders, index },
})

// ======== Question responses async action ==========
// Updates the responders for the given question number
// If responders is undefined, all persons (EE + dpendents) are added as responders
export const updateResponders = (responders, questionNumber) => {
  // responders stored in array by zero-based index
  const index = questionNumber
  return (dispatch, getState) => {
    const state = getState()
    if (responders) {
      dispatch(updateRespondersByIndex(responders, index))
    } else {
      const persons = selectors.getAllPersons(state)
      const all = persons.map((person) => person.Id)
      dispatch(updateRespondersByIndex(all, index))
    }
  }
}

export const loadPersonSelector = () => (dispatch, getState) =>
  new Promise((resolve) => {
    const state = getState()
    const employee = personsModule.selectors.getPersonSelector(state)

    // To disable employee in person selector
    // employee.disabled = true

    const dependents = personsModule.selectors.getDependentsEligibleForMedicalBenefits(state)
    dispatch(loadPersons([employee, ...dependents]))

    // // We dont want all persons to be selected
    // let selectedIds = [employee.Id]
    // selectedIds.push(...dependents.map((person) => (person.Id)))
    // dispatch(setSelectedPersons(selectedIds))

    resolve()
  })

// Cool but not being used
//
// export const loadResponse = (response, index) => {
//   return {
//     type: t.LOAD_RESPONSE,
//     payload: { response, index }
//   }
// }

// export const setSelectedPersons = (personIds) => {
//   return {
//     type: t.SET_SELECTED_PERSONS,
//     payload: personIds
//   }
// }
