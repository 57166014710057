import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import BulletList from '@hixme-ui/bullet-list'
import Text from '@hixme-ui/text'

export const ProductCardBenefitsList = ({ list }) => {
  const benefits = list.reduce((prev, benefit) => {
    const { label, value, TagProps } = benefit
    let { Tag } = benefit
    if (!value || value.length === 0) return prev

    Tag = Tag || Text
    prev.push(
      <Text bold style={{ lineHeight: '20px' }}>
        {label && <Text light>{label}:</Text>}
        <Tag {...TagProps}>{value}</Tag>
      </Text>
    )

    return prev
  }, [])

  return <BulletList bulletArray={benefits} />
}
ProductCardBenefitsList.displayName = 'ProductCardBenefitsList'
ProductCardBenefitsList.propTypes = {
  list: PropTypes.array,
}

ProductCardBenefitsList.defaultProps = {
  list: [],
}

export default ProductCardBenefitsList
