import { ActivityActorAndTarget, getActorAndTarget } from '../../../../modules/activity-log/get-actor-and-target'
import { logActivity } from '../../../../modules/activity-log'
import { ActivityActor, ActivityCategory } from '@surecompanies/activity-log'
import { isImpersonating } from '../../../../store/modules/user-session/selectors'
import {
  getCurrentGroupFavoriteBundles,
  getCurrentGroupFilterOptions,
  getCurrentGroupBundles,
} from '../modules/health-bundles/selectors'
import { groupStatistics } from './group-statistics'
import { currentGroupStatistics } from './current-group-statistics'
import { pickBundleProps } from './utils'
import { storeInstance } from '../../../../store/createStore'
import { SOURCE } from '../../../../modules/activity-log/constants'
import { UNKNOWN, MODULE, AccordionToggle } from '../constants'

const enum ShopForHealthAction {
  ShopForHealthViewed = 'Shop For Health Viewed',
  SelectShopForHealthGroup = 'Select Shop For Health Group',
  DataLoaded = 'Shop For Health Data Loaded',
  NavigateBundle = 'Navigate Bundle',
  ToggleCompare = 'Toggle Compare',
  CompareBundles = 'Compare Bundles',
  MyBenefits = 'Add/Remove My Benefits plan',
  Filter = 'Change Filter',
  OpenBenefitsSummary = 'Open Benefits Summary',
  ToggleBundleCardInfo = 'Toggle Bundle Card Info',
  AddProvider = 'Clicked the Add Provider button',
}

const formatDescription = ({ activity, actorAndTarget, state }: { activity: string, actorAndTarget: ActivityActorAndTarget, state: unknown }): string => {
  if (isImpersonating(state)) {
    return `${actorAndTarget.actorName} ${activity} on behalf of ${actorAndTarget.targetName}`
  }
  return `${actorAndTarget.actorName} ${activity}`
}

const FallbackActorAndTarget: ActivityActorAndTarget = {
    actorId: UNKNOWN,
    actorName: UNKNOWN,
    actorType: ActivityActor.User,
    targetId: UNKNOWN,
    targetName: UNKNOWN,
    targetType: ActivityActor.User,
  }

interface LogActivityInput {
  additionalData: Record<string, unknown>
  state?: unknown
  activity: string
  action: ShopForHealthAction
  showBundles?: boolean
}

// ToDo - the parameter 'state' is used in many of the calls. This is unnecessary since 'state' can be accessed from the storeInstance.

/**
 * Log a user activity on the Shop for Health page.
 * @param {Record<string, any>} additionalData - additional data to include in the log
 * @param state
 * @param {string} activity - the human-readable description of the activity. e.g. 'viewed the Shop for Health page'
 * @param {string} action - categorizes identical or similar activities. e.g. 'ShopForHealthViewed'
 * @param {boolean} showBundles - if `true`, include the current group's bundle data in the log
 */
export const logShopForHealthActivity = ({ additionalData, state = storeInstance.getState(), activity, action, showBundles = false }: LogActivityInput) =>
{
  const actorAndTarget = getActorAndTarget(state, FallbackActorAndTarget)
  if (actorAndTarget?.targetName === UNKNOWN) {
    console.warn(`logged in user could not be determined`)
  }
  const currentGroupStats = currentGroupStatistics(state, showBundles)
  return logActivity({
    ...actorAndTarget,
    source: SOURCE,
    category: ActivityCategory.UserInteraction,
    action,
    description: formatDescription({ activity, actorAndTarget, state }),
    additionalData: { ...additionalData, module: MODULE, currentGroupStats },
  })
    .catch(console.error)

}

// when plan data is loaded for the Shop for Health page
const shopForHealthDataLoaded = ({ payload, state } ) => {
  const stats = groupStatistics(payload)
  return logShopForHealthActivity({
    additionalData: { stats },
    state,
    activity: 'retrieved plan information for the \'Shop for Health\' page',
    action: ShopForHealthAction.DataLoaded,
    showBundles: true,
  })
}

// user select dropdown to change group (Select Health Plan For)
const selectHealthPlanForDropdown = (
  {
    componentLabel,
    payload,
    state
  }: {
    componentLabel: string,
    payload: Record<string, unknown>,
    state: any
  }) => {
  const { name = UNKNOWN, area = UNKNOWN } = payload
  return logShopForHealthActivity({
    additionalData: { payload },
    state,
    activity: `selected the group '${name}, Family Member(s) in ${area}' in the '${componentLabel}' dropdown`,
    action: ShopForHealthAction.SelectShopForHealthGroup,
    showBundles: true,
  })
}

// when plan data is loaded for the Shop for Health page
interface CarouselScrollInput {
  payload: Record<string, any>
  state: any
  direction: 'Next' | 'Prev'
}

// user clicks 'prev' or 'next' in the carousel
const carouselScroll = ({ state, direction, payload }: CarouselScrollInput) => {
  const { list, stackName: metal, stackCarousel: previousIndex }  = payload
  const previousBundle = pickBundleProps(list?.[previousIndex])

  const nextIndex = direction === 'Next' ? previousIndex + 1 : previousIndex - 1
  const currentBundle = pickBundleProps(list?.[nextIndex])

  const activity = `clicked '${direction}' in the '${metal}' column to view '${currentBundle.PlanName}'`

  return logShopForHealthActivity({
    additionalData: { navigate: { direction, previousBundle, currentBundle, metal } },
    state,
    activity,
    action: ShopForHealthAction.NavigateBundle,
  })
}

interface ToggleForCompareInput {
  bundle: Record<string, any>
  state: any
  currentFaveIds: string[]
}

// add a bundle to compare
const toggleForCompare = ({ state, currentFaveIds, bundle }: ToggleForCompareInput) => {
  const { HealthPlanId, PlanName }  = bundle
  const action = currentFaveIds.indexOf(bundle.HealthPlanId) > -1 ? 'removed' : 'added'

  const activity = `${action} '${PlanName}' ${action === 'removed' ? 'from' : 'to' } the plans to compare`
  let ids = currentFaveIds
  if (action === 'added') {
    ids.push(HealthPlanId)
  } else {
    ids = ids.filter((id) => id !== HealthPlanId)
  }

  const toggleForCompareData = {
    bundle: pickBundleProps(bundle),
    currentFaveIds: ids,
    action,
  }
  return logShopForHealthActivity({
    additionalData: { toggleForCompare: toggleForCompareData },
    state,
    activity,
    action: ShopForHealthAction.ToggleCompare,
  })
}

// user select dropdown to change group (Select Health Plan For)
const shopForHealthViewed = ({ payload, state } ) => {
  const { name = UNKNOWN, ratingAreaString: area = UNKNOWN } = payload
  const { carriers, planTypes } = getCurrentGroupFilterOptions(state)
  return logShopForHealthActivity({
    additionalData: { payload, carriers, planTypes },
    state,
    activity: `viewed the 'Shop for Health' page for the '${name}, Family Member(s) in ${area}' group`,
    action: ShopForHealthAction.SelectShopForHealthGroup,
    showBundles: true,
  })
}

// user clicks the 'compare' link to compare selected bundles
const compareBundles = () => {
  const currentFaveBundles: any[] = getCurrentGroupFavoriteBundles(storeInstance.getState())
  return logShopForHealthActivity({
    additionalData: { favoriteBundles: currentFaveBundles.map(b => pickBundleProps(b)) },
    activity: `compared ${currentFaveBundles.length} bundles`,
    action: ShopForHealthAction.CompareBundles,
  })
}

// add a plan
const addToMyBenfits = (BundlePublicKey) => {
  const bundles: any[] = Object.values(getCurrentGroupBundles(storeInstance.getState()))
  const bundle = bundles.find(b => b.BundlePublicKey === BundlePublicKey)
  return logShopForHealthActivity({
    additionalData: { bundle: pickBundleProps(bundle) },
    activity: `added '${bundle?.PlanName}' to their benefits`,
    action: ShopForHealthAction.MyBenefits,
  })
}

const removeFromMyBenefits = (BundlePublicKey) => {
  const bundles: any[] = Object.values(getCurrentGroupBundles(storeInstance.getState()))
  const bundle = bundles.find(b => b.BundlePublicKey === BundlePublicKey)
  return logShopForHealthActivity({
    additionalData: { bundle: pickBundleProps(bundle) },
    activity: `removed '${bundle?.PlanName}' from their benefits`,
    action: ShopForHealthAction.MyBenefits,
  })
}

// ToDo - the calls that log filter actions do not correctly log the state of the benefits panel after the action is taken.

// user toggles a filter checkbox
const toggleFilter = ({ filterName, value, checked }: { filterName: string; value: string; checked: boolean }) => {
  // there's still some problem here - this action gets logged before the bundles are updated.
  return logShopForHealthActivity({
    additionalData: { filter: { filterName, value, action: checked ? 'checked': 'unchecked' } },
    activity: `${checked ? 'checked' : 'unchecked'} the '${filterName}:${value}' filter`,
    action: ShopForHealthAction.Filter,
    showBundles: true,
  })
}

// user enters value for Plan Cost in the filter box
const updateMaxCostFilter = (newValue: number) => {
  // there's still some problem here - this action gets logged before the bundles are updated.
  return logShopForHealthActivity({
    additionalData: { filter: { newValue } },
    activity: `set the 'Plan cost' filter to $${newValue}`,
    action: ShopForHealthAction.Filter,
    showBundles: true,
  })
}

const openBenefitsSummary = (BundlePublicKey) => {
  const bundles: any[] = Object.values(getCurrentGroupBundles(storeInstance.getState()))
  const bundle = bundles.find(b => b.BundlePublicKey === BundlePublicKey)
  return logShopForHealthActivity({
    additionalData: { bundle },
    activity: `opened the 'Benefits Summary' page for '${bundle?.PlanName}'`,
    action: ShopForHealthAction.OpenBenefitsSummary,
    showBundles: true,
  })
}

// user opens/closes the 'Benefit Highlights or Prescription Drugs panel
const toggleBundlePanelAccordion = ({bundle, toggle, benefitHighlight}: { bundle: unknown, toggle: AccordionToggle, benefitHighlight: any }) => {
  return logShopForHealthActivity({
    additionalData: { bundle: pickBundleProps(bundle), toggle },
    activity: `${toggle} the '${benefitHighlight?.label}' panel`,
    action: ShopForHealthAction.ToggleBundleCardInfo,
  })
}

// user opens/closes the 'Benefit Highlights or Prescription Drugs panel
const addProviderClicked = () => {
  return logShopForHealthActivity({
    additionalData: {},
    activity: `clicked the 'Add Provider' button`,
    action: ShopForHealthAction.AddProvider,
  })
}

// wrap the functions to capture and log any exceptions
const catchException = (fn: (...args: any[]) => Promise<any>): (...args: any[]) => Promise<any> => {
  return (...args: any[]) => {
    try {
      return fn(...args); // Return the original promise
    } catch (error) {
      console.error(`shopForHealthLog error in function ${fn.name}:`, error); // Log the error
      return Promise.reject(error); // Reject the promise with the error
    }
  };
};

export const ShopForHealthLog = {
  shopForHealthViewed: catchException(shopForHealthViewed),
  shopForHealthDataLoaded: catchException(shopForHealthDataLoaded),
  selectHealthPlanForDropdown: catchException(selectHealthPlanForDropdown),
  carouselScroll: catchException(carouselScroll),
  toggleForCompare: catchException(toggleForCompare),
  compareBundles: catchException(compareBundles),
  addToMyBenfits: catchException(addToMyBenfits),
  removeFromMyBenfits: catchException(removeFromMyBenefits),
  toggleFilter: catchException(toggleFilter),
  updateMaxCostFilter: catchException(updateMaxCostFilter),
  openBenefitsSummary: catchException(openBenefitsSummary),
  toggleBundlePanelAccordion: catchException(toggleBundlePanelAccordion),
  addProviderClicked: catchException(addProviderClicked),
};

