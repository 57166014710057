/* eslint-disable */
// TODO: Lint cleanup. If you are editing this file, it is your duty to fix all linting errors.
import { createReducer } from 'modules/redux-utils'
import type from './actionTypes'

const initialState = {
  initialized: false,
}

export default createReducer(initialState, {
  [type.SESSION_INVALIDATE]: (state, payload) =>
    Object.assign({}, state, {
      initialized: false,
    }),
  [type.SESSION_INITIALIZED]: (state, payload) =>
    Object.assign({}, state, {
      initialized: true,
    }),
})
