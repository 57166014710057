import React from 'react'
import BaseIcon from './BaseIcon'

/*eslint-disable */
export default class Profile extends BaseIcon {
  static defaultProps = {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30'
  }
  render () {
    return (
      <svg {...this.getSvgProps()}>
        <g>
          <path d='M15.94 18.75c-.52 0-.94.42-.94.94 0 .5.42.9.94.9h13.12c.52 0 .94-.4.94-.94s-.42-.95-.94-.95H15.94zm0 4.7c-.52 0-.94.4-.94.93 0 .5.42.93.94.93h13.12c.52 0 .94-.4.94-.9s-.42-.93-.94-.93H15.94zm0 4.68c-.52 0-.94.4-.94.93 0 .52.42.94.94.94h13.12c.52 0 .94-.42.94-.94s-.42-.93-.94-.93H15.94z'
            {...this.getPathProps()} />
          <path d='M18.75 6.56C18.75 2.94 15.8 0 12.2 0 8.55 0 5.62 2.94 5.62 6.56c0 3.63 2.93 6.57 6.56 6.57 3.6 0 6.55-2.94 6.55-6.57zm-11.25 0c0-2.6 2.1-4.68 4.7-4.68 2.58 0 4.68 2.1 4.68 4.68 0 2.6-2.1 4.7-4.7 4.7-2.58 0-4.68-2.1-4.68-4.7zm4.7 6.57C5.46 13.13 0 18.38 0 24.86v4.2c0 .52.42.94.94.94s.94-.42.94-.94v-4.2c0-5.43 4.6-9.86 10.3-9.86 1.25 0 2.45.2 3.6.6.48.18 1-.07 1.2-.56.16-.5-.1-1.02-.6-1.2-1.32-.47-2.74-.7-4.2-.7v-.02z'
            {...this.getPathProps()} />
        </g>
      </svg>
    )
  }
}
/*eslint-enable */
