import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { connectWaiter } from 'redux-waiter'

// We don't want any residue from the global axios object
const request = axios.create()

const DownloadError = (props) => props.error
DownloadError.displayName = 'DownloadError'
DownloadError.propTypes = { error: PropTypes.any }
DownloadError.defaultProps = { error: () => null }

export default connectWaiter({
  name: (props) => `download-document:${props.url}`,
  requestCreator: (params) => request({ url: params.url }),
  requestOnMountParams: (props) => ({ url: props.url }),
  pendingView: () => <span>...</span>,
  rejectedView: DownloadError,
})(({ children, ...props }) => children(props))
