import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'

type DisplayCarrierNotice = {
  [name: string]: {
    State: string[]
    Website: string
  }
}

export type UseFeatureFlagsLoginParams = {
  /**
   * The identifying key that identifies a user in our systems, such as a user ID.
   */
  key: string
  /**
   * The user's primary email address.
   */
  email: string
  /**
   * The company id of the user's employer.
   */
  companyId: string
}

export type UseFeatureFlags = {
  /**
   * An object containing all feature flags used by the application.
   */
  flags: {
    /**
     * Controls visibility of the QLE v1 feature.
     */
    ['qle-v-1']: boolean
    /**
     * Controls visibility of the Zendesk widget.
     */
    ['customer-service.fe.zendesk-widget-enabled']: boolean
    /**
     * Controls visibility of Renewals feature
     */
    ['product.enrollme.renewals']: boolean
    /**
     * Controls visibility of custom split family feature
     */
    ['product.enrollme.custom.split.family']: boolean
    /**
     * Controls visibility of professional level inputs
     */
    ['product.enrollme.professional-experience-inputs']: boolean
    /**
     * Contains the time when the enrollment period ends. (tech debt)
     */
    ['product.enrollme.enrollment-cutoff-time']: string
    /**
     * Controls individual selection of members in QLE process
     */
    ['product.enrollme.individual-member-selection-qle']: boolean
    /**
     * Determines if terms and conditions check should occur on shop for health page during QLE process
     */
    ['product.enrollme.terms-and-condition-qle']: boolean
    /**
     * Determines if a notice should be shown for a specific Carrier in a given state
     */
    ['product.enrollme.shop-display-carrier-notice']: DisplayCarrierNotice | {}
    /**
     * When this flag is turned on we validate the length of the split family to allow QLE only if number of groups is <= 1
     */
    ['product.enrollme.check-split-family-length-qle']: boolean
    /**
     * When this flag is turned on the user is allowed to access the add a person QLE flow
     */
    ['product.enrollme.add-person-qle']: boolean
    /**
     * Controls if address verification feature is active
     */
    ['feature-address-verification']: boolean
    /**
     * Temp flag - Controls if additional medicare questions are active
     */
    ['temp.medicare.questions.ui']: boolean
    /**
     * Controls if QLE Relocation flow is available
     */
    ['qle.relocation-flow.ui']: boolean
    /**
     * Temp flag for controlling ICHRA contribution notice.
     * ICHRA contribution notice also requires company config "DisplayContributionsToEmployees" to be true
     */
    ['temp.ichra-contribution.ui']: boolean
    /**
     * Temp flag to control citizenship questions for employee form
     */
    ['temp.ui.citizenship-question']: boolean
  }
  /**
   * Callback methods used to perform actions on our feature flag storage, such as identifying a user after login.
   */
  methods: {
    /**
     * Identifies a user after a successful login.
     */
    identify: (params: UseFeatureFlagsLoginParams) => void
  }
}

export const useFeatureFlags = (): UseFeatureFlags => {
  try {
    const flags = useFlags()
    const ldClient = useLDClient()

    const identify = ({ key, email, companyId }: UseFeatureFlagsLoginParams) => {
      ldClient?.identify({ key, email, custom: { companyId } }).catch((e) => console.error('LaunchDarkly - Error identifying user', e))
    }
    return { flags, methods: { identify } } as UseFeatureFlags
  } catch (e) {
    console.error('Error initializing LaunchDarkly', e)
    return defaultFeatureFlags
  }
}

const defaultFeatureFlags: UseFeatureFlags = {
  /**
   * An object containing all feature flags used by the application.
   */
  flags: {
    /**
     * Controls visibility of the QLE v1 feature.
     */
    'qle-v-1': false,
    /**
     * Controls visibility of the Zendesk widget.
     */
    'customer-service.fe.zendesk-widget-enabled': false,
    /**
     * Controls visibility of Renewals feature
     */
    'product.enrollme.renewals': false,
    /**
     * Controls visibility of custom split family feature
     */
    'product.enrollme.custom.split.family': false,
    /**
     * Controls visibility of professional level inputs
     */
    'product.enrollme.professional-experience-inputs': false,
    /**
     * Contains the time when the enrollment period ends. (tech debt)
     */
    'product.enrollme.enrollment-cutoff-time': '17:00:02',
    /**
     * Controls individual selection of members in QLE process
     */
    'product.enrollme.individual-member-selection-qle': false,
    /**
     * Determines if terms and conditions check should occur on shop for health page during QLE process
     */
    'product.enrollme.terms-and-condition-qle': false,
    /**
     * Determines if a notice should be shown for a specific Carrier in a given state
     */
    'product.enrollme.shop-display-carrier-notice': {},
    /**
     * When this flag is turned on we validate the length of the split family to allow QLE only if number of groups is <= 1
     */
    'product.enrollme.check-split-family-length-qle': false,
    /**
     * When this flag is turned on the user is allowed to access the add a person QLE flow
     */
    'product.enrollme.add-person-qle': false,
    /**
     * Controls if address verification feature is active
     */
    'feature-address-verification': false,
    /**
     * Temp flag - Controls if additional medicare questions are active
     */
    'temp.medicare.questions.ui': false,
    /**
     * Controls if QLE Relocation flow is available
     */
    'qle.relocation-flow.ui': false,
    /**
     * Temp flag for controlling ICHRA contribution notice.
     * ICHRA contribution notice also requires company config "DisplayContributionsToEmployees" to be true
     */
    'temp.ichra-contribution.ui': false,
    /**
     * Temp flag to control citizenship questions for employee form
     */
    'temp.ui.citizenship-question': false,
  },
  /**
   * Callback methods used to perform actions on our feature flag storage, such as identifying a user after login.
   */
  methods: {
    /**
     * Identifies a user after a successful login.
     */
    identify: (_params: never) => { console.warn('No feature flag provider found.') }
  }
}

export default useFeatureFlags
