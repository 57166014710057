import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import DetailsCardContainer from '../../../../components/DetailsCard/DetailsCardContainer'
import NoPlan from './components/NoPlan'
import PlanFooter from './components/PlanFooter'

import { Row } from './style'
import { FooterSection } from './components/PlanFooter/style'
import { useLifeEventContext } from 'routes/LifeEvents/LifeEventsContext'

const CompareTable = ({
  benefits,
  currentGroupIndex,
  handleContinue,
  handleNextGroup,
  placeholderObject,
  hasSelectedPlans,
  onSelectPlan,
  baseObject,
  benefitKeys,
  totalGroupCount,
}) => {
  const qleCtx = useLifeEventContext()
  const hasNextGroup = currentGroupIndex + 1 !== totalGroupCount
  const handleSaveAndContinue = hasNextGroup ? handleNextGroup : handleContinue

  return (
    <div style={{ maxWidth: '1060px', minWidth: '800px', margin: '40px auto' }}>
      <Container flex noPadding justifyCenter>
        {hasSelectedPlans && (
          <div style={{ display: 'flex', flexBasis: '100%', minWidth: '230px' }}>
            <Container flex noPadding direction="column">
              <Container
                flex
                noPadding
                width="100%"
                height="170px"
                justifyCenter
                alignCenter
                direction="column"
              >
                <Text small thinner light>
                  All values reflect In-network services.
                </Text>
              </Container>
              <Row title>
                <Text center uppercase fontWeight="600">
                  Plan
                </Text>
              </Row>
              <Row expanded>
                <Text>Health Plan</Text>
              </Row>
              <Row>
                <Text>Style of Network</Text>
              </Row>
              <Row>
                <Text>Carrier</Text>
              </Row>
              {benefitKeys.map((item) => (
                <Row key={item.key} title={item.title}>
                  {item.text}
                </Row>
              ))}
            </Container>
            <FooterSection />
          </div>
        )}
        <Container noPadding flex style={{ gap: '20px' }}>
          {benefits.map((b) => (
            <Container flex noPadding key={b.BundlePublicKey} direction="column">
              <div
                style={{
                  width: '300px',
                  margin: '0',
                }}
              >
                {b !== placeholderObject ? (
                  <Container noPadding>
                    <div style={{ marginBottom: '100px' }}>
                      <DetailsCardContainer benefit={b} baseObject={baseObject} />
                    </div>
                    <PlanFooter
                      handleSaveAndContinue={handleSaveAndContinue}
                      totalGroupCount={totalGroupCount}
                      currentGroupIndex={currentGroupIndex}
                      plan={b}
                      hasNextGroup={hasNextGroup}
                      isQLE={Boolean(qleCtx && qleCtx.state)}
                    />
                  </Container>
                ) : (
                  <NoPlan onSelectPlan={onSelectPlan} />
                )}
              </div>
            </Container>
          ))}
        </Container>
      </Container>
    </div>
  )
}

CompareTable.propTypes = {
  benefits: PropTypes.array.isRequired,
  benefitKeys: PropTypes.array,
  currentGroupIndex: PropTypes.number,
  handleContinue: PropTypes.func,
  handleNextGroup: PropTypes.func,
  placeholderObject: PropTypes.object.isRequired,
  baseObject: PropTypes.object,
  totalGroupCount: PropTypes.number,
  hasSelectedPlans: PropTypes.bool.isRequired,
  onSelectPlan: PropTypes.func.isRequired,
}

CompareTable.defaultProps = {
  benefitKeys: [],
  currentGroupIndex: 0,
  handleContinue: () => null,
  handleNextGroup: () => null,
  totalGroupCount: 0,
  baseObject: null,
}

CompareTable.displayName = 'CompareTable'

export default CompareTable
