import React from 'react'
import PropTypes from 'prop-types'
import { MrModal } from '@hixme/modal'

// hixme-ui
import ContentContainer from '@hixme-ui/content-container'

// module
import EditQuestion from './EditQuestion'

const propTypes = {
  cancel: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  persons: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  responses: PropTypes.array,
  submitQuestions: PropTypes.func.isRequired,
  toggleSelectPerson: PropTypes.func.isRequired,
}
const defaultProps = {
  responses: [],
}

const EditQuestions = ({
  cancel,
  isProcessing,
  persons,
  questions,
  responses,
  submitQuestions,
  toggleSelectPerson,
}) => (
  <MrModal
    title="Edit Health Needs"
    dialog
    contentPadding="20px 0 0 0"
    confirmLabel="Save & return to shopping"
    confirmHandler={submitQuestions}
    confirmProcessing={isProcessing}
    cancelHandler={cancel}
  >
    <ContentContainer>
      {responses.map((r) => (
        <EditQuestion
          yesPersons={r.Responders}
          question={questions.find((q) => q.number === r.QuestionNumber)}
          key={r.QuestionNumber}
          persons={persons}
          toggleSelectPerson={(e) => toggleSelectPerson(e, r.QuestionNumber)}
        />
      ))}
    </ContentContainer>
  </MrModal>
)

EditQuestions.propTypes = propTypes
EditQuestions.defaultProps = defaultProps

export default EditQuestions
