import {
  getCurrentGroupBundlesFiltered,
  getCurrentGroupBundleFilteredCount,
  getCurrentGroupRecommendedStack,
  getCurrentGroupPreviousBundle,
  getSomeGroupPreviousBundle,
  getCurrentGroupHasPreviousBundle,
  getCurrentGroupHasHiosMatch,
  getCurrentGroupBundleCount,
  getCurrentGroupFavoriteCount,
  getCurrentGroupFavoriteBundles,
  getCurrentGroupFilterOptions,
  getCurrentGroupFilters,
  getCurrentGroupProviders,
} from '../modules/health-bundles/selectors';
import { pickBundleProps } from './utils'

// package relevant statistics for ActivityLog
export const currentGroupStatistics = (state: any, showBundles = false) => {
  return {
    currentGroupBundleFilteredCount: getCurrentGroupBundleFilteredCount(state),
    currentGroupRecommendedStack: getCurrentGroupRecommendedStack(state),
    currentGroupPreviousBundle: getCurrentGroupPreviousBundle(state),
    someGroupPreviousBundle: getSomeGroupPreviousBundle(state),
    currentGroupHasPreviousBundle: getCurrentGroupHasPreviousBundle(state),
    currentGroupHasHiosMatch: getCurrentGroupHasHiosMatch(state),
    currentGroupBundleCount: getCurrentGroupBundleCount(state),
    currentGroupFavoriteCount: getCurrentGroupFavoriteCount(state),
    currentGroupFavoriteBundles: getCurrentGroupFavoriteBundles(state).map((b) => pickBundleProps(b)),
    currentGroupBundlesFiltered: showBundles ? Object.values(getCurrentGroupBundlesFiltered(state)).map((b) => pickBundleProps(b)) : undefined,
    currentGroupFilterOptions: getCurrentGroupFilterOptions(state),
    currentGroupFilters: getCurrentGroupFilters(state),
    currentGroupProviders: getCurrentGroupProviders(state),
  };
};



