import React from 'react'
import PropTypes from 'prop-types'

const getDisabled = (props) => {
  const { disabled } = props

  if (typeof disabled === 'function') {
    return disabled ? disabled(props) : false
  }

  return disabled
}

const PureFieldComponent = (props) => {
  const { input, style, InputComponent, ...restProps } = props
  return <InputComponent disabled={getDisabled(props)} style={style} {...input} {...restProps} />
}

PureFieldComponent.propTypes = {
  input: PropTypes.object,
  InputComponent: PropTypes.func,
  style: PropTypes.object,
}

PureFieldComponent.defaultProps = {
  input: {},
  InputComponent: null,
  style: {},
}
export default PureFieldComponent
