import React from 'react'
import BaseIcon from './BaseIcon'

/*eslint-disable */
export default class Clock extends BaseIcon {
  static defaultProps = {
    width: 23,
    height: 23,
    viewBox: '1 1 23 23'
  }
  render () {
    const { color } = this.props

    return (
      <svg {...this.getSvgProps()}>
        <g fill={color} stroke={color} strokeWidth='.5'>
          <path d='M13.4593182 12.2184091v-5.4075c0-.54409092-.4390909-.98318183-.9831818-.98318183-.54409095 0-.98318185.4390909-.98318185.98318182v5.6795454c0 .019091 0 .0429546.00477272.0620455 0 .0095455 0 .0238636.00477273.0334091 0 .0190909.00477273.0381818.00954545.0572727 0 .0143182.00477273.0286364.00477273.0381818.00477273.0143182.00954546.0334091.01431818.0477273.00477273.0143182.00954546.0334091.0143182.0477273.0047727.0143182.00954544.0238636.01431817.0381818.00477272.0190909.01431818.0381818.02386363.0572727.00477273.0095455.00954546.0190909.0143182.0334091l.02863635.0572727c0 .0047728.00477274.0047728.00477274.0095455l1.35068186 2.3434091c.1813636.315.5154545.4915909.8543181.4915909.1670455 0 .3340909-.0429545.4915909-.1336364.4725-.2720454.63-.8734091.3579546-1.3459091l-1.2265909-2.1095454z'/>
          <path d='M12.4761364 20.9954545c-4.69159095 0-8.50977276-3.8181818-8.50977276-8.5145454 0-4.69636365 3.8181818-8.51454546 8.50977276-8.51454546 4.6915909 0 8.5097727 3.8181818 8.5097727 8.51454546 0 4.6963636-3.8181818 8.5145454-8.5097727 8.5145454m7.4072727-15.92659086C17.9027273 3.08818182 15.2729545 2 12.4761364 2 9.67931818 2 7.04477273 3.08818182 5.06886364 5.06886364 3.08818182 7.04954544 2 9.67931818 2 12.4809091c0 2.8015909 1.08818182 5.4313636 3.06886364 7.4120454 1.9806818 1.9806819 4.61045454 3.0688637 7.40727276 3.0688637 2.7968181 0 5.4313636-1.0881818 7.4072727-3.0688637 1.9806818-1.9806818 3.0688636-4.6104545 3.0688636-7.4120454 0-2.80159092-1.0881818-5.43136365-3.0688636-7.41204546'/>
        </g>
      </svg>
    )
  }
}
/*eslint-enable */
