import { createSelector } from 'reselect'
import { getWaiter } from 'redux-waiter'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import { isChild, isSpouse, hasSpouse, hasChildren, hasChild } from '@hixme/person-utils'
import { NAME, GET_FAMILY_REQUEST } from './constants'

export const getPersonsSelector = createSelector(
  [(state) => state[NAME].person, (state) => state[NAME].dependentsList],
  (person, dependentsList) => ({
    Person: person,
    Dependents: dependentsList,
  })
)
export const getPerson = createSelector(
  (state) => state[NAME],
  (person) => person.person
)
export const getPersonSelector = createSelector(
  (state) => state[NAME],
  (p) => {
    const person = p.person
    if (!person.PayrollCycle) {
      return {
        ...person,
        PayrollCycle: 12,
      }
    }
    return person
  }
)
export const getEmployee = getPersonSelector

export const getEmployeeKey = createSelector(
  (state) => state[NAME].person,
  (employee) => employee.Id
)

export const getDependentsKeys = createSelector(
  (state) => state[NAME].dependentsList,
  (list) => list.map((dependent) => dependent.Id)
)

export const getNamesList = createSelector(
  [(state) => state[NAME].person, (state) => state[NAME].dependentsList],
  (person, dependentsList) => {
    const flattened = [person, ...dependentsList]
    return flattened.map((p) => p.FullName)
  }
)

export const getDependents = createSelector(
  (state) => state[NAME].dependentsList,
  (dependents) => dependents
)

export const getSpouse = createSelector(
  (state) => state[NAME].dependentsList,
  (dependents) => dependents.find((d) => isSpouse(d))
)

export const getChildren = createSelector(
  (state) => state[NAME].dependentsList,
  (dependents) => dependents.filter((d) => isChild(d))
)

export const personHasDependentsSelector = createSelector(
  (state) => state[NAME].dependentsList,
  (dependents) => {
    if (isArray(dependents)) {
      return dependents.length > 0
    }

    return false
  }
)

export const getDependentsEligibleForBenefits = createSelector(
  (state) => state[NAME].dependentsList,
  (dependents) =>
    (dependents || []).filter(
      (item) => item.IsEligibleForEmployeerPaidBenefits && item.IncludedInMedical
    )
)

export const getAllEligiblePersons = createSelector(
  getEmployee,
  getDependentsEligibleForBenefits,
  (person, eligibleDependents) => {
    return [...eligibleDependents, person]
  }
)

export const getDependentsEligibleForMedicalBenefits = createSelector(
  (state) => getDependentsEligibleForBenefits(state),
  (dependents) => (dependents || []).filter((item) => item.IncludedInMedical)
)

export const personHasEligibleSpouse = createSelector(getDependentsEligibleForBenefits, (persons) =>
  hasSpouse(persons)
)

export const personHasEligibleChildren = createSelector(
  getDependentsEligibleForBenefits,
  (persons) => hasChildren(persons)
)

export const personHasEligibleChild = createSelector(getDependentsEligibleForBenefits, (persons) =>
  hasChild(persons)
)

export const personHasDependentsEligibleForBenefitsSelector = (state) =>
  getDependentsEligibleForBenefits(state).length > 0

export const personHasDependentsEligibleForMedicalBenefitsSelector = (state) =>
  getDependentsEligibleForMedicalBenefits(state).length > 0

const getDependent = (state, props) => state[NAME].dependentsList[props.index]

export const getDependentByIndexSelector = createSelector([getDependent], (dependent) => dependent)

// Borrowed, with no intention of returning, from:
// http://codereview.stackexchange.com/questions/37028/grouping-elements-in-array-by-multiple-properties
const groupPersonsBy = (concatArray) => {
  const groupBy = (array, f) => {
    const groups = {}
    array.forEach((o) => {
      const group = JSON.stringify(f(o))
      groups[group] = groups[group] || []
      groups[group].push(o)
    })
    return Object.keys(groups).map((group) => groups[group])
  }

  const groupByArray = groupBy(concatArray, (person) => [person.StateProvince, person.RatingArea])
  return groupByArray
}

export const personsGroupedBySelector = createSelector(
  (state) => state[NAME],
  (persons) => {
    if (isEmpty(persons.person)) {
      return undefined
    }
    return groupPersonsBy([persons.person, ...persons.dependentsList])
  }
)

export const isSplitFamilySelector = createSelector(
  (state) => state[NAME],
  (persons) => {
    const groupByArray = groupPersonsBy([persons.person, ...persons.dependentsList])
    return groupByArray.length !== 1
  }
)

export const getPersonById = (id, state) =>
  createSelector(
    () => state[NAME],
    (persons) => {
      let personById = {}
      const concatPersons = [persons.person, ...persons.dependentsList]
      concatPersons.forEach((person) => {
        if (person.Id === id) {
          personById = person
        }
      })
      return personById
    }
  )(state)

export const getFamilyWaiter = createSelector(
  (state) => state,
  getEmployeeKey,
  (state, employeeId) => getWaiter(state, `${GET_FAMILY_REQUEST}-${employeeId}`)
)

export const isGettingFamilySelector = createSelector(getFamilyWaiter, (waiter) => waiter.isPending)

export const getIsPendingEdit = createSelector(
  (state) => state[NAME],
  (state) => state.pendingEdit.length > 0
)
