import { namespaceActions } from 'modules/redux-utils'
import { NAME } from './constants'

const ADD_FILES = 'ADD_FILES'
const CLEAR_FILES = 'CLEAR_FILES'
const REMOVE_FILE = 'REMOVE_FILE'
const UPDATE_GENERAL_MESSAGE = 'UPDATE_GENERAL_MESSAGE'
const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE'
const UPLOAD_ERROR = 'UPLOAD_ERROR'
const UPLOADING = 'UPLOADING'

const FILE_UPLOAD_PROGESS = 'FILE_UPLOAD_PROGESS'
const FILE_UPDATE_TYPE = 'FILE_UPDATE_TYPE'
const FILE_UPDATE_NOTE = 'FILE_UPDATE_NOTE'
const FILE_UPDATE_AMOUNT = 'FILE_UPDATE_AMOUNT'
const FILE_UPLOADED_AMOUNT = 'FILE_UPLOADED_AMOUNT'
const FILE_UPDATE_ID = 'FILE_UPDATE_ID'

export default namespaceActions(NAME)([
  ADD_FILES,
  CLEAR_FILES,
  REMOVE_FILE,
  FILE_UPDATE_TYPE,
  FILE_UPLOAD_PROGESS,
  FILE_UPDATE_NOTE,
  UPDATE_GENERAL_MESSAGE,
  UPLOADING,
  UPLOAD_COMPLETE,
  UPLOAD_ERROR,
  FILE_UPDATE_AMOUNT,
  FILE_UPLOADED_AMOUNT,
  FILE_UPDATE_ID,
])
