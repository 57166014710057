import { MAX_FAVORITES } from '../constants'

export const placeholderObject = { role: 'placeholder' }

export const normalizeObject = (obj = {}) =>
  Object.entries(obj).reduce((prev, [key, value]) => ({ ...prev, [key]: value || [] }), {})

export const getCategories = (list) =>
  list.map((plan) =>
    Object.entries(normalizeObject(plan.bundleBenefits)).reduce(
      (prev, [key, value]) => (value.length ? { ...prev, [key]: value } : prev),
      {}
    )
  )

export const getBaseObject = (categories) =>
  categories.reduce((prev, curr) => {
    const prevKeys = Object.keys(prev)
    const currentKeys = Object.keys(curr)

    const selectedKeys = prevKeys.length > currentKeys.length ? prevKeys : currentKeys

    const result = selectedKeys.reduce(
      (acc, current) => ({
        ...acc,
        [current]:
          (prev[current] || []).length > (curr[current] || []).length
            ? prev[current]
            : curr[current],
      }),
      {}
    )
    return { ...prev, ...result }
  })

export const getBenefitList = (baseObject) =>
  Object.keys(baseObject).reduce((acc, category) => {
    acc.push({ title: true, text: category, key: `title-${category}` })
    return acc.concat(
      (baseObject[category] || []).map((ben) => ({
        title: false,
        text: ben.BenefitDisplayName,
        key: `data-${category}-${ben.BenefitDisplayName}`,
      }))
    )
  }, [])

export const fillSpots = (plans) => {
  const remainingPlaceholderPlaces = MAX_FAVORITES - Object.keys(plans).length
  if (remainingPlaceholderPlaces) {
    return [...plans, ...new Array(remainingPlaceholderPlaces).fill().map(() => placeholderObject)]
  }
  return [...plans]
}
