import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Animate from 'components/Animate'

// hixme-ui
import ContentContainer from '@hixme-ui/content-container'
import CloseButton from '@hixme-ui/close-button'

import { NAME } from '../../constants'
import * as actions from '../../actions'

import classes from './Modal.scss'

export default (options = {}) => {
  // Get overlay options or set defaults options
  let hideCloseButton
  let overlayClickHide
  let routeOnHide
  let dark

  if (options.overlay) {
    ({ hideCloseButton, overlayClickHide, routeOnHide, dark } = options.overlay)
  }
  if (hideCloseButton === undefined) hideCloseButton = false
  if (overlayClickHide === undefined) overlayClickHide = false
  if (routeOnHide === undefined) routeOnHide = true
  if (dark === undefined) dark = false

  // Get content options or set defaults
  let position
  let size

  if (options.content) ({ position, size } = options.content)
  if (position === undefined) position = 'middle'
  if (size === undefined) size = 'small'

  return (InnerComponent) => {
    class ModalComponent extends Component {
      constructor(props) {
        super(props)
        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.overlayClicked = this.overlayClicked.bind(this)
      }

      componentDidMount() {
        this.showModal()
      }

      onHideModal() {
        return Promise.resolve().then(
          () => this.props.data && this.props.data.onHideModal && this.props.data.onHideModal()
        )
      }

      showModal(e) {
        if (e) {
          e.preventDefault()
        }
        this.props.showModal()
      }

      hideModal(e) {
        if (e) {
          e.preventDefault()
        }
        return this.onHideModal()
          .then(() => this.props.hideModal())
          .then(() => routeOnHide && this.context.router.goBack())
      }

      overlayClicked(e) {
        if (overlayClickHide && e.target.getAttribute('id') === 'overlay') {
          this.hideModal(e)
        }
      }

      render() {
        let styles = {}

        // Overlay default Styles and overrides
        const overlayStyleDefaults = {
          background: dark ? 'rgba(40,48,54,0.8)' : 'rgba(255,255,255,1)',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          overflowY: 'auto',
        }
        if (options.overlay) ({ styles } = options.overlay)
        if (styles === undefined) styles = {}
        const overlayStyles = Object.assign(overlayStyleDefaults, styles)

        // Content default styles and overrides
        if (options.content) ({ styles } = options.content)
        if (styles === undefined) styles = {}
        const contentStyles = Object.assign({}, styles)

        // Set width of content area
        let contentWidth
        switch (size) {
          case 'full':
            contentWidth = '100%'
            break
          case 'large':
            contentWidth = '940px'
            break
          case 'smaller':
            contentWidth = '398px'
            break
          case 'small':
          default:
            contentWidth = '654px'
        }

        // Render if modal is active
        if (this.props.modalActive) {
          return (
            <div
              id="overlay"
              onClick={this.overlayClicked}
              style={overlayStyles}
              className={classes.overlay}
              role="presentation"
            >
              <ContentContainer contentWidth={contentWidth}>
                <div style={{ position: 'relative' }}>
                  {!hideCloseButton && (
                    <div style={{ position: 'fixed', top: '20px', right: '10vw' }}>
                      <Animate slideInDown trigger={!hideCloseButton}>
                        <CloseButton onClick={this.hideModal} />
                      </Animate>
                    </div>
                  )}
                  <div style={{ margin: contentStyles.margin || '0' }}>
                    <InnerComponent {...this.props} />
                  </div>
                </div>
              </ContentContainer>
            </div>
          )
        }
        return null
      }
    }

    ModalComponent.propTypes = {
      modalActive: PropTypes.bool.isRequired,
      showModal: PropTypes.func.isRequired,
      hideModal: PropTypes.func.isRequired,
      data: PropTypes.any,
    }

    ModalComponent.defaultProps = {
      data: undefined,
    }

    ModalComponent.contextTypes = {
      router: PropTypes.object.isRequired,
    }

    const mapStateToProps = (state) => state[NAME]

    return connect(mapStateToProps, actions)(ModalComponent)
  }
}
