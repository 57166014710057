import styled from 'styled-components'

const Slider = styled.span`
  cursor: pointer;
  width: 50px;
  height: 23px;
  position: relative;
  border-radius: 23px;
  display: block;
  background: #eb5465;
  background-position: 25% 50%;
  background-repeat: no-repeat;
  transition: all ease 0.2s;
  ${({ withIcons }) => (withIcons && 'background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMTAgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+Q29tYmluZWQgU2hhcGU8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iQWRkLU5ldy1FRS0tLURlYy0yMDE2LVVwZGF0ZXMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IlByb2ZpbGUtLS1BZGQtbmV3LWVtcGxveWVlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOTY0LjAwMDAwMCwgLTE0OTAuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+ICAgICAgICAgICAgPGcgaWQ9Ik5vLXRvZ2dsZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTU0LjAwMDAwMCwgMTQ4NC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPHBhdGggZD0iTTE0LjkyNTYzNDIsOS41MzU4MTQ1IEwxMi4zNzQyMzA2LDYuOTg0NDEwODIgQzExLjgyNTg4OTQsNi40MzYwNjk2NCAxMC45NTA0MDI0LDYuNDM5OTc4NDkgMTAuNDA4MDA3Niw2Ljk4MjM3MzM0IEM5Ljg2MTgzMTI1LDcuNTI4NTQ5NjcgOS44NjY1MjQ5Myw4LjQwNTA3NjIxIDEwLjQxMDA0NTEsOC45NDg1OTYzMiBMMTIuOTYxNDQ4NywxMS41IEwxMC40MTAwNDUxLDE0LjA1MTQwMzcgQzkuODYxNzAzODcsMTQuNTk5NzQ0OSA5Ljg2NTYxMjcyLDE1LjQ3NTIzMTggMTAuNDA4MDA3NiwxNi4wMTc2MjY3IEMxMC45NTQxODM5LDE2LjU2MzgwMyAxMS44MzA3MTA0LDE2LjU1OTEwOTMgMTIuMzc0MjMwNiwxNi4wMTU1ODkyIEwxNC45MjU2MzQyLDEzLjQ2NDE4NTUgTDE3LjQ3NzAzNzksMTYuMDE1NTg5MiBDMTguMDI1Mzc5MSwxNi41NjM5MzA0IDE4LjkwMDg2NiwxNi41NjAwMjE1IDE5LjQ0MzI2MDksMTYuMDE3NjI2NyBDMTkuOTg5NDM3MiwxNS40NzE0NTAzIDE5Ljk4NDc0MzUsMTQuNTk0OTIzOCAxOS40NDEyMjM0LDE0LjA1MTQwMzcgTDE2Ljg4OTgxOTcsMTEuNSBMMTkuNDQxMjIzNCw4Ljk0ODU5NjMyIEMxOS45ODk1NjQ2LDguNDAwMjU1MTQgMTkuOTg1NjU1Nyw3LjUyNDc2ODE5IDE5LjQ0MzI2MDksNi45ODIzNzMzNCBDMTguODk3MDg0Niw2LjQzNjE5NzAyIDE4LjAyMDU1OCw2LjQ0MDg5MDcgMTcuNDc3MDM3OSw2Ljk4NDQxMDgyIEwxNC45MjU2MzQyLDkuNTM1ODE0NSBaIiBpZD0iQ29tYmluZWQtU2hhcGUiPjwvcGF0aD4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);' // eslint-disable-line
  )}
  &:before {
    width: 21px;
    height: 21px;
    content: "";
    position: absolute;
    top: 1px;
    left: 28px;
    border-radius: 50%;
    background: #fff;
    box-shadow: -2px 0px 3px 1px rgba(0, 0, 0, 0.2);
    transition: all ease 0.2s;
  }
`

export default Slider
