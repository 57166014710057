import React from 'react'
import PropTypes from 'prop-types'
import HappyTip from 'components/HappyTip'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import barSecure from '../../assets/bar-secure.png'
import SecureMiniWheelPng from '../../../assets/workerSecure-mini.png'
import SharedText from '../SharedText'

const ActurialSecureTooltip = ({ tooltipId }) => (
  <HappyTip id={tooltipId} type="light" effect="solid" globalEventOff="click">
    <Container
      flex
      width="350px"
      justifyContent="center"
      padding="10px"
      background="white"
      direction="column"
    >
      <div>
        <img alt="" src={barSecure} />
      </div>
      <Text p thin>
        We start with <Text bold>an even richer base plan</Text> and Hixme wraps insurance and
        financial benefits around it to <Text bold>increase the actuarial value score</Text> in
        order to <Text bold>reduce your out of pocket costs</Text> if you need the benefits.
      </Text>

      <Container flex noPadding margin="0 0 20px 0" justifyContent="flex-start">
        <img alt="" src={SecureMiniWheelPng} />
        <Text p style={{ marginLeft: '10px' }}>
          <Text bold>Highest peace of mind</Text> with <Text bold>fewest or no steps</Text> to
          trigger additional Hixme coverage if needed.
        </Text>
      </Container>
      <Container flex noPadding justifyContent="center">
        <SharedText />
      </Container>
    </Container>
  </HappyTip>
)

ActurialSecureTooltip.propTypes = {
  tooltipId: PropTypes.string.isRequired,
}

ActurialSecureTooltip.defaultProps = {}

export default ActurialSecureTooltip
