import React from 'react'
import PropTypes from 'prop-types'

/* eslint-disable */
const LearnMoreIcon = ({ size }) => (
  <svg width={size} height={size} viewBox="0 0 23 23" version="1.1">
    <title>icon-mini-wheel-learn-more</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="bug-learn-about-bundle-levels"
        transform="translate(-5.000000, -1.000000)"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(5.000000, 1.000000)">
          <g id="Group-2">
            <path
              d="M11.2531229,11.3835895 L11.2105379,11.3698524 L0.516203941,7.95435093 C-0.196751657,10.2708842 -0.171109066,12.4605783 0.59267381,14.7734484 C2.53784747,20.6671231 8.88805186,23.8623731 14.7766896,21.9167415 C20.6218266,19.9839312 23.8166186,13.7156915 21.956615,7.84491202 L11.2531229,11.3835895 Z"
              id="Shape"
              fill="#23836D"
            />
            <path
              d="M21.8700712,7.58436498 C20.6895963,4.13314703 18.1715855,1.65405801 14.6709139,0.535857898 C8.80746086,-1.33925654 2.54013698,1.86377777 0.601373974,7.69288809 L11.2517492,11.0946525 L21.8700712,7.58436498 Z"
              id="Shape"
              fill="#2EAE8F"
            />
            <circle
              id="Oval"
              stroke="#FFFFFF"
              strokeWidth="0.5"
              fill="#FEBE14"
              cx="11.2860471"
              cy="11.2860471"
              r="4.28604712"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

LearnMoreIcon.propTypes = {
  size: PropTypes.string,
}

LearnMoreIcon.defaultProps = {
  size: '23px',
}

export default LearnMoreIcon
