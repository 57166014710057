import { compose, withState } from 'recompose'
import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'

import {
  getCurrentGroupFilterOptions,
  getCurrentGroupBundleFilteredCount,
  getCurrentGroupBundleCount,
  getCurrentGroupIndex,
  getPersonsInCurrentGroup,
} from '../selectors'

import { resetFilters, setMatchBundle, setMatchBundleByHios } from '../actions'
import FilterOptions from '../components/FilterOptions/FilterOptions'

const mapStateToProps = (state) => {
  const isProcessing = getWaiter(state, 'GET_BUNDLES').isPending
  const getHospitals = getWaiter(state, 'GET_HOSPITAL_LIST')
  const currentGroupIndex = getCurrentGroupIndex(state)

  return {
    ...getCurrentGroupFilterOptions(state),
    currentGroupBundleCount: getCurrentGroupBundleCount(state),
    currentGroupBundleFilteredCount: getCurrentGroupBundleFilteredCount(state),
    currentGroupIndex,
    persons: getPersonsInCurrentGroup(state),
    isProcessing,
    isRejected: getHospitals.isRejected,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetFilters: () => {
    dispatch(setMatchBundle(false))
    dispatch(setMatchBundleByHios(false))
    dispatch(resetFilters())
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withState('open', 'toggleOpen', true)
)(FilterOptions)
