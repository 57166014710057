import React from 'react'
import PropTypes from 'prop-types'

import Container from '@hixme-ui/container'

import Animate from 'components/Animate'

import { StackNav, EmptyStackCard } from '../../components'
import BundleCardContainer from '../../modules/health-bundles/containers/BundleCardContainer'
import useFeatureFlags from 'hooks/useFeatureFlags'

const BundleStackCard = ({
  list,
  stackName,
  recommended,
  groupFavoriteCount,
  stackCarousel,
  onViewDetails,
  next,
  previous,
  isProcessing,
  // editHealthNeeds,
  answeredQuestions,
}) => {
  const { flags } = useFeatureFlags()
  return (
    <Container width="100%" flex noPadding direction="column" margin="10px 0">
      <StackNav
        onNextBundle={next}
        onPreviousBundle={previous}
        bundleCount={list.length}
        currentBundleIndex={stackCarousel + 1}
        stackName={stackName}
        isProcessing={isProcessing}
        recommended={recommended}
        // editHealthNeeds={editHealthNeeds}
        answeredQuestions={answeredQuestions}
      />

      {(isProcessing || list.length === 0) && <EmptyStackCard isProcessing={isProcessing} />}
      {!isProcessing && list.length > 0 && list[stackCarousel] !== undefined && (
        <Animate pulse time="500ms" trigger={list.length !== 0}>
          <BundleCardContainer
            onNextBundle={next}
            onPreviousBundle={previous}
            onViewDetails={onViewDetails}
            stackLength={list.length}
            groupFavoriteCount={groupFavoriteCount}
            currentBundleIndex={stackCarousel + 1}
            bundleId={list.length && list[stackCarousel].BundlePublicKey}
            carrierNoticeConfig={flags['product.enrollme.shop-display-carrier-notice']}
          />
        </Animate>
      )}
    </Container>
  )
}

BundleStackCard.propTypes = {
  list: PropTypes.array,
  stackCarousel: PropTypes.number,
  stackName: PropTypes.string,
  recommended: PropTypes.string,
  groupFavoriteCount: PropTypes.number,
  onViewDetails: PropTypes.func,
  next: PropTypes.func,
  previous: PropTypes.func,
  isProcessing: PropTypes.bool,
  // editHealthNeeds: PropTypes.func.isRequired,
  answeredQuestions: PropTypes.bool.isRequired,
}

BundleStackCard.defaultProps = {
  list: [],
  stackCarousel: 0,
  stackName: '',
  recommended: '',
  groupFavoriteCount: 0,
  onViewDetails: () => {},
  next: () => {},
  previous: () => {},
  isProcessing: false,
}

BundleStackCard.displayName = 'BundleStackCard'

export default BundleStackCard
