import React from 'react'
import PropTypes from 'prop-types'

/* eslint-disable max-len */
const BundleCapIcon = ({ size }) => (
  <svg width={size} height={size} viewBox={'0 0 31 31'} version="1.1">
    <title>icon-mini-wheel</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="icon-mini-wheel"
        transform="translate(1.000000, 1.000000)"
        fillRule="nonzero"
        stroke="#A374D2"
      >
        <g>
          <path
            d="M14.3692614,14.5358559 L14.314884,14.5183148 L0.659147635,10.1570158 C-0.251234791,13.1150278 -0.218491428,15.9110771 0.75679302,18.8644115 C3.24061097,26.3901226 11.3492709,30.4701795 18.8685503,27.9857769 C26.3322829,25.5177459 30.411755,17.5137478 28.0366918,10.0172718 L14.3692614,14.5358559 Z"
            id="Shape"
            fill="#DBE2E8"
          />
          <path
            d="M27.926183,9.68457588 C26.4188189,5.27767007 23.2035376,2.11208853 18.7334839,0.684244032 C11.2463632,-1.71011437 3.24353448,2.37988246 0.767902375,9.82315047 L14.3675073,14.1669084 L27.926183,9.68457588 Z"
            id="Shape"
            fill="#F8FAFC"
          />
          <circle id="Oval" fill="#FFFFFF" cx="14.3442351" cy="14.3188152" r="5.56166729" />
        </g>
      </g>
    </g>
  </svg>
)

BundleCapIcon.propTypes = {
  size: PropTypes.string,
}

BundleCapIcon.defaultProps = {
  size: '31px',
}

export default BundleCapIcon
