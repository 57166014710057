import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

const ProviderBadge = ({ name, count }) => (
  <Container flex marginRight="30px" padding="10px 0" style={{ gap: '10px' }}>
    <Text>{name}</Text>
    {count > 0 && <Text color={'#36ECBF'}>{`${count} ${count === 1 ? 'plan' : 'plans'}`}</Text>}
  </Container>
)

ProviderBadge.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
}

export default ProviderBadge
