export const NAME = 'Cart'
export const CART_GET_ROUTE = 'cartGet'
export const CART_GET = 'cartGet'
export const CART_POST_ROUTE = 'cartPost'
export const CART_SAVE_MANUAL_SIGNATURE_ROUTE = 'cartManualsignaturePost'
export const CART_SAVE_SIGNATURE_ROUTE = 'applicationStatusPut'
export const CART_DELETE_ROUTE = 'cartDelete'
export const CART_RECALCULATE_POST = 'cartRecalculatePost'

export const CART_POST_BASE_ROUTE = '{EnrollmentPublicKey}/cart/benefits'
export const CART_ACCIDENT_POST = `${CART_POST_BASE_ROUTE}/Accident`
export const CART_HOSPITAL_INDEMNITY_POST = `${CART_POST_BASE_ROUTE}/HospitalIndemnity`
export const CART_CRITICAL_ILLNESS_POST = `${CART_POST_BASE_ROUTE}/CriticalIllness`
export const CART_VISION_POST = `${CART_POST_BASE_ROUTE}/VisionInsurance`
export const SUPPLEMENTAL_LIFE_INSURANCE_POST = `${CART_POST_BASE_ROUTE}/SupplementalLifeInsurance`
export const CART_GROUPHEALTH_POST = `${CART_POST_BASE_ROUTE}/GroupHealth`
export const CART_LEGAL_POST = `${CART_POST_BASE_ROUTE}/LegalPlan`
export const CART_EAP_POST = `${CART_POST_BASE_ROUTE}/EmployeeAssistanceProgram`

export const getBenefitPath = (BenefitType) => `${CART_POST_BASE_ROUTE}/${BenefitType}`

export const INCLUDED = 'Included'
export const NOT_INCLUDED = 'NotIncluded'
export const DECLINED = 'Declined'

export const DECLINE = 'decline'
export const ADD = 'add'
export const REMOVE = 'remove'

export const HMO = 'HMO'
export const PPO = 'PPO'
