import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'redux-form'

// hixme-ui
import Button from '@hixme-ui/button'
import Checkbox from '@hixme-ui/checkbox'
import Container from '@hixme-ui/container'
import FormGroup from '@hixme-ui/form-group'

// project
import { FieldComponent } from 'components/ReduxFormComponents'

const PersonCoverageForm = ({ handleSubmit, submitting, onCancelClick, persons }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup>
      <Container flex noPadding direction="column" width="320px">
        {persons.map((field, index) => {
          const { FirstName, Id, LastName, disabled, selected } = field
          return (
            <Container flex noPadding margin="10px 0 10px" key={`PersonSelect-${index.toString()}`}>
              <Container flex noPadding alignCenter>
                <Container flex noPadding margin="0 20px 0 0">
                  <FieldComponent
                    inputComponent={Checkbox}
                    small
                    normalize={(value) => value || false}
                    type="checkbox"
                    name={Id}
                    text={`${FirstName} ${LastName}`}
                    props={{ disabled, selected }}
                  />
                </Container>
              </Container>
            </Container>
          )
        })}
        <Container flex noPadding justifySpaceBetween margin="20px 0 0 0">
          <Container flex noPadding flexBasis="150px">
            <Button block secondary type="button" onClick={onCancelClick}>
              Cancel
            </Button>
          </Container>
          <Container flex noPadding flexBasis="150px">
            <Button submittingText="Saving..." submitting={submitting} block>
              Save
            </Button>
          </Container>
        </Container>
      </Container>
    </FormGroup>
  </Form>
)

PersonCoverageForm.displayName = 'CoverageSelection'

PersonCoverageForm.propTypes = {
  persons: PropTypes.array,
  onCancelClick: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
}

PersonCoverageForm.defaultProps = {
  persons: [],
  submitting: false,
}

export default PersonCoverageForm
