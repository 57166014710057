import React from 'react'
import FontAwesome from 'react-fontawesome'
import uuid from 'uuid/v4'
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import { LinkOutlined } from '@ant-design/icons'

// project
import { PersonBenefitPrice } from 'store/modules/persons/components'

// module
import { BundleCapIcon } from '../../components'
import { MAX_FAVORITES } from '../../modules/health-bundles/constants'
import classes from './DetailsCard.scss'
import { CardHeader, PLAN_COLORS } from '../StackNav/styles/style'
import BenefitsSummaryButton from '../../modules/health-bundles/components/BenefitsSummaryButton/BenefitsSummaryButton'
import {
  languageMapper,
  onBenefitsSummaryOpen,
} from '../../modules/health-bundles/components/BundleCard/BundleCard'

const DetailsCard = ({
  benefit,
  baseObject,
  benefit: { SbcLinks },
  groupFavoriteCount,
  showFunctionality,
  onToggleFavorite,
}) => {
  const titleRowGen = () => <div key={uuid()} className={classes.titleRow} />

  const dataRowGen = (category, obj, id) => (
    <div key={uuid()} className={classes.basicRow}>
      {obj.DisplayValue ? (
        <Row middle="xs" style={{ width: '100%' }}>
          <Col xs={2} />
          <Col xs={8}>
            <div data-tip data-for={`${obj.BenefitDisplayName}${id}`}>
              <Text
                thin
                dashed={!!obj.DisplayValueMessage && !!obj.DisplayValueMessage.length}
                purple={false && obj.BundleBenefitApplies}
              >
                {obj.DisplayValue}
              </Text>
            </div>
            {obj.DisplayValueMessage && obj.DisplayValueMessage.length > 0 && (
              <ReactTooltip id={`${obj.BenefitDisplayName}${id}`} effect="solid">
                <Text>{obj.DisplayValueMessage}</Text>
              </ReactTooltip>
            )}
          </Col>
          <Col xs={2}>
            {false && obj.BundleBenefitApplies && (
              <div style={{ margin: '2px 0 0 0' }}>
                <BundleCapIcon size="20px" />
              </div>
            )}
          </Col>
        </Row>
      ) : (
        <div style={{ width: '100%' }}>
          <Text>Not Available</Text>
        </div>
      )}
    </div>
  )

  return (
    <Container noPadding width="100%">
      <Container noPadding heavyShadow rounded width="100%">
        <Row center="xs" style={{ margin: '0' }}>
          <Col xs={12} style={{ padding: '0' }}>
            <Container flex noPadding direction="column" height="170px">
              <CardHeader metal={benefit.MetalLevel} />
              <Container
                flex
                noPadding
                justifyContent="flex-end"
                height="50px"
                margin="0 10px 0 0"
                alignItems="center"
              >
                {showFunctionality && (
                  <div>
                    {benefit.favorite ? (
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => onToggleFavorite(benefit.BundlePublicKey)}
                      >
                        <FontAwesome
                          style={{
                            color: PLAN_COLORS[benefit.MetalLevel],
                          }}
                          name="heart"
                          size="lg"
                        />
                      </div>
                    ) : (
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => onToggleFavorite(benefit.BundlePublicKey)}
                      >
                        {groupFavoriteCount === MAX_FAVORITES && (
                          <ReactTooltip id="maxReached" effect="solid">
                            <span>Compare maximum of {MAX_FAVORITES} reached</span>
                          </ReactTooltip>
                        )}
                        <div data-tip data-for="maxReached">
                          <FontAwesome
                            style={{
                              color: PLAN_COLORS[benefit.MetalLevel],
                            }}
                            name="heart"
                            size="lg"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Container>
              <Container
                flex
                noPadding
                justifyContent="center"
                direction="column"
                alignItems="center"
              >
                <PersonBenefitPrice price={benefit.bundlePrice} />
              </Container>
            </Container>
            <div className={classes.titleRow}>
              <Text large bolder>
                {benefit.MetalLevel.toUpperCase()}
              </Text>
            </div>
            <div className={classes.expandedRow}>
              <BenefitsSummaryButton
                label={benefit.PlanName}
                as="link"
                items={SbcLinks.map((link) => ({
                  label: languageMapper[link.Language] || 'English',
                  icon: <LinkOutlined />,
                  key: link.Url,
                }))}
                onItemSelected={(key) => onBenefitsSummaryOpen(key, benefit.BundlePublicKey)}
              />
            </div>
            <div className={classes.basicRow}>
              <Text thin>{benefit.PlanType}</Text>
            </div>
            <div className={classes.basicRow}>
              <Text thin>{benefit.CarrierName}</Text>
            </div>
            {Object.entries(baseObject).reduce((acc, [key, value]) => {
              acc.push(titleRowGen(key))
              return acc.concat(
                value.map((ben) => {
                  const planBenefit =
                    (benefit.bundleBenefits[key] || []).find(
                      (b) => b.BenefitDisplayName === ben.BenefitDisplayName
                    ) || {}
                  return dataRowGen(key, planBenefit, benefit.HealthPlanId)
                })
              )
            }, [])}
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

DetailsCard.propTypes = {
  benefit: PropTypes.object.isRequired,
  baseObject: PropTypes.object,
  groupFavoriteCount: PropTypes.number,
  showFunctionality: PropTypes.bool,
  onToggleFavorite: PropTypes.func,
}

DetailsCard.defaultProps = {
  groupFavoriteCount: 0,
  showFunctionality: true,
  onToggleFavorite: () => null,
  baseObject: {},
}

export default DetailsCard
