/* eslint-disable */
// TODO: Lint cleanup. If you are editing this file, it is your duty to fix all linting errors.
import { createReducer } from 'modules/redux-utils'
import t from './actionTypes'

const initialState = {
  locations: {},
}

export default createReducer(initialState, {
  [t.LOAD_LOCATIONS]: (state, { locations, postalCode }) => ({ ...state, [postalCode]: locations }),
})
