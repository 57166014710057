import { bundleActivationService } from '@hixme/api'
import axios from 'axios'

import type from './actionTypes'

export const addFiles = (files, groupKey) => ({
  type: type.ADD_FILES,
  payload: {
    files: files.map((file) => {
      const wrapper = {
        id: null,
        complete: false,
        error: false,
        errorMessage: '',
        file,
        fileType: '',
        groupKey,
        note: '',
        amount: 0,
        validAmount: false,
        progress: 0,
        uploaded: 0,
        uploading: false,
      }
      return wrapper
    }),
    groupKey,
  },
})

export const clearFiles = (groupKey) => ({
  type: type.CLEAR_FILES,
  payload: {
    groupKey,
  },
})

export const removeFile = (fileName, groupKey) => ({
  type: type.REMOVE_FILE,
  payload: {
    fileName,
    groupKey,
  },
})

export const fileUploadProgress = (fileName, progress, groupKey) => ({
  type: type.FILE_UPLOAD_PROGESS,
  payload: {
    fileName,
    progress,
    groupKey,
  },
})

export const fileUploadedAmount = (fileName, uploaded, groupKey) => ({
  type: type.FILE_UPLOADED_AMOUNT,
  payload: {
    fileName,
    uploaded,
    groupKey,
  },
})

export const updateGeneralMessage = (message, groupKey) => ({
  type: type.UPDATE_GENERAL_MESSAGE,
  payload: {
    message,
    groupKey,
  },
})

export const updateFileType = (value, fileName, groupKey) => ({
  type: type.FILE_UPDATE_TYPE,
  payload: {
    value,
    fileName,
    groupKey,
  },
})

export const updateNote = (value, fileName, groupKey) => ({
  type: type.FILE_UPDATE_NOTE,
  payload: {
    value,
    fileName,
    groupKey,
  },
})

export const updateAmount = (data, fileName, groupKey) => ({
  type: type.FILE_UPDATE_AMOUNT,
  payload: {
    data,
    fileName,
    groupKey,
  },
})

export const uploadComplete = (fileName, groupKey) => ({
  type: type.UPLOAD_COMPLETE,
  payload: {
    fileName,
    groupKey,
  },
})

export const uploadError = (fileName, groupKey, message) => ({
  type: type.UPLOAD_ERROR,
  payload: {
    fileName,
    groupKey,
    message,
  },
})

export const uploading = (bool, groupKey) => ({
  type: type.UPLOADING,
  payload: {
    bool,
    groupKey,
  },
})

export const updateFileId = (fileName, groupKey, id) => ({
  type: type.FILE_UPDATE_ID,
  payload: {
    fileName,
    groupKey,
    id,
  },
})

export const getSignedUrl = ({ EmployeePublicKey, fileName }) =>
  bundleActivationService({
    route: 'bundle-events/signed-urls/{EmployeePublicKey}',
    params: { EmployeePublicKey, Name: fileName },
  })

export const uploadFile = (signedUrl, file, groupKey) => (dispatch) =>
  axios.create({
    onUploadProgress: (progressEvent) => {
      dispatch(
        fileUploadProgress(file.name, (progressEvent.loaded / progressEvent.total) * 100, groupKey)
      )
      dispatch(fileUploadedAmount(file.name, progressEvent.loaded, groupKey))
    },
  })({
    method: 'put',
    url: signedUrl,
    data: file,
  })
