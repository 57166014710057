import { createReducer } from 'modules/redux-utils'
import t from './actionTypes'

// initialState
const initialState = {
  userId: null,
  user: {},
  ichra: {},
  providers: [],
  clientSettings: {},
  clientBenefitsEnrollment: {},
  enrollmentResponses: [],
  enrollmentSession: {},
  userInitialized: false,
  updatingUser: false,
  scrolledToBottom: false,
  errors: [],
  location: null,
  enrollmentCutoffTime: '',
}

export default createReducer(initialState, {
  [t.SET_USER_ID]: (state, payload) => ({
    ...state,
    userId: payload,
  }),
  [t.LOAD_USER]: (state, payload) => ({
    ...state,
    user: payload,
  }),
  [t.LOAD_PAYROLL]: (state, payload) => ({
    ...state,
    payroll: payload,
  }),
  [t.LOAD_CLIENT_SETTINGS]: (state, payload) => ({
    ...state,
    clientSettings: payload,
  }),
  [t.LOAD_ENROLLMENT_CUTOFF_TIME]: (state, payload) => ({
    ...state,
    enrollmentCutoffTime: payload,
  }),
  [t.LOAD_CLIENT_BENEFITS_ENROLLMENT]: (state, payload) => ({
    ...state,
    clientBenefitsEnrollment: payload,
  }),
  [t.LOAD_ENROLLMENT_RESPONSES]: (state, payload) => ({
    ...state,
    enrollmentResponses: payload.map((res) =>
      // solve for difference in 2017 / 2018+ data shapes
      res.Responders.length && res.Responders[0].Response
        ? {
            ...res,
            Responders: res.Responders.reduce((prev, curr) => {
              if (curr.Response === 'Yes') {
                return [...prev, curr.PersonPublicKey]
              }
              return prev
            }, []),
            Response: res.Responders.some((responder) => responder.Response === 'Yes')
              ? 'Yes'
              : 'No',
          }
        : res
    ),
  }),
  [t.LOAD_ENROLLMENT_SESSION]: (state, payload) => ({
    ...state,
    enrollmentSession: payload,
  }),
  [t.LOAD_PREVIOUS_ENROLLMENT]: (state, payload) => ({
    ...state,
    previousEnrollment: payload,
  }),
  [t.UPDATING_USER]: (state) => ({
    ...state,
    updatingUser: true,
    errors: [],
  }),
  [t.USER_INITIALIZED]: (state) => ({
    ...state,
    userInitialized: true,
    updatingUser: false,
    errors: [],
  }),
  [t.USER_INITIALIZE_ERROR]: (state, payload) => ({
    ...state,
    errors: [...state.errors, payload],
  }),
  [t.SCROLLED_TO_BOTTOM]: (state) => ({
    ...state,
    scrolledToBottom: true,
  }),
  [t.SET_PROVIDER_QUESTION]: (state, payload) => ({
    ...state,
    enrollmentSession: {
      ...state.enrollmentSession,
      ProviderSearchCheck: payload,
    },
  }),
  [t.LOAD_ICHRA_INFORMATION]: (state, payload) => ({
    ...state,
    ichra: payload,
  }),
  [t.SET_USER_LOCATION]: (state, payload) => ({
    ...state,
    location: {
      ...payload,
    },
  }),
  [t.SET_FAMILY_GROUPS]: (state, payload) => ({
    ...state,
    enrollmentSession: {
      ...state.enrollmentSession,
      FamilyGroups: payload,
    },
  }),
  [t.DELETE_FAMILY_GROUPS]: (state) => {
    const { FamilyGroups, ...session } = state.enrollmentSession
    return {
      ...state,
      enrollmentSession: session,
    }
  },
  [t.DELETE_ALL_FROM_PROVIDER]: (state, payload) => ({
    ...state,
    providers: state.providers.filter((provider) => provider.id !== payload.ProviderPublicKey),
  }),
  [t.LOAD_PROVIDERS]: (state, payload) => ({
    ...state,
    providers: payload,
  }),
  [t.REMOVE_PROVIDER]: (state, payload) => {
    const isEmpty =
      state.providers
        .find((provider) => provider.id === payload.ProviderPublicKey)
        .selected.filter((person) => person.id !== payload.PersonPublicKey).length === 0

    const providers = isEmpty
      ? state.providers.filter((provider) => provider.id !== payload.ProviderPublicKey)
      : state.providers.map((provider) => ({
          ...provider,
          selected:
            provider.id === payload.ProviderPublicKey
              ? provider.selected.filter((person) => person.id !== payload.PersonPublicKey)
              : provider.selected,
        }))

    return {
      ...state,
      providers,
    }
  },
  [t.ADD_PROVIDER]: (state, payload) => {
    const providerExists = state.providers.find(
      (provider) => provider.id === payload.ProviderPublicKey
    )

    const providers = providerExists
      ? state.providers.map((provider) => ({
          ...provider,
          selected:
            provider.id === payload.ProviderPublicKey
              ? [...provider.selected, { id: payload.person.Id, name: payload.person.FullName }]
              : provider.selected,
        }))
      : [
          ...state.providers,
          {
            ...payload.Provider,
            selected: [{ id: payload.person.Id, name: payload.person.FullName }],
          },
        ]
    return {
      ...state,
      providers,
    }
  },
})
