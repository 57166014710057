import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid/v4'

import { BundleListItemContainer } from '../../modules/health-bundles/containers'

// BundleListItemContainer
const BundleList = ({ list }) => (
  <div style={{ overflowY: 'scroll', height: '600px' }}>
    {list.map((bundle) => (
      <BundleListItemContainer key={uuid()} bundleId={bundle.BundlePublicKey} />
    ))}
  </div>
)

BundleList.displayName = 'BundleList'
BundleList.propTypes = {
  list: PropTypes.array,
}

BundleList.defaultProps = {
  list: [],
}

export default BundleList
