import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import { Typography } from '@sureco/design-system'
import { Button } from '@surecompanies/core_components'

const NoPlan = ({ onSelectPlan }) => (
  <Container
    rounded
    width="100%"
    height="500px"
    style={{ border: '2px dashed rgb(143, 157, 168)' }}
  >
    <Container textCenter noPadding>
      <Container noPadding margin="30px 0 20px 0">
        <Icon light heartOutline size={2} />
      </Container>
      <Typography size={16} color="#8F9DA8">
        Compare your 3 favorite plans.
      </Typography>
      <Container>
        <Button type="primary" onClick={onSelectPlan}>
          Select
        </Button>
      </Container>
    </Container>
  </Container>
)

NoPlan.propTypes = {
  onSelectPlan: PropTypes.func.isRequired,
}

export default NoPlan
