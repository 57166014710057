import React from 'react'

import Container from '@hixme-ui/container'

import Placeholder from 'components/Placeholder'

const AllowanceLoader = () => (
  <Container flex padding="0 10px" width="100%">
    <Placeholder width="100%" height="60px" />
  </Container>
)

export default AllowanceLoader
