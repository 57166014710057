import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import { ThemeProvider } from '@hixme-ui/theme'
import { Button } from '@hixme-ui/forms'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import { OutlineButton } from '../NewExpenseRequest/styles/style'

const DiscardExpense = ({ onLeave, onStay }) => (
  <ThemeProvider>
    <Container flex padding="10px 0" direction="column">
      <Text danger bold large left>
        Your reimbursement request is not complete.
      </Text>
      <Text fontSize="14px" left>
        All your progress will be lost.
      </Text>
      <Container flex noPadding margin="20px 0">
        <Text bold fontSize="14px">
          Are you sure you want to Cancel and Leave this page?
        </Text>
      </Container>
      <Container flex noPadding margin="20px 0" justifySpaceBetween>
        <OutlineButton onClick={onLeave} style={{ fontSize: '14px', fontWeight: 'normal' }}>
          Leave this page
        </OutlineButton>

        <Button primary onClick={onStay} style={{ fontSize: '14px', fontWeight: 'normal' }}>
          Stay on this page
        </Button>
      </Container>
    </Container>
  </ThemeProvider>
)

DiscardExpense.propTypes = {
  onLeave: PropTypes.func.isRequired,
  onStay: PropTypes.func.isRequired,
}

DiscardExpense.displayName = 'DiscardExpense'

export default DiscardExpense
