import React from 'react'
import { MrModal } from '@hixme/modal'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import { containers } from 'store/modules/person-coverage'

const { PersonCoverageFormContainer } = containers

const GroupHealthCoverageModal = (props) => (
  <MrModal hideCloseButton title="Who would you like to cover?">
    <Container flex alignCenter direction="column" noPadding>
      <Container flex justifyCenter noPadding>
        <Container flex flexBasis="580px" margin="20px 0 40px 0" noPadding>
          <Container noPadding textCenter>
            <Text medium light thin>
              We’ll calculate your group health plan pricing based on who you want to cover. Use the
              checkboxes and click “Save” to make any changes.
            </Text>
          </Container>
        </Container>
      </Container>
      <PersonCoverageFormContainer {...props} />
    </Container>
  </MrModal>
)

GroupHealthCoverageModal.displayName = 'GroupHealthCoverageModal'

export default GroupHealthCoverageModal
