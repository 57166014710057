const newExpensesTexts = {
  familyServices: 'Family member(s) who received services',
  checkboxConditions:
    'I certify that the expenses for which I am requesting reimbursement for meet the following conditions:',
  conditions: [
    'The above expenses were incurred for services or supplies for me and/or my eligible dependents listed above who reside with me in a parent/child relationship or are legally dependent on me for their support.',
    'The above services and/or supplies were furnished to me or my eligible dependents on or after my effective date with the Plan.',
    'I have not been reimbursed for the above expenses, nor have any of my dependents been reimbursed for these expenses.',
    'I understand that any amounts not used for qualified expenses by the end of the Plan Year or Grace Period will be forfeited to my Employer.',
    'I have not and will not itemize and deduct, nor claim credit for these expenses on my income tax returns.',
  ],
  conditionsNote: 'Reimbursement will be made in accordance with the provisions of the Plan.',
}

export default newExpensesTexts
