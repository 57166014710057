import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { clearModal } from '@hixme/modal'

import { selectors as userSessionSelectors } from 'store/modules/user-session'
import { selectors as personSelectors } from 'store/modules/persons'
import { HEALTH_PRODUCTS_GROUP_SPLIT_PATH } from 'routes/path-names'

import SplitPreviewModal from './SplitPreviewModal'

const mapStateToProps = (state) => {
  const bundleGroups = userSessionSelectors.getBundleGroups(state)

  const addRequiredProps = (obj) => {
    const person = personSelectors.getPersonById(obj.Id, state)
    return {
      ...obj,
      Name: person.FullName,
    }
  }

  const bundleGroupsWithProps = bundleGroups.map((group) => ({
    ...group,
    Persons: group.Persons.map((obj) => addRequiredProps(obj)),
  }))

  return {
    groups: bundleGroupsWithProps,
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleContinue: () => dispatch(clearModal()),
  handleEditGroups: () => {
    browserHistory.push(HEALTH_PRODUCTS_GROUP_SPLIT_PATH)
    dispatch(clearModal())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SplitPreviewModal)
