import { createSelector } from 'reselect'

import { NAME } from './constants'

export const getFilesByGroupKey = (groupKey, state) =>
  createSelector(
    () => state[NAME],
    (uploader) => uploader.fileGroups[groupKey]
  )(state)

export const getMessageByGroupKey = (groupKey, state) =>
  createSelector(
    () => state[NAME],
    (uploader) => {
      if (!uploader.messages[groupKey]) return null
      return uploader.messages[groupKey]
    }
  )(state)

export const getIsUploadingByGroupKey = (groupKey, state) =>
  createSelector(
    () => state[NAME],
    (uploader) => {
      if (!uploader.isUploading[groupKey]) return null
      return uploader.isUploading[groupKey]
    }
  )(state)

export const getPendingUploadFilesByGroupKey = (groupKey, state) =>
  createSelector(
    () => state[NAME],
    (uploader) => {
      if (!uploader.fileGroups[groupKey]) return null
      return uploader.fileGroups[groupKey].filter((file) => file.complete === false)
    }
  )(state)

export const getTotalAmountByGroupKey = (groupKey, state) =>
  createSelector(
    () => state[NAME],
    (uploader) => {
      const groups = uploader.fileGroups[groupKey]
      if (!groups) {
        return 0
      }
      return groups.reduce((prev, curr) => {
        const amount = curr.amount ? Number(curr.amount.replace(/[^0-9.]+/g, '')) : 0
        // eslint-disable-next-line no-param-reassign
        prev += Number(amount)
        return prev
      }, 0)
    }
  )(state)
