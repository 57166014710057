import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Card from '@hixme-ui/card'

export const ProductCardOuter = ({ children, heavyShadow, ...restProps }) => (
  <Card margin="20px 0" animate heavyShadow={heavyShadow} {...restProps}>
    {children}
  </Card>
)

ProductCardOuter.displayName = 'ProductCardOuter'
ProductCardOuter.propTypes = {
  children: PropTypes.node.isRequired,
  heavyShadow: PropTypes.bool,
}

ProductCardOuter.defaultProps = {
  heavyShadow: true,
}

export default ProductCardOuter
