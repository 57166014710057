import { ActivityEntity, ActivityCategory } from '@surecompanies/activity-log'
import { isImpersonating } from '../../store/modules/user-session/selectors'
import { logActivity } from './index'
import { debounce } from 'lodash'
import { getActorAndTarget } from './get-actor-and-target'
import { SOURCE } from './constants'

function capitalizeFirstLetter(string) {
  return string
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const logPageView = debounce(async (state) => {
  try {
    const actorAndTarget = getActorAndTarget(state)
    if (!actorAndTarget) {
      console.warn('Activity log disabled for page')
      return
    }
    const { targetName, actorName } = actorAndTarget

    const { referrer } = document;
    const { pathname } = window.location;
    let pageTitle = capitalizeFirstLetter(pathname.split('/').filter((p) => p).join(' - '));
    if( !pageTitle ) {
      pageTitle = 'Home'
    }
    let description =  `${targetName} visited "${pageTitle}"`
    if (isImpersonating(state)) {
      description = `${actorName} visited "${pageTitle}" as ${targetName}`
    }

    const activity: ActivityEntity = {
      source: SOURCE,
      ...actorAndTarget,
      category: ActivityCategory.UserInteraction,
      action: 'Page View',
      timestamp: new Date().toISOString(),
      description,
      additionalData: {
        referrer,
        pageTitle,
        pathname
      }
    }
    return logActivity(activity)
  } catch (e) {
    console.error('Error logging Page View', e)
  }
}, 1100)
