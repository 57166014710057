import React from 'react'
import PropTypes from 'prop-types'

import Container from './style'

const ClearContainer = ({ children, ...rest }) => (
  <Container contentPadding="20px 20px 100px" contentWidth="1024px" {...rest}>
    {children}
  </Container>
)

ClearContainer.propTypes = {
  children: PropTypes.node,
}

ClearContainer.defaultProps = {
  children: null,
}
export default ClearContainer
