import styled from 'styled-components'

const ArrowIcon = styled.div`
  display: inline-flex;
  align-items: center;
  transform: ${(props) => (props.open ? 'rotate(270deg)' : 'rotate(90deg)')};
  transition: transform 0.25s ease;
  width: 10px;
`

ArrowIcon.displayName = 'ChevronRow'

export default ArrowIcon
