export const NAME = 'persons'

// API routes
export const POST_PERSON_GRAPH_ROUTE = 'personGraphPost'

// Waiter names
export const GET_FAMILY_REQUEST = 'persons:getFamily()'

// person constants

export const MEDICARE_AGE = 65
export const CHILD_ELIGIBLE_AGE = 26

export const requiredFields = ['SSN', 'IsDisabled', 'IsMedicareEligible']
