import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// hixme-ui
import Container from '@hixme-ui/container'
import ContentContainer from '@hixme-ui/content-container'
import Title from '@hixme-ui/title'
import Text from '@hixme-ui/text'
import Email from '@hixme-ui/email'
import Button from '@hixme-ui/button'

import LoggedInAsContainer from '../containers/LoggedInAsContainer'
import SessionErrorContainer from '../containers/SessionErrorContainer'

import { logout } from '../actions'

const UserSessionErrorView = ({ userLogout }) => (
  <div>
    <LoggedInAsContainer />
    <ContentContainer>
      <Container>
        <Title primary marginBottom="30px">
          We could not initialize your session!
        </Title>

        <Container>
          <Text large>
            Please email us at <Email address="enrollme@sureco.com" /> to help with your enrollment
            or benefit management experience.
          </Text>
        </Container>

        <SessionErrorContainer />
        <Container textCenter noPadding>
          <Button onClick={userLogout}>Logout</Button>
        </Container>
      </Container>
    </ContentContainer>
  </div>
)

UserSessionErrorView.displayName = 'UserSessionErrorView'
UserSessionErrorView.propTypes = {
  userLogout: PropTypes.func,
}
UserSessionErrorView.defaultProps = {
  userLogout: () => {},
}

export default connect(
  null,
  (dispatch) => ({
    userLogout: () => dispatch(logout()),
  })
)(UserSessionErrorView)
