import { connect } from 'react-redux'

// module
import { getEmployeeKey } from 'store/modules/persons/selectors'
import { getTotalAmountByGroupKey } from 'modules/ultimate-uploader/selectors'
import { actions, selectors } from '../modules'
import UploadForm from '../components/NewExpenseRequest/components/UploadForm'

const mapStateToProps = (state) => {
  const expense = selectors.getSelectedExpense(state)
  const selectedLifeEvent = selectors.getSelectedLifeEvent(state)
  const totalAmount = getTotalAmountByGroupKey(expense.id, state)
  const employeePublicKey = getEmployeeKey(state)
  const coinsurance = (selectedLifeEvent && selectedLifeEvent.coinsurance) || 0
  return {
    expense,
    coinsurance,
    employeePublicKey,
    totalAmount,
    groupKey: expense.id,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onPrevious: () => {
    dispatch(
      actions.updateUi({
        isUploadingFiles: false,
      })
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadForm)
