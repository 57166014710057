import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

const ICHRATooltip = ({ tooltipId }) => (
  <ReactTooltip id={tooltipId} effect="solid">
    <Container flex direction="column" padding="10px" width="350px">
      <Text p>
        With this plan, you may apply your ICHRA Expense Reimbursement allowance to help pay for
        Eligible Expenses like co-payments, prescriptions, health equipment and more.
      </Text>
    </Container>
  </ReactTooltip>
)

ICHRATooltip.propTypes = {
  tooltipId: PropTypes.string.isRequired,
}

ICHRATooltip.defaultProps = {}

export default ICHRATooltip
