/* eslint-disable no-param-reassign */
import moment from 'moment'
import { buildFileName, rename, sanitizeFile } from '../utils/file'

class Expense {
  constructor({
    reimbursementId,
    expenseDate,
    notes,
    amount,
    persons,
    isCertified,
    name,
    userId,
    status,
    notification,
    dateError,
    createdDate,
    updatedDate,
  } = {}) {
    this.id = reimbursementId
    this.date = expenseDate
    this.dateError = dateError || ''
    this.notes = notes
    this.name = name || ''
    this.amount = amount || 0
    this.status = status || 'Draft'
    this.persons = persons || []
    this.notification = notification ? notification.split('\n') : []
    this.isCertified = isCertified
    this.userId = userId
    this.receipts = {}
    this.touched = false
    this.createdDate = createdDate ? moment.utc(createdDate).format('MM/DD/YYYY') : null
    this.updatedDate = updatedDate ? moment.utc(updatedDate).format('MM/DD/YYYY') : null
    // Figure out a way to use Proxy on testing with PhantomJS
    // return new Proxy (this, {
    //   set: (target, prop, receiver) => {
    //     // eslint-disable-next-line no-prototype-builtins
    //     if (target.hasOwnProperty(prop)) {
    //       target[prop] = receiver
    //       target.touched = true
    //       return true
    //     }
    //     return false
    //   },
    // })
  }

  getAsExpenseData() {
    const { userId, notes, date, isCertified, name, id, persons } = this
    return {
      reimbursementId: id,
      employeePublicKey: userId,
      note: notes,
      personPublicKeys: persons,
      name,
      expenseDate: date,
      isCertified,
    }
  }

  setField(field, value) {
    this[field] = value
    this.touched = true
  }

  setId(id) {
    this.setField('id', id)
  }

  setDate(date) {
    this.setField('date', date)
  }

  setStatus(status) {
    this.setField('status', status)
  }

  reset() {
    this.touched = false
  }

  addReceipts(receipts) {
    const receiptsHash = receipts.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.name]: curr,
      }),
      {}
    )
    this.touched = true
    this.receipts = { ...this.receipts, ...receiptsHash }
  }

  clearReceipts() {
    this.receipts = {}
  }

  removeReceipt(name) {
    this.touched = true
    delete this.receipts[name]
  }

  getReceiptsToAdd(receipts) {
    const newReceipts = receipts
      .filter((receipt) => {
        const fileName = buildFileName(receipt)
        return this.receipts[fileName] === undefined
      })
      .map((file) => sanitizeFile(file))
    const existingReceipts = this.getDuplicatedEntries(receipts).map((receipt) => {
      const fileName = buildFileName(receipt, true)
      return rename(receipt, fileName)
    })
    return [...newReceipts, ...existingReceipts]
  }

  getDuplicatedEntries(receipts) {
    return receipts.filter((receipt) => {
      const fileName = buildFileName(receipt)
      return this.receipts[fileName] !== undefined
    })
  }

  canUpdateStatus(status) {
    if (this.isDraft() && status === 'New Request') return true
    if (this.status === 'Need More Info' && status === 'In Progress') {
      return true
    }
    return false
  }

  setIsCertified(isCertified) {
    this.setField('isCertified', isCertified)
  }

  getAsUpdatableData() {
    const { notes, status, date, name } = this
    return {
      note: notes,
      status,
      name,
      expenseDate: date,
    }
  }

  isDraft() {
    return this.status === 'Draft'
  }

  isEmpty() {
    return this.persons.length === 0
  }

  isAbleToEdit() {
    return ['Need More Info', 'Draft'].includes(this.status)
  }

  isViewAvailable() {
    return ['Approved', 'Declined'].includes(this.status)
  }

  isApproved() {
    return this.status === 'Approved'
  }

  hasPerson(personId) {
    return this.persons.some((id) => id === personId)
  }

  togglePerson(personId) {
    const exists = this.persons.includes(personId)
    if (exists) {
      this.persons = this.persons.filter((id) => id !== personId)
    } else {
      this.persons.push(personId)
    }
  }

  getYear() {
    return this.date && this.date.slice(this.date.length - 4)
  }

  toIchraDateFormat() {
    return moment(this.date, 'MM/DD/YYYY').format('YYYY-MM-DD')
  }

  getDateError() {
    if (this.date && !this.isValidDate()) return 'Invalid Date'
    return this.date === '' ? 'Required' : null
  }

  isValidDate() {
    const now = moment()
    const momentDate = moment(this.date, 'MM/DD/YYYY')
    const previousYear = now
      .clone()
      .subtract(1, 'years')
      .startOf('year')
    return (
      momentDate.isSameOrBefore(now) &&
      momentDate.isSameOrAfter(previousYear, 'day') &&
      momentDate.isValid() &&
      !this.date.includes('_')
    )
  }

  isValid() {
    return this.name && this.isCertified && !this.isEmpty() && this.isValidDate()
  }

  wasTouched() {
    return this.touched
  }

  clone() {
    return new Expense({
      ...this,
    })
  }
}

export default Expense
