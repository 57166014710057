import React from 'react'
import { Stack, Typography, Button } from '@sureco/design-system'

export type CTABannerProps = {
  title: string
  description: string
  buttonText: string
  indicatorIcon: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  style?: React.CSSProperties
  indicatorStyle?: React.CSSProperties
  buttonIcon?: React.ReactNode
}

/**
 * Call to action banner
 */
export const CTABanner = ({
  style,
  onClick,
  title,
  description,
  buttonText,
  indicatorStyle,
  indicatorIcon,
  buttonIcon,
}: CTABannerProps) => {
  return (
    <Stack
      role="banner"
      direction="row"
      style={{
        minHeight: 88,
        borderRadius: 6,
        padding: '20px 24px',
        width: '100%',
        background: '#fff',
        alignItems: 'center',
        boxSizing: 'border-box',
        ...style,
      }}
      space={24}
    >
      <Stack
        direction="row"
        style={{
          width: 48,
          height: 48,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 6,
          backgroundColor: '#f5f5f5',
          ...indicatorStyle,
        }}
      >
        {indicatorIcon}
      </Stack>
      <Stack style={{ flex: 1 }}>
        <Typography size={14} bold>
          {title}
        </Typography>
        <Typography color="#595959" size={14}>
          {description}
        </Typography>
      </Stack>
      <Button variant="secondary" onClick={onClick}>
        {buttonIcon}
        <Typography size={14} color={'#262626'}>
          {buttonText}
        </Typography>
      </Button>
    </Stack>
  )
}
