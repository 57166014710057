/* eslint-disable no-param-reassign */
import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Text from '@hixme-ui/text'

// project
import VisionProductCard from 'components/VisionProductCard'

const VisionDetails = ({
  currentProduct,
  isUpdating,
  visionProducts = [],
  toggleVisionProduct,
  coveredPersons,
  excludedPersons,
  isAddingBenefit,
}) => (
  <div>
    {isUpdating && (
      <Text primary p center>
        Retrieving vision products...
      </Text>
    )}
    {visionProducts.map((product) => {
      product.isAddingBenefit =
        currentProduct && currentProduct.BenefitPublicKey === product.BenefitPublicKey
          ? isAddingBenefit
          : false
      product.coveredPersons = coveredPersons
      product.excludedPersons = excludedPersons
      product.added = currentProduct && currentProduct.BenefitPublicKey === product.BenefitPublicKey
      product.productOnClick = toggleVisionProduct

      return <VisionProductCard key={`${product.BenefitPublicKey}`} {...product} />
    })}
  </div>
)

VisionDetails.propTypes = {
  currentProduct: PropTypes.object,
  isUpdating: PropTypes.bool.isRequired,
  visionProducts: PropTypes.array.isRequired,
  toggleVisionProduct: PropTypes.func.isRequired,
  coveredPersons: PropTypes.array.isRequired,
  excludedPersons: PropTypes.array.isRequired,
  isAddingBenefit: PropTypes.bool,
}

VisionDetails.defaultProps = {
  currentProduct: {},
  isAddingBenefit: false,
}

export default VisionDetails
