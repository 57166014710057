import { namespaceActions } from 'modules/redux-utils'

export const NAME = 'modal'

const SHOW_MODAL = 'SHOW_MODAL'
const HIDE_MODAL = 'HIDE_MODAL'
const SET_MODAL_VIEW = 'SET_MODAL_VIEW'
const SET_MODAL_DATA = 'SET_MODAL_DATA'
const CLEAR_MODAL_VIEW = 'CLEAR_MODAL_VIEW'

export default namespaceActions(NAME)([
  SHOW_MODAL,
  HIDE_MODAL,
  SET_MODAL_VIEW,
  SET_MODAL_DATA,
  CLEAR_MODAL_VIEW,
])
