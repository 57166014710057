import React from 'react'
import { MrModal } from '@hixme/modal'

// hixme-ui
import Separator from '@hixme-ui/separator'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'

const DCFSAInformation = () => {
  const textDefaultStyles = {
    p: true,
    light: true,
    thin: true,
    medium: true,
  }

  return (
    <MrModal medium contentStart contentPadding="40px 60px">
      <Title small>Important information about your DCFSA</Title>
      <Separator light />
      <Text {...textDefaultStyles} style={{ margin: '2em 0 1em' }}>
        {'1)'} There are limits to how much can be saved in one year. The maximum limit allowed by
        the IRS for 2019 is $5,000.
      </Text>
      <Text {...textDefaultStyles}>
        {'2)'} The contributions made by employees are pre-tax, so you don&apos;t pay taxes on this
        money. This means you&apos;ll save an amount equal to the taxes you would have paid on the
        money you set aside. Each employee elects how much to contribute per pay period.
      </Text>
      <Text {...textDefaultStyles}>
        {'3)'} You may only file claims when you have a balance that will cover the amount of the
        claim.
      </Text>
      <Text p light bold medium>
        Eligibility rules:
      </Text>
      <Text {...textDefaultStyles}>Your qualified dependents for a DCFSA may include:</Text>
      <Text {...textDefaultStyles}>
        {'1)'} Your child(ren) under age 13.
        <br />
        {'2)'} Qualified dependents of any age that are mentally or physically incapable of
        self-care.
      </Text>
      <Text p light bold medium>
        Expenses for care of a qualified dependent:
      </Text>
      <Text {...textDefaultStyles}>
        {'1)'} Fees for licensed day care or adult care facilities
        <br />
        {'2)'} Amounts paid for services (including babysitters or nursery school) in or outside of
        your home.
        <br />
        {'3)'} Placement fees for a dependent care provider, such as an au pair.
        <br />
        {'4)'} Summer day camp for children
        <br />
        {'5)'} Before and after school care programs for dependents under age 13.
        <br />
        {'6)'} Payment to a relative (age 19 or older who is not your dependent) who cares for your
        qualified dependent
        <br />
        {'7)'} Payment to a housekeeper whose duties also include dependent day care.
      </Text>
      <Text p light bold medium>
        Expenses not covered:
      </Text>
      <Text {...textDefaultStyles}>
        {'1)'} Baby-sitter in or out of your home for reasons other than to enable you to work
        <br />
        {'2)'} Food, clothing, and entertainment
        <br />
        {'3)'} Child support payments
        <br />
        {'4)'} Activity fees and educational supplies
        <br />
        {'5)'} Overnight camp
        <br />
        {'6)'} Cleaning and cooking services not provided by a caregiver
      </Text>
    </MrModal>
  )
}

DCFSAInformation.displayName = 'DCFSAInformation'

export default DCFSAInformation
