import { getUser, isImpersonating } from '../../store/modules/user-session/selectors'
import { ActivityActor, ActivityEntity } from '@surecompanies/activity-log'
import { selectors as authSelectors } from '@hixme/auth-ui'

export type ActivityActorAndTarget = Pick<ActivityEntity, 'actorId' | 'actorName'| 'targetId' | 'targetName' | 'actorRole' | 'targetRole' | 'actorType' | 'targetType' | 'actorClientPublicKey' | 'targetClientPublicKey'>

// get actor and target values for ActivityLog
export const getActorAndTarget = (state: any, fallback?: ActivityActorAndTarget): ActivityActorAndTarget | undefined => {
  const target = getUser(state)
  if (!target?.Id) {
    return fallback
  }
  const { Id: targetId, FullName: targetName, UserRole: targetRole, ClientPublicKey: targetClientPublicKey } = target
  if (isImpersonating(state)) {
    const creds = authSelectors.getCredentials(state)
    const { personPublicKey: actorId, firstname, lastname, userRole: actorRole } = creds
    return {
      actorId,
      actorName: `${firstname} ${lastname}`,
      actorType: ActivityActor.User,
      actorRole,
      actorClientPublicKey: undefined,
      targetClientPublicKey,
      targetType: ActivityActor.User,
      targetId,
      targetName,
      targetRole,
    }
  }
  return {
    actorId: targetId,
    actorName: targetName,
    actorType: ActivityActor.User,
    actorRole: targetRole,
    actorClientPublicKey: targetClientPublicKey,
    targetClientPublicKey,
    targetType: ActivityActor.User,
    targetId,
    targetName,
    targetRole,
  }
}
