import * as _actions from './actions'
import _actionTypes from './actionTypes'
import * as _constants from './constants'
import * as _selectors from './selectors'
import _reducer from './reducer'

export const actions = _actions
export const actionTypes = _actionTypes
export const constants = _constants
export const selectors = _selectors
export const reducer = _reducer

export default { actions, actionTypes, constants, selectors, reducer }
