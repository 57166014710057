import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

const HSAHDHPTooltip = ({ tooltipId }) => (
  <ReactTooltip id={tooltipId} effect="solid" place="right">
    <Container flex direction="column" padding="10px" width="350px">
      <Text primary large thin>
        What is an <Text bold>HSA / HDHP</Text>?
      </Text>
      <Text p>
        In a Health Savings Account (HSA) or a High Deductible Health Plan (HDHP) the coverage has a
        large deductible with low premiums.
      </Text>
      <Text p>Until the deductible is met, you are responsible for all eligible expenses.</Text>
      <Text p>
        Certain preventive care and wellness services are covered at 100% with no deductible
        requirement.
      </Text>
      <Text p>
        You can use the funds you have contributed to the HSA to help pay for qualified healthcare
        expenses.
      </Text>
      <Text p>
        A designated HSA plan can be an HMO or EPO/PPO plan depending on the health plan.
      </Text>
    </Container>
  </ReactTooltip>
)

HSAHDHPTooltip.propTypes = {
  tooltipId: PropTypes.string.isRequired,
}

HSAHDHPTooltip.defaultProps = {}

export default HSAHDHPTooltip
