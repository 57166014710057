import { namespaceActions } from 'modules/redux-utils'
import { NAME } from './constants'

// Employee constants
const LOAD_EMPLOYEE = 'LOAD_EMPLOYEE'
const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'

// Dependent Constants
const ADD_DEPENDENT = 'ADD_DEPENDENT'
const DELETE_DEPENDENT_BYINDEX = 'DELETE_DEPENDENT_BYINDEX'
const LOAD_DEPENDENTS_LIST = 'LOAD_DEPENDENTS_LIST'
const SET_ERROR = 'SET_ERROR'
const UPDATE_DEPENDENT_BYINDEX = 'UPDATE_DEPENDENT_BYINDEX'

const LOAD_PENDING_EDIT = 'LOAD_PENDING_EDIT'
const COMPLETE_PERSON_INFORMATION = 'COMPLETE_PERSON_INFORMATION'

export default namespaceActions(NAME)([
  LOAD_EMPLOYEE,
  UPDATE_EMPLOYEE,
  LOAD_PENDING_EDIT,
  ADD_DEPENDENT,
  DELETE_DEPENDENT_BYINDEX,
  LOAD_DEPENDENTS_LIST,
  SET_ERROR,
  UPDATE_DEPENDENT_BYINDEX,
  COMPLETE_PERSON_INFORMATION,
])
