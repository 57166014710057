import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

const HMOTooltip = ({ tooltipId }) => (
  <ReactTooltip id={tooltipId} effect="solid" place="right">
    <Container flex direction="column" padding="10px" width="350px">
      <Text primary large thin>
        What is an <Text bold>HMO</Text>?
      </Text>
      <Text p>
        With a Health Maintenance Organization (HMO), your insurance only pays for services provided
        by doctors, clinics and hospitals within the HMO network.
      </Text>
      <Text p>
        You are required to choose a Primary Care Physician (PCP) who acts as your
        &quot;gatekeeper&quot; who coordinates your healthcare and refers you to specialists within
        the HMO network.
      </Text>
      <Text p>There are no out-of-network benefits (expect for emergencies).</Text>
    </Container>
  </ReactTooltip>
)

HMOTooltip.propTypes = {
  tooltipId: PropTypes.string.isRequired,
}

HMOTooltip.defaultProps = {}

export default HMOTooltip
