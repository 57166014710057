import {
  PERSON_RELATIONSHIP_EMPLOYEE,
  PERSON_RELATIONSHIP_SPOUSE,
  PERSON_RELATIONSHIP_CHILD,
} from '@hixme/person-relationships'

export const RelationshipSortOrder = {
  [PERSON_RELATIONSHIP_EMPLOYEE.toUpperCase()]: 100,
  [PERSON_RELATIONSHIP_SPOUSE.toUpperCase()]: 90,
  DOMESTIC_PARTNER: 80,
  [PERSON_RELATIONSHIP_CHILD.toUpperCase()]: 70,
  CHILD_OF_DOMESTIC_PARTNER: 60,
}

export const PERSON_RELATIONSHIP_CHILD_OF_DOMESTIC_PARTNER = 'CHILD_OF_DOMESTIC_PARTNER'

export const CHILD_NOTICE = `The information you have provided results in 2 or more children selected to be in a group
          of their own. Although they are being displayed as if you will be shopping for a single
          health plan to cover them, common practice among health insurance carriers is to split
          each child into their own individual health plan if they are not grouped as a dependent
          under a parent. Please keep in mind that this is a likely and necessary change which will
          result in duplicated deductibles and out-of-pocket maximums for each child.`
