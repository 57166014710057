import React from 'react'
import { connect } from 'react-redux'

import * as actions from 'modules/notification-manager/actions'
import Notify, { type NotifyProps } from 'components/Notify'

import { NAME } from '../../constants'
import classes from './NotificationManager.scss'

export type NotifyData = {
  Id?: string | number
} & NotifyProps

export type NotificationManagerProps = {
  hideNotifications?: () => void | null
  notifications?: NotifyData[]
  showNotifications?: boolean
}

const NotificationManager = ({
  hideNotifications,
  notifications,
  showNotifications,
}: NotificationManagerProps) => (
  <div className={showNotifications ? classes.opened : classes.closed}>
    {notifications.map((notification) => (
      <Notify
        key={notification.Id}
        message={notification.message}
        type={notification.type}
        onClose={hideNotifications}
        autoClose={notification.autoClose}
        timeToClose={notification.timeToClose}
      />
    ))}
  </div>
)

const mapStateToProps = (state) => state[NAME]
export default connect(mapStateToProps, actions)(NotificationManager)
