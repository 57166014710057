import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

const EPOTooltip = ({ tooltipId }) => (
  <ReactTooltip id={tooltipId} effect="solid" place="right">
    <Container flex direction="column" padding="10px" width="350px">
      <Text primary large thin>
        What is an <Text bold>EPO</Text>?
      </Text>
      <Text p>
        In an Exclusive Provider Organization (EPO) you can see any doctor, specialist or hospital
        within your provider network without a referral. (Certain services will require
        pre-authorization and referrals depending on carrier).
      </Text>
      <Text p>
        EPO plans do not cover out-of-network services unless it is a medical emergency.
      </Text>
    </Container>
  </ReactTooltip>
)

EPOTooltip.propTypes = {
  tooltipId: PropTypes.string.isRequired,
}

EPOTooltip.defaultProps = {}

export default EPOTooltip
