import React from 'react'
import PropTypes from 'prop-types'
import HappyTip from 'components/HappyTip'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import barBalanced from '../../assets/bar-balanced.png'
import BalancedMiniWheelPng from '../../../assets/workerBalanced-mini.png'
import SharedText from '../SharedText'

const ActuarialBalancedTooltip = ({ tooltipId }) => (
  <HappyTip id={tooltipId} type="light" effect="solid" globalEventOff="click">
    <Container
      flex
      width="350px"
      justifyContent="center"
      padding="10px"
      background="white"
      direction="column"
    >
      <div>
        <img alt="" src={barBalanced} />
      </div>
      <Text p thin>
        We start with <Text bold>a richer base plan</Text> and Hixme wraps insurance and financial
        benefits around it to <Text bold>increase the actuarial value score</Text> in order to{' '}
        <Text bold>reduce your out of pocket costs</Text> if you need the benefits.
      </Text>

      <Container flex noPadding margin="0 0 20px 0" justifyContent="flex-start">
        <img alt="" src={BalancedMiniWheelPng} />
        <Text p style={{ marginLeft: '10px' }}>
          <Text bold>Higher peace of mind</Text> with <Text bold>fewer steps</Text> to trigger
          additional Hixme coverage if needed.
        </Text>
      </Container>

      <Container flex noPadding justifyContent="center">
        <SharedText />
      </Container>
    </Container>
  </HappyTip>
)

ActuarialBalancedTooltip.propTypes = {
  tooltipId: PropTypes.string.isRequired,
}

ActuarialBalancedTooltip.defaultProps = {}

export default ActuarialBalancedTooltip
