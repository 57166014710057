import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'

const GroupValue = ({ value }) => (
  <React.Fragment>
    <span style={{ marginLeft: '20px', marginTop: '5px' }}>
      <Icon group light fontSize={24} />
    </span>
    <Text light medium upperCase style={{ marginLeft: '10px', fontWeight: 500 }}>
      {value.name}
    </Text>
    <Text medium primary style={{ marginLeft: '10px', fontWeight: 500, flex: 2 }}>
      {value.members}
    </Text>
  </React.Fragment>
)

GroupValue.displayName = 'GroupValue'

GroupValue.propTypes = {
  value: PropTypes.object.isRequired,
}

export default GroupValue
