/* eslint-disable global-require, no-underscore-dangle, no-param-reassign */
import React from 'react'
import { createRoot } from 'react-dom/client'
import axios from 'axios'
import { ApolloProvider } from '@apollo/client'
import { config as configureApiBuilder } from '@hixme/api'
import * as Sentry from '@sentry/react'
import { datadogRum } from '@datadog/browser-rum';

// project
import client from 'apollo/client'
import appConfig from 'modules/app-config'
import analytics from 'modules/analytics'
import createStore from 'store/createStore'
import AppContainer from 'containers/AppContainer'
import { selectors, config as authConfig, restoreLoginState, refreshAuth } from '@hixme/auth-ui'
import 'styles/core.scss'
import { initializeLDClient } from 'helpers/launchDarklyClient'

analytics.initialize({ ga: 'UA-58480436-2' })

const stage = appConfig.getStage()

// auth setup
authConfig({
  stage, // auth module follows stage of app
  refreshMilliseconds: 15 * 60 * 1000, // 45 minutes
})

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllInputs: true,
      maskAllText: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: stage === 'prod' ? 0.1 : 1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: stage === 'prod' ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event, _hint) => {
    // Disable Sentry for localhost
    if (window.location.href.indexOf('localhost') > -1) {
      return null
    }

    // Disable Sentry based on url
    const disableSentryForPaths = []
    if (disableSentryForPaths.indexOf(window.location.pathname) > -1) {
      return null
    }

    return event
  },
  beforeSendTransaction: (transaction, _hint) => {
    // Disable Sentry for localhost
    if (window.location.href.indexOf('localhost') > -1) {
      return null
    }

    return transaction
  },
  environment: `${process.env.ENV}-${stage}`, // looks like process.env.ENV is the dev/qa/prod UI env, stage is API env
})

datadogRum.init({
  applicationId: process.env.DATADOG_APP_ID,
  clientToken: process.env.DATADOG_CLIENT_TOKEN,
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.com',
  service: 'enrollment-platform-web-client',
  env: process.env.ENV,
  // version: '1.0.0', 
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.__INITIAL_STATE__
let store = {}
const getAuthToken = () => ({
  Authorization: selectors.getToken(store.getState()),
})
const api = configureApiBuilder({ stage, getAuthToken })
store = createStore(initialState, { api })

// Restore and refresh the user's auth status
store.dispatch(restoreLoginState())
store.dispatch(refreshAuth())

// Listen to each Amazon API Gateway request
// axios is the http module used inside of the sdk
axios.interceptors.request.use(
  (config) => {
    // Add Authorization header to each API Gateway request
    const Authorization = selectors.getCredentials(store.getState() || {}).idToken
    config.headers.Authorization = Authorization || ''
    return config
  },
  (error) => Promise.reject(error)
)

// ========================================================
// Developer Tools Setup
// ========================================================
// if (__DEBUG__) {
//   if (window.devToolsExtension) {
//     window.devToolsExtension.open()
//   }
// }

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')
const root = createRoot(MOUNT_NODE)
let render = async () => {
  const routes = require('./routes/index').default(store)

  // initialize LD Client for no react hook LD calls
  // I.E. in mapDispatchToProps etc
  try {
    await initializeLDClient()
  } catch (e) {
    console.error(e)
  }

  root.render(
    <ApolloProvider client={client}>
      <AppContainer store={store} routes={routes} />
    </ApolloProvider>
  )
}

// This code is excluded from production bundle
if (__DEV__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render
    const renderError = (error) => {
      const RedBox = require('redbox-react').default

      root.render(<RedBox error={error} />)
    }

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp()
      } catch (error) {
        renderError(error)
      }
    }

    // Setup hot module replacement
    module.hot.accept('./routes/index', () => {
      setTimeout(() => {
        root.unmount()
        render()
      })
    })
  }
}

// ========================================================
// Go!
// ========================================================
render()
