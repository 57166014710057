import React from 'react'
import PropTypes from 'prop-types'
import Text from '@hixme-ui/text'

const FieldValue = ({ children }) => <Text bold>{children}</Text>

FieldValue.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}
FieldValue.defaultProps = {
  children: '',
}
FieldValue.displayName = 'FieldValue'

export default FieldValue
