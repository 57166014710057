import { connect } from 'react-redux'
import { clearModal } from '@hixme/modal'
import { getWaiter } from 'redux-waiter'

// route modules
import DentalCoverageModal from '../components/DentalCoverageModal'
import { actions, constants, selectors } from '../modules/dental-coverage'

const mapStateToProps = (state) => {
  const persons = selectors.getAllPersons(state)
  const fields = persons.map((p) => p.Id)

  const initialValues = {}
  selectors.getSelectedPersons(state).forEach((person) => {
    initialValues[person.Id] = true
  })

  return {
    isProcessing: getWaiter(state, constants.GET_DENTAL_PLANS).isPending,
    persons,
    fields,
    initialValues,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onCancelClick: () => dispatch(clearModal()),
  onSubmit: (values) =>
    new Promise((resolve, reject) => {
      const selectedIds = Object.keys(values).reduce((prev, curr) => {
        if (values[curr]) prev.push(curr)
        return prev
      }, [])
      dispatch(actions.setSelectedPersons(selectedIds))
      dispatch(actions.updateDentalPlansWithCart()).then(
        () => {
          dispatch(clearModal())
          resolve()
        },
        () => {
          reject()
        }
      )
    }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DentalCoverageModal)
