import * as _constants from './constants'
import * as _selectors from './selectors'
import * as _actions from './actions'
import _reducer from './reducer'

export const constants = _constants
export const selectors = _selectors
export const actions = _actions
export const reducer = _reducer

export default {
  constants,
  selectors,
  actions,
  reducer,
}
