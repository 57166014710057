import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

import { Input, Label } from '@hixme-ui/forms'
import Text from '@hixme-ui/text'

import { PureFieldComponent } from './'

const DefaultComponent = (props) => {
  const {
    divProps,
    children,
    label,
    meta: { touched, error },
    altError,
    preError,
    noError,
    input,
    ...restProps
  } = props

  const { onBlur, onChange, onFocus, onDrop, onDragStart, ...restInput } = input
  return (
    <div {...divProps}>
      {label && label instanceof String ? <Label>{label}</Label> : label}
      {Children.count(children) > 1 ? Children.toArray(children) : children}
      <PureFieldComponent
        input={restInput}
        onChange={input.value !== undefined && onChange}
        {...restProps}
      />
      {(!noError &&
        ((altError && altError({ touched, error })) ||
          (touched && error && (
            <Text div error>
              {error}
            </Text>
          )))) ||
        (preError && preError())}
    </div>
  )
}

const FieldComponent = ({ component, inputComponent, ...props }) => (
  <Field name={props.name} component={component} InputComponent={inputComponent} {...props} />
)

FieldComponent.displayName = 'FieldComponent'
FieldComponent.propTypes = {
  inputComponent: PropTypes.func,
  component: PropTypes.func,
  name: PropTypes.string,
}

DefaultComponent.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  meta: PropTypes.object,
  divProps: PropTypes.object,
  preError: PropTypes.func,
  altError: PropTypes.func,
  noError: PropTypes.bool,
  input: PropTypes.object,
}

FieldComponent.defaultProps = {
  inputComponent: Input,
  component: DefaultComponent,
  name: '',
}

DefaultComponent.defaultProps = {
  label: '',
  children: '',
  meta: {},
  divProps: {},
  preError: null,
  altError: null,
  noError: null,
  input: {},
}

export default FieldComponent
