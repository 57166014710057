import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Title from '@hixme-ui/title'
import { Checkbox } from '@hixme-ui/forms'
import { Row, Col } from 'react-flexbox-grid'
import Container from '@hixme-ui/container'

const propTypes = {
  question: PropTypes.object,
  persons: PropTypes.array,
  yesPersons: PropTypes.array,
  toggleSelectPerson: PropTypes.func.isRequired,
}

const defaultProps = {
  question: { header: {} },
  persons: [],
  yesPersons: [],
}

const EditQuestion = ({ question, persons, yesPersons, toggleSelectPerson }) => (
  <Container background="#F8FAFC" margin="2em 0">
    <Title smallest center margin="0 0 1em">
      {question.header.question}
    </Title>
    <Title smallest center muted margin="0 0 1em">
      To which person(s) does this apply?
    </Title>
    <Row>
      {persons.map((p) => (
        <Col xs={4} key={p.Id}>
          <div style={{ margin: '0.5em 0' }}>
            <Checkbox
              text={`${p.FirstName} ${p.LastName}`}
              value={p.Id}
              checked={yesPersons.includes(p.Id)}
              onChange={toggleSelectPerson}
            />
          </div>
        </Col>
      ))}
    </Row>
  </Container>
)

EditQuestion.propTypes = propTypes

EditQuestion.defaultProps = defaultProps

export default EditQuestion
