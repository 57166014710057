import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'
import isEmpty from 'lodash/isEmpty'

import { clearModal } from '@hixme/modal'

// module
import { getFilesByGroupKey, getIsUploadingByGroupKey } from 'modules/ultimate-uploader/selectors'
import { getEmployeeKey } from 'store/modules/persons/selectors'
import { getClientId } from 'store/modules/user-session/selectors'
import { actions, selectors, constants } from '../modules'
import NewExpenseRequest from '../components/NewExpenseRequest/NewExpenseRequest'
import ExpenseFactory from '../models/ExpenseFactory'
import {
  getAllowanceMessage,
  getExpenseRequestsByEmployeeKeyAndUserId,
  updateSelectedExpenseData,
  updateUi,
} from '../modules/actions'
import { clearFiles } from '../../../modules/ultimate-uploader/actions'
import { createSuccessNotification } from '../../../modules/notification-manager/actions'
import { GET_EXPENSE_REQUEST_VALIDATE } from '../modules/constants'

const mapStateToProps = (state) => {
  const employeePublicKey = getEmployeeKey(state)
  const clientId = getClientId(state)
  const {
    selectedExpense,
    selectedLifeEvent,
    expenseId,
    family,
    ui: uiConfig,
  } = selectors.getExpenseReimbursementData(state)
  const isUploading = getIsUploadingByGroupKey(selectedExpense.id, state)
  const files = getFilesByGroupKey(selectedExpense.id, state)
  const hasUploadedFiles = files && files.some((file) => file.id && file.complete)
  const isCreatingOrUpdating = getWaiter(state, constants.CREATE_REIMBURSEMENT).isPending
  const familyNames = family.map((member) => ({
    id: member.Id,
    name: `${member.FirstName} ${member.LastName}`,
  }))
  const isLoading = getWaiter(
    state,
    `${GET_EXPENSE_REQUEST_VALIDATE}_${selectedExpense.toIchraDateFormat()}`
  ).isPending

  return {
    files,
    familyNames,
    lifeEvent: selectedLifeEvent,
    isCreatingOrUpdating,
    isUploading,
    expenseId,
    clientId,
    employeePublicKey,
    isUploadingFiles: uiConfig && uiConfig.isUploadingFiles,
    selectedExpense,
    isEditing: !!(selectedExpense && selectedExpense.id),
    hasUploadedFiles,
    allowanceInformation: uiConfig.allowanceInformation,
    isLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closeModal: async (clientId, employeePublicKey, expense) => {
    if (!clientId) {
      dispatch(clearModal())
      return
    }
    try {
      dispatch(updateSelectedExpenseData(ExpenseFactory.createWithId({}, employeePublicKey)))
      dispatch(
        updateUi({
          isUploadingFiles: false,
        })
      )
      if (!isEmpty(expense.receipts)) {
        dispatch(clearFiles(expense.id))
      }
      if (expense.id) {
        await dispatch(actions.deleteBenefitExpense(expense.id))
        dispatch(getExpenseRequestsByEmployeeKeyAndUserId(clientId, employeePublicKey))
      }
      dispatch(clearModal())
    } catch (err) {
      dispatch(clearModal())
    }
  },
  createOrUpdate: ({ expense, clientId }) => {
    const params = {
      clientId,
      reimbursementId: expense.id,
      employeeId: expense.userId,
    }
    if (expense.id) {
      if (expense.wasTouched()) {
        dispatch(actions.updateBenefitExpense(expense.getAsUpdatableData(), params))
        return
      }
      if (expense.isDraft() || expense.status === 'Need More Info') {
        dispatch(
          updateUi({
            isUploadingFiles: true,
          })
        )
      } else {
        dispatch(clearModal())
        dispatch(updateSelectedExpenseData(ExpenseFactory.createWithId({}, expense.userId)))
        dispatch(
          updateUi({
            isUploadingFiles: false,
          })
        )
      }
    } else {
      dispatch(actions.createBenefitExpense(expense))
    }
  },
  onAgreementChange: (e, expense) => {
    const isChecked = e.target.checked
    expense.setIsCertified(isChecked)
    dispatch(updateSelectedExpenseData(expense))
  },
  onFamilyMemberChange: (personId, expense) => {
    expense.togglePerson(personId)
    dispatch(updateSelectedExpenseData(expense))
  },
  onFieldChange: ({ target }, expense) => {
    const { name, value, maxLength } = target
    const fieldValue = maxLength > 0 ? value.slice(0, maxLength) : value
    expense.setField(name, fieldValue)
    expense.setField('dateError', '')
    dispatch(updateSelectedExpenseData(expense))
  },
  handleDone: async (expense, clientId) => {
    expense.setStatus(expense.isDraft() ? 'NewRequest' : 'InProgress')
    dispatch(updateSelectedExpenseData(expense))
    await dispatch(
      actions.updateBenefitExpense(expense.getAsUpdatableData(), {
        clientId,
        reimbursementId: expense.id,
        employeeId: expense.userId,
      })
    )
    dispatch(clearModal())
    dispatch(
      updateUi({
        isUploadingFiles: false,
      })
    )
    dispatch(createSuccessNotification('Your expense request was successfully created'))
    dispatch(getExpenseRequestsByEmployeeKeyAndUserId(clientId, expense.userId))
  },
  setDateError: (expense, errorMessage) => {
    expense.setField('dateError', errorMessage)
    dispatch(updateSelectedExpenseData(expense))
  },

  handleBlur: async ({ expense, setDateError }) => {
    setDateError(expense, expense.getDateError())
    if (expense.isValidDate()) {
      dispatch(getAllowanceMessage(expense.toIchraDateFormat()))
    }
  },
  handleFocus: (expense, setDateError) => {
    setDateError(expense, '')
    dispatch(
      updateUi({
        allowanceInformation: null,
      })
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewExpenseRequest)
