/* eslint-disable */
// TODO: Lint cleanup. If you are editing this file, it is your duty to fix all linting errors.
import { connect } from 'react-redux'
import { logout, selectors as authSelectors } from '@hixme/auth-ui'
import { ROLE_PLATFORM_HIXME_ADMIN, ROLE_BROKER } from '@hixme/role-policy'
import * as personSelectors from 'store/modules/persons/selectors'
import SideNav from 'components/SideNav'

const mapStateToProps = (state) => {
  const { userRole } = authSelectors.getCredentials(state)
  const isHixmeAdmin = [ROLE_PLATFORM_HIXME_ADMIN, ROLE_BROKER].indexOf(userRole) > -1

  return {
    person: personSelectors.getPersonSelector(state),
    isAdmin: isHixmeAdmin,
  }
}

export default connect(
  mapStateToProps,
  (dispatch) => ({
    // TODO: Make the logout better with a notifcation and redirect. Currently @hixme/auth-ui
    //       automatically redirects to /login on logout. May need to change that
    logout: (e) => {
      e.preventDefault()
      dispatch(logout())
      // dispatch(notification.actions.createNotification({
      //   message: 'Logging out...',
      //   autoClose: true
      // }))

      // hard refresh to remove redux data. slight delay for caution
      setTimeout(() => {
        window.location = '/login'
      }, 10)
    },
  })
)(SideNav)
