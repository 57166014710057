import { namespaceActions } from 'modules/redux-utils'
import { NAME } from './constants'

const ADD_FAVORITE = 'ADD_FAVORITE'
const BUNDLE_ADDED = 'BUNDLE_ADDED'
const BUNDLE_REMOVED = 'BUNDLE_REMOVED'
const LOAD_LIST = 'LOAD_LIST'
const LOAD_GROUPS = 'LOAD_GROUPS'
const NEXT_BUNDLE = 'NEXT_BUNDLE'
const NEXT_GROUP = 'NEXT_GROUP'
const PREVIOUS_BUNDLE = 'PREVIOUS_BUNDLE'
const PREVIOUS_GROUP = 'PREVIOUS_GROUP'
const REMOVE_FAVORITE = 'REMOVE_FAVORITE'
const REMOVE_USER_FAVORITES = 'REMOVE_USER_FAVORITES'
const RESET_FILTERS = 'RESET_FILTERS'
const SET_AS_ADDED_BY_GROUP = 'SET_AS_ADDED_BY_GROUP'
const SET_GROUP_INDEX = 'SET_GROUP_INDEX'
const SET_GROUP_ID = 'SET_GROUP_ID'
const SET_GROUPS_RENEWAL_ELIGIBILITY = 'SET_GROUPS_RENEWAL_ELIGIBILITY'
const SET_PREVIOUS_BUNDLE_FOR_GROUP = 'SET_PREVIOUS_BUNDLE_FOR_GROUP'
const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE'
const TOGGLE_FILTER = 'TOGGLE_FILTER'
const UNLOAD_LIST = 'UNLOAD_LIST'
const UPDATE_BUNDLES = 'UPDATE_BUNDLES'
const UPDATE_MAX_COST_FILTER = 'UPDATE_MAX_COST_FILTER'
const UPDATE_MATCH_BUNDLE = 'UPDATE_MATCH_BUNDLE'
const UPDATE_MATCH_BUNDLE_BY_HIOS = 'UPDATE_MATCH_BUNDLE_BY_HIOS'
const UPDATE_SELECTED_PROVIDERS = 'UPDATE_SELECTED_PROVIDERS'
const UPDATE_VALUE_SCORE = 'UPDATE_VALUE_SCORE'

export default namespaceActions(NAME)([
  ADD_FAVORITE,
  BUNDLE_ADDED,
  BUNDLE_REMOVED,
  LOAD_LIST,
  LOAD_GROUPS,
  NEXT_BUNDLE,
  NEXT_GROUP,
  PREVIOUS_BUNDLE,
  PREVIOUS_GROUP,
  REMOVE_FAVORITE,
  REMOVE_USER_FAVORITES,
  RESET_FILTERS,
  SET_AS_ADDED_BY_GROUP,
  SET_GROUP_INDEX,
  SET_GROUP_ID,
  SET_PREVIOUS_BUNDLE_FOR_GROUP,
  TOGGLE_FAVORITE,
  TOGGLE_FILTER,
  UNLOAD_LIST,
  UPDATE_BUNDLES,
  UPDATE_MATCH_BUNDLE,
  UPDATE_MATCH_BUNDLE_BY_HIOS,
  UPDATE_MAX_COST_FILTER,
  UPDATE_SELECTED_PROVIDERS,
  UPDATE_VALUE_SCORE,
  SET_GROUPS_RENEWAL_ELIGIBILITY,
])
