import { connect } from 'react-redux'
import { setModal } from '@hixme/modal'

import { BUNDLE_COMPARE } from 'modal-constants'

import { selectors } from '../modules/health-bundles'
import CompareNav from '../components/CompareNav/CompareNav'
import { datadog } from 'helpers/datadog'

const mapStateToProps = (state) => ({
  groupFavoriteCount: selectors.getCurrentGroupFavoriteCount(state),
})

const mapDispatchToProps = (dispatch) => ({
  compare: () => {
    datadog.trackViewCompareBundles()
    dispatch(setModal(BUNDLE_COMPARE))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompareNav)
