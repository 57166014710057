import React from 'react'
import PropTypes from 'prop-types'

import ReactTooltip from 'react-tooltip'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import { getBenefitDisplayValue } from '../utils/accordion'
import FootNotes from '../../FootNotes/FootNotes'

import classes from '../Accordion.scss'

const GeneralBundleAccordionBody = ({
  list,
  label,
  healthPlanId,
  showProofOfResidenceRequireMessage,
  notes,
}) => (
  <div className={classes.itemContent}>
    {list.length === 0 && (
      <Text center red>
        There was a problem showing {label} information
      </Text>
    )}
    {list.length > 0 && (
      <div>
        {list.map((benefit, idx) => (
          <Container
            flex
            noPadding
            justifyContent="space-between"
            key={`${label}-benefit-${benefit.BenefitDisplayName || idx}`}
          >
            <Container flex noPadding>
              <Text thin style={{ fontSize: '13px' }}>
                {benefit.BenefitDisplayName || 'BenefitDisplayName'}
              </Text>
            </Container>
            <Container flex noPadding>
              {benefit.DisplayValueMessage && benefit.DisplayValueMessage.length > 0 && (
                <ReactTooltip
                  id={`${benefit.BenefitDisplayName}${healthPlanId}`}
                  place="bottom"
                  effect="solid"
                >
                  <Text small>{benefit.DisplayValueMessage}</Text>
                </ReactTooltip>
              )}
              <div
                data-tip
                data-for={
                  benefit.DisplayValueMessage && benefit.DisplayValueMessage.length > 0
                    ? `${benefit.BenefitDisplayName}${healthPlanId}`
                    : null
                }
              >
                <Text
                  thin
                  style={{ fontSize: '13px' }}
                  primary={benefit.BundleBenefitApplies}
                  dashed={benefit.DisplayValueMessage && benefit.DisplayValueMessage.length > 0}
                >
                  {getBenefitDisplayValue(
                    benefit.DisplayValue,
                    benefit.BenefitDisplayName,
                    benefit.Notes,
                    label
                  )}
                  {benefit.Notes && (
                    <sup style={{ color: '#3A4951' }}>{benefit.Notes.join(',')}</sup>
                  )}
                </Text>
              </div>
            </Container>
          </Container>
        ))}
        {notes && <FootNotes notes={notes} planId={healthPlanId} />}
        {showProofOfResidenceRequireMessage && (
          <Text p small light style={{ lineHeight: '1.5' }}>
            Proof of residence will be required to complete your application. A SureCo
            representative will contact you regarding next steps.
          </Text>
        )}
      </div>
    )}
  </div>
)

GeneralBundleAccordionBody.propTypes = {
  list: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  healthPlanId: PropTypes.string.isRequired,
  showProofOfResidenceRequireMessage: PropTypes.bool,
  notes: PropTypes.object,
}

GeneralBundleAccordionBody.defaultProps = {
  showProofOfResidenceRequireMessage: false,
  notes: null,
}

export default GeneralBundleAccordionBody
