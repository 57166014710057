import React, { useEffect, useRef, useState } from 'react'
import { MrModal, clearModal } from '@hixme/modal'
import { connect } from 'react-redux'

import { Stack, Typography } from '@sureco/design-system'
import { Button, Input, InputNumber } from '@surecompanies/core_components'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
// hixme-ui
import RadioButton from '@hixme-ui/radio-button'
import notification from 'modules/notification-manager'
import { selectors as personsSelectors } from 'store/modules/persons'
import { getEmployee } from 'store/modules/persons/selectors'
import { getEnrollmentSession } from 'store/modules/user-session/selectors'

// project
import { useQuery } from '@apollo/client'
import { GET_MEDICARE_COVERAGE } from 'apollo/queries'
import { actions as cartActions } from 'store/modules/cart'
import { useMedicareDocumentsUpload } from './useMedicareDocumentsUpload'
import { currencyFormatter } from 'routes/Expenses/utils/expenses'

const PlanBlock = ({
  type,
  planType,
  premium,
  handlePlanTypeChange,
  handlePremiumChange,
  cleanupPlan,
}) => {
  return (
    <div
      style={{
        margin: '20px 0',
        borderTop: '1px solid #fafafa',
        borderColor: '#acacac',
        padding: '10px 0',
      }}
    >
      <Stack direction="row" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography bold size={14}>
          Plan type {+type + 1}
        </Typography>
        {+type !== 0 && (
          <Button danger onClick={() => cleanupPlan(type)}>
            Remove
          </Button>
        )}
      </Stack>
      <Stack direction="row" style={{ padding: '5px 0', marginBottom: '10px' }}>
        {['Plan A', 'Plan B', 'Plan D', 'Other'].map((plan, index) => {
          return (
            <RadioButton
              key={`${type}-${plan}`}
              style={{ width: index === 4 ? '120px' : '110px', marginRight: '10px' }}
              name={`${type}-${plan}`}
              text={plan}
              value={plan}
              onChange={(e) => {
                handlePlanTypeChange(e)
              }}
              checked={planType === plan}
            />
          )
        })}
      </Stack>
      <Stack space={5}>
        <Typography bold size={14}>
          Premium (monthly)
        </Typography>
        <InputNumber
          name="Premium"
          controls={false}
          defaultValue={premium}
          formatter={(value) =>
            value === '' ? '' : `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          onChange={handlePremiumChange}
          size="middle"
          style={{ width: '100px' }}
          maxLength={9}
        />
        <Typography>
          Enter the monthly premium cost for the selected plan. If there is no cost, please enter
          $0. If your bill is for more than one month, please enter ONLY the premium cost for a
          single month.
        </Typography>
      </Stack>
    </div>
  )
}

const MedicareModal = ({
  confirm,
  confirmUpload,
  employee,
  enrollment,
  dependents,
  closeModal,
  data: { personId },
}) => {
  const { generateFileUrl, uploadFile } = useMedicareDocumentsUpload()

  const { data } = useQuery(GET_MEDICARE_COVERAGE, {
    variables: {
      enrollmentPublicKey: enrollment.Id,
    },
    onCompleted: (data) => {
      setSelectedMemberId(data?.getMedicareCoverage[0]?.personId)
    },
  })

  const [selectedMemberId, setSelectedMemberId] = useState('')
  const [selectedMemberCoverage, setSelectedMemberCoverage] = useState(null)

  useEffect(() => {
    const selectedMemberCoverage = data?.getMedicareCoverage?.find(
      (item) => item?.personId === selectedMemberId
    )
    setSelectedMemberCoverage(selectedMemberCoverage)
    setMedicareNumber(selectedMemberCoverage?.medicareNumber)
    setPlans(
      selectedMemberCoverage?.medicareCoverage?.map((item) => {
        return {
          planType: item.planType,
          premium: item.premium,
        }
      }) ?? []
    )
    setUploadedFiles(selectedMemberCoverage?.documents || [])
  }, [selectedMemberId])

  const getFamily = data?.getMedicareCoverage.map((item) => {
    const dependent = dependents.find((dependent) => dependent.Id === item?.personId)
    if (!dependent) {
      return {
        ...employee,
        ...item,
      }
    }
    return {
      ...item,
      ...dependent,
    }
  })

  const [uploading, setUploading] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const [error, setError] = useState(null)
  const inputFile = useRef(null)

  const [uploadedFiles, setUploadedFiles] = useState(selectedMemberCoverage?.documents || [])
  const [plans, setPlans] = useState(
    selectedMemberCoverage?.medicareCoverage?.map((item) => {
      return {
        planType: item.planType,
        premium: item.premium,
      }
    }) ?? []
  )

  const handleUpload = async (event) => {
    const file = event.target.files[0]
    if (!file) {
      setError('Please select a file to upload.')
      return
    }
    setUploading(true)
    setError(null)

    try {
      const {
        data: { generateMedicareCoveragePreSignedUrl },
      } = await generateFileUrl({
        variables: {
          input: {
            personPublicKey: employee.EmployeePublicKey,
            extension: file.name.split('.').pop(), // Get file extension
          },
        },
      })

      await uploadFile(generateMedicareCoveragePreSignedUrl.preSignedUrl, file)
      setUploadedFiles([...uploadedFiles, { key: generateMedicareCoveragePreSignedUrl.fileKey }])
      confirmUpload()
    } catch (err) {
      setError('Failed to upload file.')
    } finally {
      setUploading(false)
    }
  }

  const handlePlanTypeChange = (index) => (e) => {
    const newPlans = [...plans]
    newPlans[index].planType = e.target.value
    setPlans(newPlans)
  }

  const handlePremiumChange = (index) => (value: number) => {
    const newPlans = [...plans]
    newPlans[index].premium = value
    setPlans(newPlans)
  }
  const cleanupPlan = (index) => setPlans(plans.filter((_, idx) => idx !== index))

  const addPlan = () => {
    setPlans([
      ...plans,
      {
        planType: '',
        premium: '',
      },
    ])
  }

  const handleMedicareNumberChange = (e) => {
    setMedicareNumber(e.target.value)
  }

  const [medicareNumber, setMedicareNumber] = useState(selectedMemberCoverage?.medicareNumber || '')

  return (
    <MrModal small hideCloseButton>
      <Stack space={10}>
        {selectedMemberCoverage?.medicareCoverage?.coverageDate && (
          <>
            <Typography
              style={{
                position: 'absolute',
                fontSize: '20px',
                fontWeight: '700',
                lineHeight: '28px',
                letterSpacing: '0.01em',
                textAlign: 'left',
                top: '-9px',
              }}
            >
              Medicare
            </Typography>
            <Typography
              style={{
                backgroundColor: '#FCE9E9',
                padding: '10px 15px',
                margin: '30px 0 10px 0',
                borderRadius: '10px',
                color: '#ba8a8a',
              }}
            >
              Medicare needs to be complete by{' '}
              {selectedMemberCoverage?.medicareCoverage?.coverageDate} or there could be significant
              penalties. Link to penalties?
            </Typography>
          </>
        )}
        <select
          style={{
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            marginBottom: '10px',
          }}
          value={selectedMemberId}
          onChange={(e) => setSelectedMemberId(e.target.value)}
        >
          {getFamily?.map((member: any) => (
            <option key={member.Id} value={member.Id}>
              {member.FullName} - {member.Relationship}
            </option>
          ))}
        </select>
        <Typography bold size={14}>
          Medicare number
        </Typography>
        <Input
          placeholder="0000-000-0000"
          style={{ width: '135px' }}
          name="Medicare number"
          onChange={handleMedicareNumberChange}
          size="middle"
          value={medicareNumber}
          maxLength={12}
        />
        <Typography>
          This can be found on your account on{' '}
          <Typography
            color="info"
            as="a"
            onClick={() => window.open('https://www.medicare.gov', 'blank')}
          >
            Medicare.gov
          </Typography>
          , your Medicare Premium Bill, or on your Social Security Administration (SSA) account.{' '}
          <Typography
            color="info"
            as="a"
            onClick={() =>
              window.open(
                'https://www.medicare.gov/basics/get-started-with-medicare/using-medicare/your-medicare-card',
                'blank'
              )
            }
          >
            Learn more
          </Typography>
        </Typography>

        {plans?.map((plan, index) => {
          return (
            <PlanBlock
              type={index}
              key={index}
              planType={plan.planType}
              premium={plan.premium}
              cleanupPlan={cleanupPlan}
              handlePlanTypeChange={handlePlanTypeChange(index)}
              handlePremiumChange={handlePremiumChange(index)}
            />
          )
        })}

        {plans?.length > 0 && (
          <Typography color="primary">
            If you have more than one Medicare plan or supplement, click Add Plan to provide this
            additional information for each.
          </Typography>
        )}

        <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            color="primary"
            icon={<PlusOutlined />}
            onClick={addPlan}
            style={{ width: 'fit-content' }}
          >
            Add Plan
          </Button>

          <Typography bold size={14}>
            Monthly total:{' '}
            {currencyFormatter(
              plans?.reduce((prev, arg) => {
                return +prev + parseFloat(arg.premium || 0)
              }, 0)
            )}
          </Typography>
        </Stack>

        <Typography bold size={14}>
          Upload verification document
        </Typography>
        <Typography style={{ marginBottom: '10px' }}>
          A photo or scan of your Medicare Premium Bill.{' '}
          <Typography
            color="info"
            as="a"
            onClick={() =>
              window.open(
                'https://s3.us-east-1.amazonaws.com/assets.hixme.com/enrollme-materials/Sample+Medicare+Documents.pdf',
                'blank'
              )
            }
          >
            See sample documents.
          </Typography>
        </Typography>
        <input style={{ display: 'none' }} ref={inputFile} type="file" onChange={handleUpload} />
        <Button
          color="primary"
          icon={<UploadOutlined />}
          onClick={() => inputFile.current.click()}
          style={{ width: 'fit-content' }}
        >
          {uploading ? 'Uploading...' : 'Upload'}
        </Button>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {uploadedFiles.map((file, index) => {
            return (
              <a
                href={file.preSignedUrl}
                download={file.key}
                target="_blank"
                style={{ color: '#acacac', marginBottom: '10px' }}
                key={index}
              >
                {file.key.split('/').pop()}
              </a>
            )
          })}
        </div>
        {error && (
          <Typography color="error" as="p" style={{ textAlign: 'center' }}>
            {error}
          </Typography>
        )}
        <Stack direction="row" space={15} style={{ justifyContent: 'flex-end' }}>
          <Button size="large" style={{ width: '120px' }} onClick={closeModal}>
            <Typography size={14}>Cancel</Typography>
          </Button>
          <Button
            size="large"
            type="primary"
            disabled={confirming}
            loading={confirming}
            style={{ width: '120px' }}
            onClick={() => {
              if (uploadedFiles.length === 0) {
                setError(
                  'Please upload at least one document as evidence for your Medicare monthly premiums.'
                )
                return
              }
              setConfirming(true)
              confirm({
                personId: selectedMemberId,
                enrollmentPublicKey: enrollment.Id,
                personPublicKey: employee.Id,
                medicareNumber: medicareNumber,
                medicareCoverage: plans,
                documents: uploadedFiles,
                insuredName: getFamily?.find((item) => item.Id === selectedMemberId)?.FullName,
                insuredId: getFamily?.find((item) => item.Id === selectedMemberId)?.Id,
              })
            }}
          >
            <Typography size={14}>Confirm</Typography>
          </Button>
        </Stack>
      </Stack>
    </MrModal>
  )
}

const mapStateToProps = (state) => {
  const employee = getEmployee(state)
  const enrollment = getEnrollmentSession(state)
  const dependents = personsSelectors.getDependents(state)

  return {
    dependents,
    employee,
    enrollment,
  }
}

const mapDispatchToProps = (dispatch) => ({
  confirm: async (confirmData) => {
    dispatch(cartActions.UpdateMedicare(confirmData))
  },
  confirmUpload: async () => {
    dispatch(
      notification.actions.createNotification({
        type: 'success',
        autoClose: true,
        message: `File was successfully uploaded`,
      })
    )
  },
  closeModal: () => dispatch(clearModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MedicareModal)
