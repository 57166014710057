import * as constants from './constants'

// Helper function for building the cart person object
export const buildCartPerson = (person, addFields) => {
  const { FirstName, Id, LastName, PostalCode, Relationship, RatingArea, StateProvince } = person

  return {
    FirstName,
    Id,
    LastName,
    PostalCode,
    RatingArea,
    Relationship,
    StateProvince,
    BundleMembership: Relationship === 'Employee' ? 'Subscriber' : 'Dependent',
    ...addFields,
  }
}

export const buildDeclinedPerson = (person) =>
  buildCartPerson(person, { BenefitStatus: constants.DECLINED })

export const buildIncludedPerson = (person) =>
  buildCartPerson(person, { BenefitStatus: constants.INCLUDED })

export const buildNotIncludedPerson = (person) =>
  buildCartPerson(person, { BenefitStatus: constants.NOT_INCLUDED })
