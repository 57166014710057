import React from 'react'

import { MAIL_ROOM_NAME } from 'routes/path-names'

import InfoCard from '../InfoCard/InfoCard'
import MessagesIcon from '../assets/Messages.png'

const MessagesCard = () => (
  <InfoCard
    Image={MessagesIcon}
    title="Messages"
    message="Visit your digital mail room for incoming correspondence."
    url={MAIL_ROOM_NAME}
    state="resume"
    buttonLabel="View"
  />
)

export default MessagesCard
