import React from 'react'
import PropTypes from 'prop-types'
import { MrModal } from '@hixme/modal'

// hixme-ui
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'

const DeclineCoverageModal = (props) => {
  const { onDeclineClick, isProcessing, coveredPersons, benefitProducts, enrollmentKey } = props

  const declineClickHandler = (e) => {
    e.preventDefault()
    onDeclineClick(coveredPersons, benefitProducts, enrollmentKey)
  }

  return (
    <MrModal
      small
      hideCloseButton
      closeOnEsc={false}
      shouldCloseOnOverlayClick={false}
      errorDialog
      confirmHandler={declineClickHandler}
      confirmLabel="Decline"
      confirmProcessing={isProcessing}
      confirmSubmittingText="Declining..."
    >
      <Container textCenter noPadding>
        <Icon alert red size={4} />
        <Title>Are you sure?</Title>
        <Container padding="0px 20px">
          <Text p light>
            If you choose to decline this coverage you will not be able to enroll again until next
            year’s open enrollment – except under certain qualifying events (e.g. getting married,
            having a baby, losing coverage, spouse losing their coverage, etc.)
          </Text>
        </Container>
      </Container>
    </MrModal>
  )
}

DeclineCoverageModal.displayName = 'DeclineCoverageModal'
DeclineCoverageModal.propTypes = {
  onDeclineClick: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  coveredPersons: PropTypes.array.isRequired,
  benefitProducts: PropTypes.array.isRequired,
}

export default DeclineCoverageModal
