import React from 'react'

import Phone from '@hixme-ui/phone'
import Text from '@hixme-ui/text'

const HIXME_TELEPHONE = '9499894906'

const HelpNumber = () => (
  <Text a href={`tel:+${Phone.format(HIXME_TELEPHONE)}`}>
    {Phone.format(HIXME_TELEPHONE)}
  </Text>
)

HelpNumber.displayName = 'HelpNumber'

export default HelpNumber
