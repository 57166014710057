import React from 'react'

import Separator from 'components/Separator'
import Title from '@hixme-ui/title'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import Modal from 'modules/modal'

const OutOfPocketRatingsInfo = () => (
  <Container white rounded padding="40px 60px">
    <Title small>Out-of-Pocket Cost Estimates Explained</Title>
    <Separator light />
    <Text p light thin fontSize="16px" style={{ margin: '2em 0 1em' }}>
      First of all, what are out-of-pocket expenses? These are your expenses for medical care that
      isn’t reimbursed by insurance, or whatever cost is left over after insurance has paid its
      share.
    </Text>
    <Text p light thin fontSize="16px">
      The “Out-of-Pocket Cost Estimate” shows you what your remaining out-of-pocket cost might
      really be across 5 treatment scenarios once the full bundle has been applied. The {'"meters"'}{' '}
      for each scenario also allow you to compare potential out-of-pocket costs across bundles.
    </Text>
    <Text p light thin fontSize="16px">
      A low $ cost estimate (green) means that this bundle, combined with all of the applicable
      Hixme’s Gap Coverages, is better for reducing your out-of-pocket costs than a bundle with a
      higher estimate ($$$ approaching red).
    </Text>
    <Text light thin fontSize="16px">
      Bundles result in varying out-of-pocket costs for different treatment scenarios, so it’s
      important to look at all situations. For example:
    </Text>
    <ul>
      <li>
        <Text p light thin fontSize="16px">
          If you see a low out-of-pocket cost estimate for “Surgery in hospital,” ($ approaching
          green), it means you are getting very good coverage with this bundle, in the event that
          you do have surgery and need to stay overnight.
        </Text>
      </li>
      <li>
        <Text p light thin fontSize="16px">
          If you see a high out-of-pocket cost estimate ($$$ approaching red) for “Having a baby,”
          there may be better bundles out there for someone planning on having a child in 2017.
        </Text>
      </li>
    </ul>
    <Text p light thin fontSize="16px">
      NOTE: Make sure to consider treatment scenarios that most concern you, as well as those you
      might not expect. For example, if you’re not planning on having a baby next year, you
      shouldn’t be concerned if you choose a plan that has a higher out-of-pocket cost estimate for
      this scenario! However, you might want to consider other scenarios that better fit your
      situation and see what the out-of-pocket cost estimate might look like.
    </Text>
  </Container>
)

OutOfPocketRatingsInfo.displayName = 'OutOfPocketRatingsInfo'

export default Modal.containers.Modal({
  content: {
    position: 'top',
    size: 'small',
    styles: {
      margin: '50px 0 0 0',
    },
  },
  overlay: {
    hideCloseButton: false,
    routeOnHide: false,
    dark: true,
  },
})(OutOfPocketRatingsInfo)
