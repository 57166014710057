import { createReducer } from 'modules/redux-utils'
import t from './actionTypes'

// initialState
const initialState = []

export default createReducer(initialState, {
  [t.LOAD_PERSON_LIST]: (state, payload) => payload,

  [t.SET_SELECTED]: (state, payload = []) =>
    state.map((person) => {
      if (payload.indexOf(person.Id) !== -1) {
        return Object.assign({}, person, { selected: true })
      }
      return person
    }),
  [t.SET_UNSELECTED]: (state, payload = []) =>
    state.map((person) => {
      if (payload.indexOf(person.Id) !== -1) {
        return Object.assign({}, person, { selected: false })
      }
      return person
    }),
  [t.SET_DISABLED]: (state, payload = []) =>
    state.map((person) => {
      if (payload.indexOf(person.Id) !== -1) {
        return Object.assign({}, person, { disabled: true })
      }
      return person
    }),
  [t.SET_ACTIVE]: (state, payload = []) =>
    state.map((person) => {
      if (payload.indexOf(person.Id) !== -1) {
        return Object.assign({}, person, { disabled: true })
      }
      return person
    }),
})
