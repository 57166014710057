/* eslint-disable */
// TODO: Lint cleanup. If you are editing this file, it is your duty to fix all linting errors.
import { createReducer } from 'modules/redux-utils'
import { NUM_QUESTIONS } from './constants'
import t from './actionTypes'

const QUESTION_NUMBER_ONE = 1
const initialState = {
  currentQuestion: -1,
  lastQuestion: -1,

  // question responses
  questionAnswers: [],
  responses: [],

  // person selector
  persons: [],
  respondersList: [],
}

export default createReducer(initialState, {
  // Questionnaire Wizard
  [t.INIT_QUESTIONNAIRE]: (state, payload) =>
    Object.assign({}, state, { lastQuestion: NUM_QUESTIONS }),
  [t.RESET_QUESTIONNAIRE]: (state, payload) => Object.assign({}, state, initialState),
  [t.START_QUESTIONNAIRE]: (state, payload) =>
    Object.assign({}, state, { currentQuestion: QUESTION_NUMBER_ONE }),
  [t.NEXT_QUESTION]: (state, payload) => {
    const { currentQuestion, lastQuestion } = state
    const nextQuestion =
      currentQuestion !== lastQuestion ? state.currentQuestion + 1 : QUESTION_NUMBER_ONE
    return Object.assign({}, state, { currentQuestion: nextQuestion })
  },
  [t.PREV_QUESTION]: (state, payload) => {
    const { currentQuestion } = state
    const prevQuestion =
      currentQuestion !== QUESTION_NUMBER_ONE ? state.currentQuestion - 1 : QUESTION_NUMBER_ONE
    return Object.assign({}, state, { currentQuestion: prevQuestion })
  },

  // Question responses
  [t.LOAD_RESPONSES]: (state, payload) => Object.assign({}, state, { responses: payload }),
  [t.LOAD_QUESTIONANSWERS]: (state, payload) =>
    Object.assign({}, state, { questionAnswers: payload }),
  [t.LOAD_PERSONS]: (state, payload) => Object.assign({}, state, { persons: payload }),
  [t.UPDATE_RESPONDERS_BYINDEX]: (state, payload) => {
    const list = state.respondersList
    const { index, responders } = payload

    // This first path handles the situation where the user deselects all persons from
    // the person selector, passing an empty responders array here.
    // We will disallow this behavior by disabling the "save" button until
    // A person is selected.
    if (responders.length === 0) {
      return Object.assign({}, state, {
        respondersList: list
          .slice(0, index)
          .concat([responders])
          .concat(list.slice(index + 1)),
      })
    }
    return Object.assign({}, state, {
      respondersList: list
        .slice(0, index)
        .concat([responders])
        .concat(list.slice(index + 1)),
    })
  },

  // Cool but not being used
  //
  // [t.LOAD_RESPONSE]: (state, payload) => {
  //   const { responses } = state
  //   const { response, index } = payload
  //
  //   return Object.assign({}, state, {
  //     responses: responses
  //       .slice(0, index)
  //       .concat(
  //         [ Object.assign({}, responses[index], response) ]
  //       )
  //       .concat(responses.slice(index + 1))
  //   })
  // },

  // [t.SET_SELECTED_PERSONS]: (state, payload) => {
  //   const persons = state.persons.map((person) => {
  //     if (payload.indexOf(person.Id) !== -1) {
  //       person.selected = true
  //     } else {
  //       person.selected = false
  //     }
  //     return person
  //   })
  //   return Object.assign({}, state, { persons })
  // },
})
