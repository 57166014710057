import React from 'react'
import BaseIcon from './BaseIcon'

/*eslint-disable */
export default class Home extends BaseIcon {
  static defaultProps = {
    width: 18,
    height: 18,
    viewBox: '3 3 18 18'
  }
  render () {
    return (
      <svg {...this.getSvgProps()}>
        <path d='M18.729266 17.535476v-5.7744791h.3913931c1.0360888 0 1.5198148-1.3196242.7419311-2.02233423l-7.0802124-6.4003813c-.4387853-.45104182-1.1496664-.45104182-1.5884516 0l-7.08021246 6.4003813c-.7778837.70271003-.2941577 2.02233423.7419311 2.02233423H5.247038v5.7744791c0 .6373416-.50333652 1.1545689-1.123519 1.1545689-.6201825 0-1.123519.5172273-1.123519 1.154569C3 20.4827727 3.5033365 21 4.123519 21h4.494076c.62099958 0 1.123519-.5172273 1.123519-1.1553861v-3.464524c0-.6373417.5033365-1.154569 1.123519-1.154569h2.247038c.6201825 0 1.123519.5172273 1.123519 1.154569v3.464524C14.23519 20.4827727 14.7385265 21 15.358709 21h4.494076c.6201825 0 1.123519-.5172273 1.123519-1.1553861 0-.6373417-.5033365-1.154569-1.123519-1.154569s-1.123519-.5172273-1.123519-1.1545689z'
          {...this.getPathProps()} />
      </svg>
    )
  }
}
/*eslint-enable */
