import { createRequest } from '@hixme/redux-gateway'
import moment from 'moment'
import t from './actionTypes'
import { GET_LOCATIONS_ROUTE } from './constants'

export const loadLocations = (locations, postalCode) => ({
  type: t.LOAD_LOCATIONS,
  payload: { locations, postalCode },
})

export const getLocations = (postalCode) => (dispatch) =>
  dispatch(
    createRequest({
      route: GET_LOCATIONS_ROUTE,
      params: {
        year: moment(new Date()).year(),
        zipCode: postalCode,
      },
    })
  ).then(
    (data) => dispatch(loadLocations(data, postalCode)).payload,
    () => {
      throw new Error('Error getting updated county/state locations')
    }
  )
