import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import enrollmeGateway from '@hixme/enrollme-api-gateway'
import { setGateway } from '@hixme/redux-gateway'
import { AuthenticatedComponent, selectors as authSelectors } from '@hixme/auth-ui'
import { FeatureProvider } from '@hixme/features'

// project
import * as personSelectors from 'store/modules/persons/selectors'
import userSession, {
  actions as userSessionActions,
  containers as userSessionContainers,
} from 'store/modules/user-session'
import appConfig from 'modules/app-config'

// module
import EnrollmeLayout from './EnrollmeLayout'

const { UserSessionContainer } = userSessionContainers

class EnrollmeLayoutContainer extends Component {
  componentWillMount() {
    this.initializeAPIGateway(this.props)
  }

  componentWillReceiveProps(props) {
    if (props.credentials && props.credentials.idToken !== this.props.credentials.idToken) {
      this.initializeAPIGateway(props)
    }
  }

  initializeAPIGateway() {
    // Setup AWS API Gateway and redux-gateway
    const { credentials } = this.props
    if (credentials) {
      const gateway = enrollmeGateway.newClient(credentials, appConfig.getStage())
      setGateway(gateway)
    }
  }

  render() {
    const { children, userId, person, ...restProps } = this.props

    return (
      <FeatureProvider stage={appConfig.getStage()} token={this.props.token} masqueradeAs={userId}>
        <UserSessionContainer>
          <EnrollmeLayout {...restProps} person={person}>
            {children}
          </EnrollmeLayout>
        </UserSessionContainer>
      </FeatureProvider>
    )
  }
}

EnrollmeLayoutContainer.displayName = 'EnrollmeLayoutContainer'
EnrollmeLayoutContainer.propTypes = {
  children: PropTypes.element.isRequired,
  credentials: PropTypes.object,
  userCredentials: PropTypes.object,
  token: PropTypes.string,
  userId: PropTypes.string,
  person: PropTypes.object,
}
EnrollmeLayoutContainer.defaultProps = {
  credentials: undefined,
  userCredentials: undefined,
  token: undefined,
  userId: undefined,
  person: undefined,
}

const mapStateToProps = (state) => ({
  username: userSession.selectors.getUserName(state),
  userCredentials: authSelectors.getCredentials(state),
  token: authSelectors.getToken(state),
  userId: userSession.selectors.getUserId(state),
  person: personSelectors.getPerson(state),
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  initializeUserSession: (userCredentials) => {
    dispatch(userSessionActions.initializeUser(userCredentials))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticatedComponent(EnrollmeLayoutContainer))
