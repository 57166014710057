import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'
import { CSSTransitionGroup } from 'react-transition-group'

import { MrModal, getModalData, clearModal } from '@hixme/modal'

import Button from '@hixme-ui/button'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import classes from '../styles/transitionStyles.scss'

import {
  clearFiles,
  getSignedUrl,
  removeFile,
  updateFileType,
  uploading,
  uploadComplete,
  uploadError,
  uploadFile,
  updateGeneralMessage,
  updateNote,
} from '../../actions'

import { getFilesByGroupKey, getMessageByGroupKey, getIsUploadingByGroupKey } from '../../selectors'

import { DropZone, FileUploadList } from './components'

const Uploader = ({
  accept,
  documentTypes,
  files,
  handleCloseModal,
  handleFileTypeChange,
  handleNoteChange,
  handleRemoveAllFiles,
  handleRemoveFile,
  handleUploadAllFiles,
  isUploading,
  groupKey,
  uploaderTitle,
  message,
}) => (
  <MrModal medium title={uploaderTitle}>
    <div style={{ marginTop: '10px' }}>
      <DropZone groupKey={groupKey} accept={accept} />
      <div>
        <div
          style={{
            margin: '20px 0 20px 0',
            height: '280px',
            maxHeight: '280px',
            overflowX: 'scroll',
            border: '1px solid Gainsboro',
            borderRadius: '4px',
            padding: '8px',
          }}
        >
          <FileUploadList
            documentTypes={documentTypes}
            wrappers={files}
            handleRemoveFile={handleRemoveFile}
            handleFileTypeChange={handleFileTypeChange}
            handleNoteChange={handleNoteChange}
          />
        </div>
        <div>
          <Container noPadding flex justifyContent="space-between" alignItems="center">
            <Container flex noPadding>
              <Button outline blue onClick={handleCloseModal}>
                Close
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                disabled={!files.length}
                outline
                blue
                onClick={() => handleRemoveAllFiles(files)}
              >
                Clear files
              </Button>
            </Container>

            <CSSTransitionGroup
              transitionName={{
                enter: classes.enter,
                enterActive: classes.enterActive,
                leave: classes.leaveMessage,
                leaveActive: classes.leaveMessageActive,
              }}
              transitionEnterTimeout={300}
              transitionLeaveTimeout={200}
            >
              {message && (
                <Container flex padding="0 10px 0 10px">
                  <Text key="message">{message}</Text>
                </Container>
              )}
            </CSSTransitionGroup>
            <Button
              style={{ minWidth: '180px' }}
              disabled={!files.length}
              submitting={isUploading}
              submittingText="Uploading..."
              onClick={() => handleUploadAllFiles(files)}
            >
              UPLOAD FILES
            </Button>
          </Container>
        </div>
      </div>
    </div>
  </MrModal>
)

const mapStateToProps = (state) => {
  const accept = getModalData(state).accept
  const documentTypes = getModalData(state).documentTypes
  const groupKey = getModalData(state).groupKey

  const files = getFilesByGroupKey(groupKey, state)
  const isUploading = getIsUploadingByGroupKey(groupKey, state)
  const message = getMessageByGroupKey(groupKey, state)
  const uploaderTitle = getModalData(state).uploaderTitle
  return {
    accept,
    documentTypes,
    files,
    groupKey,
    isUploading,
    message,
    uploaderTitle,
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  handleCloseModal: () => {
    dispatch(clearModal())
  },
  handleFileTypeChange: (value, fileName) => {
    dispatch(updateFileType(value, fileName, props.data.groupKey))
  },
  handleNoteChange: (value, fileName) => {
    dispatch(updateNote(value, fileName, props.data.groupKey))
  },
  handleRemoveAllFiles: () => {
    dispatch(clearFiles(props.data.groupKey))
    dispatch(updateGeneralMessage(null, props.data.groupKey))
  },
  handleRemoveFile: (fileName) => {
    dispatch(removeFile(fileName, props.data.groupKey))
    dispatch(updateGeneralMessage(null, props.data.groupKey))
  },
  handleUploadAllFiles: (wrappers) => {
    const missingFileTypes = wrappers.filter(
      (wrapper) => wrapper.fileType === '-1' || wrapper.fileType.length === 0
    )

    missingFileTypes.forEach((wrapper) => {
      dispatch(updateFileType('-1', wrapper.file.name, props.data.groupKey))
    })

    if (missingFileTypes.length) {
      dispatch(
        updateGeneralMessage(
          'There are files with missing file types. Please fix before uploading.',
          props.data.groupKey
        )
      )
      return
    }

    dispatch(updateGeneralMessage(null, props.data.groupKey))
    dispatch(uploading(true, props.data.groupKey))

    const groupKey = props.data.groupKey
    const EmployeePublicKey = props.data.employeePublicKey
    const filesToUpload = wrappers.filter((wrapper) => !wrapper.complete)

    const allUploads = filesToUpload.map((wrapper) => {
      async function uploadSingleFile() {
        const signedData = await getSignedUrl({
          EmployeePublicKey,
          fileName: wrapper.file.name,
        }).catch((error) => {
          dispatch(uploadError(wrapper.file.name, groupKey))
          props.data.onUploadsError({
            message: 'There was an error getting url',
            groupKey,
            error,
          })
          return error
        })
        return dispatch(uploadFile(signedData.URL, wrapper.file, groupKey))
          .then((result) => {
            dispatch(uploadComplete(wrapper.file.name, groupKey))
            return {
              Name: result.config.data.name,
              URL: result.config.url,
              DocumentType: wrapper.fileType,
              Note: wrapper.note,
              Key: signedData.Key,
            }
          })
          .catch(() => {
            dispatch(uploading(false, props.data.groupKey))
            dispatch(uploadError(wrapper.file.name, groupKey))
          })
      }
      return uploadSingleFile()
    })

    axios.all(allUploads).then((responses) => {
      dispatch(uploading(false, props.data.groupKey))
      if (!responses.includes(undefined) && responses.length) {
        dispatch(updateGeneralMessage('All uploads have completed.', props.data.groupKey))
        props.data.onUploadsComplete(responses)
      }
    })
  },
})

Uploader.displayName = 'Uploader'
Uploader.propTypes = {
  accept: PropTypes.string,
  documentTypes: PropTypes.array,
  files: PropTypes.array,
  groupKey: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleFileTypeChange: PropTypes.func.isRequired,
  handleNoteChange: PropTypes.func.isRequired,
  handleRemoveAllFiles: PropTypes.func.isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
  handleUploadAllFiles: PropTypes.func.isRequired,
  isUploading: PropTypes.bool,
  message: PropTypes.string,
  uploaderTitle: PropTypes.string,
}

Uploader.defaultProps = {
  accept: '',
  documentTypes: [],
  files: [],
  isUploading: false,
  uploaderTitle: 'Upload your files and signed documents',
  message: '',
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Uploader)
