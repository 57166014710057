import { connect } from 'react-redux'
import { connectWaiter, getWaiter } from 'redux-waiter'
import { compose } from 'recompose'
import { clearModal } from '@hixme/modal'
import { enrollmentService } from '@hixme/api'
import { BENEFIT_TYPE_VISION } from '@hixme/benefit-types'

// project
import DeclineCoverageModal from 'components/DeclineCoverageModal'
import { createErrorNotification } from 'modules/notification-manager/actions'

// store
import cart from 'store/modules/cart'
import person from 'store/modules/persons'
import userSession from 'store/modules/user-session'

// module
import { constants } from '../modules/vision-coverage'
import { datadog } from 'helpers/datadog'

const mapStateToProps = (state, props) => {
  const waiter = getWaiter(state, constants.GET_VISION_PLANS)
  return {
    benefitProducts: (waiter.response && waiter.response.ClientBenefits) || [],
    enrollmentKey:
      props.data?.enrollmentPublicKey || userSession.selectors.getEnrollmentPublicKey(state),
    coveredPersons: props.data?.persons
      ? props.data.persons.map((person) => ({ Id: person.personPublicKey }))
      : [
        person.selectors.getPersonSelector(state),
        ...person.selectors.getDependentsEligibleForBenefits(state),
      ],
    isProcessing: getWaiter(state, cart.constants.CART_VISION_POST).isPending || false,
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  onDeclineClick: (coveredPersons, benefitProducts, enrollmentKey) => {
    dispatch(cart.actions.declineVisionProduct(coveredPersons, benefitProducts, enrollmentKey))
      .then(
        () => {
          datadog.declineBenefits(BENEFIT_TYPE_VISION, coveredPersons, benefitProducts)
          if (props.data?.enrollmentPublicKey) {
            props.data?.onDecline?.()
          } else {
            dispatch(userSession.actions.pushNextBenefitRoute())
          }
        },
        () => {
          dispatch(
            createErrorNotification('There was an error declining vision. Please try again.')
          )
        }
      )
      .finally(() => dispatch(clearModal()))
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  connectWaiter({
    name: constants.GET_VISION_PLANS,
    requestCreator: ({ EnrollmentPublicKey, PersonPublicKeys }) =>
      enrollmentService({
        route: '{EnrollmentPublicKey}/client/benefits/{BenefitType}',
        params: {
          EnrollmentPublicKey,
          BenefitType: BENEFIT_TYPE_VISION,
          PersonPublicKeys,
        },
      }),
    requestOnMountParams: ({ enrollmentKey, coveredPersons }) => {
      const keys = coveredPersons.map((i) => i.Id)
      return {
        EnrollmentPublicKey: enrollmentKey,
        PersonPublicKeys: keys,
      }
    },
  })
)(DeclineCoverageModal)
