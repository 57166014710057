import { connect } from 'react-redux'
import { SaveAndContinueButton } from '../components'
import { pushNextBenefitRoute } from '../actions'

export default connect(
  null,
  (dispatch, { skipShop }) => ({
    saveButtonClick: () => {
      dispatch(pushNextBenefitRoute(null, skipShop))
    },
  })
)(SaveAndContinueButton)
