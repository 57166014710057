import ReactGA from 'react-ga'

export const initialize = ({
  ga, // google analytics id
}) => {
  ReactGA.initialize(ga)
}

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname })
  ReactGA.pageview(window.location.pathname)
}

export default {
  initialize,
  logPageView,
}
