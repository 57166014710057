import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { withRouter } from 'react-router'
import { enrollmentService } from '@hixme/api'

import { NAME } from '../constants'
import { getEnrollmentPublicKey } from '../selectors'
import { getEnrollmentSession } from '../actions'
import { TermsAndConditionsForm } from '../components'

export default withRouter(
  connect((state) => ({
    EnrollmentPublicKey: getEnrollmentPublicKey(state),
    disableForm: !state[NAME].scrolledToBottom,
  }))(
    reduxForm({
      form: 'TermsAndConditionsForm',
      initialValues: {
        TermsAndConditionsAgreed: false,
      },
      validate: (values) => {
        const errors = {}
        const { TermsAndConditionsAgreed } = values

        if (!TermsAndConditionsAgreed) {
          errors.TermsAndConditionsAgreed = 'Please accept the terms and conditions.'
        }

        return errors
      },
      onSubmit: (values, dispatch, { EnrollmentPublicKey, router, redirectTo }) =>
        dispatch(() =>
          enrollmentService({
            route: '{EnrollmentPublicKey}/terms',
            method: 'PUT',
            params: { EnrollmentPublicKey },
            body: { TermsAndConditionsAgreed: true },
          })
            .then(() => dispatch(getEnrollmentSession()))
            .then(() => {
              if (redirectTo) {
                router.push(redirectTo)
              } else {
                router.push('/')
              }
            })
        ),
    })(TermsAndConditionsForm)
  )
)
