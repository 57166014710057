import React from 'react'
import PropTypes from 'prop-types'
import ProductCard from 'components/ProductCard'

const VisionProductCard = ({
  BenefitPublicKey,
  Carrier,
  Contacts,
  Description,
  EnrollmentPublicKey,
  EyeExams,
  Frames,
  Lenses,
  Math,
  Name,
  Note,
  Persons,
  Price,
  SBCUrl,
  added,
  coveredPersons,
  excludedPersons,
  productOnClick,
  viewOnly,
  isAddingBenefit,
}) => {
  const hasMathWorkerPrice = Math !== undefined && Math.WorkerPrice !== undefined

  const productProps = {
    BenefitPublicKey,
    name: Description,
    price: hasMathWorkerPrice ? Math.WorkerPrice : Price,
    productURL: SBCUrl,
    SBCUrl,
    benefitsList: [
      {
        label: 'Carrier',
        value: Carrier,
      },
      {
        label: 'Contacts',
        value: Contacts,
      },
      {
        label: 'Eye Exams',
        value: EyeExams,
      },
      {
        label: 'Frames',
        value: Frames,
      },
      {
        label: 'Lenses',
        value: Lenses,
      },
    ],
    added,
    handleProductOnClick: () => {
      const visionProduct = {
        BenefitPublicKey,
        Name,
        Description,
        Price,
        Carrier,
        Contacts,
        EyeExams,
        Frames,
        Lenses,
        SBCUrl,
      }
      productOnClick({ coveredPersons, visionProduct, excludedPersons, added, EnrollmentPublicKey })
    },
    viewOnly,
    Persons,
    Note,
    isProcessing: isAddingBenefit,
  }

  return <ProductCard {...productProps} />
}

VisionProductCard.displayName = 'VisionProductCard'
VisionProductCard.propTypes = {
  BenefitPublicKey: PropTypes.string,
  Carrier: PropTypes.string,
  Contacts: PropTypes.string,
  Description: PropTypes.string,
  EyeExams: PropTypes.string,
  Frames: PropTypes.string,
  Lenses: PropTypes.string,
  Math: PropTypes.object.isRequired,
  Name: PropTypes.string,
  Note: PropTypes.string,
  Persons: PropTypes.array,
  Price: PropTypes.number,
  SBCUrl: PropTypes.string,
  added: PropTypes.bool,
  coveredPersons: PropTypes.array,
  excludedPersons: PropTypes.array,
  productOnClick: PropTypes.func,
  viewOnly: PropTypes.bool,
  isAddingBenefit: PropTypes.bool,
}

VisionProductCard.defaultProps = {
  BenefitPublicKey: '',
  Carrier: '',
  Contacts: '',
  Description: 'My Vision Plan',
  EyeExams: '',
  Frames: '',
  Lenses: '',
  Name: '',
  Note: '',
  Persons: [],
  Price: 0,
  SBCUrl: '',
  added: false,
  coveredPersons: [],
  excludedPersons: [],
  productOnClick: () => null,
  viewOnly: false,
  isAddingBenefit: false,
}

export default VisionProductCard
