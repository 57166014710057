import {
  sanitizeCurrencyValue,
  thousandSaparatorValue,
} from 'components/CurrencyInput/CurrencyInputUtils'
import { currencyFormatter } from 'routes/Expenses/utils/expenses'
// @TODO: Ask Phil about these specific benefits, could be more dynamic?
// eslint-disable-next-line import/prefer-default-export
export const getBenefitDisplayValue = (value, displayName, notes, label) => {
  const isNumber = value && /^\d+$/.test(value.replace('$', ''))
  let displayVal = value
  let sanitizedValue = '0'
  if (value && isNumber) {
    sanitizedValue = sanitizeCurrencyValue(value)
    displayVal = currencyFormatter(sanitizedValue).replace(/\D00$/, '')
    displayVal = thousandSaparatorValue(displayVal)

    if (label === 'Prescription Drugs') {
      if (
        notes &&
        notes.length === 0 &&
        ['Generic', 'Preferred', 'Non-preferred'].includes(displayName)
      ) {
        displayVal += ' (after Ded.)'
      } else {
        displayVal = displayVal || 'BenefitDisplayName'
      }
    }

    if (label === 'Benefit Highlights') {
      if (
        notes &&
        notes.length === 0 &&
        ['Primary Care', 'Specialist Visit', 'Urgent Care', 'Emergency Room'].includes(displayName)
      ) {
        displayVal += ' (after Ded.)'
      } else {
        displayVal = displayVal || 'BenefitDisplayName'
      }
    }
  } else if (notes && notes.length === 0 && displayVal.includes('Coinsurance')) {
    displayVal += ' (after Ded.)'
  }

  return displayVal || 'BenefitDisplayName'
}
