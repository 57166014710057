import React from 'react'
import PropTypes from 'prop-types'

import Container from '@hixme-ui/container'
import FootNote from './FootNote'

const FootNotes = ({ notes, planId }) => (
  <Container flex noPadding direction="column">
    {Object.keys(notes).map((key) => (
      <FootNote key={`${planId}-${key}`} id={key} text={notes[key]} />
    ))}
  </Container>
)

FootNotes.propTypes = {
  notes: PropTypes.object.isRequired,
  planId: PropTypes.string.isRequired,
}

export default FootNotes
