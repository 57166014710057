import React from 'react'
import BaseIcon from './BaseIcon'

/*eslint-disable */
export default class Group extends BaseIcon {
  static defaultProps = {
    width: 23,
    height: 15,
    viewBox: '1 5 23 15'
  }
  render () {
    return (
      <svg {...this.getSvgProps()}>
        <path d='M6.3082 17.4897v-.0067c.0042-.3636.1645-1.506 1.8627-2.545.3104-.4223.5662-.998.827-1.6626.1805-.4598.15-.8518.15-1.41 0-.4125.0776-1.0744-.0245-1.438-.3455-1.2292-1.2177-1.5684-2.24-1.5684-1.0226 0-1.8957.3408-2.2403 1.5722-.1018.3624-.024 1.023-.024 1.4343 0 .5594-.03.9527.151 1.4137.3137.7974.6124 1.4665 1.011 1.8932-1.5523.285-2.6013 1.095-3.3096 1.4105C1.007 17.2357 1 17.951 1 17.951v1.215l5.3082-.001v-1.6753zm10.861-3.819c.5494-.589.966-1.5094 1.3942-2.6004.2485-.6323.2058-1.171.2058-1.9384 0-.567.1066-1.4768-.034-1.9763C18.2605 5.466 17.0622 5 15.6572 5c-1.4058 0-2.6063.4684-3.0795 2.1608-.1396.4982-.0327 1.4057-.0327 1.971 0 .7695-.0417 1.3097.2074 1.943.4312 1.0967.8413 2.0156 1.3895 2.6023-2.1338.392-3.5758 1.505-4.5485 1.9388-2.013.8976-2.023 1.8802-2.023 1.8802v1.6698l16.2114-.0023V17.496s-.01-.9858-2.0304-1.884c-.9788-.435-2.432-1.556-4.5825-1.9412z'
          {...this.getPathProps()} />
      </svg>
    )
  }
}
/*eslint-enable */
