import React from 'react'
import { MrModal } from '@hixme/modal'
import { Grid, Row, Col } from 'react-flexbox-grid'

// hixme-ui
import Container from '@hixme-ui/container'
import Separator from '@hixme-ui/separator'
import Text from '@hixme-ui/text'

import balancedSrc from '../assets/workerBalanced.png'
import flexibleSrc from '../assets/workerFlexible.png'
import secureSrc from '../assets/workerSecure.png'

const imgStyle = {
  maxWidth: '90%',
  maxHeight: '90%',
}

const LearnMoreModal = () => (
  <MrModal contentPadding="65px 0 0 0">
    <Grid fluid style={{ maxWidth: '1100px' }}>
      <Row center="xs">
        <Col xs={12} sm={6} md={4} style={{ padding: '0', marginBottom: '50px' }}>
          <img alt="flexible Bundle" style={imgStyle} src={flexibleSrc} />
          <Container>
            <Text bolder primary uppercase larger>
              Flexible
            </Text>
          </Container>
          <Separator size="1px" />
          <Container padding="0 20px">
            <Text thin light large center>
              A lower cost core insurance plan with a larger amount of additional coverage
            </Text>
          </Container>
          <br />
          {/*
            <Container>
              <a href={'/'}>
                <Icon download primary />
                <Text
                  primary
                  style={{ marginLeft: '10px' }}
                >
                  {BUNDLE_LEVEL_FLEXIBLE} Bundle Overview
                </Text>
              </a>
            </Container>
          */}
        </Col>
        <Col xs={12} sm={6} md={4} style={{ padding: '0', marginBottom: '50px' }}>
          <img alt="balanced Bundle" style={imgStyle} src={balancedSrc} />
          <Container>
            <Text bolder primary uppercase larger>
              Balanced
            </Text>
          </Container>
          <Separator size="1px" />
          <Container padding="0 20px">
            <Text thin light large center>
              A medium cost core insurance plan with a balanced amount of additional coverage
            </Text>
          </Container>
          <br />
          {/*
            <Container>
              <a href={'/'}>
                <Icon download primary />
                <Text
                  primary
                  style={{ marginLeft: '10px' }}
                >
                  {BUNDLE_LEVEL_BALANCED} Bundle Overview
                </Text>
              </a>
            </Container>
          */}
        </Col>
        <Col xs={12} sm={6} md={4} style={{ padding: '0', marginBottom: '50px' }}>
          <img alt="secure Bundle" style={imgStyle} src={secureSrc} />
          <Container>
            <Text bolder primary uppercase larger>
              Secure
            </Text>
          </Container>
          <Separator size="1px" />
          <Container padding="0 20px">
            <Text thin light large center>
              A higher cost core insurance plan with a smaller amount of additional coverage
            </Text>
          </Container>
          <br />
          {/*
            <Container>
              <a href={'/'}>
                <Icon download primary />{}
                <Text
                  download
                  primary
                  style={{ marginLeft: '10px' }}
                >
                  {BUNDLE_LEVEL_SECURE} Bundle Overview
                </Text>
              </a>
            </Container>
          */}
        </Col>
      </Row>
    </Grid>
  </MrModal>
)

LearnMoreModal.displayName = 'LearnMoreModal'

export default LearnMoreModal
