/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const Row = styled.div`
  align-items: center;
  background-color: ${({ title }) => (title ? 'rgba(185,197,205,0.2)' : 'white')};
  display: flex;
  font-weight: ${({ title }) => (title ? 'bold' : 'normal')};
  height: ${({ expanded }) => (expanded ? '80px' : '50px')};
  justify-content: flex-start;
  padding: 0 20px;
  text-align: ${({ title }) => (title ? 'center' : 'left')};
  width: 100%;
`
