import { namespaceActions } from 'modules/redux-utils'
import { NAME } from './constants'

// Related to questionnaire itself
const INIT_QUESTIONNAIRE = 'INIT_QUESTIONNAIRE'
const RESET_QUESTIONNAIRE = 'RESET_QUESTIONNAIRE'
const START_QUESTIONNAIRE = 'START_QUESTIONNAIRE'
const NEXT_QUESTION = 'NEXT_QUESTION'
const PREV_QUESTION = 'PREV_QUESTION'

// Related to medical question responses
const LOAD_RESPONSES = 'LOAD_RESPONSES'
const LOAD_QUESTIONANSWERS = 'LOAD_QUESTIONANSWERS'

// Related to person selector modal
const LOAD_PERSONS = 'LOAD_PERSONS'
const UPDATE_RESPONDERS_BYINDEX = 'UPDATE_RESPONDERS_BYINDEX'

// Not being used
// const LOAD_RESPONSE = 'LOAD_RESPONSE'
// const SET_SELECTED_PERSONS = 'SET_SELECTED_PERSONS'

export default namespaceActions(NAME)([
  INIT_QUESTIONNAIRE,
  RESET_QUESTIONNAIRE,
  START_QUESTIONNAIRE,
  NEXT_QUESTION,
  PREV_QUESTION,

  // question responses
  LOAD_RESPONSES,
  LOAD_QUESTIONANSWERS,

  // person selector
  LOAD_PERSONS,
  UPDATE_RESPONDERS_BYINDEX,

  // Not being used
  // LOAD_RESPONSE,
  // SET_SELECTED_PERSONS,
])
