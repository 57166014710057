import { createReducer } from 'modules/redux-utils'
import t from './actionTypes'

// initialState
const initialState = {
  bundle: {
    added: false,
    active: false,
    favorite: false,
  },
}

export default createReducer(initialState, {
  [t.TOGGLE_FAVORITE]: (state) => ({
    ...state,
    favorite: !state.favorite,
  }),
  [t.REMOVE_FAVORITE]: (state) => ({
    ...state,
    favorite: false,
  }),
  [t.ADD_FAVORITE]: (state) => ({
    ...state,
    favorite: true,
  }),
  [t.BUNDLE_ADDED]: (state) => ({
    ...state,
    added: true,
  }),
  [t.BUNDLE_REMOVED]: (state) => ({
    ...state,
    added: false,
  }),
})
