import React from 'react'
import PropTypes from 'prop-types'

import {
  EPOTooltip,
  HMOTooltip,
  HSAHDHPTooltip,
  HSPTooltip,
  PPOTooltip,
  POSTooltip,
} from './components'

// TODO: receive key as prop in order to make the display dynamic
const BundlePlanTypeTooltips = ({ IsHsa, PlanType, tooltipId }) => {
  const key = `${IsHsa}-${PlanType}`
  return (
    <div>
      {
        {
          'false-EPO': <EPOTooltip tooltipId={tooltipId} />,
          'false-HMO': <HMOTooltip tooltipId={tooltipId} />,
          'false-HSP': <HSPTooltip tooltipId={tooltipId} />,
          'false-POS': <POSTooltip tooltipId={tooltipId} />,
          'false-PPO': <PPOTooltip tooltipId={tooltipId} />,
          'true-HSA': <HSAHDHPTooltip tooltipId={tooltipId} />,
        }[key]
      }
    </div>
  )
}

BundlePlanTypeTooltips.propTypes = {
  IsHsa: PropTypes.bool.isRequired,
  PlanType: PropTypes.string.isRequired,
  tooltipId: PropTypes.string.isRequired,
}

BundlePlanTypeTooltips.defaultProps = {}

export default BundlePlanTypeTooltips
