import { connect } from 'react-redux'
import { setModal } from '@hixme/modal'

import { OpenTermsAndConditionsModal } from 'components/Modals'
import { TERMS_AND_CONDITIONS } from 'modal-constants'

export default connect(
  null,
  (dispatch) => ({
    onClickHandler: () => {
      dispatch(setModal(TERMS_AND_CONDITIONS))
    },
  })
)(OpenTermsAndConditionsModal)
