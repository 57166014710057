import { connect } from 'react-redux'
import SignOut from '../components/SignOut'

import { logout } from '../actions'

const mapDispatchToProps = (dispatch) => ({
  logout: (e) => {
    e.preventDefault()
    dispatch(logout())
  },
})

export default connect(
  undefined,
  mapDispatchToProps
)(SignOut)
