export const QLEEntryIDQueryKey = 'qle-id'
export const QLETypeURLParam = ':qleType'

export enum QLEType {
  relocation = 'relo',
  addMember = 'add-member',
  removeMember = 'remove-member',
}

export enum PersonRelationship {
  employee = 'EMPLOYEE',
  spouse = 'SPOUSE',
  domesticPartner = 'DOMESTIC_PARTNER',
  child = 'CHILD',
  childOfDomesticPartner = 'CHILD_OF_DOMESTIC_PARTNER',
}

export const QLETypeMapper = {
  Remove: QLEType.removeMember,
  Relocation: QLEType.relocation,
  Add: QLEType.addMember
}

export const QLEModalKey = 'qle-annoucement'

export const DAYS_BEFORE_NEXT_QLE = 5
