import React from 'react'
import BaseIcon from './BaseIcon'

/*eslint-disable */
export default class Building extends BaseIcon {
  static defaultProps = {
    width: 17,
    height: 19,
    viewBox: '3 3 19 17'
  }
  render () {
    return (
      <svg
        {...this.getSvgProps()}>
        <path d='M18.8333333 14.5555556h1.5833334v-1.5833334h-1.5833334v1.5833334zm0 3.1666666h1.5833334v-1.5833333h-1.5833334v1.5833333zM12.5 14.5555556h1.5833333v-1.5833334H12.5v1.5833334zm0 3.1666666h1.5833333v-1.5833333H12.5v1.5833333zm3.1666667-3.1666666H17.25v-1.5833334h-1.5833333v1.5833334zm0 3.1666666H17.25v-1.5833333h-1.5833333v1.5833333zM4.58333333 7.16666667h6.33333337V5.58333333H4.58333333v1.58333334zm0 3.16666663h6.33333337V8.75H4.58333333v1.5833333zm0 3.1666667h6.33333337v-1.5833333H4.58333333V13.5zm0 3.1666667h6.33333337v-1.5833334H4.58333333v1.5833334zM12.5 11.3888889V4.79166667C12.5 4.34306634 12.1569337 4 11.7083333 4H3.79166667C3.34306634 4 3 4.34306634 3 4.79166667V18.5138889c0 .4486003.34306634.7916667.79166667.7916667H21.2083333c.4486004 0 .7916667-.3430664.7916667-.7916667v-6.3333333c0-.4486004-.3430663-.7916667-.7916667-.7916667H12.5z'
          fill={this.props.fill}
          fillRule='evenodd' />
      </svg>
    )
  }
}
/*eslint-enable */
