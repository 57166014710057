import * as actions from './actions'
import * as components from './components'
import * as containers from './containers'
import * as constants from './constants'
import * as selectors from './selectors'
import reducer from './reducer'

export default {
  actions,
  components,
  containers,
  constants,
  selectors,
  reducer,
}
