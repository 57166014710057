import { createReducer } from 'modules/redux-utils'
import uuid from 'uuid/v4'

import ActionType from './actionTypes'

const initialState = {
  notifications: [],
  showNotifications: false,
}

export default createReducer(initialState, {
  [ActionType.HIDE_NOTIFICATIONS]: (state) =>
    Object.assign({}, state, {
      showNotifications: false,
    }),
  [ActionType.CREATE_NOTIFICATION]: (state, payload) => ({
    notifications: [Object.assign({}, payload, { Id: uuid() })],
    showNotifications: true,
  }),
})
