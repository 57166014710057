import React, { FC } from 'react'
import Icon from '@hixme-ui/icon'

export type CheckboxProps = {
  name?: string
  checked: boolean
  onChange?: ({ name, nextValue }: { name: string; nextValue: boolean }) => void
  disabled: boolean
}

export const Checkbox: FC<CheckboxProps> = ({ name, checked, onChange, disabled }) => {
  const onClick = () => {
    if (!disabled) {
      onChange?.({
        name,
        nextValue: !checked,
      })
    }
  }

  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        cursor: 'pointer',
        height: 16,
        width: 16,
        borderWidth: 1,
        borderRadius: 4,
        borderStyle: 'solid',
        backgroundColor: checked ? '#1677FF' : '#ffffff',
        borderColor: '#00000026',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {checked ? <Icon check color={'white'} /> : null}
    </div>
  )
}
