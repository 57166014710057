import React from 'react'
import { Drawer, DrawerProps } from '@surecompanies/core_components'
import { mapStateToProps } from 'routes/Benefits/HealthBundles/modules/health-bundles/containers/CompareTableContainer'
import CompareTable from 'routes/Benefits/HealthBundles/modules/health-bundles/components/CompareTable/CompareTable'
import { connect } from 'react-redux'
import { selectors } from 'routes/Benefits/HealthBundles/modules/health-bundles'
import { Stack, Typography } from '@sureco/design-system'

const containerMapStateToProps = (state) => ({
  plans: selectors.getCurrentGroupFavoriteBundles(state),
})

const QLECompareTableContainer = connect(mapStateToProps, (_, ownProps) => ({
  onSelectPlan: () => {
    ownProps.onClose?.()
  },
}))(CompareTable)

export const QLEComparisonTable = connect(containerMapStateToProps)(QLECompareTableContainer)

export const QLEBundleCompareDrawer = ({ afterOpenChange, ...props }: DrawerProps) => {
  return (
    <Drawer
      title={
        <Stack direction="row" style={{ justifyContent: 'center' }}>
          <Typography size={24} bold color="base">
            Compare favorite plans
          </Typography>
        </Stack>
      }
      width={'100%'}
      footer={false}
      afterOpenChange={(isOpen) => {
        if (isOpen) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'unset'
        }
        afterOpenChange?.(isOpen)
      }}
      {...props}
    >
      <QLEComparisonTable onClose={props.onClose} />
    </Drawer>
  )
}
