import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withState } from 'recompose'
import ClickOutside from 'react-click-outside'

import Button from '@hixme-ui/button'
import { Link } from 'react-router'
import Text from '@hixme-ui/text'
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'

import { selectors as cartSelectors } from 'store/modules/cart'

import { CartSummaryContainer } from 'containers/CartSummaryContainer'
import ModalSlider from 'components/ModalSlider'
import classes from './CartSummaryModal.scss'

const enhanced = withState('open', 'setOpen', false)

const CartSummaryModal = enhanced(({ cartSummary, open, setOpen }) => {
  const toggleOpen = () => setOpen(!open)
  const closeModal = () => setOpen(false)

  return (
    <div>
      <ClickOutside onClickOutside={closeModal}>
        <Container flex noPadding alignItems="center" width="160px" justifyContent="space-between">
          <span role="button" className={classes.pointer} onClick={toggleOpen}>
            <Text>My Benefits</Text>
          </span>
          <span role="button" onClick={toggleOpen} className={classes.circleButton}>
            <Text primary large>
              {cartSummary.count}
            </Text>
          </span>
          <span role="button" className={classes.pointer} onClick={toggleOpen}>
            <Icon arrowDown light />
          </span>
        </Container>
        <ModalSlider
          defaultClassName={classes.default}
          openClassName={classes.open}
          onClose={toggleOpen}
          open={open}
        >
          <CartSummaryContainer />
          <br />
          <Link to="/cart" style={{ width: '100%' }}>
            <Button block onClick={toggleOpen}>
              Go to My Benefits
            </Button>
          </Link>
        </ModalSlider>
      </ClickOutside>
    </div>
  )
})

CartSummaryModal.propTypes = {
  cartSummary: PropTypes.object,
}

const mapStateToProps = (state) => ({
  cartSummary: cartSelectors.getCartSummary(state),
})

export default connect(mapStateToProps)(CartSummaryModal)
