import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransitionGroup } from 'react-transition-group'

import Container from '@hixme-ui/container'

import classes from '../../styles/transitionStyles.scss'

import FileUpload from './FileUpload'

const FileUploadList = ({
  documentTypes,
  handleFileTypeChange,
  handleNoteChange,
  handleRemoveFile,
  wrappers,
}) => (
  <Container flex noPadding direction="column">
    <CSSTransitionGroup
      transitionName={{
        enter: classes.enter,
        enterActive: classes.enterActive,
        leave: classes.leave,
        leaveActive: classes.leaveActive,
      }}
      transitionEnterTimeout={300}
      transitionLeaveTimeout={300}
    >
      {wrappers.map((wrapper) => (
        <FileUpload
          documentTypes={documentTypes}
          handleFileTypeChange={handleFileTypeChange}
          handleNoteChange={handleNoteChange}
          handleRemoveFile={handleRemoveFile}
          key={wrapper.file.lastModified}
          wrapper={wrapper}
        />
      ))}
    </CSSTransitionGroup>
  </Container>
)

FileUploadList.propTypes = {
  documentTypes: PropTypes.array,
  handleFileTypeChange: PropTypes.func,
  handleNoteChange: PropTypes.func,
  handleRemoveFile: PropTypes.func,
  wrappers: PropTypes.array,
}

FileUploadList.defaultProps = {
  documentTypes: [],
  handleFileTypeChange: () => null,
  handleNoteChange: () => null,
  handleRemoveFile: () => null,
  wrappers: [],
}

export default FileUploadList
