import React from 'react'

// hixme-ui
import CloseButton from '@hixme-ui/close-button'
import Card from '@hixme-ui/card'
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import { Row, Col } from 'react-flexbox-grid'
import Text from '@hixme-ui/text'

import classes from './Notify.scss'

export type NotifyProps = {
  autoClose?: boolean
  message: string
  onClose: () => void | null // since notification manager passes `() => null` by default
  timeToClose?: number
  type?: 'general' | 'success' | 'error'

  key?: string | number
}

const Notify = ({
  autoClose = true,
  message,
  onClose,
  timeToClose = 5000,
  type = 'general',
  key,
}: NotifyProps): JSX.Element => {
  let timeoutId
  function doClose() {
    clearTimeout(timeoutId)
    onClose()
  }

  if (autoClose) {
    timeoutId = setTimeout(doClose, timeToClose)
  }

  const getIcon = (notificationType) => {
    if (notificationType === 'success') {
      return <Icon checkMarkCircle white size={3} />
    }
    if (notificationType === 'error') return <Icon alert white size={3} />
    return <Icon info white size={3} />
  }

  return (
    <Row center="xs" key={key}>
      <Col xs={12} sm={11} md={9} lg={7}>
        <Card noPadding white height="70px" margin="20px">
          <Row
            between="xs"
            style={{ width: '100%', height: '100%', margin: '0' }}
          >
            <Col xs={3} sm={2} md={2} lg={2} style={{ padding: '0 10px 0 0' }}>
              <Row style={{ width: '100%', height: '100%' }}>
                <div className={classes[type]} style={{ height: '100%' }}>
                  <Container
                    flex
                    noPadding
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    {getIcon(type)}
                  </Container>
                </div>
              </Row>
            </Col>
            <Col xs={8} sm={9} md={9} lg={9}>
              <Row
                middle="xs"
                style={{ width: '100%', height: '100%', padding: '10px' }}
              >
                <div>
                  {type === 'success' && (
                    <span style={{ margin: '0 5px 0 0' }}>
                      <Text upperCase primary bold>
                        Success:{' '}
                      </Text>
                    </span>
                  )}
                  {type === 'error' && (
                    <span style={{ margin: '0 5px 0 0' }}>
                      <Text upperCase error bold>
                        Error:{' '}
                      </Text>
                    </span>
                  )}
                  <Text id="message" thin>
                    {message}
                  </Text>
                </div>
              </Row>
            </Col>
            <Col xs={1}>
              <Row
                middle="xs"
                end="xs"
                style={{ height: '100%', width: '100%' }}
              >
                <CloseButton onClick={doClose} noLabel />
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
}

export default Notify
