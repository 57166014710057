import React from 'react'
import PropTypes from 'prop-types'
import { MrModal } from '@hixme/modal'
import { Row, Col } from 'react-flexbox-grid'
import uuid from 'uuid/v4'

// hixme-ui
import Button from '@hixme-ui/button'
import ContentContainer from '@hixme-ui/content-container'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'

import CircleStep from 'components/CircleStep'

// Bundle Level images
import workerBalanced from 'routes/Benefits/HealthBundles/components/assets/workerBalanced-mini.png'
import workerFlexible from 'routes/Benefits/HealthBundles/components/assets/workerFlexible-mini.png'
import workerSecure from 'routes/Benefits/HealthBundles/components/assets/workerSecure-mini.png'

const SplitPreviewModal = ({ groups, handleContinue, handleEditGroups }) => (
  <MrModal>
    <ContentContainer>
      <Row center="xs">
        <Title small center thin light>
          Bundle group recommendation:{' '}
          <Text bold light>
            Multiple groups
          </Text>
        </Title>
      </Row>
      <Row center="xs" style={{ margin: '20px 0' }}>
        <Col xs={12} sm={7}>
          <Text p large thin light>
            We have set you up to shop for medical in {groups.length} groups to let you take
            advantage of Right-Fitting Coverage.&#8482;
          </Text>
        </Col>
      </Row>
      <Row style={{ maxWidth: '600px', margin: '0px auto' }}>
        <Col xs={12}>
          <Row
            center="xs"
            middle="xs"
            style={{
              padding: '20px 0 ',
              marginBottom: '10px',
              borderBottom: '1px solid #DBE2E8',
            }}
          >
            <Col xs={2}>
              <Text larger thin>
                #
              </Text>
            </Col>
            <Col xs={5}>
              <Text upperCase large>
                Group
              </Text>
            </Col>
            <Col xs={5}>
              <Text upperCase large>
                Recommendation
              </Text>
            </Col>
          </Row>
          {groups.map((group) => {
            const bundleLevelImgPath = (bundleLevel) => {
              if (bundleLevel === 'Secure') return workerSecure
              if (bundleLevel === 'Flexible') return workerFlexible
              return workerBalanced
            }

            return (
              <Row
                center="xs"
                key={uuid()}
                style={{
                  marginBottom: '10px',
                  borderBottom: '1px solid #DBE2E8',
                }}
              >
                <Col xs={2}>
                  <Row top="xs" center="xs" style={{ margin: '20px 0' }}>
                    <CircleStep>{groups.indexOf(group) + 1}</CircleStep>
                  </Row>
                </Col>
                <Col xs={5}>
                  <Row top="xs" center="xs" style={{ margin: '30px 0' }}>
                    {group.Persons.map((person) => (
                      <Col xs={12} key={uuid()}>
                        <Text key={uuid()} large light thin>
                          {person.Name}
                        </Text>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col xs={5}>
                  <Row middle="xs" center="xs">
                    <Col xs={12}>
                      <img
                        src={bundleLevelImgPath(group.BundleRecommendation)}
                        alt="Bundle level graphic"
                      />
                    </Col>
                    <Col xs={12}>
                      <div style={{ margin: '20px 0' }}>
                        <Text upperCase primary thin medium>
                          {group.BundleRecommendation}
                        </Text>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          })}
        </Col>
      </Row>
      <Row center="xs" middle="xs" style={{ maxWidth: '550px', margin: '30px auto' }}>
        <Col xs={12} sm={3} style={{ marginBottom: '10px' }}>
          <Button outline block onClick={handleEditGroups}>
            Edit groups
          </Button>
        </Col>
        <Col xs={12} sm={9} style={{ marginBottom: '10px' }}>
          <Button block onClick={handleContinue}>
            Shop for Bundle group 1 {'>'}
          </Button>
        </Col>
      </Row>
      <Row center="xs" style={{ margin: '20px 0' }}>
        <Text light thin>
          You can always go to
          <Text blue cursor="pointer">
            <span role="button" tabIndex={0} onClick={handleEditGroups}>
              {' '}
              Edit Bundle groups{' '}
            </span>
          </Text>
          later from the Bundles shopping screen.
        </Text>
      </Row>
    </ContentContainer>
  </MrModal>
)

SplitPreviewModal.propTypes = {
  groups: PropTypes.array,
  handleContinue: PropTypes.func,
  handleEditGroups: PropTypes.func,
}

SplitPreviewModal.defaultProps = {
  groups: [],
  handleContinue: () => null,
  handleEditGroups: () => null,
}

export default SplitPreviewModal
