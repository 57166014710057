import React from 'react'
import BaseIcon from './BaseIcon'

/*eslint-disable */
export default class LineChart extends BaseIcon {
  static defaultProps = {
    width: 18,
    height: 18,
    viewBox: '3 3 18 18'
  }
  render () {
    return (
      <svg {...this.getSvgProps()}>
        <path d='M6.0311927 10.0906441c0-2.23837432 1.82107708-4.0594514 4.0596771-4.0594514 2.2383744 0 4.0594515 1.82107708 4.0594515 4.0594514 0 2.2383744-1.8210771 4.0594515-4.0594515 4.0594515-2.23860002 0-4.0596771-1.8210771-4.0596771-4.0594515m9.0385818 3.5058386c.6994073-.9914477 1.1117394-2.200233 1.1117394-3.5058386C16.1815139 6.72676334 13.4547506 4 10.0908698 4 6.72721472 4 4 6.72676334 4 10.0906441c0 3.3634294 2.72721472 6.0906442 6.0908698 6.0906442 1.3121505 0 2.5263523-.4161689 3.5205083-1.1218955l4.8213744 4.8218258c.4423486.4421229 1.1329542.4757505 1.5346789.0738.4017248-.4017248.3685487-1.0923303-.0738-1.5344532l-4.8238569-4.8240827z'
          {...this.getPathProps()} />
      </svg>
    )
  }
}
/*eslint-enable */
