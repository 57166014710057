import { connect } from 'react-redux'
import { compose, withReducer, withHandlers } from 'recompose'
import { enrollmentService } from '@hixme/api'
import { getModalData, clearModal } from '@hixme/modal'

// project
import { createErrorNotification } from 'modules/notification-manager/actions'
import {
  getPersonSelector,
  getDependentsEligibleForMedicalBenefits,
  getEmployeeKey,
  getDependentsKeys,
} from 'store/modules/persons/selectors'
import questions2018 from 'data/EnrollmentQuestions'
import {
  getEnrollmentPublicKey,
  getEnrollmentResponses,
} from 'store/modules/user-session/selectors'
import { loadEnrollmentResponses } from 'store/modules/user-session/actions'
import { getWaiter, callWaiter } from 'redux-waiter'

// module
import EditQuestions from './EditQuestions'

const mapStateToProps = (state) => {
  const responses = getEnrollmentResponses(state)
  const person = getPersonSelector(state)
  const dependents = getDependentsEligibleForMedicalBenefits(state)
  const persons = [person, ...dependents]
  const modalData = getModalData(state) || {}
  return {
    isProcessing: getWaiter(state, 'responsesPost').isPending,
    modalData,
    persons,
    questions: questions2018,
    responses,
  }
}

const mapDispatchToProps = (dispatch) => ({
  editQuestions: (responses) =>
    dispatch((_, getState) => {
      const enrollmentKey = getEnrollmentPublicKey(getState())
      const EmployeePublicKey = getEmployeeKey(getState())
      const allPersons = [EmployeePublicKey, ...getDependentsKeys(getState())]
      const Responses = responses.map((r) => ({
        EmployeePublicKey,
        QuestionNumber: r.QuestionNumber,
        QuestionPublicKey: r.QuestionPublicKey,
        Responders: allPersons.map((p) => ({
          PersonPublicKey: p,
          Response: r.Responders.includes(p) ? 'Yes' : 'No',
        })),
      }))
      const body = { Responses }

      return dispatch(
        callWaiter('responsesPost', {
          requestCreator: () =>
            enrollmentService({
              route: '{enrollmentKey}/responses',
              method: 'POST',
              params: { enrollmentKey },
              body,
            }),
        })
      )
        .then((data) => {
          dispatch(loadEnrollmentResponses(data.Responses))
          dispatch(clearModal())
        })
        .catch(() =>
          dispatch(
            createErrorNotification('Error saving medical responses. Please reload the page.')
          )
        )
    }),
  closeModal: () => dispatch(clearModal()),
})

const reducer = (responses, action) => {
  switch (action.type) {
    case 'TOGGLE_SELECT_PERSON':
      return responses.map((r) => {
        if (r.QuestionNumber === action.payload.QuestionNumber) {
          if (r.Responders.includes(action.payload.Responder)) {
            return {
              ...r,
              Responders: r.Responders.filter((p) => p !== action.payload.Responder),
            }
          }
          return {
            ...r,
            Responders: [...r.Responders, action.payload.Responder],
          }
        }
        return r
      })
    default:
      return responses
  }
}

const initialState = (props) => props.responses

const enhance = withReducer('responses', 'dispatch', reducer, initialState)

const withActions = withHandlers({
  toggleSelectPerson: ({ dispatch }) => (e, QuestionNumber) => {
    dispatch({
      type: 'TOGGLE_SELECT_PERSON',
      payload: { QuestionNumber, Responder: e.target.value },
    })
  },
  cancel: ({ closeModal, modalData }) => () => {
    closeModal()
    modalData && modalData.onHideModal && modalData.onHideModal()
  },
  submitQuestions: ({ editQuestions, responses, modalData }) => () =>
    editQuestions(responses).then(
      () => modalData && modalData.onHideModal && modalData.onHideModal()
    ),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  enhance,
  withActions
)(EditQuestions)
