import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalProps } from '@surecompanies/core_components'
import RelocationIcon from './home.svg'
import AddMemberIcon from './user-plus.svg'
import RemoveMemberIcon from './user-minus.svg'
import CloseIcon from './close.svg'
import { QLEModalKey } from '../constants'
import { Stack, Typography } from '@sureco/design-system'
import useFeatureFlags from 'hooks/useFeatureFlags'

const AnnouncementIconWrapper = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
        width: 120,
        height: 120,
        background: 'rgba(8, 151, 156, 0.07)',
        borderRadius: 100,
      }}
    >
      {children}
    </div>
  )
}

export type QLEAnnouncementModalViewProps = Omit<ModalProps, 'onCancel'> & {
  onClose: () => void
}

/**
 * Modal based on AntD for QLE Announcement Modal.
 * This adds a required `onClose` prop and replaces `onCancel`
 */
export const QLEAnnouncementModalView = ({
  open,
  footer = false,
  onClose,
  ...props
}: QLEAnnouncementModalViewProps) => {
  const onCloseHandler = () => {
    onClose()
  }

  return (
    <Modal
      open={open}
      width={640}
      footer={footer}
      closable={false}
      {...props}
      onCancel={onCloseHandler}
    >
      <Stack space={16}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography bold style={{ flex: 1 }} size={16} color={'rgba(0, 0, 0, 0.75)'}>
            Announcement
          </Typography>
          <Button type="text" onClick={onCloseHandler}>
            <CloseIcon />
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            width: 592,
            height: 333,
            background: 'linear-gradient(310.87deg, #002329 20.2%, #00474F 86.19%)',
            borderRadius: 6,
            gap: 54,
          }}
        >
          <Stack style={{ alignItems: 'center' }} space={16}>
            <AnnouncementIconWrapper>
              <RelocationIcon />
            </AnnouncementIconWrapper>
            <Typography color={'white'} size={14} unselectable>
              Relocation
            </Typography>
          </Stack>
          <Stack style={{ alignItems: 'center' }} space={16}>
            <AnnouncementIconWrapper>
              <AddMemberIcon />
            </AnnouncementIconWrapper>
            <Typography color={'white'} size={14} unselectable>
              Add Dependent
            </Typography>
          </Stack>
          <Stack style={{ alignItems: 'center' }} space={16}>
            <AnnouncementIconWrapper>
              <RemoveMemberIcon />
            </AnnouncementIconWrapper>
            <Typography color={'white'} size={14} unselectable>
              Remove Dependent
            </Typography>
          </Stack>
        </div>
        <Stack>
          <Typography size={20} bold style={{ margin: '8px 0px' }}>
            Qualifying Life Events
          </Typography>
          <Typography size={14} color={'rgba(0, 0, 0, 0.88)'}>
            When life happens the Enrollment platform has your back. Starting today we provide
            in-product tools for applying for a variety of qualifying life events.
          </Typography>
        </Stack>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="primary"
            size="large"
            style={{ background: '#1877F2' }}
            onClick={onCloseHandler}
          >
            Close
          </Button>
        </div>
      </Stack>
    </Modal>
  )
}

/**
 * Shows `QLEAnnoucementModalView` based on key in localStorage (temp) that is unique per user.
 * If the entry exists, the modal will not be shown.
 * The modal is considered shown when it is closed. Refreshing the page without closing will not register
 * as "shown"
 */
export type QLEAnnouncementModalProps = {
  id: string
} & QLEAnnouncementModalViewProps

export const QLEAnnouncementModal = ({ id }: QLEAnnouncementModalProps) => {
  const [visible, setVisible] = useState(false)
  const { flags } = useFeatureFlags()

  const onOpen = () => {
    setVisible(true)
  }
  const onClose = () => {
    localStorage.setItem(`${QLEModalKey}-${id}`, '1')
    setVisible(false)
  }

  useEffect(() => {
    const hasSeenModal = localStorage.getItem(`${QLEModalKey}-${id}`)
    if (!hasSeenModal) {
      onOpen()
    }
  }, [])

  if (flags['qle-v-1']) {
    return <QLEAnnouncementModalView open={visible} onClose={onClose} />
  } else {
    return null
  }
}
