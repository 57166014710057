interface Bundle {
  PlanType: string;
  CarrierName: string;
}

interface Group {
  Bundles: Bundle[];
  Persons: any[];
}

// packages information about the current group for the ActivityLog
export const groupStatistics = (groups: Group[]) => {
  // 1. Number of elements in the list (groups)
  const numberOfGroups = groups.length;

  // 2. Total number of "Bundles" combined in all groups
  const totalBundles = groups.reduce((acc, group) => acc + group.Bundles.length, 0);

  // 3. Total number of "persons" in all groups combined
  const totalPersons = groups.reduce((acc, group) => acc + group.Persons.length, 0);

  // 4. Unique list of "PlanType" in all groups combined
  const planTypes = new Set<string>();
  groups.forEach(group => {
    group.Bundles.forEach(bundle => {
      planTypes.add(bundle.PlanType);
    });
  });

  // 5. Unique list of "CarrierName" in all groups combined
  const carrierNames = new Set<string>();
  groups.forEach(group => {
    group.Bundles.forEach(bundle => {
      carrierNames.add(bundle.CarrierName);
    });
  });

  return {
    numberOfGroups,
    totalBundles,
    totalPersons,
    uniquePlanTypes: Array.from(planTypes),
    uniqueCarrierNames: Array.from(carrierNames),
  };
}
