import React from 'react'
import PropTypes from 'prop-types'

const headerStyles = {
  backgroundColor: 'white',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
  height: '56px',
  left: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  padding: '0 20px 0 20px',
}
const imgStyles = { margin: '0 auto', height: '36px' }

const TermsAndConditionsHeader = ({ logoUrl }) => (
  <div style={headerStyles}>
    <img src={logoUrl} style={imgStyles} alt="" />
  </div>
)

TermsAndConditionsHeader.displayName = 'TermsAndConditionsHeader'
TermsAndConditionsHeader.propTypes = {
  logoUrl: PropTypes.string,
}
TermsAndConditionsHeader.defaultProps = {
  logoUrl: '',
}

export default TermsAndConditionsHeader
