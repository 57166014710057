import { Typography } from '@sureco/design-system'
import React, { useState } from 'react'
import packageJson from '../../package.json'
import { getEnvironment, isProduction } from '../modules/app-config/environment'

export const VersionCodeDebugBox = () => {
  const [visible, setVisible] = useState(!isProduction())
  const env = getEnvironment()
  const buildId = process.env.PIPELINE_BUILD_ID

  return (
    <div
      onClick={() => setVisible(!visible)}
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: 50,
        minWidth: 100,
        padding: 20,
        cursor: 'pointer',
      }}
    >
      {visible && (
        <Typography size={16}>
          Version {packageJson.version}, build {buildId || 'local'} (env: "{env}")
        </Typography>
      )}
    </div>
  )
}
