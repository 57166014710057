import { callWaiter } from 'redux-waiter'
import { enrollmentService } from '@hixme/api'
import { BENEFIT_TYPE_HOSPITAL_INDEMNITY } from '@hixme/benefit-types'

import userSession from 'store/modules/user-session'
import personsModule from 'store/modules/persons'
import cart from 'store/modules/cart'
import notification from 'modules/notification-manager'

import * as constants from './constants'
import * as selectors from './selectors'
import t from './actionTypes'

export const loadPersons = (persons) => ({
  type: t.LOAD_PERSONS,
  payload: persons,
})

export const setSelectedPersons = (personIds) => ({
  type: t.SET_SELECTED_PERSONS,
  payload: personIds,
})

// complex actions
export const loadHospitalIndemnity = () => (dispatch, getState) =>
  new Promise((resolve) => {
    const state = getState()
    const employee = personsModule.selectors.getPersonSelector(state)
    employee.disabled = true

    const dependents = personsModule.selectors.getDependentsEligibleForBenefits(state)
    dispatch(loadPersons([employee, ...dependents]))

    const hospitalIndemnityProduct = cart.selectors.getHospitalIndemnityProduct(state)
    const selectedIds = [employee.Id]
    if (hospitalIndemnityProduct) {
      selectedIds.push(...hospitalIndemnityProduct.Persons.map((person) => person.Id))
    } else {
      selectedIds.push(...dependents.map((person) => person.Id))
    }

    dispatch(setSelectedPersons(selectedIds))

    resolve()
  })

export const getHospitalIndemnity = () => (dispatch, getState) => {
  const state = getState()
  const req = dispatch(
    callWaiter(constants.GET_HOSPITAL_INDEMNITY, {
      requestCreator: () => {
        const PersonPublicKeys = selectors.getSelectedPersons(state).map((person) => person.Id)

        const EnrollmentPublicKey = userSession.selectors.getEnrollmentPublicKey(state)

        return enrollmentService({
          route: '{EnrollmentPublicKey}/client/benefits/{BenefitType}',
          params: {
            EnrollmentPublicKey,
            BenefitType: BENEFIT_TYPE_HOSPITAL_INDEMNITY,
            PersonPublicKeys,
          },
        })
      },
    })
  )

  return req
}

export const updateHospitalIndemnityWithCart = () => (dispatch, getState) => {
  const state = getState()
  const req = dispatch(getHospitalIndemnity())

  const currentProduct = cart.selectors.getHospitalIndemnityProduct(state)
  if (currentProduct) {
    req.then(
      (response) => {
        const hospitalIndemnityProduct = response.ClientBenefits.find(
          (plan) => plan.BenefitPublicKey === currentProduct.BenefitPublicKey
        )

        if (hospitalIndemnityProduct) {
          const coveredPersons = selectors.getSelectedPersons(state)
          const excludedPersons = selectors.getUnselectedPersons(state)

          dispatch(
            cart.actions.addHospitalIndemnityProduct({
              hospitalIndemnityProduct,
              coveredPersons,
              excludedPersons,
            })
          ).then(
            () => {},
            () => {
              dispatch(
                notification.actions.createNotification({
                  type: 'error',
                  message:
                    'There was an error saving your hospital indemnity selection. Please try again.',
                })
              )
            }
          )
        }
      },
      () => {
        dispatch(
          notification.actions.createNotification({
            type: 'error',
            message: 'There was an error retrieving hospital indemnity plans. Please try again.',
          })
        )
      }
    )
  }

  return req
}
