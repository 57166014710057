import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Button from '@hixme-ui/button'

const SaveAndContinueButton = ({ saveButtonClick }) => (
  <Button width="190px" onClick={saveButtonClick}>
    Save and continue
  </Button>
)

SaveAndContinueButton.displayName = 'SaveAndContinueButton'
SaveAndContinueButton.propTypes = {
  saveButtonClick: PropTypes.func,
}

SaveAndContinueButton.defaultProps = {
  saveButtonClick: () => {},
}

export default SaveAndContinueButton
