import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import { Input } from '@hixme-ui/forms'
import { format, isInvalidValue, sanitizeCurrencyValue } from './CurrencyInputUtils'

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 4,
  allowNegative: false,
  allowLeadingZeroes: false,
}

const CurrencyInput = ({ onBlur, ...inputProps }) => {
  const currencyMask = createNumberMask(defaultMaskOptions)

  const handleBlur = (e) => {
    if (!onBlur) return
    const value = sanitizeCurrencyValue(e.target.value)
    if (isInvalidValue(value)) {
      onBlur(e, {
        valid: false,
        value: e.target.value,
      })
      return
    }
    const formatted = format(value)
    onBlur(e, {
      valid: true,
      value: formatted,
    })
  }

  return (
    <MaskedInput
      inputMode="numeric"
      mask={currencyMask}
      render={(ref, props) => <Input innerRef={ref} {...props} />}
      onBlur={handleBlur}
      {...inputProps}
    />
  )
}

CurrencyInput.defaultProps = {
  inputMode: 'numeric',
  onBlur: null,
  maskOptions: {},
}

CurrencyInput.propTypes = {
  inputMode: PropTypes.string,
  onBlur: PropTypes.func,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.boolean,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.boolean,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.boolean,
    allowNegative: PropTypes.boolean,
    allowLeadingZeroes: PropTypes.boolean,
    integerLimit: PropTypes.number,
  }),
}

export default CurrencyInput
