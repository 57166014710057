import React from 'react'

import Container from '@hixme-ui/container'

import FieldValue from 'components/FieldValue'
import FieldName from 'components/FieldName'

const BundleHighlights = () => (
  <Container
    flex
    noPadding
    justifyContent="space-between"
    rounded
    background="#F8FAFC"
    padding="20px"
  >
    <Container flex noPadding>
      <Container flex noPadding direction="column" margin="0 20px 0 0">
        <FieldName>Primary Visit</FieldName>
        <FieldName>Specialist Visit</FieldName>
      </Container>
      <Container flex noPadding direction="column">
        <FieldValue purple>$30</FieldValue>
        <FieldValue purple>$35</FieldValue>
      </Container>
    </Container>

    <Container flex noPadding>
      <Container flex noPadding direction="column" margin="0 20px 0 0">
        <FieldName>Drugs Tier 1</FieldName>
        <FieldName>Drugs Tier 2</FieldName>
        <FieldName>Drugs Tier 3</FieldName>
      </Container>
      <Container flex noPadding direction="column">
        <FieldValue>$15</FieldValue>
        <FieldValue purple>$40</FieldValue>
        <FieldValue purple>$40</FieldValue>
      </Container>
    </Container>

    <Container flex noPadding>
      <Container flex noPadding direction="column" margin="0 20px 0 0">
        <FieldName>Inpatient deducable</FieldName>
        <FieldName>Inpatient coinsurance</FieldName>
      </Container>
      <Container flex noPadding direction="column">
        <FieldValue>$30</FieldValue>
        <FieldValue>$35</FieldValue>
      </Container>
    </Container>

    <Container flex noPadding>
      <Container flex noPadding direction="column" margin="0 20px 0 0">
        <FieldName>Outpatient deducable</FieldName>
        <FieldName>Outpatient coinsurance</FieldName>
      </Container>
      <Container flex noPadding direction="column">
        <FieldValue>$35</FieldValue>
        <FieldValue>$35</FieldValue>
      </Container>
    </Container>
  </Container>
)

BundleHighlights.displayName = 'BundleHighlights'

export default BundleHighlights
