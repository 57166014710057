import React from 'react'
import BaseIcon from './BaseIcon'

/*eslint-disable */
export default class Alert extends BaseIcon {
  static defaultProps = {
    width: 37,
    height: 33,
    viewBox: '-1 -1 37 33'
  }
  render () {
    return (
      <svg {...this.getSvgProps()}>
        <path d='M19.5258082 21.75168H16.308L15.2250411 8.67500308h5.3842192L19.5258082 21.75168zm-1.6086575 5.9657846c-1.2639452 0-2.2694795-1.0033231-2.2694795-2.2656s1.0055343-2.2660923 2.2694795-2.2660923 2.2699726 1.0038154 2.2699726 2.2660923-1.0060274 2.2656-2.2699726 2.2656zm16.7518356-2.4206769L21.5210959 2.64521846c-2.0465754-3.52541538-5.3980274-3.5273846-7.448548-.00443077L.87879452 25.3022031c-2.05052055 3.5224615-.3890959 6.4044307 3.69221918 6.4044307h26.3983562c4.0808219 0 5.7456986-2.8844307 3.6996164-6.4098461z'
          {...this.getPathProps()} />
      </svg>
    )
  }
}
/*eslint-enable */
