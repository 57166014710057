import React, { ReactElement, ReactNode } from 'react'

import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'
import { TooltipProps } from 'routes/Profile/routes/Person/components/DisplayField/DisplayField'

type FieldNameProps = {
  tooltipId?: string
  children: ReactNode
  Tooltip?: ReactElement<TooltipProps | string | null>
}

export default function FieldName({ tooltipId, children, Tooltip }: FieldNameProps) {
  return (
    <>
      <Text light>{children}</Text>
      {tooltipId && (
        <span data-tip data-for={tooltipId} style={{ cursor: 'pointer', paddingLeft: '10px' }}>
          <Icon info light size={1.3} />
        </span>
      )}
      {Tooltip}
    </>
  )
}
