import React from 'react'
import PropTypes from 'prop-types'

import Text from '@hixme-ui/text'

const OpenTermsAndConditionsModal = ({ onClickHandler }) => (
  <Text light small onClick={onClickHandler} cursor="pointer">
    Terms & Conditions
  </Text>
)

OpenTermsAndConditionsModal.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
}

export default OpenTermsAndConditionsModal
