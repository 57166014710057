export const ENVIRONMENT_DEV = 'dev'
export const ENVIRONMENT_TEST = 'test'
export const ENVIRONMENT_QA = 'qa'
export const ENVIRONMENT_DEMO = 'demo'
export const ENVIRONMENT_STAGE = 'stage'
export const ENVIRONMENT_PROD = 'prod'

export const ENVIRONMENT_PREFIX_DEV = 'dev-'
export const ENVIRONMENT_PREFIX_TEST = 'test-'
export const ENVIRONMENT_PREFIX_QA= 'qa-'
export const ENVIRONMENT_PREFIX_DEMO = 'demo-'
export const ENVIRONMENT_PREFIX_STAGE = 'stage-'

const { hostname } = window.location
let environment = 'prod'

// localhost and dev-hostname result in a dev environment. hostname.stage will be stage environment
// ie: localhost:3000 or dev-app.hixme.com will be dev
// ie: stage-app.hixme.com will be stage
// default is prod
if (
  hostname === 'localhost' ||
  hostname.indexOf(ENVIRONMENT_PREFIX_DEV) !== -1 ||
  hostname.indexOf(ENVIRONMENT_DEV) !== -1 ||
  hostname.indexOf('192.168') !== -1
) {
  environment = ENVIRONMENT_DEV
} else if (hostname.indexOf(ENVIRONMENT_PREFIX_TEST) !== -1) {
  environment = ENVIRONMENT_TEST
} else if (hostname.indexOf(ENVIRONMENT_PREFIX_DEMO) !== -1) {
  environment = ENVIRONMENT_DEMO
} else if (hostname.indexOf(ENVIRONMENT_PREFIX_STAGE) !== -1) {
  environment = ENVIRONMENT_STAGE
} else if (hostname.startsWith(ENVIRONMENT_PREFIX_QA) || hostname.includes(`.${ENVIRONMENT_QA}`)) {
  environment = ENVIRONMENT_QA
}

// helper functions
// Here dev and test are considered development environments
// Prod and demo are considered production level environments
export const isDevelopment = () =>
  environment === ENVIRONMENT_DEV ||
  environment === ENVIRONMENT_TEST ||
  environment === ENVIRONMENT_QA
export const isStaging = () => environment === ENVIRONMENT_STAGE
export const isTesting = () => environment === ENVIRONMENT_TEST
export const isDemo = () => environment === ENVIRONMENT_DEMO
export const isProduction = () =>
  environment === ENVIRONMENT_PROD ||
  environment === ENVIRONMENT_DEMO ||
  environment === ENVIRONMENT_STAGE

export const getEnvironment = () => environment

// prefix used for url building, to keep testing consistent
// link from dev-admin.hixme.com can link to dev-enrollme.hixme.com
export const getEnvironmentPrefix = () => {
  if (environment === ENVIRONMENT_DEV) {
    return ENVIRONMENT_PREFIX_DEV
  } else if (environment === ENVIRONMENT_TEST) {
    return ENVIRONMENT_PREFIX_TEST
  } else if (environment === ENVIRONMENT_DEMO) {
    return ENVIRONMENT_PREFIX_DEMO
  } else if (environment === ENVIRONMENT_STAGE) {
    return ENVIRONMENT_PREFIX_STAGE
  } else if (environment === ENVIRONMENT_QA) {
    return ENVIRONMENT_PREFIX_QA
  }

  // production doesn't require a prefix
  return ''
}

export const getLaunchDarklyConfig = () => {
  const LAUNCH_DARKLY_CONFIG_MAP = {
    dev: {
      clientSideID: '647e5a2d23dfee131a3e5c5c',
    },
    qa: {
      clientSideID: '6479137cbbaef41373c01c8f',
    },
    uat: {
      clientSideID: '647e5a3495a60913574bc456',
    },
    stage: {
      clientSideID: '647e5a3b2257ff133b1d1b24',
    },
    prod: {
      clientSideID: '6479137cbbaef41373c01c90',
    },
  }

  return LAUNCH_DARKLY_CONFIG_MAP[environment]
}

export default {
  isDevelopment,
  isStaging,
  isTesting,
  isDemo,
  isProduction,
  getEnvironment,
  getEnvironmentPrefix,
  getLaunchDarklyConfig,
}
