/* eslint-disable */
// TODO: Lint clean up, may take more than one pass...
import { createSelector } from 'reselect'
import isEqual from 'lodash/isEqual'

import { isEmployee } from '@hixme/person-utils'
import { isHealthBundle, isHospitalIndemnity, isCriticalIllness } from '@hixme/benefit-utils'
import {
  BENEFIT_TYPE_ACCIDENT,
  BENEFIT_TYPE_CHIROPRACTIC,
  BENEFIT_TYPE_DCFSA,
  BENEFIT_TYPE_DENTAL,
  BENEFIT_TYPE_EMPLOYEE_ASSISTANCE,
  BENEFIT_TYPE_FSA,
  BENEFIT_TYPE_FTA,
  BENEFIT_TYPE_GROUP_HEALTH,
  BENEFIT_TYPE_HEALTH,
  BENEFIT_TYPE_HSA,
  BENEFIT_TYPE_HOSPITAL_INDEMNITY,
  BENEFIT_TYPE_INTERESTED,
  BENEFIT_TYPE_LEGAL_PLAN,
  BENEFIT_TYPE_LEGAL_SHIELD,
  BENEFIT_TYPE_LONG_TERM_DISABILITY,
  BENEFIT_TYPE_LPFSA,
  BENEFIT_TYPE_VISION,
  BENEFIT_TYPE_VOLUNTARY_AD_D,
  BENEFIT_TYPE_VOLUNTARY_LIFE,
  BENEFIT_TYPE_VOLUNTARY_LIFE_SIMPLE,
  BENEFIT_TYPE_VOLUNTARY_LONG_TERM_DISABILITY,
  BENEFIT_TYPE_VOLUNTARY_SHORT_TERM_DISABILITY,
  BENEFIT_TYPE_ICHRA,
} from '@hixme/benefit-types'

import { NAME, DECLINED, HMO, NOT_INCLUDED } from './constants'
import { currencyFormatter } from 'routes/Expenses/utils/expenses'
import { isMedicare } from 'routes/Cart/components/ViewCart/helpers'

export const getCartData = createSelector(
  (state) => state[NAME],
  (cartData) => cartData && cartData.cart
)

// ======== Medical Product ==========
// Returns Medical Benefits from the cart
export const getMedicalBenefits = createSelector(getCartData, (cartData) => {
  if (!cartData) return

  return cartData.find((benefit) => benefit.BenefitType === BENEFIT_TYPE_HEALTH)
})

export const getMedicalProducts = createSelector(getMedicalBenefits, (medicalBenefits) => {
  if (medicalBenefits && medicalBenefits.Benefits.length > 0) {
    return medicalBenefits.Benefits
  }
})

export const getHsaCompatibleMedicalProducts = createSelector(
  getMedicalProducts,
  (medicalProducts) => {
    if (medicalProducts && medicalProducts.length > 0) {
      return medicalProducts.filter((m) => m.IsHsa)
    }
  }
)

// ======== DECLINES ==========

/**
 * Returns an array of medical declined persons
 * each person object is normalized for the decline api
 */
export const getMedicalDeclinedPersons = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find((benefit) => benefit.BenefitType === BENEFIT_TYPE_HEALTH)

    if (benefitType && benefitType.NotIncluded.length) {
      return benefitType.NotIncluded.map((person) => {
        const declinedPerson = Object.assign({}, person)

        declinedPerson.BenefitType = benefitType.BenefitType
        declinedPerson.Name = benefitType.Name

        if (declinedPerson.Id) {
          declinedPerson.PersonPublicKey = declinedPerson.Id
          delete declinedPerson.Id
        }

        delete declinedPerson.BenefitStatus

        return declinedPerson
      })
    }
  }
)

/**
 * Returns an array of products
 * that have one or more person declinations
 */
export const getPersonDeclinedProducts = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const nonMedicalProducts = cartData.cart.filter((benefit) => {
      const { BenefitType, NotIncluded } = benefit
      if (
        (BenefitType === BENEFIT_TYPE_DENTAL ||
          BenefitType === BENEFIT_TYPE_VISION ||
          BenefitType === BENEFIT_TYPE_ACCIDENT ||
          BenefitType === BENEFIT_TYPE_HOSPITAL_INDEMNITY ||
          BenefitType === BENEFIT_TYPE_GROUP_HEALTH) &&
        NotIncluded.length > 0
      ) {
        return benefit
      }
    })

    if (nonMedicalProducts.length) {
      return nonMedicalProducts
    }
  }
)

/**
 * Returns an array of non-medical products
 * that have one or more person declinations
 */
export const getAdditionalDeclinedProducts = createSelector(
  (state) => state[NAME],
  (state) => getHSABenefitProduct(state),
  (cartData, HSABenefit) => {
    if (!cartData.cart) return

    const nonMedicalProducts = cartData.cart.filter((benefit) => {
      const { BenefitType, NotIncluded } = benefit
      if (
        BenefitType !== BENEFIT_TYPE_HEALTH &&
        BenefitType !== BENEFIT_TYPE_GROUP_HEALTH &&
        BenefitType !== BENEFIT_TYPE_DENTAL &&
        BenefitType !== BENEFIT_TYPE_HSA &&
        BenefitType !== BENEFIT_TYPE_CHIROPRACTIC &&
        BenefitType !== BENEFIT_TYPE_VISION &&
        BenefitType !== BENEFIT_TYPE_ACCIDENT &&
        BenefitType !== BENEFIT_TYPE_HOSPITAL_INDEMNITY &&
        NotIncluded.length
      ) {
        return benefit
      }
    })

    if (nonMedicalProducts.length) {
      if (HSABenefit) {
        return nonMedicalProducts.filter((product) => product.BenefitType !== BENEFIT_TYPE_FSA)
      }
      return nonMedicalProducts.filter((product) => product.BenefitType !== BENEFIT_TYPE_LPFSA)
    }
  }
)

/**
 * Returns true if any additional benefits has
 * a Benefits array length greater than 0
 */
export const additionalBenefitsComplete = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    return cartData.cart.some((product) => {
      if (
        product.BenefitType !== BENEFIT_TYPE_HEALTH &&
        product.BenefitType !== BENEFIT_TYPE_GROUP_HEALTH &&
        product.BenefitType !== BENEFIT_TYPE_DENTAL &&
        product.BenefitType !== BENEFIT_TYPE_VISION
      ) {
        return product.Benefits.length > 0
      }
    })
  }
)

/**
 * Returns an array of non-medical declined persons
 * each person object is normalized for the decline api
 */
export const getNonMedicalDeclinedPersons = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const nonMedicalProducts = cartData.cart.filter((benefit) => {
      if (
        benefit.BenefitType !== BENEFIT_TYPE_HEALTH &&
        benefit.BenefitType !== BENEFIT_TYPE_GROUP_HEALTH &&
        benefit.NotIncluded.length
      ) {
        return benefit
      }
    })

    if (nonMedicalProducts.length) {
      const otherDeclines = []
      nonMedicalProducts.forEach((benefit) => {
        benefit.NotIncluded.forEach((person) => {
          const declinedPerson = Object.assign({}, person)

          declinedPerson.BenefitType = benefit.BenefitType
          declinedPerson.Name = benefit.Name

          if (declinedPerson.Id) {
            declinedPerson.PersonPublicKey = declinedPerson.Id
            delete declinedPerson.Id
          }

          delete declinedPerson.BenefitStatus
          delete declinedPerson.BundleMembership
          delete declinedPerson.PostalCode
          delete declinedPerson.RatingArea
          delete declinedPerson.StateProvince

          otherDeclines.push(declinedPerson)
        })
      })
      return otherDeclines
    }
  }
)

// ======== MEDICAL ==========

/**
 * Get the medical products that have been selected
 * otherwise return
 */
export const getAllMedicalProducts = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const medicalBenefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_HEALTH
    )

    if (medicalBenefitType && medicalBenefitType.Benefits.length) {
      return medicalBenefitType.Benefits
    }
  }
)

export const employeeHasMedicalProduct = createSelector(
  getAllMedicalProducts,
  (medicalProducts) =>
    medicalProducts &&
    medicalProducts.some((product) => product.Persons.some((person) => isEmployee(person)))
)

/**
 * Get the medical products that have been selected
 * otherwise return
 */
export const getHMOMedicalProducts = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const medicalBenefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_HEALTH
    )

    if (medicalBenefitType && medicalBenefitType.Benefits.length) {
      return medicalBenefitType.Benefits.filter((plan) => plan.PlanType === HMO)
    }
  }
)

// ======== VISION ==========

/**
 * Get the vision product that has been selected
 * otherwise return
 */
export const getVisionProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find((benefit) => benefit.BenefitType === BENEFIT_TYPE_VISION)

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

export const visionBenefitDeclined = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find((benefit) => benefit.BenefitType === BENEFIT_TYPE_VISION)

    if (benefitType) {
      if (benefitType.NotIncluded.every((obj) => obj.BenefitStatus === DECLINED)) {
        return true
      }
    }

    return false
  }
)

export const isBenefitDeclined = (type, state) =>
  createSelector(
    () => state[NAME],
    (cartData) => {
      if (!cartData.cart) return false
      const benefitType = cartData.cart.find((benefit) => benefit.BenefitType === type)

      if (!benefitType) return false

      return benefitType.NotIncluded.every((obj) => obj.BenefitStatus === DECLINED)
    }
  )(state)

export const visionBenefitComplete = createSelector(
  [(state) => visionBenefitDeclined(state), (state) => getVisionProduct(state)],
  (declined, product) => declined || product
)

export const isVisionBenefitCompleted = createSelector(
  [(state) => isBenefitDeclined(BENEFIT_TYPE_VISION, state), (state) => getVisionProduct(state)],
  (declined, product) => declined || product
)

// ======== DENTAL ==========

/**
 * Get the dental product that has been selected
 * otherwise return
 */
export const getDentalProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const dentalBenefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_DENTAL
    )

    if (dentalBenefitType && dentalBenefitType.Benefits.length) {
      return Object.assign({}, dentalBenefitType.Benefits[0])
    }
  }
)

export const isDentalBenefitCompleted = createSelector(
  [(state) => isBenefitDeclined(BENEFIT_TYPE_DENTAL, state), (state) => getDentalProduct(state)],
  (declined, product) => declined || product
)

/**
 * returns true if there is an HMO dental product in the cart
 * otherwise it returns false
 */
export const hasDentalHMO = createSelector(getDentalProduct, (getDentalProduct) => {
  if (!getDentalProduct) return false
  return getDentalProduct.PlanType === HMO
})

/**
 * get the dental PrimaryCareProvider
 * otherwise return
 */
export const getDentalPrimaryCareProvider = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const dentalBenefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_DENTAL
    )

    if (dentalBenefitType) {
      return dentalBenefitType.PrimaryCareProvider
    }
  }
)

/**
 * get the dental HowToFindDentistUrl
 * otherwise return
 */
export const getHowToFindDentistUrl = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const dentalBenefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_DENTAL
    )

    if (dentalBenefitType && dentalBenefitType.Benefits.length > 0) {
      return dentalBenefitType.Benefits[0].HowToFindDentistUrl
    }
  }
)

export const dentalBenefitDeclined = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const dentalProduct = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_DENTAL
    )

    if (dentalProduct) {
      if (dentalProduct.NotIncluded.every((obj) => obj.BenefitStatus === DECLINED)) {
        return true
      }
    }

    return false
  }
)

export const dentalBenefitComplete = createSelector(
  [(state) => dentalBenefitDeclined(state), (state) => getDentalProduct(state)],
  (declined, product) => declined || product
)

// ======== ACCIDENT ==========

/**
 * Get the hospital indemnity product that has been selected
 * otherwise return
 */
export const getAccidentProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const accidentBenefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_ACCIDENT
    )

    if (accidentBenefitType && accidentBenefitType.Benefits.length) {
      return Object.assign({}, accidentBenefitType.Benefits[0])
    }
  }
)

export const accidentBenefitDeclined = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const accidentProduct = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_ACCIDENT
    )

    if (accidentProduct) {
      if (accidentProduct.NotIncluded.every((obj) => obj.BenefitStatus === DECLINED)) {
        return true
      }
    }

    return false
  }
)

export const accidentBenefitComplete = createSelector(
  [(state) => accidentBenefitDeclined(state), (state) => getAccidentProduct(state)],
  (declined, product) => declined || product
)

// ======== HOSPITAL INDEMNITY ==========

/**
 * Get the hospital indemnity product that has been selected
 * otherwise return
 */
export const getHospitalIndemnityProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const hospitalIndemnityBenefitType = cartData.cart.find((benefit) =>
      isHospitalIndemnity(benefit)
    )

    if (hospitalIndemnityBenefitType && hospitalIndemnityBenefitType.Benefits.length) {
      return Object.assign({}, hospitalIndemnityBenefitType.Benefits[0])
    }
  }
)

export const hospitalIndemnityBenefitDeclined = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const hospitalIndemnityProduct = cartData.cart.find((benefit) => isHospitalIndemnity(benefit))

    if (hospitalIndemnityProduct) {
      if (hospitalIndemnityProduct.NotIncluded.every((obj) => obj.BenefitStatus === DECLINED)) {
        return true
      }
    }

    return false
  }
)

export const hospitalIndemnityBenefitComplete = createSelector(
  [
    (state) => hospitalIndemnityBenefitDeclined(state),
    (state) => getHospitalIndemnityProduct(state),
  ],
  (declined, product) => declined || product
)

// ======== CRITICAL ILLNESS ==========

export const getCriticalIllnessProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const criticalIllnessBenefitType = cartData.cart.find((benefit) => isCriticalIllness(benefit))

    if (criticalIllnessBenefitType && criticalIllnessBenefitType.Benefits.length) {
      return Object.assign({}, criticalIllnessBenefitType.Benefits[0])
    }
  }
)

export const criticalIllnessBenefitDeclined = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const criticalIllnessProduct = cartData.cart.find((benefit) => isCriticalIllness(benefit))

    if (criticalIllnessProduct) {
      if (criticalIllnessProduct.NotIncluded.every((obj) => obj.BenefitStatus === DECLINED)) {
        return true
      }
    }

    return false
  }
)

export const criticalIllnessBenefitComplete = createSelector(
  [(state) => criticalIllnessBenefitDeclined(state), (state) => getCriticalIllnessProduct(state)],
  (declined, product) => declined || product
)

// ======== GROUP HEALTH ==========

export const groupHealthBenefitDeclined = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const groupHealthProduct = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_GROUP_HEALTH
    )

    if (groupHealthProduct) {
      if (groupHealthProduct.NotIncluded.every((obj) => obj.BenefitStatus === DECLINED)) {
        return true
      }
    }

    return false
  }
)

export const getGroupHealthProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const groupHealthBenefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_GROUP_HEALTH
    )

    if (groupHealthBenefitType && groupHealthBenefitType.Benefits.length) {
      return Object.assign({}, groupHealthBenefitType.Benefits[0])
    }
  }
)

export const groupHealthBenefitComplete = createSelector(
  [(state) => groupHealthBenefitDeclined(state), (state) => getGroupHealthProduct(state)],
  (declined, product) => declined || product
)

// ======== FSA ==========

/**
 * Get the FSA product that has been selected
 * otherwise return
 */
export const getFSAProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find((benefit) => benefit.BenefitType === BENEFIT_TYPE_FSA)

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

// ======== LPFSA ==========

/**
 * Get the LPFSA product that has been selected
 * otherwise return
 */
export const getLPFSAProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find((benefit) => benefit.BenefitType === BENEFIT_TYPE_LPFSA)

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

// ======== DCFSA ==========

/**
 * Get the DCFSA product that has been selected
 * otherwise return
 */
export const getDCFSAProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find((benefit) => benefit.BenefitType === BENEFIT_TYPE_DCFSA)

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

// ======== FTA ==========

/**
 * Get the FTA product that has been selected
 * otherwise return
 */
export const getFTAProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find((benefit) => benefit.BenefitType === BENEFIT_TYPE_FTA)

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

export const getProductByBenefitType = (BenefitType, state) =>
  createSelector(
    (state) => state[NAME],
    (cartData) => {
      if (!cartData.cart) return

      const benefitType = cartData.cart.find((benefit) => benefit.BenefitType === BenefitType)

      if (benefitType && benefitType.Benefits.length) {
        return { ...benefitType.Benefits[0] }
      }
    }
  )(state)

export const hasDeclinedBenefit = (BenefitType, state) =>
  createSelector(
    () => state[NAME],
    (cartData) => {
      if (!cartData.cart) return

      const benefit = cartData.cart.find((b) => b.BenefitType === BenefitType)
      if (!benefit) return false

      return (
        benefit.NotIncluded.length > 0 &&
        benefit.NotIncluded.every(
          (declinedBenefit) =>
            declinedBenefit.BenefitStatus === DECLINED ||
            declinedBenefit.BenefitStatus === NOT_INCLUDED
        )
      )
    }
  )(state)

// ======== Interested In Additional Benefits ==========
export const getInterestedAdditionalBenefitsProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_INTERESTED
    )

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

// ======== Employee Assistance Program Benefits ==========
export const getEmployeeAssistanceProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_EMPLOYEE_ASSISTANCE
    )

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

// ======== Long Term Disability Benefits ==========
export const getLongTermDisabilityBenefitProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_LONG_TERM_DISABILITY
    )

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

// ======== Voluntary Long Term Disability Benefits ==========
export const getVoluntaryLongTermDisabilityBenefitProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_VOLUNTARY_LONG_TERM_DISABILITY
    )

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

// ======== Voluntary Short Term Disability Benefits ==========
export const getVoluntaryShortTermDisabilityBenefitProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_VOLUNTARY_SHORT_TERM_DISABILITY
    )

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

// ======== Voluntary Life Simple Benefits ==========
export const getVoluntaryLifeSimpleBenefitProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_VOLUNTARY_LIFE_SIMPLE
    )

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

// ======== Voluntary Life Benefits ==========
export const getVoluntaryLifeBenefitProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_VOLUNTARY_LIFE
    )

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

// ======== Voluntary AD&D Benefits ==========
export const getVoluntaryADDBenefitProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_VOLUNTARY_AD_D
    )

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

// ======== HSA Benefits ==========
export const getHSABenefitProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find((benefit) => benefit.BenefitType === BENEFIT_TYPE_HSA)

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

// ======== Legal Shield Benefits ==========
export const getLegalShieldBenefitProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_LEGAL_SHIELD
    )

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

// ======== Legal Plan Benefits ==========
export const getLegalPlanBenefitProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_LEGAL_PLAN
    )

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

// ======== Chiropractic Benefits ==========
export const getChiropracticBenefitProduct = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return

    const benefitType = cartData.cart.find(
      (benefit) => benefit.BenefitType === BENEFIT_TYPE_CHIROPRACTIC
    )

    if (benefitType && benefitType.Benefits.length) {
      return Object.assign({}, benefitType.Benefits[0])
    }
  }
)

export const getCartSummary = createSelector(
  (state) => state[NAME],
  (cartData) => {
    const summaryRaw = []

    cartData.cart.forEach((item) => {
      if (item.Benefits.length) {
        item.Benefits.forEach((benefit) => {
          const hasMathWorkerPrice =
            benefit.Math !== undefined && benefit.Math.WorkerPrice !== undefined
          const hasPrice = benefit.Price !== undefined
          let price

          if (hasMathWorkerPrice) {
            price = benefit.Math.WorkerPrice
          } else if (hasPrice) {
            price = benefit.Price
          } else {
            price = 0
          }

          const summaryItem = {
            BenefitType: item.BenefitType,
            Name: item.BenefitType === 'HealthBundle' ? benefit.PlanName : benefit.Name,
            Price: price,
            inCart: true,
            Primary:
              item.BenefitType === BENEFIT_TYPE_ICHRA || item.BenefitType === BENEFIT_TYPE_HEALTH,
            ...(item.BenefitType === BENEFIT_TYPE_ICHRA && {
              Allowance: currencyFormatter(benefit.ReimbursementAllowance || 0),
              Name: `Expense Reimbursement up to`,
            }),
            ...(isMedicare(benefit.PlanType) && {
              Medicare: true,
              DisplayValue: Math.abs(price),
              isPriceDefined: benefit.IsPriceDefined,
            }),
          }
          summaryRaw.push(summaryItem)
        })
      } else {
        const summaryItem = {
          BenefitType: item.BenefitType,
          Name: item.Name === 'Health bundle' ? 'Health plan' : item.Name,
          Price: 0,
          inCart: false,
        }
        summaryRaw.push(summaryItem)
      }
    })

    // Hide benefits that should not be in the cart
    const summary = summaryRaw.filter((item) => item.BenefitType !== BENEFIT_TYPE_INTERESTED)

    const countTotal = summary.reduce(
      (prev, curr) => {
        if (curr.inCart && !curr.hide) prev.count++
        prev.total += !curr.Medicare ? curr.Price : 0
        return prev
      },
      { count: 0, total: 0 }
    )
    return {
      items: summary,
      total: countTotal.total,
      count: countTotal.count,
    }
  }
)

export const getBundleCartCount = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return
    let healthBenefitsCount = 0
    cartData.cart.forEach((product) => {
      if (product.BenefitType === BENEFIT_TYPE_HEALTH) {
        healthBenefitsCount = product.Benefits.length
      }
    })
    return healthBenefitsCount
  }
)

export const getHealthBundlesInCartIds = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return
    const ids = []
    for (const product of cartData.cart) {
      if (product.BenefitType === BENEFIT_TYPE_HEALTH) {
        for (const benefit of product.Benefits) {
          ids.push(benefit.HealthPlanId)
        }
      }
    }
    return ids
  }
)

export const getCartIndex = (ids, state) =>
  createSelector(
    (state) => state[NAME],
    (cartData) => {
      if (!cartData.cart) return
      let indexToAdd = -1
      cartData.cart.forEach((product) => {
        if (product.BenefitType === BENEFIT_TYPE_HEALTH) {
          const idsInCart = product.Benefits.map((benefit) =>
            benefit.Persons.map((person) => person.Id)
          )
          idsInCart.forEach((benefitIdsArray, index) => {
            if (isEqual(benefitIdsArray, ids)) {
              indexToAdd = index
            }
          })
        }
      })
      return indexToAdd
    }
  )(state)

export const getPersonIdsInHealthProducts = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return
    const ids = []
    for (const product of cartData.cart) {
      if (product.BenefitType === BENEFIT_TYPE_HEALTH) {
        for (const benefit of product.Benefits) {
          for (const person of benefit.Persons) {
            ids.push(person.Id)
          }
        }
      }
    }
    return ids
  }
)

export const getDeclineHealthPersonIds = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return
    const ids = []
    for (const product of cartData.cart) {
      if (product.BenefitType === BENEFIT_TYPE_HEALTH) {
        for (const person of product.NotIncluded) {
          if (person.BenefitStatus === 'Declined') {
            ids.push(person.Id)
          }
        }
      }
    }
    return ids
  }
)

export const getHealthBenefitIndexByPersons = (personIdsArray, state) =>
  createSelector(
    (state) => state[NAME],
    (cartData) => {
      if (!cartData.cart) return
      let index = -1
      cartData.cart.forEach((product) => {
        if (product.BenefitType === BENEFIT_TYPE_HEALTH) {
          product.Benefits.forEach((benefit, benefitIndex) => {
            const benefitPersonsIds = []
            benefit.Persons.forEach((person) => {
              benefitPersonsIds.push(person.Id)
            })
            if (isEqual(personIdsArray, benefitPersonsIds)) {
              index = benefitIndex
            }
          })
        }
      })
      return index
    }
  )(state)

export const healthBenefitComplete = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return
    let benefitInCart = false
    let benefitDeclined = false
    cartData.cart.forEach((product) => {
      if (product.BenefitType === BENEFIT_TYPE_HEALTH) {
        if (product.Benefits.length > 0) {
          benefitInCart = true
        } else {
          benefitDeclined = product.NotIncluded.some(
            (person) => person.BenefitStatus === 'Declined'
          )
        }
      }
    })
    return benefitInCart || benefitDeclined
  }
)

export const hsaBenefitPersons = createSelector(
  (state) => state[NAME],
  (cartData) => {
    if (!cartData.cart) return
    let persons = []
    cartData.cart.forEach((product) => {
      if (
        product.BenefitType === BENEFIT_TYPE_HEALTH ||
        product.BenefitType === BENEFIT_TYPE_GROUP_HEALTH
      ) {
        const hsaBundle = product.Benefits.find((benefit) => benefit.IsHsa)
        if (hsaBundle) persons = hsaBundle.Persons
      }
    })
    return persons
  }
)

export const needsToSignHealthBundles = createSelector(
  (state) => getAllMedicalProducts(state),
  (MedicalBenefits) => {
    // Not using docusign for SureCo. Short circuiting this selector
    return false

    /*
    if (!MedicalBenefits) return false
    const numberOfBenefits = MedicalBenefits.length
    const hasBenefits = numberOfBenefits > 0
    if (!hasBenefits) return false

    const benefitsThatHaveBeenSigned = MedicalBenefits.filter((benefit) => {
      // first, check if benefit has already been DocuSign-ed
      const hasBeenDocuSigned =
        benefit.PdfSignatures &&
        benefit.PdfSignatures.every((signer) => signer.Signed)
      // second, check if benefit has been marked as being manually-signed
      const hasBeenManuallySigned = benefit.PdfApplicationIsManual

      if (hasBeenDocuSigned || hasBeenManuallySigned) {
        return benefit
      }
    })

    if (numberOfBenefits === benefitsThatHaveBeenSigned.length) {
      // returning `false` means `needsToSignHealthBundles` modal *WON'T* show
      return false
    }

    // returning `true` means `needsToSignHealthBundles` modal *WILL* show
    return true
    */
  }
)

export const cartModalIsOpen = createSelector(
  (state) => state[NAME],
  (cart) => cart.cartModalOpen
)

export const hasOnExchange = createSelector(
  (state) => state[NAME],
  ({ cart }) => {
    const bundles = cart.find((item) => item.BenefitType === 'HealthBundle')
    return bundles.Benefits.some((i) => i.Exchange === 'On Exchange')
  }
)

export const digitalMailEnabled = createSelector(
  (state) => state[NAME],
  ({ cart }) => {
    const healthBenefit = cart.find((benefit) => isHealthBundle(benefit))
    const result = (healthBenefit || {}).Benefits.some((healthBenefit) => {
      return healthBenefit.DigitalMailConfig && healthBenefit.DigitalMailConfig.Enabled
    })
    return result
  }
)

// Gets all benefits in cart that is a health medicare plan
export const getAllMedicareBenefits = createSelector(
  (state) => state[NAME],
  ({ cart }) => {
    const healthPlans = cart.filter((bundle) => isHealthBundle(bundle))
    return healthPlans
      .map((plan) => plan.Benefits.filter((benefit) => isMedicare(benefit.PlanType)))
      .flat()
  }
)
