import moment from 'moment'
import Expense from './Expense'

export default class ExpenseFactory {
  static create(data) {
    return new Expense(data)
  }

  static createWithId(data, id) {
    return new Expense({ ...data, userId: id })
  }

  static fromReimbursementService(data) {
    const expenseDate = moment.utc(data.expenseDate).format('MM/DD/YYYY')
    return new Expense({ ...data, isCertified: true, expenseDate })
  }
}
