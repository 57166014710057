/* eslint-disable */
// TODO: Lint cleanup. If you are editing this file, it is your duty to fix all linting errors.
import type from './actionTypes'

export const sessionInitialized = () => ({
  type: type.SESSION_INITIALIZED,
})

export const sessionInvalidate = () => ({
  type: type.SESSION_INVALIDATE,
})

export const invalidate = () =>
  function(dispatch) {
    dispatch(sessionInvalidate())
  }

export const initialize = () =>
  function(dispatch) {
    dispatch(sessionInitialized())
  }
