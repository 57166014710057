import React from 'react'
import BaseIcon from './BaseIcon'

/*eslint-disable */
class Summary extends BaseIcon {
  static defaultProps = {
    width: 19,
    height: 19,
    viewBox: '0 0 32 32'
  }
  render () {
    return (
      <svg {...this.getSvgProps()}>
        <path d="M0,3.5 C0,5.43299662 1.56508869,7 3.50077369,7 L28.4992263,7 C30.4326502,7 32,5.42906336 32,3.5 C32,1.56700338 30.4349113,0 28.4992263,0 L3.50077369,0 C1.56734977,0 0,1.57093664 0,3.5 Z M28.4992263,2 C29.3294601,2 30,2.67069097 30,3.5 C30,4.32610067 29.3264745,5 28.4992263,5 L3.50077369,5 C2.67053994,5 2,4.32930903 2,3.5 C2,2.67389933 2.67352547,2 3.50077369,2 L28.4992263,2 Z M0,15.5 C0,17.4329966 1.56508869,19 3.50077369,19 L28.4992263,19 C30.4326502,19 32,17.4290634 32,15.5 C32,13.5670034 30.4349113,12 28.4992263,12 L3.50077369,12 C1.56734977,12 0,13.5709366 0,15.5 Z M28.4992263,14 C29.3294601,14 30,14.670691 30,15.5 C30,16.3261007 29.3264745,17 28.4992263,17 L3.50077369,17 C2.67053994,17 2,16.329309 2,15.5 C2,14.6738993 2.67352547,14 3.50077369,14 L28.4992263,14 Z M0,28.5 C0,30.4329966 1.56508869,32 3.50077369,32 L28.4992263,32 C30.4326502,32 32,30.4290634 32,28.5 C32,26.5670034 30.4349113,25 28.4992263,25 L3.50077369,25 C1.56734977,25 0,26.5709366 0,28.5 Z M28.4992263,27 C29.3294601,27 30,27.670691 30,28.5 C30,29.3261007 29.3264745,30 28.4992263,30 L3.50077369,30 C2.67053994,30 2,29.329309 2,28.5 C2,27.6738993 2.67352547,27 3.50077369,27 L28.4992263,27 Z" id="Rectangle-660" stroke="none" />
      </svg>
    )
  }
}
/*eslint-enable */

export default Summary
