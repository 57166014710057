import { callWaiter } from 'redux-waiter'
import { BENEFIT_TYPE_GROUP_HEALTH } from '@hixme/benefit-types'
import { enrollmentService } from '@hixme/api'

import userSession from 'store/modules/user-session'
import personsModule from 'store/modules/persons'
import cart from 'store/modules/cart'
import notification from 'modules/notification-manager'

import * as constants from './constants'
import * as selectors from './selectors'
import t from './actionTypes'

export const loadPersons = (persons) => ({
  type: t.LOAD_PERSONS,
  payload: persons,
})

export const setSelectedPersons = (personIds) => ({
  type: t.SET_SELECTED_PERSONS,
  payload: personIds,
})

// complex actions
export const loadGroupHealthCoverage = () => (dispatch, getState) =>
  new Promise((resolve) => {
    const state = getState()
    const employee = personsModule.selectors.getPersonSelector(state)
    employee.disabled = true

    const dependents = personsModule.selectors.getDependentsEligibleForBenefits(state)
    dispatch(loadPersons([employee, ...dependents]))

    const groupHealthProduct = cart.selectors.getGroupHealthProduct(state)
    const selectedIds = [employee.Id]
    if (groupHealthProduct) {
      selectedIds.push(...groupHealthProduct.Persons.map((person) => person.Id))
    } else {
      selectedIds.push(...dependents.filter((p) => p.IncludedInMedical).map((person) => person.Id))
    }

    dispatch(setSelectedPersons(selectedIds))

    resolve()
  })

export const getGroupHealthPlans = () => (dispatch, getState) => {
  const state = getState()
  const req = dispatch(
    callWaiter(constants.GET_DENTAL_PLANS, {
      requestCreator: () => {
        const PersonPublicKeys = selectors.getSelectedPersons(state).map((person) => person.Id)

        const EnrollmentPublicKey = userSession.selectors.getEnrollmentPublicKey(state)

        return enrollmentService({
          route: '{EnrollmentPublicKey}/client/benefits/{BenefitType}',
          params: {
            EnrollmentPublicKey,
            BenefitType: BENEFIT_TYPE_GROUP_HEALTH,
            PersonPublicKeys,
          },
        })
      },
    })
  )

  return req
}

export const updateGroupHealthPlansWithCart = () => (dispatch, getState) => {
  const state = getState()
  const req = dispatch(getGroupHealthPlans())

  const currentProduct = cart.selectors.getGroupHealthProduct(state)
  if (currentProduct) {
    req.then(
      (response) => {
        const groupHealthProduct = response.find(
          (plan) => plan.BenefitPublicKey === currentProduct.BenefitPublicKey
        )

        if (groupHealthProduct) {
          const coveredPersons = selectors.getSelectedPersons(state)
          const excludedPersons = selectors.getUnselectedPersons(state)

          dispatch(
            cart.actions.addGroupHealthProduct({
              groupHealthProduct,
              coveredPersons,
              excludedPersons,
            })
          ).then(
            () => {},
            () => {
              dispatch(
                notification.actions.createNotification({
                  type: 'error',
                  message:
                    'There was an error saving your group health selection. Please try again.',
                })
              )
            }
          )
        }
      },
      () => {
        dispatch(
          notification.actions.createNotification({
            type: 'error',
            message: 'There was an error retrieving group health plans. Please try again.',
          })
        )
      }
    )
  }

  return req
}
