import React from 'react'
import BaseIcon from './BaseIcon'

/*eslint-disable */
export default class Sliders extends BaseIcon {
  static defaultProps = {
    width: 19,
    height: 21,
    viewBox: '107 10 19 21'
  }
  render () {
    return (
      <svg {...this.getSvgProps()}>
        <path d='M119.6 23.405h1.265c.467 0 .845-.378.845-.845v-1.222h3.262c.466 0 .844-.378.844-.844 0-.466-.378-.845-.844-.845h-3.262v-1.31c0-.47-.378-.85-.845-.85H119.6c-.467 0-.845.38-.845.84v1.3h-10.91c-.467 0-.845.377-.845.843 0 .466.378.844.844.844h10.91v1.223c0 .47.38.847.845.847zm-6.383-12.983h-1.266c-.46 0-.84.378-.84.844v1.223h-3.26c-.46 0-.84.37-.84.84 0 .46.38.84.85.84h3.265v1.3c0 .46.378.84.845.84h1.267c.467 0 .845-.38.845-.846v-1.3h10.91c.47 0 .847-.378.847-.844 0-.466-.38-.844-.846-.844h-10.91v-1.22c0-.467-.38-.845-.848-.845zm-.796 13.745h-1.26c-.46 0-.84.378-.84.845v1.286h-2.46c-.463 0-.842.378-.842.845 0 .467.38.845.846.845h2.465v1.236c0 .467.38.845.845.845h1.267c.47 0 .848-.38.848-.85v-1.24h11.703c.466 0 .844-.38.844-.85s-.38-.85-.847-.85h-11.71V25c0-.466-.38-.844-.846-.844z'
          {...this.getPathProps()} />
      </svg>
    )
  }
}
/*eslint-enable */
