import React from 'react'
import PropTypes from 'prop-types'

import {
  ActuarialBalancedTooltip,
  ActuarialFlexibleTooltip,
  ActuarialSecureTooltip,
} from './components'

const ActuarialTooltips = ({ BundleLevel, tooltipId }) => (
  <div>
    {
      {
        Flexible: <ActuarialFlexibleTooltip tooltipId={tooltipId} />,
        Balanced: <ActuarialBalancedTooltip tooltipId={tooltipId} />,
        Secure: <ActuarialSecureTooltip tooltipId={tooltipId} />,
      }[BundleLevel]
    }
  </div>
)

ActuarialTooltips.propTypes = {
  BundleLevel: PropTypes.string.isRequired,
  tooltipId: PropTypes.string.isRequired,
}

ActuarialTooltips.defaultProps = {}

export default ActuarialTooltips
