import React from 'react'
import dayjs from 'dayjs'
import {
  Stack,
  Typography,
  Modal,
  ModalProps,
  CloseModalButton,
  Button,
} from '@sureco/design-system'

const defaultYear = dayjs().year()

export type OptOutModalProps = {
  year?: number
  isLoading?: boolean
  onConfirm?: (close: () => void) => void
} & Omit<ModalProps, 'children'>

export const OptOutModal = ({
  year = defaultYear,
  onConfirm,
  isLoading,
  ...modalProps
}: OptOutModalProps) => {
  const renewalYear = year + 1

  const onConfirmHandler = (close: () => void) => () => {
    onConfirm?.(close)
  }
  return (
    <Modal wrapper={{ style: { borderRadius: 8, padding: '12px 16px' } }} {...modalProps}>
      {({ close }) => (
        <Stack direction="col" space={8}>
          <Stack direction="row" style={{ alignItems: 'center' }}>
            <Typography bold size={14}>
              Opt-out for {renewalYear}
            </Typography>
            <CloseModalButton />
          </Stack>

          <Typography size={14}>
            Our records show that you opted out of all of your benefits last year. Would you like to
            opt-out again this year?
          </Typography>
          <Stack
            direction="row"
            space={4}
            style={{ alignItems: 'center', justifyContent: 'flex-end', paddingTop: 16 }}
          >
            <Button disabled={isLoading} variant="secondary" onClick={close}>
              Cancel
            </Button>
            <Button disabled={isLoading} onClick={onConfirmHandler(close)}>
              Confirm opt-out
            </Button>
          </Stack>
        </Stack>
      )}
    </Modal>
  )
}
