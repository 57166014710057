import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import classes from './BundleCapTooltip.scss'

const BundleCapTooltip = ({ tooltipId }) => (
  <ReactTooltip className={classes.link} id={tooltipId} place="bottom" effect="solid">
    <Container padding="10px 0" width="350px">
      <Text thinner>
        Benefit shown is discounted based on the Bundle features. You may need to pay more at point
        of service and submit receipts for reimbursement.
      </Text>
    </Container>
  </ReactTooltip>
)

BundleCapTooltip.propTypes = {
  tooltipId: PropTypes.string.isRequired,
}

BundleCapTooltip.defaultProps = {}

export default BundleCapTooltip
