import { callWaiter } from 'redux-waiter'
import { enrollmentService } from '@hixme/api'
import { BENEFIT_TYPE_ACCIDENT } from '@hixme/benefit-types'

import userSession from 'store/modules/user-session'
import personsModule from 'store/modules/persons'
import cart from 'store/modules/cart'
import notification from 'modules/notification-manager'

import * as constants from './constants'
import * as selectors from './selectors'
import t from './actionTypes'

export const loadPersons = (persons) => ({
  type: t.LOAD_PERSONS,
  payload: persons,
})

export const setSelectedPersons = (personIds) => ({
  type: t.SET_SELECTED_PERSONS,
  payload: personIds,
})

// complex actions
export const loadAccident = () => (dispatch, getState) =>
  new Promise((resolve) => {
    const state = getState()
    const employee = personsModule.selectors.getPersonSelector(state)
    employee.disabled = true

    const dependents = personsModule.selectors.getDependentsEligibleForBenefits(state)
    dispatch(loadPersons([employee, ...dependents]))

    const accidentProduct = cart.selectors.getAccidentProduct(state)
    const selectedIds = [employee.Id]
    if (accidentProduct) {
      selectedIds.push(...accidentProduct.Persons.map((person) => person.Id))
    } else {
      selectedIds.push(...dependents.map((person) => person.Id))
    }

    dispatch(setSelectedPersons(selectedIds))

    resolve()
  })

export const getAccident = () => (dispatch, getState) => {
  const state = getState()
  const req = dispatch(
    callWaiter(constants.GET_ACCIDENT, {
      requestCreator: () => {
        const PersonPublicKeys = selectors.getSelectedPersons(state).map((person) => person.Id)

        const EnrollmentPublicKey = userSession.selectors.getEnrollmentPublicKey(state)

        return enrollmentService({
          route: '{EnrollmentPublicKey}/client/benefits/{BenefitType}',
          params: {
            EnrollmentPublicKey,
            BenefitType: BENEFIT_TYPE_ACCIDENT,
            PersonPublicKeys,
          },
        })
      },
    })
  )

  return req
}

export const updateAccidentWithCart = () => (dispatch, getState) => {
  const state = getState()
  const req = dispatch(getAccident())

  const currentProduct = cart.selectors.getAccidentProduct(state)
  if (currentProduct) {
    req.then(
      (response) => {
        const accidentProduct = response.ClientBenefits.find(
          (plan) => plan.BenefitPublicKey === currentProduct.BenefitPublicKey
        )

        if (accidentProduct) {
          const coveredPersons = selectors.getSelectedPersons(state)
          const excludedPersons = selectors.getUnselectedPersons(state)

          dispatch(
            cart.actions.addAccidentProduct({
              accidentProduct,
              coveredPersons,
              excludedPersons,
            })
          ).then(
            () => {},
            () => {
              dispatch(
                notification.actions.createNotification({
                  type: 'error',
                  message: 'There was an error saving your accident selection. Please try again.',
                })
              )
            }
          )
        }
      },
      () => {
        dispatch(
          notification.actions.createNotification({
            type: 'error',
            message: 'There was an error retrieving accient plans. Please try again.',
          })
        )
      }
    )
  }

  return req
}
