import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import UploadBox from '@hixme-ui/upload-box'

import { addFiles, updateGeneralMessage } from '../../../actions'

const DropZone = ({ dropHandler, accept }) => <UploadBox onDrop={dropHandler} accept={accept} />

const mapDispatchToProps = (dispatch, props) => ({
  dropHandler: (acceptedFiles) => {
    dispatch(addFiles(acceptedFiles, props.groupKey))
    dispatch(updateGeneralMessage(null, props.groupKey))
  },
})

DropZone.displayName = 'DropZone'
DropZone.propTypes = {
  dropHandler: PropTypes.func.isRequired,
  accept: PropTypes.string,
}
DropZone.defaultProps = {
  accept: '',
}

export default connect(
  null,
  mapDispatchToProps
)(DropZone)
