import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Icon from '@hixme-ui/icon'
import Card from '@hixme-ui/card'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

export const ModalSlider = ({ children, open, onClose, openClassName, defaultClassName }) => {
  const modalState = open ? openClassName : defaultClassName
  return (
    <div className={modalState}>
      <Card width="400px">
        <Container noPadding textRight>
          <Text cursor="pointer" onClick={onClose}>
            <Icon xCircle light size={1.6} />
          </Text>
        </Container>
        {children}
      </Card>
    </div>
  )
}

ModalSlider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openClassName: PropTypes.string.isRequired,
  defaultClassName: PropTypes.string.isRequired,
}

export default ModalSlider
