import React from 'react'

import Container from '@hixme-ui/container'
import Separator from '@hixme-ui/separator'

import Placeholder from 'components/Placeholder'

const LoadingFilterOptions = () => (
  <div>
    <Container
      background="#FFF"
      flex
      height="60px"
      alignItems="center"
      padding="0 20px 0 20px"
      style={{ borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}
    >
      <Container flex noPadding justifyContent="flex-start" width="100%">
        <Container flex noPadding width="240px" justifyContent="space-between">
          <Placeholder width="150px" height="20px" />
        </Container>
      </Container>

      <Container flex noPadding width="100%" justifyContent="flex-end">
        <Container flex noPadding width="170px" justifyContent="space-between">
          <Placeholder width="100%" height="20px" />
        </Container>
      </Container>
    </Container>
    <Separator size="1px" margin="0px" />
  </div>
)

LoadingFilterOptions.displayName = 'LoadingFilterOptions'

export default LoadingFilterOptions
