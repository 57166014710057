import { namespaceActions } from 'modules/redux-utils'
import { NAME } from './constants'

const LOAD_EXPENSE_REQUESTS = 'LOAD_EXPENSE_REQUESTS'
const LOAD_FAMILY_BENEFITS = 'LOAD_FAMILY_BENEFITS'
const LOAD_EXPENSE_REQUEST = 'LOAD_EXPENSE_REQUEST'
const LOAD_RECEIPTS = 'LOAD_RECEIPTS'
const SELECT_LIFE_EVENT = 'SELECT_LIFE_EVENT'
const SEND_EXPENSE_MESSAGE = 'SEND_EXPENSE_MESSAGE'
const CREATE_EXPENSE_REQUEST = 'CREATE_EXPENSE_REQUEST'
const SET_EXPENSE_ID = 'SET_EXPENSE_ID'
const SET_SELECTED_EXPENSE = 'SET_SELECTED_EXPENSE'
const SET_UI_STATE = 'SET_UI_STATE'
const SET_BENEFIT_YEAR = 'SET_BENEFIT_YEAR'
const VIEW_MORE = 'VIEW_MORE'
const LOAD_LINE_ITEMS = 'LOAD_LINE_ITEMS'
const SET_EXPENSE_AVAILABLE = 'SET_EXPENSE_AVAILABLE'

export default namespaceActions(NAME)([
  LOAD_EXPENSE_REQUESTS,
  LOAD_EXPENSE_REQUEST,
  SELECT_LIFE_EVENT,
  SEND_EXPENSE_MESSAGE,
  CREATE_EXPENSE_REQUEST,
  SET_EXPENSE_ID,
  SET_SELECTED_EXPENSE,
  SET_UI_STATE,
  LOAD_RECEIPTS,
  LOAD_FAMILY_BENEFITS,
  SET_BENEFIT_YEAR,
  VIEW_MORE,
  LOAD_LINE_ITEMS,
  SET_EXPENSE_AVAILABLE,
])
