import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'

import { MrModal, clearModal } from '@hixme/modal'
import { connect } from 'react-redux'

import Container from '@hixme-ui/container'
import Title from '@hixme-ui/title'
import Text from '@hixme-ui/text'
import { Button } from '@hixme-ui/forms'
import Currency from '@hixme-ui/currency'

import { selectors as authSelectors } from '@hixme/auth-ui'
import { ROLE_PLATFORM_HIXME_ADMIN, ROLE_BROKER } from '@hixme/role-policy'

const thStyle = {
  textAlign: 'left',
}

const tdStyle = {
  padding: '10px 0',
}

class TransactionHistoryModal extends Component {
  constructor() {
    super()
    this.state = {
      transactions: [
        {
          date: '2021-01-31',
          reason: 'Approved reimbursement: Broken Arm X-Ray',
          id: 'txn_QVsZ39lBPdYyHLz',
          type: 'Payroll',
          amount: '593.13',
        },
        {
          date: '2021-02-15',
          reason: 'Hearing Aid',
          id: 'txn_NJsM11fVLrWqMLo',
          type: 'Payroll',
          amount: '100.50',
        },
        {
          date: '2021-01-31',
          reason: 'Approved reimbursement: Broken Arm X-Ray',
          id: 'txn_QVsZ39lBPdYyHLz',
          type: 'Payroll',
          amount: '593.13',
        },
        {
          date: '2021-02-15',
          reason: 'Hearing Aid',
          id: 'txn_NJsM11fVLrWqMLo',
          type: 'Payroll',
          amount: '100.50',
        },
      ],
    }
  }

  render() {
    return (
      <MrModal>
        <Container flex justifyContent="center">
          <Title>Transaction History</Title>
        </Container>
        <Container direction="column" flex noPadding margin="50px 0 0 0" rounded>
          <Container width="100%" flex justifyContent="center" padding="20px 50px">
            <Col xs={12} sm={10}>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th style={thStyle}>Date</th>
                    <th style={thStyle}>Reason for Visit</th>
                    <th style={thStyle}>Transaction ID</th>
                    <th style={thStyle}>Type</th>
                    <th style={thStyle}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.transactions.map((transaction, idx) => (
                    <tr
                      style={{
                        background: idx % 2 === 0 ? '#fbfbfb' : 'white',
                      }}
                      key={transaction.id}
                    >
                      <td style={tdStyle}>
                        <Text blue>{transaction.date}</Text>
                      </td>
                      <td style={tdStyle}> {transaction.reason}</td>
                      <td style={tdStyle}> {transaction.id}</td>
                      <td style={tdStyle}> {transaction.type}</td>
                      <td style={tdStyle}>
                        <Currency>{transaction.amount}</Currency>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Container>
        </Container>
        <Container
          flex
          padding="20px 0 0 0"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Container flex noPadding width="100%" alignItems="center" justifyContent="center">
            <Button blue onClick={this.props.closeModal}>
              Close
            </Button>
          </Container>
        </Container>
      </MrModal>
    )
  }
}

TransactionHistoryModal.displayName = 'TransactionHistoryModal'

const mapStateToProps = (state) => {
  const isHixmeAdmin = [ROLE_PLATFORM_HIXME_ADMIN, ROLE_BROKER].indexOf(authSelectors.getRole(state)) > -1

  return {
    isHixmeAdmin,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(clearModal())
  },
  handleSubmit: (values, props) => {
    props.family
      .map((person) => ({
        FullName: person.FullName,
        EmployeePublicKey: person.EmployeePublicKey,
        Id: person.Id,
      }))
      .filter((person) => props.PatientIds.includes(person.Id))
  },
})

TransactionHistoryModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionHistoryModal)
