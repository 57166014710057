import React from 'react'

import Title from '@hixme-ui/title'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import Modal from 'modules/modal'

const MultiRatingAreaGrouping = () => (
  <Container white rounded>
    <Title small>Grouping Family Members into one Bundle</Title>
    <hr />
    <Text p>
      The information you’ve provided shows that you have family members that live in one or more
      areas that are not the same as your residence. In your case, these are different service
      areas. The availability of health insurance plans, levels of coverage, and provider networks
      can vary widely by service area.
    </Text>
    <Text p>
      Hixme has recommended separate health insurance bundles for your family in order to ensure
      that everyone gets comprehensive coverage wherever they currently reside.
    </Text>
    <Text p>
      You can, of course, choose to override this recommendation, and group your entire family under
      a single health insurance plan as your dependents. However, depending on the carrier you
      choose, you may incur the risk of your dependents having limited coverage in their area.
      Emergency services would be covered, but other treatments like doctor visits may only be
      covered as long as your dependents visit providers that are in-network for the plan that you
      select.
    </Text>
    <Text p>
      We recommend that you review the Summary of Benefits and Coverage documents for any plans that
      you are considering, before making your final decision at checkout.
    </Text>
  </Container>
)

MultiRatingAreaGrouping.displayName = 'MultiRatingAreaGrouping'

export default Modal.containers.Modal({
  content: {
    position: 'top',
    size: 'large',
    styles: {
      margin: '50px 0 0 0',
    },
  },
  overlay: {
    hideCloseButton: false,
    routeOnHide: false,
    dark: true,
  },
})(MultiRatingAreaGrouping)
