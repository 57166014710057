import React from 'react'
import PropTypes from 'prop-types'

import { Accordion, AccordionItem } from 'react-sanfona'

import Container from '@hixme-ui/container'

import Modal from 'modules/modal'

import classes from './CoreMedicalCoverage.scss'

const CoreMedicalCoverage = ({ data }) => {
  if (!data.isProspect) {
    return (
      <Container background="white" width="100%">
        {data.isHsa ? (
          <Accordion>
            <AccordionItem
              titleClassName={classes.itemTitle}
              title="Health policy portion of benefits and coverage"
            >
              <iframe src={data.sbcMedicalLink} />
            </AccordionItem>
          </Accordion>
        ) : (
          <Accordion>
            <AccordionItem
              titleClassName={classes.itemTitle}
              title="Bundle summary of benefits and coverage"
            >
              <iframe src={data.sbcGapInsuranceLink} />
            </AccordionItem>
            <AccordionItem
              titleClassName={classes.itemTitle}
              title="Health policy portion of benefits and coverage"
            >
              <iframe src={data.sbcMedicalLink} />
            </AccordionItem>
          </Accordion>
        )}
      </Container>
    )
  }
  return (
    <Container background="white" width="100%">
      <Accordion>
        <AccordionItem titleClassName={classes.itemTitle} title="Bundle Benefit Chart">
          <iframe src={data.prospectPdfLink} />
        </AccordionItem>
      </Accordion>
    </Container>
  )
}

CoreMedicalCoverage.displayName = 'CoreMedicalCoverage'
CoreMedicalCoverage.propTypes = {
  data: PropTypes.object,
}

CoreMedicalCoverage.defaultProps = {
  data: null,
}

export default Modal.containers.Modal({
  content: {
    position: 'top',
    size: 'large',
    styles: {
      width: '100%',
      margin: '50px 0 0 0',
    },
  },
  overlay: {
    hideCloseButton: false,
    routeOnHide: false,
    dark: true,
  },
})(CoreMedicalCoverage)
