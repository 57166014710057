import React from 'react'
import BaseIcon from './BaseIcon'

/*eslint-disable */
export default class TestUser extends BaseIcon {
  static defaultProps = {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24'
  }
  render () {
    return (
      <svg {...this.getSvgProps()}>
        <path d="M20.873 14.742c1.873 1.05 1.883 2.07 1.883 2.07v2.176H20.53l.343-4.246zm-17.228 4.25H1.45v-2.18s.01-1.01 1.86-2.057l.335 4.236zm5.748-8.112c-.405-.68-.763-1.516-1.133-2.45-.327-.827-.272-1.533-.272-2.536 0-.738-.14-1.923.043-2.574C8.654 1.11 10.23.5 12.08.5c1.846 0 3.422.61 4.046 2.814.184.652.044 1.84.044 2.58 0 1 .056 1.705-.27 2.53-.37.934-.732 1.773-1.142 2.456H9.393zM3.9 12.493c-.04-.546.368-.99.923-.99h14.55c.55 0 .962.453.92.99l-.66 8.534c-.043.546-.524.99-1.08.99H5.623c-.553 0-1.037-.453-1.077-.99L3.9 12.493zM18.36 22.7H5.564c-.125 0-.226.153-.226.342 0 .19.1.342.226.342H18.36c.125 0 .226-.153.226-.342 0-.19-.1-.342-.226-.342z" {...this.getPathProps()}/>
      </svg>
    )
  }
}
/*eslint-enable */
