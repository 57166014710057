import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getAllPersons = createSelector(
  (state) => state[NAME],
  (coverage) => coverage
)

export const getSelectedPersons = createSelector(
  (state) => state[NAME],
  (coverage) => coverage.reduce((prev, curr) => (curr.selected ? [...prev, curr] : prev), [])
)

export const getUnselectedPersons = createSelector(
  (state) => state[NAME],
  (coverage) => coverage.reduce((prev, curr) => (!curr.selected ? [...prev, curr] : prev), [])
)
