import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid/v4'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Icon from '@hixme-ui/icon'

import HelpNumber from 'components/HelpNumber/HelpNumber'

const Hospitals = ({ CoveredHospitals }) => (
  <Container flex wrap rounded padding="20px" background="#F8FAFC">
    {CoveredHospitals.length > 0 &&
      CoveredHospitals.map((hospital) => (
        <div key={uuid()} style={{ marginRight: '20px' }}>
          <Text light>{hospital}</Text>
        </div>
      ))}
    {!CoveredHospitals.length && (
      <Container
        flex
        noPadding
        direction="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <div>
          <Icon building size={3} light />
        </div>
        <Text div center light>
          Contact us if you need help finding plans with a specific in-network hospital
        </Text>
        <Text primary large>
          <HelpNumber />
        </Text>
      </Container>
    )}
  </Container>
)

Hospitals.displayName = 'Hospitals'

Hospitals.propTypes = {
  CoveredHospitals: PropTypes.array,
}

Hospitals.defaultProps = {
  CoveredHospitals: [],
}

export default Hospitals
