import styled from 'styled-components'
import { HixmeReactSelect } from '@hixme-ui/select'
import { colorTheme } from '@hixme-ui/theme'

const CustomMultiSelect = styled(HixmeReactSelect)`
  width: 50%;

  & .Select-control {
    background-color: transparent;
    border: 1px solid #aab5bd;
    border-radius: 4px;
    color: #333;
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    height: 42px;
    outline: none;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 100%;

    > *:last-child {
      padding-right: 0;
    }
  }

  & .Select-multi-value-wrapper {
    display: flex;
    align-items: center;
  }

  & .Select-arrow-zone {
    .Select-arrow {
      border-color: ${colorTheme.primary} transparent transparent;
      border-width: 6px 6px 1px;
    }
  }

  & .Select-clear-zone {
    color: #b9c5cd;
  }
`

export default CustomMultiSelect
