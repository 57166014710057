import { callWaiter } from 'redux-waiter'
import { reimbursementService } from '@hixme/api'
import {
  GET_EXPENSE_REQUESTS_NAME,
  SEND_EXPENSE_MESSAGE,
  CREATE_REIMBURSEMENT,
  UPDATE_RECEIPT,
  GET_RECEIPTS,
  GET_LINE_ITEMS,
  GET_EXPENSE_REQUEST_VALIDATE,
} from './constants'

export const getExpenseRequests = (params) => (dispatch) =>
  dispatch(
    callWaiter(
      `${GET_EXPENSE_REQUESTS_NAME}_${params.employeePublicKey}_${params.clientId}_${
        params.benefitYear
      }`,
      {
        requestCreator: () =>
          reimbursementService({
            route: 'reimbursements/clientId/{clientId}/employeeId/{employeePublicKey}',
            method: 'GET',
            params,
          }),
      }
    )
  )

export const getIsValidExpenseDate = (params) => (dispatch) =>
  dispatch(
    callWaiter(`${GET_EXPENSE_REQUEST_VALIDATE}_${params.ichraExpenseDate}`, {
      requestCreator: () =>
        reimbursementService({
          route: 'reimbursements/clientId/{clientId}/employeeId/{employeePublicKey}/validate',
          method: 'GET',
          params,
        }),
    })
  )

export const sendReimbursmentMessage = (body, id) => (dispatch) =>
  dispatch(
    callWaiter(SEND_EXPENSE_MESSAGE, {
      requestCreator: () =>
        reimbursementService({
          route: 'reimbursements/{reimbursementId}/messages',
          method: 'POST',
          body,
          params: {
            reimbursementId: id,
          },
        }),
    })
  )

export const createExpense = (body) => (dispatch) =>
  dispatch(
    callWaiter(CREATE_REIMBURSEMENT, {
      requestCreator: () =>
        reimbursementService({
          route: 'reimbursements/benefit',
          method: 'POST',
          body,
        }),
    })
  )
export const updateExpense = (body, params) => (dispatch) =>
  dispatch(
    callWaiter(CREATE_REIMBURSEMENT, {
      requestCreator: () =>
        reimbursementService({
          route:
            'reimbursements/clientId/{clientId}/employeeId/{employeeId}/reimbursementId/{reimbursementId}',
          method: 'PATCH',
          params,
          body,
        }),
    })
  )

export const updateReceipt = (receiptPublicKey, body) => (dispatch) =>
  dispatch(
    callWaiter(UPDATE_RECEIPT, {
      requestCreator: () => {
        reimbursementService({
          route: 'receipts/{receiptPublicKey}',
          method: 'PATCH',
          params: {
            receiptPublicKey,
          },
          body,
        })
      },
    })
  )

export const deleteReceipt = (receiptPublicKey) => (dispatch) =>
  dispatch(
    callWaiter(UPDATE_RECEIPT, {
      requestCreator: () => {
        reimbursementService({
          route: 'receipts/{reimbursementPublicKey}',
          method: 'DELETE',
          params: {
            receiptPublicKey,
          },
        })
      },
    })
  )

export const getReceiptsByReimbursement = (reimbursementPublicKey) => (dispatch) =>
  dispatch(
    callWaiter(`${GET_RECEIPTS}-${reimbursementPublicKey}`, {
      requestCreator: () =>
        reimbursementService({
          route: 'reimbursements/{reimbursementPublicKey}/receipts',
          method: 'GET',
          params: {
            reimbursementPublicKey,
          },
        }),
    })
  )

export const getLineItemsByReimbursement = (reimbursementPublicKey) => (dispatch) =>
  dispatch(
    callWaiter(GET_LINE_ITEMS, {
      requestCreator: () =>
        reimbursementService({
          route: 'reimbursements/{reimbursementPublicKey}/items',
          method: 'GET',
          params: {
            reimbursementPublicKey,
          },
        }),
    })
  )
