import React from 'react'
import PropTypes from 'prop-types'
import { MrModal, clearModal } from '@hixme/modal'
import { connect } from 'react-redux'

// hixme-ui
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'

// project
import Separator from 'components/Separator'
import { actions as cartActions } from 'store/modules/cart'
import { actions as sessionActions } from 'store/modules/user-session'
import { actions as questionActions } from 'routes/Profile/routes/Questions/Questions2016/modules/questionResponses'

const propTypes = {
  confirm: PropTypes.func.isRequired,
}

const ClearCartModal = ({ confirm }) => (
  <MrModal
    errorDialog
    small
    cancelLabel="< Go back"
    confirmLabel="Ok, I understand"
    confirmHandler={confirm}
  >
    <Container flexColumn justifySpaceBetween alignCenter>
      <Icon alert red size={3} />
      <Title small>Clear cart and make new elections?</Title>
      <Separator width="500px" margin="35px 0 0 0" light />
      <Container padding="30px 20px 0">
        <Text thin light>
          The changes you have made require us to remove the benefits you’ve elected from your
          shopping cart. But don’t worry - you will be asked to make new elections instead.
        </Text>

        <Text thin light p>
          <strong>Why?</strong> In order to determine your available benefits and costs, we take
          into account <strong>who</strong> is being covered, their <strong>location</strong>
          (s), and their <strong>age</strong>
          (s), and other factors.
        </Text>

        <Text thin light p>
          We will use the updated information you‘ve provided to make sure you are shown the correct
          benefits and rates that are available to you.
        </Text>

        <Text thin light p>
          Click ”Ok, I understand” to proceed.
        </Text>
      </Container>
    </Container>
  </MrModal>
)

ClearCartModal.propTypes = propTypes

const mapDispatchToProps = (dispatch, { data }) => ({
  confirm: async () => {
    data.confirmHandler && data.confirmHandler()
    dispatch(cartActions.deleteCart()).then(async () => {
      if (data.afterClear) {
        await data.afterClear()
      }
    })
    sessionActions.removeAttestationsItem()
    dispatch(questionActions.deleteResponses())
    dispatch(clearModal())
  },
})
export default connect(
  null,
  mapDispatchToProps
)(ClearCartModal)
