import React from 'react'

import Title from '@hixme-ui/title'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import Modal from 'modules/modal'

const BillSettlement = () => (
  <Container white rounded>
    <Title small>Bill Settlement</Title>
    <hr />
    <Text p light>
      If you are left with a sizeable medical bill over $500, send Hixme the claim and we will
      negotiate with the provider on your behalf to reduce the bill*. This benefit can be used in
      combination with gap financing, if necessary.
    </Text>
    <Text p light>
      *15% negotiated reduction on bills over $500
    </Text>
  </Container>
)

BillSettlement.displayName = 'BillSettlement'

export default Modal.containers.Modal({
  content: {
    position: 'top',
    size: 'large',
    styles: {
      margin: '50px 0 0 0',
    },
  },
  overlay: {
    hideCloseButton: false,
    routeOnHide: false,
    dark: true,
  },
})(BillSettlement)
