import { connect } from 'react-redux'
import { clearModal } from '@hixme/modal'
import { actions as userSessionActions } from 'store/modules/user-session'

import CompareTable from '../components/CompareTable/CompareTable'
import { actions, selectors } from '../../health-bundles'

import {
  fillSpots,
  getBaseObject,
  getBenefitList,
  placeholderObject,
  getCategories,
} from '../utils/compareTable'

// this is also used in QLE version
export const mapStateToProps = (state, { plans = [] }) => {
  const plansList = fillSpots(plans)
  const baseObject = getBaseObject(getCategories(plansList))

  const hasSelectedPlans = plansList.some((plan) => plan.bundleBenefits)
  const benefitList = hasSelectedPlans && getBenefitList(baseObject)
  return {
    benefitKeys: benefitList,
    hasSelectedPlans,
    baseObject,
    benefits: plansList,
    currentGroupIndex: selectors.getCurrentGroupIndex(state),
    groupFavoriteCount: plans.length,
    placeholderObject,
    totalGroupCount: selectors.getTotalGroupCount(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleContinue: () => {
    dispatch(clearModal())
    dispatch(userSessionActions.pushNextBenefitRoute())
  },
  handleNextGroup: () => {
    dispatch(clearModal())
    dispatch(actions.nextGroup())
  },
  onSelectPlan: () => {
    dispatch(clearModal())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompareTable)
