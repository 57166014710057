import React, { useContext, useState } from 'react'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

// module
import { MAX_FAVORITES } from '../../modules/health-bundles/constants'
import { LifeEventsContext } from 'routes/LifeEvents/LifeEventsContext'
import { QLEBundleCompareDrawer } from 'routes/LifeEvents/Health/QLEBundleCompareDrawer'

export type CompareNavProps = {
  groupFavoriteCount: number
  compare: () => void
}

const CompareNav = ({ groupFavoriteCount, compare }: CompareNavProps) => {
  const qleCtx = useContext(LifeEventsContext)
  const [openQLECompareDrawer, setOpenQLECompareDrawer] = useState(false)
  const onClick = () => {
    if (qleCtx) {
      setOpenQLECompareDrawer((p) => !p)
    } else {
      compare()
    }
  }

  return (
    <Container flex noPadding justifyContent="center" alignItems="center" width="100%">
      {groupFavoriteCount > 0 && (
        <div onClick={onClick}>
          <Text small cursor="pointer" blue style={{ textDecoration: 'underline' }}>
            Compare {groupFavoriteCount} of {MAX_FAVORITES} favorite plans
          </Text>
        </div>
      )}
      {qleCtx && (
        <QLEBundleCompareDrawer
          open={openQLECompareDrawer}
          onClose={() => {
            setOpenQLECompareDrawer(false)
          }}
        />
      )}
    </Container>
  )
}

export default CompareNav
