/* eslint-disable no-console */
import { getWaiter } from 'redux-waiter'

import { reimbursementService } from '@hixme/api'
import { BENEFIT_TYPE_ICHRA } from '@hixme/benefit-types'
import { clearModal } from '@hixme/modal'

// project
import userSession from 'store/modules/user-session'
import { GET_USER_BENEFITS } from 'store/modules/user-session/constants'
import persons from 'store/modules/persons'
import {
  createErrorNotification,
  createInfoNotification,
  createSuccessNotification,
} from 'modules/notification-manager/actions'

import ExpenseFactory from '../models/ExpenseFactory'
import type from './actionTypes'

import {
  sendReimbursmentMessage,
  getExpenseRequests,
  getReceiptsByReimbursement,
  getLineItemsByReimbursement,
  createExpense,
  updateExpense,
  getIsValidExpenseDate,
} from './api'
import { selectors } from './index'
import { GET_EXPENSE_REQUEST_VALIDATE } from './constants'

export const loadExpenseRequests = (payload) => ({
  type: type.LOAD_EXPENSE_REQUESTS,
  payload,
})

export const loadFamilyBenefits = (payload) => ({
  type: type.LOAD_FAMILY_BENEFITS,
  payload,
})

export const loadReimbursementsReceipt = (payload) => ({
  type: type.LOAD_RECEIPTS,
  payload,
})

export const selectLifeEvent = (payload) => ({
  type: type.SELECT_LIFE_EVENT,
  payload,
})

export const selectExpense = (payload) => ({
  type: type.SET_SELECTED_EXPENSE,
  payload,
})

export const sendReimbursementMessage = (payload) => ({
  type: type.SEND_EXPENSE_MESSAGE,
  payload,
})

export const setCreatedExpenseId = (payload) => ({
  type: type.SET_EXPENSE_ID,
  payload,
})

export const clearExpenseId = () => ({
  type: type.SET_EXPENSE_ID,
  payload: null,
})

export const updateUi = (payload) => ({
  type: type.SET_UI_STATE,
  payload,
})

export const viewOlderReimbursements = () => ({
  type: type.VIEW_MORE,
})

export const updateSelectedExpenseData = (payload) => ({
  type: type.SET_SELECTED_EXPENSE,
  payload,
})

export const updateBenefitYear = (year) => (dispatch, getState) => {
  const { Benefits } = getWaiter(getState(), `${GET_USER_BENEFITS}_`).response || {}
  const [ICHRABenefit] = Benefits.filter(
    (benefit) =>
      benefit.BenefitType === BENEFIT_TYPE_ICHRA &&
      Number(benefit.BenefitEndDate.slice(0, 4)) === Number(year)
  ).sort((a, b) => new Date(a.BenefitEndDate) - new Date(b.BenefitEndDate))
  if (!ICHRABenefit) {
    return dispatch({
      type: type.SET_BENEFIT_YEAR,
      payload: { year, family: [] },
    })
  }
  return dispatch({
    type: type.SET_BENEFIT_YEAR,
    payload: {
      year,
      family: ICHRABenefit.Persons.filter((person) => person.BenefitStatus === 'Included'),
    },
  })
}

export const loadLineItems = (payload) => ({
  type: type.LOAD_LINE_ITEMS,
  payload,
})

export const setExpenseAvailable = (payload) => ({
  type: type.SET_EXPENSE_AVAILABLE,
  payload,
})

export const getAllowanceMessage = (ichraExpenseDate) => async (dispatch, getState) => {
  try {
    const clientId = userSession.selectors.getClientId(getState())
    const employeePublicKey = persons.selectors.getEmployeeKey(getState())
    let summaryData =
      getWaiter(getState(), `${GET_EXPENSE_REQUEST_VALIDATE}_${ichraExpenseDate}`).response || null
    if (!summaryData) {
      summaryData = await dispatch(
        getIsValidExpenseDate({ clientId, employeePublicKey, ichraExpenseDate })
      )
    }

    dispatch(
      updateUi({
        allowanceInformation: {
          valid: summaryData.isValid,
          message: summaryData.message,
        },
      })
    )
  } catch (err) {
    console.debug('No Benefit')
  }
}

export const getExpenseRequestsByEmployeeKeyAndUserId = () => async (dispatch, getState) => {
  try {
    const benefitYear = selectors.getSelectedYear(getState())
    const clientId = userSession.selectors.getClientId(getState())
    const employeePublicKey = persons.selectors.getEmployeeKey(getState())
    dispatch(updateSelectedExpenseData(ExpenseFactory.createWithId({}, employeePublicKey)))
    const expensesRequests = await dispatch(
      getExpenseRequests({ clientId, employeePublicKey, benefitYear })
    )
    if (!expensesRequests.benefitPublicKey) {
      dispatch(
        createInfoNotification(
          'Reimbursements are not enabled, Please check your benefits effective date'
        )
      )
      return
    }
    if (expensesRequests) {
      const reimbursements = expensesRequests.reimbursements.map((reimbusement) =>
        ExpenseFactory.fromReimbursementService({
          ...reimbusement,
          userId: employeePublicKey,
        })
      )
      dispatch(loadExpenseRequests(reimbursements))
      dispatch(
        setExpenseAvailable(
          expensesRequests.previousYearAllowed || expensesRequests.canSubmitExpenseRequest
        )
      )
      dispatch(
        selectLifeEvent({
          ...expensesRequests,
          reimbursements,
        })
      )
    }
  } catch (error) {
    dispatch(loadExpenseRequests([]))
    dispatch(selectLifeEvent(null))
    if (error.status !== 404) {
      dispatch(createErrorNotification(`There was a problem getting your Expense Requests.`))
    }
  }
}

export const sendExpenseMessage = (data, id) => async (dispatch) => {
  try {
    dispatch(sendReimbursmentMessage(data, id))
      .then(() => {
        dispatch(clearModal())
        dispatch(createSuccessNotification('Message successfully sent.'))
        dispatch(getExpenseRequestsByEmployeeKeyAndUserId())
      })
      .catch((err) => {
        dispatch(
          createErrorNotification(`Failed to send message to admin.
          Error: ${err}`)
        )
      })
  } catch (error) {
    dispatch(
      createErrorNotification(`Failed to send message to admin.
      Error: ${error}`)
    )
  }
}

export const createBenefitExpense = (expense) => async (dispatch) => {
  try {
    const { ReimbursementPublicKey } = await dispatch(
      createExpense({ ...expense.getAsExpenseData() })
    )
    expense.setId(ReimbursementPublicKey)
    expense.reset()
    dispatch(updateSelectedExpenseData(expense))
    dispatch(updateUi({ isUploadingFiles: true }))
  } catch (error) {
    dispatch(
      createErrorNotification(`There was a problem creating your Expense Request.
      Error: ${error}`)
    )
  }
}

export const updateBenefitExpense = (data, params) => async (dispatch) => {
  try {
    await dispatch(updateExpense(data, params))
    dispatch(updateUi({ isUploadingFiles: true }))
  } catch (error) {
    dispatch(
      createErrorNotification(`There was a problem updating your Expense.
      Error: ${error}`)
    )
  }
}
export const getReceitpsByReimbursementId = (reimbusementId) => async (dispatch) => {
  try {
    const receipts = await dispatch(getReceiptsByReimbursement(reimbusementId))
    dispatch(
      loadReimbursementsReceipt({
        id: reimbusementId,
        receipts,
      })
    )
  } catch (error) {
    dispatch(
      createErrorNotification(`There was a problem updating your Expense.
      Error: ${error}`)
    )
    dispatch(loadReimbursementsReceipt({ id: reimbusementId, message: error.message }))
  }
}

export const getReceiptSignedUrl = (reimbursementPublicKey, filename) =>
  reimbursementService({
    route: 'reimbursements/{reimbursementPublicKey}/fileName/{filename}/preSignedUrl',
    method: 'GET',
    params: {
      reimbursementPublicKey,
      filename,
    },
  })

export const deleteBenefitExpense = (id) => async (dispatch) => {
  try {
    await reimbursementService({
      route: 'reimbursements/admin/reimbursementId/{reimbursementPublicKey}',
      method: 'DELETE',
      params: {
        reimbursementPublicKey: id,
      },
    })
  } catch (err) {
    dispatch(createErrorNotification(`There was a problem deleting your Expense.`))
  }
}

export const createReceipt = (reimbursementPublicKey, body) =>
  reimbursementService({
    route: 'reimbursements/{reimbursementPublicKey}/receipts',
    method: 'POST',
    params: {
      reimbursementPublicKey,
    },
    body,
  })

export const deleteReceipt = (reimbursementPublicKey, receiptPublicKey, body) =>
  reimbursementService({
    route: 'reimbursements/{reimbursementPublicKey}/receipts/{receiptPublicKey}',
    method: 'DELETE',
    params: {
      receiptPublicKey,
      reimbursementPublicKey,
    },
    body,
  })

export const patchReceipt = (reimbursementPublicKey, receiptPublicKey, body) =>
  reimbursementService({
    route: 'reimbursements/{reimbursementPublicKey}/receipts/{receiptPublicKey}',
    method: 'PATCH',
    params: {
      receiptPublicKey,
      reimbursementPublicKey,
    },
    body,
  })

export const getExpenseLineItems = (reimbursementId) => async (dispatch) => {
  try {
    const lineItems = await dispatch(getLineItemsByReimbursement(reimbursementId))
    dispatch(loadLineItems(lineItems))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.err(err)
  }
}
