import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 7px;
  width: 100%;
  position: relative;
`

export const BaseBox = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: width 1s ease-in-out;
`

export const Background = styled(BaseBox)`
  background: transparent;
  width: 100%;
`

export const Progress = styled(BaseBox)`
  background: ${({ color }) => color};
  width: ${({ percent }) => percent}%;
`
