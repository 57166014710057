import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// project
import notification from 'modules/notification-manager'

// module
import * as actions from '../actions'
import { getLocationsListSelectorByPostalCode } from '../selectors'
import { LocationsDropdown } from '../components'

const VALID_POSTALCODE_LENGTH = 5

class LocationsContainer extends Component {
  constructor(props) {
    super(props)
    this.previousPostalCode = props.postalCode
  }

  componentWillMount() {
    if (this.props.postalCode.length === VALID_POSTALCODE_LENGTH) {
      this.props
        .getLocations(this.props.postalCode)
        .catch((e) => this.props.createErrorNotification(e.message))
      this.previousPostalCode = this.props.postalCode
    }
  }

  // Reload county/state list if postal code changes.
  componentWillReceiveProps(nextProps) {
    if (
      this.previousPostalCode !== nextProps.postalCode &&
      nextProps.postalCode.length === VALID_POSTALCODE_LENGTH
    ) {
      this.props
        .getLocations(nextProps.postalCode)
        .catch((e) => this.props.createErrorNotification(e.message))
      this.previousPostalCode = nextProps.postalCode
    }
  }

  render() {
    const { list } = this.props
    return list ? <LocationsDropdown {...this.props} /> : <span>Loading . . .</span>
  }
}

LocationsContainer.propTypes = {
  list: PropTypes.array,
  postalCode: PropTypes.string.isRequired,
  getLocations: PropTypes.func.isRequired,
  createErrorNotification: PropTypes.func.isRequired,
}

LocationsContainer.defaultProps = { list: [] }

const mapStateToProps = (state, { postalCode }) => ({
  list: getLocationsListSelectorByPostalCode(postalCode, state) || [],
})

export default connect(mapStateToProps, {
  ...actions,
  ...notification.actions,
})(LocationsContainer)
