import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { lifecycle } from 'recompose'
import { connect } from 'react-redux'

import AppLoader from 'components/AppLoader'
import UserSessionErrorView from '../views/UserSessionErrorView'

// project
import { initUserId, initializeUser } from '../actions'

import {
  getEnrollmentSession,
  getTermsAndConditionsAgreed,
  getUserId,
  getUserName,
  sessionErrorsSelector,
  sessionStarted,
  sessionIsUpdating,
} from '../selectors'

import TermsAndConditionsView from '../components/TermsAndConditionsView'

const userSessionLifecycle = lifecycle({
  componentWillMount() {
    this.props.initializeUserSession()
  },
  componentWillReceiveProps(props) {
    if (props.personPublicKey !== this.props.personPublicKey) {
      this.props.initializeUserSession()
    }
  },
})

class UserSessionContainer extends Component {
  render() {
    const {
      children,
      userSessionStarted,
      termsAndConditionsAgreed,
      hasErrors,
      userInitializing,
      enrollmentSession,
    } = this.props

    const isQLEEnrollment = Boolean(enrollmentSession.QualifyingLifeEvent)

    if (!userInitializing && hasErrors) {
      return <UserSessionErrorView />
    }

    if (userSessionStarted) {
      if (!termsAndConditionsAgreed && !isQLEEnrollment) {
        return <TermsAndConditionsView />
      }
      return children
    }
    return <AppLoader />
  }
}

UserSessionContainer.displayName = 'UserSessionContainer'
UserSessionContainer.propTypes = {
  children: PropTypes.element.isRequired,
  hasErrors: PropTypes.bool,
  userSessionStarted: PropTypes.bool,
  termsAndConditionsAgreed: PropTypes.bool,
  userInitializing: PropTypes.bool,
}
UserSessionContainer.defaultProps = {
  dispatch: undefined,
  userSessionStarted: false,
  termsAndConditionsAgreed: undefined,
  hasErrors: false,
  userInitializing: true,
}

const mapStateToProps = (state) => ({
  userId: getUserId(state),
  userInitializing: sessionIsUpdating(state),
  username: getUserName(state),
  userSessionStarted: sessionStarted(state),
  hasErrors:
    !(Object.keys(getEnrollmentSession(state)).length > 0) ||
    sessionErrorsSelector(state).length > 0,
  termsAndConditionsAgreed: getTermsAndConditionsAgreed(state),
  enrollmentSession: getEnrollmentSession(state),
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  initializeUserSession: () => {
    dispatch(initUserId())
    dispatch(initializeUser())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(userSessionLifecycle(UserSessionContainer))
