import React from 'react'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'

import Modal from 'modules/modal'
import VideoThumb from '../VideoThumb'

const HixmeGapCoverage = () => (
  <Container white rounded>
    <Title small>Hixme Gap Coverage</Title>
    <hr />
    <Text p light>
      {"Hixme's"} Bundles integrate additional Gap Coverages to fill increasing
      {'"gaps"'} in {"today's"} health insurance coverages. Bundles are prepared for your specific
      gap exposures as you shop, while keeping your monthly payroll deductions to a minimum.
    </Text>
    <Text p light>
      Hixme Gap Coverages are triggered after a significant health care expense that you would
      otherwise have to pay due to your health coverage deductible, for example. Watch the attached
      video to see how the Hixme Gap Coverages work.
    </Text>

    <Container flex noPadding margin="10px 0 0 0">
      <VideoThumb videoId="sdg2oiy2dl" />
    </Container>
  </Container>
)

HixmeGapCoverage.displayName = 'HixmeGapCoverage'
HixmeGapCoverage.propTypes = {}

export default Modal.containers.Modal({
  content: {
    position: 'top',
    size: 'medium',
    styles: {
      margin: '50px 0 0 0',
    },
  },
  overlay: {
    hideCloseButton: false,
    routeOnHide: false,
    dark: true,
  },
})(HixmeGapCoverage)
