import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import UploadBox from '@hixme-ui/upload-box'

// project
import {
  addFiles,
  removeFile,
  updateAmount,
  updateGeneralMessage,
  updateNote,
} from 'modules/ultimate-uploader/actions'
import { createInfoNotification } from 'modules/notification-manager/actions'

import { getFilesByGroupKey, getIsUploadingByGroupKey } from 'modules/ultimate-uploader/selectors'

import FileList from '../FileList/FileList'
import { deleteReceipt, updateSelectedExpenseData } from '../../../../modules/actions'
import Expense from '../../../../models/Expense'

const FileUploader = ({
  expense,
  files,
  handleUpdateAmount,
  handleNoteChange,
  dropHandler,
  handleRemoveFile,
  onDropRejected,
  isUploading,
  maxSize,
}) => (
  <Container flex noPadding direction="column">
    <Container flex direction="column" width="100%" padding="5px 5px 0 5px">
      <Container noPadding margin="10px 0">
        <UploadBox onDrop={dropHandler} maxSize={maxSize} onDropRejected={onDropRejected} />
        <Container flex padding="10px 0" justifySpaceBetween>
          <Text light small>
            Max size: 15 Mb
          </Text>
          <Text light small>
            Supported files: PDF, JPEG, JPG and PNG
          </Text>
        </Container>
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          <FileList
            expense={expense}
            isUploading={isUploading}
            files={files}
            handleRemoveFile={handleRemoveFile}
            handleUpdateAmount={handleUpdateAmount}
            handleNoteChange={handleNoteChange}
          />
        </div>
      </Container>
    </Container>
  </Container>
)

const mapStateToProps = (state, props) => {
  const files = getFilesByGroupKey(props.expense.id, state) || []
  const isUploading = getIsUploadingByGroupKey(props.expense.id, state)
  return {
    files: files.sort((_, b) => (b.id ? -1 : 1)),
    expenseId: props.expense.id,
    isUploading,
    maxSize: 15728640, // 15MB
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  dropHandler: (files) => {
    const receipts = props.expense.getReceiptsToAdd(files)
    if (receipts.length) {
      props.expense.addReceipts(receipts)
      dispatch(updateSelectedExpenseData(props.expense))
      dispatch(addFiles(receipts, props.expense.id))
    }
    dispatch(updateGeneralMessage(null, props.expense.id))
  },
  handleRemoveFile: (fileName, id) => {
    if (id) {
      deleteReceipt(props.groupKey, id, {
        employeePublicKey: props.employeePublicKey,
      }).then(() => {
        props.expense.removeReceipt(fileName)
        dispatch(removeFile(fileName, props.expense.id))
      })
    } else {
      props.expense.removeReceipt(fileName)
      dispatch(removeFile(fileName, props.expense.id))
    }
    dispatch(updateSelectedExpenseData(props.expense))
    dispatch(updateGeneralMessage(null, props.expense.id))
  },
  handleUpdateAmount: (data, fileName) => {
    dispatch(updateAmount(data, fileName, props.expense.id))
  },
  handleNoteChange: (value, fileName) => {
    dispatch(updateNote(value, fileName, props.expense.id))
  },
  onDropRejected: () => {
    dispatch(createInfoNotification('Selected file is too large.'))
  },
})

FileUploader.displayName = 'FileUploader'
FileUploader.propTypes = {
  expense: PropTypes.instanceOf(Expense),
  files: PropTypes.array,
  maxSize: PropTypes.number.isRequired,
  handleUpdateAmount: PropTypes.func,
  handleNoteChange: PropTypes.func,
  dropHandler: PropTypes.func,
  handleRemoveFile: PropTypes.func,
  onDropRejected: PropTypes.func,
  isUploading: PropTypes.bool,
}

FileUploader.defaultProps = {
  handleUpdateAmount: () => null,
  handleNoteChange: () => null,
  dropHandler: () => null,
  handleRemoveFile: () => null,
  onDropRejected: () => null,
  files: [],
  isUploading: false,
  expense: null,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploader)
