/* eslint-disable import/prefer-default-export */
import { ButtonBase } from '@hixme-ui/button'

export const OutlineButton = ButtonBase.extend`
  background: white;
  border-color: #3a4951;
  border-style: solid;
  border-width: 1px;
  color: #3a4951;
  padding: 20px 40px;
  :hover {
    box-shadow: 0px 1px 4px 0px rgba(56, 59, 60, 0.18);
  }
`
