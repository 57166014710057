import React from 'react'
import { MrModal } from '@hixme/modal'
import { Typography, Stack } from '@sureco/design-system'
// hixme-ui
import ContentContainer from '@hixme-ui/content-container'

const HelpModal = () => (
  <MrModal title="Need help?">
    <ContentContainer contentMargin="20px auto 0">
      <Stack space={24}>
        <Stack space={4}>
          <Typography bold size={16} style={{ textTransform: 'uppercase' }}>
            Self-Help Articles Are Now Available in Platform!
          </Typography>
          <Typography>
            Click on the <Typography bold>Help</Typography> button on the lower right side of screen
            to ask a question or contact a specialist for assistance.
          </Typography>
          <Typography>
            Search Self-Help Articles: <a href="https://sureco.zendesk.com/hc/en-us" target="_blank" style={{ fontSize: 12 }}>SureCo Enrollment Platform</a>
          </Typography>
        </Stack>

        <Stack space={4}>
          <Typography
            bold
            size={14}
            style={{ textDecoration: 'underline', textTransform: 'uppercase' }}
          >
            Employees
          </Typography>
          <Typography>
            Use one of the following methods to contact an Employee Experience Specialist:
          </Typography>
          <a
            style={{ fontSize: 12 }}
            href="https://sureco.zendesk.com/hc/en-us/requests/new?ticket_form_id=14500070598039"
            target="_blank"
          >
            Submit Ticket
          </a>
          <Typography>
            Email:{' '}
            <a style={{ fontSize: 12 }} href="mailto:employee.experience@sureco.com">
              employee.experience@sureco.com
            </a>
          </Typography>
          <Typography>Call: 949.989.4906 (press 1 for English, press 2 for Spanish)</Typography>
        </Stack>

        <Stack space={4}>
          <Typography
            bold
            size={14}
            style={{ textDecoration: 'underline', textTransform: 'uppercase' }}
          >
            HR Admins and Benefit Consultants:
          </Typography>
          <Typography>
            Use one of the following methods to contact a Customer Experience Specialist:
          </Typography>
          <a
            style={{ fontSize: 12 }}
            href="https://sureco.zendesk.com/hc/en-us/requests/new?ticket_form_id=15673612216343"
            target="_blank"
          >
            Submit Ticket
          </a>
          <Typography>
            Email:{' '}
            <a style={{ fontSize: 12 }} href="mailto:customer.experience@sureco.com">
              customer.experience@sureco.com
            </a>
          </Typography>
          <Typography>Call: 949.356.6976</Typography>
        </Stack>
      </Stack>
    </ContentContainer>
  </MrModal>
)

HelpModal.displayName = 'HelpModal'

export default HelpModal
