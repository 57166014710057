import React from 'react'
import PropTypes from 'prop-types'

import Container from '@hixme-ui/container'

import AttachedFile from 'components/ExpenseCard/components/AttachedFile/AttachedFile'
import ReceiptLineItem from 'components/ReceiptLineItem/ReceiptLineItem'

const ReceiptItems = ({ receipt, onRemove }) => {
  if (receipt.lineItems.length === 0) {
    return null
  }
  return (
    <Container noPadding width="100%">
      <AttachedFile text={receipt.name} onClick={() => window.open(receipt.url, '_blank')} />
      <Container flex noPadding width="100%" direction="column">
        {receipt.lineItems.map((lineItem) => (
          <ReceiptLineItem key={lineItem.id} item={lineItem} onRemove={onRemove} />
        ))}
      </Container>
    </Container>
  )
}

ReceiptItems.propTypes = {
  receipt: PropTypes.object,
  onRemove: PropTypes.func,
}

ReceiptItems.defaultProps = {
  receipt: {},
  onRemove: null,
}

export default ReceiptItems
