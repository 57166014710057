import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { MrModalManager } from '@hixme/modal'

// hixme-ui
import AppLayout from '@hixme-ui/app-layout'

// project
import SideNavContainer from 'containers/SideNavContainer'
import { NotificationManager } from 'modules/notification-manager/components'
import { JodalContainer } from 'modules/modal/containers'
import { LoggedInAsContainer } from 'store/modules/user-session/containers'
import HeaderContainer from 'containers/HeaderContainer'

// modals
import { modals } from 'modal-constants'
import useFeatureFlags from '../../hooks/useFeatureFlags'
import { datadog } from 'helpers/datadog'

const EnrollmeLayout = ({ person, username, children }) => {
  const { flags, methods } = useFeatureFlags()

  if (person) {
    // Identify the user with LaunchDarkly when the enrollme application is loaded
    methods.identify({
      key: `${person.Id}`,
      email: person.EmailAddress,
      companyId: person.ClientPublicKey,
    })

    // identify the user for datadog
    datadog.setUser({
      id: person.Id,
      name: person.FullName,
      email: person.EmailAddress,
      companyId: person.ClientPublicKey,
      role: person.UserRole
    })
  }

  const SideNavUser = () => <SideNavContainer userName={username} />

  useEffect(() => {
    if (flags['customer-service.fe.zendesk-widget-enabled']) {
      try {
        window.zE('webWidget', 'show')
      } catch(e) {
        console.error('Error loading Zendesk widget', e)
      }
    }
  }, [])

  return (
    <div style={{ height: '100%' }}>
      <NotificationManager />
      <MrModalManager appElementId="app-layout-node" modals={modals} />
      <JodalContainer />
      <div id="app-layout-node" style={{ height: '100%' }}>
        <AppLayout HeaderComponent={HeaderContainer} SidebarComponent={SideNavUser}>
          <LoggedInAsContainer />
          <div>{children}</div>
        </AppLayout>
      </div>
    </div>
  )
}

EnrollmeLayout.propTypes = {
  children: PropTypes.element.isRequired,
  username: PropTypes.string,
  person: PropTypes.object,
}

EnrollmeLayout.defaultProps = {
  username: '',
  person: undefined,
}

export default EnrollmeLayout
