import { createSelector } from 'reselect'
import { NAME } from './constants'

export const shouldDisplayModalSelector = createSelector(
  (state) => state[NAME],
  (modal) => modal.modalActive
)

export const getViewSelector = createSelector(
  (state) => state[NAME],
  (modal) => modal.View
)
