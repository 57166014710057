import validator from 'validator'
import { capitalize } from 'lodash'
import getDistance from 'geolib/es/getDistance'

const MILES_VALUE = 0.000621371192

export const validateSearchForm = ({ Name, PostalCode }) => {
  const errors = {}

  if (!Name) {
    errors.Name = 'This field is required'
    return errors
  }

  if (!validator.isLength(Name, { min: 3 })) {
    errors.Name = 'Minimum of 3 characters'
  }

  if (!PostalCode) {
    errors.PostalCode = 'This field is required'
    return errors
  }
  if (!validator.isNumeric(PostalCode) || !validator.isLength(PostalCode, { min: 5, max: 5 })) {
    errors.PostalCode = 'Invalid Zip Code'
  }

  return errors
}

const getProviderDistance = (origin, coords) =>
  `${(getDistance(origin, coords) * MILES_VALUE).toFixed(2)} miles`

export const getProviderFullName = (name) =>
  `${name
    .split(' ')
    .map(capitalize)
    .join(' ')}`

export const providerMapper = (data, distance) =>
  data &&
  data.map((result) => ({
    id: `${result.id}`,
    fullName: getProviderFullName(result.presentation_name),
    specialty: result.specialty,
    phoneNumber: result.phone,
    addresses: result.addresses.map((address) => ({
      id: address.id,
      address: `${address.street_line_1}, ${address.city}, ${address.state} ${address.zip_code}`,
      phone: address.phone_numbers.shift(),
      distance:
        distance &&
        getProviderDistance(distance, {
          latitude: address.latitude,
          longitude: address.longitude,
        }),
    })),
  }))
