export const BUNDLE_RECOMMENDED_POST = 'bundlesRecommendedPost'
export const COMPARE_VIEW = 'compare'
export const EMPLOYEE_BENEFITS_GET = 'employeeBenefitsGet'
export const LIST_VIEW = 'list'
export const LOCAL_STORAGE_NAME = 'hx-fabu'
export const MAX_FAVORITES = 3
export const NAME = 'healthBundles'
export const STACK_VIEW = 'stack'

export const DrugsForIllnessCondition = 'DRUGS FOR ILLNESS / CONDITION'
