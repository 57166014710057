import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Title from '@hixme-ui/title'

const CircleStep = ({ children, completed, uncompletedColor }) => (
  <div>
    {completed ? (
      <Container flex noPadding justifyContent="center" alignItems="center">
        <Icon primary checkMarkCircle fontSize={48} />
      </Container>
    ) : (
      <div
        style={{
          display: 'inline-block',
          height: '48px',
          width: '48px',
          backgroundColor: uncompletedColor,
          borderRadius: '50%',
          margin: '0',
        }}
      >
        <Title thin white small style={{ margin: '4px 0 0 0' }}>
          {children}
        </Title>
      </div>
    )}
  </div>
)

CircleStep.displayName = 'CircleStep'
CircleStep.propTypes = {
  children: PropTypes.node.isRequired,
  completed: PropTypes.bool,
  uncompletedColor: PropTypes.string,
}

CircleStep.defaultProps = {
  completed: false,
  uncompletedColor: '#677786',
}

export default CircleStep
