import { QLEType } from './constants'
import { QLERecordData } from './types'

export type QLERelocationAddress = {
  streetAddress: string
  aptSuite?: string
  city: string
  zip: string
  countyState: string
}

/**
 * Shared state that is used by every screen within Life Events (QLE).
 */
export type LifeEventsSharedState = {
  /**
   * If any of the screens have unsaved values. Dirty will true.
   */
  dirty: boolean
  /**
   * If any form is being saved, this will be true. Otherwise, false.
   */
  saving: boolean
  /**
   * The type of QLE that the user has selected on the root /life-events page.
   */
  qleType: QLEType
  /**
   * The QLE record data
   */
  record?: QLERecordData
  /**
   * The address of the new location for relocation life events.
   */
  location?: QLERelocationAddress
  /**
   * Stores the selected health bundle public key.
   */
  selectedHealthBundlePublicKey?: string
  /**
   * Temporary data store for steps like Health
   */
  temp?: Record<string, any>
}

/**
 * The root state object for Life Events (QLE).
 */
export type LifeEventsState = {
  /**
   * Any variable shared across every screen within the QLE workflow.
   */
  shared: LifeEventsSharedState
}

/**
 * All possible actions supported by the Life Events Reducer.
 */
export enum LifeEventsActions {
  UpdateQLEType = 'UPDATE_QLE_TYPE',
  Load = 'LOAD',
  Save = 'SAVE',
  SaveSuccess = 'SAVE_SUCCESS',
  SaveError = 'SAVE_ERROR',
  UpdateMembers = 'UPDATE_MEMBERS',
  UpdateDocuments = 'UPDATE_DOCUMENTS',
  UpdateResults = 'UPDATE_RESULTS',
  UpdateStatus = 'UPDATE_STATUS',
  UpdateRecord = 'UPDATE_RECORD',
  UpdateSelectedHealthBundle = 'UPDATE_SELECTED_HEALTH_BUNDLE',
  Clear = 'CLEAR',
}
