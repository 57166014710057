const MsgAllowedCardList = [
  // Sureco
  '1E20A109-EB7D-4226-B159-E67BC858D0DA',
  // UHSM
  '78298a3b-e566-4d92-8810-33e06fb2cc00',
  // Anuvu
  '9784542f-8ef7-48bb-bfc4-ee45c085256d',
]

export default MsgAllowedCardList
