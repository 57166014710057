import React from 'react'
import PropTypes from 'prop-types'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

// hixme-ui
import { Button } from '@hixme-ui/forms'

// project
import {
  updateFileId,
  uploadComplete,
  uploading,
  uploadError,
  uploadFile,
} from 'modules/ultimate-uploader/actions'
import {
  getIsUploadingByGroupKey,
  getPendingUploadFilesByGroupKey,
} from 'modules/ultimate-uploader/selectors'
import { getEmployeeKey } from 'store/modules/persons/selectors'
import { getSelectedExpense } from '../../../../modules/selectors'
import { createReceipt, getReceiptSignedUrl } from '../../../../modules/actions'
import { sanitizeCurrencyValue } from '../../../../../../components/CurrencyInput/CurrencyInputUtils'

const UploadButton = ({ disabled, files, isSubmitting, onUpload, submittingText }) => (
  <Button
    disabled={disabled}
    submitting={isSubmitting}
    submittingText={submittingText}
    style={{ padding: '0 60px' }}
    onClick={onUpload}
  >
    {`Upload files ${!isEmpty(files) ? `(${files.length})` : ''}`}
  </Button>
)

UploadButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onUpload: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool,
  submittingText: PropTypes.string,
}

UploadButton.defaultProps = {
  submittingText: 'Uploading...',
  isSubmitting: false,
}

UploadButton.displayName = 'UploadButton'

const mapStateToProps = (state) => {
  const expense = getSelectedExpense(state)
  const pendingFiles = getPendingUploadFilesByGroupKey(expense.id, state)
  const hasInvalidAmount = pendingFiles && pendingFiles.some((file) => !file.validAmount)
  const hasMissingDescription = pendingFiles && pendingFiles.some((file) => file.note === '')
  const disabled = isEmpty(pendingFiles) || hasInvalidAmount || hasMissingDescription
  const employeeKey = getEmployeeKey(state)
  const isUploading = getIsUploadingByGroupKey(expense.id, state)
  return {
    expenseId: expense.id,
    files: pendingFiles || [],
    disabled,
    employeePublicKey: employeeKey,
    isSubmitting: isUploading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onClickHandler: ({ expenseId, files, employeePublicKey }) => {
    const getErrorMessage = (message) => {
      const defaultFailedMessage = 'File failed to upload.'
      if (message.includes('supported')) {
        return `${defaultFailedMessage} File type not supported`
      }
      return `${defaultFailedMessage} Please check your internet connection try again.
      `
    }
    dispatch(uploading(true, expenseId))
    const filesToUpload = files.filter((wrapper) => !wrapper.complete && !wrapper.id)

    const allUploads = filesToUpload.map((wrapper) => {
      async function uploadSingleFile() {
        try {
          const filename = wrapper.file.name
          const signedUrl = await getReceiptSignedUrl(expenseId, filename)
          return dispatch(uploadFile(signedUrl, wrapper.file, expenseId))
            .then(async () => {
              const [ReceiptPublicKeyWrapper] = await createReceipt(expenseId, {
                employeePublicKey,
                amount: sanitizeCurrencyValue(wrapper.amount),
                description: wrapper.note,
                fileName: filename,
              })
              dispatch(updateFileId(filename, expenseId, ReceiptPublicKeyWrapper.ReceiptPublicKey))
              dispatch(uploadComplete(filename, expenseId))
            })
            .catch((err) => {
              dispatch(uploading(false, expenseId))
              dispatch(uploadError(filename, expenseId, getErrorMessage(err.message)))
            })
        } catch (err) {
          dispatch(uploading(false, expenseId))
          dispatch(uploadError(wrapper.file.name, expenseId, getErrorMessage(err.message)))
          return false
        }
      }
      return uploadSingleFile()
    })
    Promise.all(allUploads).finally(() => {
      dispatch(uploading(false, expenseId))
    })
  },
})

const withActions = withHandlers({
  onUpload: ({ onClickHandler, expenseId, files, employeePublicKey }) => () => {
    onClickHandler({ expenseId, files, employeePublicKey })
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withActions
)(UploadButton)
