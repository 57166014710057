import { combineReducers } from 'redux'
import { reducer as authui } from '@hixme/auth-ui'
import { reducer as form } from 'redux-form'
import { reducer as features } from '@hixme/features'
import mrModalReducer, { NAME as mrModalName } from '@hixme/modal'

import cart from 'store/modules/cart'
import gateway from '@hixme/redux-gateway'
import locations from 'modules/locations'
import modal from 'modules/modal'
import notificationManager from 'modules/notification-manager'
import personsModule from 'store/modules/persons'
import reduxWaiter from 'redux-waiter'
import session from 'modules/session'
import ultimateUploader from 'modules/ultimate-uploader'
import userSession from './modules/user-session'

/* router location */
import locationReducer from './location'

export const reducers = (asyncReducers) =>
  combineReducers({
    // Add sync reducers here
    authui,
    features,
    form,
    location: locationReducer,

    [cart.constants.NAME]: cart.reducer,
    [gateway.constants.NAME]: gateway.reducer,
    [locations.constants.NAME]: locations.reducer,
    [modal.constants.NAME]: modal.reducer,
    [mrModalName]: mrModalReducer,
    [notificationManager.constants.NAME]: notificationManager.reducer,
    [personsModule.constants.NAME]: personsModule.reducer,
    [reduxWaiter.constants.NAME]: reduxWaiter.reducer,
    [session.constants.NAME]: session.reducer,
    [ultimateUploader.constants.NAME]: ultimateUploader.reducer,

    // eslint-disable-next-line
    [userSession.constants.NAME]: userSession.reducer,

    ...asyncReducers,
  })

/* eslint-disable no-param-reassign */
export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer
  store.replaceReducer(reducers(store.asyncReducers))
}

export default reducers
