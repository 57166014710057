import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

const EPOTooltip = ({ tooltipId }) => (
  <ReactTooltip id={tooltipId} effect="solid" place="right">
    <Container flex direction="column" padding="10px" width="350px">
      <Text primary large thin>
        What is a <Text bold>PPO</Text>?
      </Text>
      <Text p>
        In a Preferred Provider Organization (PPO), you can see any doctor, specialist or hospital
        within your provider network without a referral. (Certain services will require
        pre-authorization and referrals depending on carrier)
      </Text>
      <Text p>
        Your insurance will also provide reimbursement for covered services rendered by doctors
        outside the network.
      </Text>
      <Text p>
        Most policies have a separate deductible for out-of-network providers. Going out-of-network
        will usually lead to higher out-of-pocket costs.
      </Text>
    </Container>
  </ReactTooltip>
)

EPOTooltip.propTypes = {
  tooltipId: PropTypes.string.isRequired,
}

EPOTooltip.defaultProps = {}

export default EPOTooltip
