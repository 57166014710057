import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import { FooterSection } from './style'
import AddBenefitButtonContainer from '../../../../containers/AddBenefitButtonContainer'

const PlanFooter = ({
  plan,
  handleSaveAndContinue,
  totalGroupCount,
  currentGroupIndex,
  hasNextGroup,
  isQLE,
}) => (
  <FooterSection noLeft>
    <Container noPadding white margin="0" width="300px">
      <Container noPadding margin="12px 0" height="22px">
        {!isQLE && plan.added && (
          <Text onClick={handleSaveAndContinue} primary medium cursor="pointer" center>
            {hasNextGroup ? 'Shop for next group' : 'Save & Continue'}
          </Text>
        )}
      </Container>
      <AddBenefitButtonContainer bundleId={plan.BundlePublicKey} />
      <Container noPadding margin="8px 0" height="18px">
        {totalGroupCount > 1 && (
          <Text small light center>
            {`Group ${currentGroupIndex + 1} of ${totalGroupCount}`}
          </Text>
        )}
      </Container>
    </Container>
    <div style={{ width: '20px', background: 'white' }} />
  </FooterSection>
)

PlanFooter.propTypes = {
  plan: PropTypes.object.isRequired,
  handleSaveAndContinue: PropTypes.func.isRequired,
  currentGroupIndex: PropTypes.number.isRequired,
  totalGroupCount: PropTypes.number.isRequired,
  hasNextGroup: PropTypes.bool.isRequired,
  isQLE: PropTypes.bool,
}

export default PlanFooter
