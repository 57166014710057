import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'
import { clearModal } from '@hixme/modal'
import { BENEFIT_TYPE_LONG_TERM_DISABILITY } from '@hixme/benefit-types'

// project
import DeclineCoverageModal from 'components/DeclineCoverageModal'
import { createErrorNotification } from 'modules/notification-manager/actions'

// store
import cart from 'store/modules/cart'
import { datadog } from 'helpers/datadog'

const mapStateToProps = (state, { data }) => ({
  coveredPersons: data.persons,
  benefitProducts: [data.benefit],
  isProcessing:
    getWaiter(state, cart.constants.getBenefitPath(BENEFIT_TYPE_LONG_TERM_DISABILITY)).isPending ||
    false,
})

const mapDispatchToProps = (dispatch) => ({
  onDeclineClick: (coveredPersons, [benefit]) => {
    dispatch(
      cart.actions.declineBenefit(BENEFIT_TYPE_LONG_TERM_DISABILITY, coveredPersons, benefit)
    ).then(
      () => {
        datadog.declineBenefits(BENEFIT_TYPE_LONG_TERM_DISABILITY, coveredPersons, benefit)
        dispatch(clearModal())
      },
      () => {
        dispatch(clearModal())
        dispatch(createErrorNotification('There was an error declining vision. Please try again.'))
      }
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeclineCoverageModal)
