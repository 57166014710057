import React from 'react'
import BaseIcon from './BaseIcon'

/*eslint-disable */
export default class Dollar extends BaseIcon {
  static defaultProps = {
    width: 13,
    height: 20,
    viewBox: '6 2 13 20'
  }
  render () {
    return (
      <svg {...this.getSvgProps()}>
        <path d='M9.9002 14.40705c0 .80108.24717 1.4251.7415 1.87385.4943.44875 1.1236.67312 1.8861.67312.66787 0 1.21127-.16302 1.6267-.4873.4172-.32605.62404-.752.62404-1.27963 0-.2945-.06836-.5399-.20334-.73973-.13672-.19808-.32253-.37337-.55567-.52763-.23313-.15425-.5066-.28747-.8221-.4014-.31553-.1122-.6591-.2314-1.03247-.3541-.76602-.25417-1.49347-.5066-2.18412-.76076-.6889-.25242-1.29364-.55392-1.81425-.90274-.52062-.34883-.9343-.76602-1.2393-1.24807-.30677-.4838-.45927-1.0833-.45927-1.802 0-.5767.10517-1.09204.312-1.54605.20685-.45575.49608-.8554.8642-1.19723.3681-.34183.80633-.6258 1.31642-.85192.50835-.22612 1.06577-.3944 1.66877-.50308V2h3.55138v2.3524c.67487.13672 1.2761.34882 1.80198.6363.52588.28748.96585.63105 1.31994 1.03246.35233.39966.62228.8414.80634 1.3252.1858.48204.27696.9904.27696 1.52502h-3.91774c0-.65734-.1823-1.1762-.54866-1.55658-.36636-.38038-.88522-.5697-1.55483-.5697-.52938 0-.99566.12622-1.39882.3804-.40317.2524-.6065.62578-.6065 1.11834 0 .4803.20683.86594.61877 1.15692.41193.29098 1.00266.55567 1.77393.79582.78005.2384 1.53204.48906 2.25424.7485.7222.26117 1.35324.56968 1.89138.92552.5364.35584.9676.78355 1.29014 1.28312.32078.49958.48205 1.1131.48205 1.8388 0 1.17795-.39968 2.16308-1.2025 2.95715-.80108.79582-1.89665 1.30942-3.2867 1.5408v2.3629h-3.55138v-2.33135c-.67136-.1227-1.2919-.3278-1.85808-.61176-.5662-.28397-1.05525-.64332-1.46367-1.07804-.41194-.43472-.73096-.93955-.96235-1.5145C6.11394 15.74275 6 15.1047 6 14.40704h3.9002z'
          {...this.getPathProps()} />
      </svg>
    )
  }
}
/*eslint-enable */
