import React from 'react'
import { string } from 'prop-types'

import InfoCard from '../InfoCard/InfoCard'
import AdditionalBenefitsIcon from '../assets/AdditionalBenefits.png'

const MyExpensesRequest = ({ title, message, url, state }) => (
  <InfoCard
    Image={AdditionalBenefitsIcon}
    title={title}
    message={message}
    url={url}
    state={state}
  />
)

MyExpensesRequest.propTypes = {
  title: string.isRequired,
  message: string.isRequired,
  url: string.isRequired,
  state: string.isRequired,
}

export default MyExpensesRequest
