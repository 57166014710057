import styled from 'styled-components'
import theme from '@hixme-ui/theme'

const ColorCircle = styled.div`
  color: ${(props) => props.color || 'white'};
  background:${(props) => props.background || 'black'};
  ${({ red }) => red && `background:${theme.colors.red};`}
  ${({ yellow }) => yellow && `background:${theme.colors.yellow};`}
  ${({ primary }) => primary && `background:${theme.colors.primary};`}
  ${({ purple }) => purple && `background:${theme.colors.purple};`}
  ${({ blue }) => blue && `background:${theme.colors.blue};`}
  ${({ light }) => light && `background:${theme.colors.light};`}
  border-radius: 50%;
  font-size: ${(props) => props.fontSize || 'inherit'};
  height: ${(props) => props.diameter || '25px'};
  width: ${(props) => props.diameter || '25px'};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export default ColorCircle
