import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

const StyledTooltip = styled.div`
  opacity: 1 !important;
  pointer-events: auto !important;
  box-shadow: 2px 3px 3px #e5ecf0;
  &:after {
    filter: drop-shadow(2px 3px 3px #e5ecf0) !important;
  }
`

const HappyTip = StyledTooltip.withComponent(ReactTooltip)
HappyTip.displayName = 'HappyTip'

export default HappyTip
