import { connect } from 'react-redux'
import { SessionErrors } from '../components'

// project
import { sessionErrorsSelector } from '../selectors'

const mapStateToProps = (state) => ({
  errors: sessionErrorsSelector(state),
})

export default connect(mapStateToProps)(SessionErrors)
