import React from 'react'
import BaseIcon from './BaseIcon'

/*eslint-disable */
export default class Printer extends BaseIcon {
  static defaultProps = {
    width: 20,
    height: 20,
    viewBox: '0 0 32 32'
 }
  render () {
    const { color } = this.props
    return (
      <svg {...this.getSvgProps()}>
        <g>
          <path d="M2.99763182,12 C1.34220684,12 0,13.3402158 0,14.9950893 L0,29.0049107 C0,30.6592161 1.3405012,32 2.99508929,32 L17.0049107,32 C18.6622279,32 20,30.6569129 20,28.9923949 L20,22.9567871 C20,22.4045024 19.5522847,21.9567871 19,21.9567871 C18.4477153,21.9567871 18,22.4045024 18,22.9567871 L18,28.9923949 C18,29.5541147 17.5558793,30 17.0049107,30 L2.99508929,30 C2.44516513,30 2,29.554741 2,29.0049107 L2,14.9950893 C2,14.4454505 2.44611226,14 2.99763182,14 L9.08300781,14 C9.63529256,14 10.0830078,13.5522847 10.0830078,13 C10.0830078,12.4477153 9.63529256,12 9.08300781,12 L2.99763182,12 Z" id="Rectangle-507" stroke="none" fill="#95909E"></path>
          <path d="M12,2.99508929 L12,17.0049107 C12,18.6592161 13.3405012,20 14.9950893,20 L29.0049107,20 C30.6592161,20 32,18.6594988 32,17.0049107 L32,2.99508929 C32,1.34078395 30.6594988,0 29.0049107,0 L14.9950893,0 C13.3407839,0 12,1.3405012 12,2.99508929 Z M29.0049107,2 C29.5548349,2 30,2.44525903 30,2.99508929 L30,17.0049107 C30,17.5548349 29.554741,18 29.0049107,18 L14.9950893,18 C14.4451651,18 14,17.554741 14,17.0049107 L14,2.99508929 C14,2.44516513 14.445259,2 14.9950893,2 L29.0049107,2 Z" id="Rectangle-508" stroke="none" fill="#3A4951"></path>
        </g>
      </svg>
    )
  }
}
/*eslint-enable */
