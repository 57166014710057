import React from 'react'

// hixme-ui
import Container from '@hixme-ui/container'
import Print from '@hixme-ui/print'
import Separator from '@hixme-ui/separator'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'

import TermsAndConditionsCopy from 'components/TermsAndConditionsCopy'

import {
  ScrollDetectorContainer,
  SignOutContainer,
  TermsAndConditionsFormContainer,
  TermsAndConditionsHeaderContainer,
} from '../../containers'
import classes from './TermsAndConditionsView.scss'

const TermsAndConditionsView = ({ redirectTo }) => (
  <div>
    <TermsAndConditionsHeaderContainer />
    <div style={{ margin: '20px auto', maxWidth: '950px' }}>
      <div style={{ margin: '30px 0' }}>
        <Title thin>Terms &amp; Conditions</Title>
        <Separator light />
      </div>
      <div className={classes.hideOnPrint}>
        <Text p light thin fontSize="18px">
          Before selecting your benefits, please review and agree to the Terms &amp; Conditions
          below.
          <br />
          You may print it for later review.
        </Text>
        <Container flex noPadding justifyContent="space-between" margin="0 0 10px 0">
          <div>
            <SignOutContainer />
          </div>
          <Print />
        </Container>
      </div>
      <ScrollDetectorContainer>
        <TermsAndConditionsCopy />
      </ScrollDetectorContainer>
      <div className={classes.hideOnPrint}>
        <Text p thin purple fontSize="18px">
          You must scroll through the entire Terms &amp; Conditions to proceed.
        </Text>
        <TermsAndConditionsFormContainer redirectTo={redirectTo} />
      </div>
    </div>
  </div>
)
TermsAndConditionsView.displayName = 'TermsAndConditionsView'

export default TermsAndConditionsView
