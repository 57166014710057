export default [
  '50c1c8f7-d07f-455e-909c-68a3070607e3', // DEV user: 1277
  '36618b3f-2b36-4bcd-9349-06bd69492f09',
  '54d008b5-4de2-49f4-85e1-9df86040d739',
  'ac99f1fa-420e-4bb7-8f28-6893480b4393',
  'f3d249b8-5423-40c1-a5d3-678d24db81d2',
  '8793ff26-be2d-4477-9719-3d78d8696ba2',
  '237a7d69-0e6c-4c16-aa98-98b8624597e5', // guest users
  '0a810559-4a5b-4501-b8f6-98f2673c57a3',
  '8d1dfa16-13b9-4abd-944e-695d6542aa77',
  '961bedf1-a9d8-4826-9d81-e1f59e228eb0',
  'f0f3fe8b-7141-42b0-bc7d-3c9fa9e67b85',
  '59310332-003c-419d-a082-8dffb1b31d62',
  '04dd6a46-4329-4bfc-a11f-78d6bdf91576',
  'ce5e2474-2c6c-413b-8b6d-bcce8b86c9c3',
  '918cae20-6f4c-4afa-a56f-3045f4fc675a',
  '77970dc2-248b-4f59-8ea8-190372e5b255',
  'ec4f3f09-9fa5-4a6a-83c4-064b4242d7e4',
  '4397d2bf-ab0d-4221-8f49-5a3cfde79453',
  '16038da7-c2b1-47ad-8038-de45d1a436e8',
  '707cd3e4-39a1-48b9-af6f-f0703ac53c93',
  '08dcd24e-d954-4dce-989d-94d0101b00f2',
  'f88d34ab-94d9-4541-bf65-398a5f6a34d6',
  '83258547-e88e-43db-89ec-30fc575a80ac',
  '34f7edf6-0846-4ddd-9d12-5c75236079f8',
  'ad087e91-969a-4bba-b797-66725a1fedd8',
  '01e3585f-2907-48be-87fb-fed7c3cc7aea',
  'b69347b6-3793-4e6e-be2a-e846df2ff036',
  'c4966d95-c15c-4ea1-b09c-e5ba9b5ec657',
  '21e221d0-784a-44b7-8744-503fcf7167c5',
  'b8e51d1b-8790-4c25-b3ab-1703f7f06fbd',
  'f7827810-f75a-46a2-bef9-33bb536de450',
  '63614b6b-44a4-46e6-8778-74d535fe7d3c',
  'd7b526a7-f187-4714-8312-fe198226d496',
  'd4f0a320-c6fe-4253-9e81-72747951ba8e',
  'ca20d4bf-dd2f-4871-a24a-26f9fd01ca27',
]
