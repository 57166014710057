import groupBy from 'lodash/groupBy'
import { bundleCategoryOrder } from '@hixme/bundle-utils'

const prepairMedicalProductForViewDetails = (
  product,
  benefitCategoryOrder = bundleCategoryOrder
) => {
  const renamedCategory = product.BundleBenefits.map((benefit) => ({
    ...benefit,
    SectionDisplayLabel: benefit.SectionDisplayLabel ? benefit.SectionDisplayLabel : 'OTHER',
  }))

  const categoryOrder = renamedCategory.find((b) => b.SectionDisplayLabel === 'OTHER')
    ? [...benefitCategoryOrder, 'OTHER']
    : benefitCategoryOrder

  const bundleBenefitGroups = groupBy(renamedCategory, 'SectionDisplayLabel')

  const bundleBenefits = categoryOrder.reduce((newOrder, listItem) => {
    newOrder[listItem] = bundleBenefitGroups[listItem] // eslint-disable-line
    return newOrder
  }, {})

  const bundlePrice = product.Price

  return { ...product, bundleBenefits, bundlePrice }
}

export { prepairMedicalProductForViewDetails as default }
