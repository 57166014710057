import React from 'react'
import PropTypes from 'prop-types'

import { colorTheme } from '@hixme-ui/theme'
import { Container, Background, Progress } from './style'

const ProgressBar = ({ value, error }) => (
  <Container>
    <Background />
    <Progress color={error ? colorTheme.red : colorTheme.green} percent={value} />
  </Container>
)

ProgressBar.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.number.isRequired,
}

ProgressBar.defaultProps = {
  error: false,
}

ProgressBar.displayName = 'ProgressBar'

export default ProgressBar
