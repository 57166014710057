import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui

import { Checkbox } from '@hixme-ui/forms'
import Text from '@hixme-ui/text'
import Container from '@hixme-ui/container'

const FilterOptionCheckbox = ({ label, data, onChange }) => (
  <Container noPadding>
    <Text fontWeight="600" medium>
      {label}
    </Text>
    <Container flex noPadding flexWrap>
      {data.map((instance) => (
        <div
          key={`container-${instance.text}`}
          style={{
            margin: '10px 0 10px 0',
            flexBasis: instance.text.length > 20 ? '60%' : '50%',
          }}
        >
          <Checkbox
            checked={instance.checked}
            onChange={onChange}
            text={instance.text}
            value={instance.value}
          />
        </div>
      ))}
    </Container>
  </Container>
)

FilterOptionCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default FilterOptionCheckbox
