import React from 'react'
import BaseIcon from './BaseIcon'

/*eslint-disable */
export default class NoteAdd extends BaseIcon {
  static defaultProps = {
    width: 29,
    height: 29,
    viewBox: '-1 3 29 29'
  }
  render () {
    return (
      <svg {...this.getSvgProps()}>
        <path d='M3.98 8.68H1.66c-.37 0-.66-.43-.66-.95 0-.53.3-.95.66-.95h2.32V4.45c0-.36.43-.66.95-.66.53 0 .95.3.95.64v2.33H8.2c.37 0 .66.42.66.95 0 .52-.3.95-.65.95H5.9V11c0 .36-.42.66-.95.66-.52 0-.95-.3-.95-.66V8.68zM13.83 4H25c1.66 0 3 1.34 3 3v22c0 1.66-1.34 3-3 3H3c-1.66 0-3-1.34-3-3V15.5c0-.54.45-1 1-1s1 .46 1 1V29c0 .55.45 1 1 1h22c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1H11.83c-.55 0-1-.45-1-1s.45-1 1-1h2zm8.24 7H11.33c-.27 0-.5-.22-.5-.5s.23-.5.5-.5h10.74c.27 0 .48.22.48.5s-.2.5-.48.5zm-.2 4H6.53c-.38 0-.7-.22-.7-.5s.32-.5.7-.5h15.32c.38 0 .7.22.7.5s-.32.5-.7.5zm0 4H6.53c-.38 0-.7-.22-.7-.5s.32-.5.7-.5h15.32c.38 0 .7.22.7.5s-.32.5-.7.5zm0 4H6.53c-.38 0-.7-.22-.7-.5s.32-.5.7-.5h15.32c.38 0 .7.22.7.5s-.32.5-.7.5zm0 4H6.53c-.38 0-.7-.22-.7-.5s.32-.5.7-.5h15.32c.38 0 .7.22.7.5s-.32.5-.7.5z'
          {...this.getPathProps()} />
      </svg>
    )
  }
}
/*eslint-enable */
