import { callWaiter } from 'redux-waiter'
import { personService } from '@hixme/api'
import {
  GET_MY_PROVIDERS,
  ADD_TO_MY_PROVIDERS,
  REMOVE_FROM_MY_PROVIDERS,
  REMOVE_ALL_FROM_PROVIDER,
} from '../constants'

export const getAllProvidersByEmployeeId = (EmployeePublicKey) => (dispatch) =>
  dispatch(
    callWaiter(GET_MY_PROVIDERS, {
      requestCreator: () =>
        personService({
          route: 'persons/{EmployeePublicKey}/providers',
          method: 'GET',
          params: {
            EmployeePublicKey,
            ByEmployeePK: 1,
          },
        }),
    })
  )

export const associatePersonToProvider = (body) => (dispatch) =>
  dispatch(
    callWaiter(`${ADD_TO_MY_PROVIDERS}_${body.ProviderPublicKey}_${body.PersonPublicKey}`, {
      requestCreator: () =>
        personService({
          route: 'provider/person',
          method: 'POST',
          body,
        }),
    })
  )

export const dissociatePersonFromProvider = (params) => (dispatch) =>
  dispatch(
    callWaiter(
      `${REMOVE_FROM_MY_PROVIDERS}_${params.ProviderPublicKey}_${params.PersonPublicKey}`,
      {
        requestCreator: () =>
          personService({
            route: `provider/{ProviderPublicKey}/person/{PersonPublicKey}`,
            method: 'DELETE',
            params,
          }),
      }
    )
  )

export const removeAllPersonsFromProvider = (params) => (dispatch) =>
  dispatch(
    callWaiter(
      `${REMOVE_ALL_FROM_PROVIDER}_${params.ProviderPublicKey}_${params.EmployeePublicKey}`,
      {
        requestCreator: () =>
          personService({
            route: `provider/{ProviderPublicKey}/employee/{EmployeePublicKey}`,
            method: 'DELETE',
            params,
          }),
      }
    )
  )
