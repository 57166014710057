import * as _actions from './actions'
import * as _containers from './containers'
import * as _constants from './constants'
import _reducer from './reducer'

export const actions = _actions
export const containers = _containers
export const constants = _constants
export const reducer = _reducer

export default { actions, constants, containers, reducer }
