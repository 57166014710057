import React from 'react'
import { connect } from 'react-redux'
import { setModal } from '@hixme/modal'

import { HELP } from 'modal-constants'

import HelpIcon from '../assets/Help.png'
import InfoCard from '../InfoCard'

type HelpCardProps = {
  showHelpModal: () => void
}

const HelpCard = ({ showHelpModal }: HelpCardProps) => (
  <InfoCard
    Image={HelpIcon}
    title="Enrollment Help"
    message="Get help before, during and after your enrollment."
    onClick={showHelpModal}
    state="completed"
  />
)

export default connect(null, (dispatch) => ({
  showHelpModal: () => {
    dispatch(setModal(HELP))
  },
}))(HelpCard)
