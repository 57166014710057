import React, { CSSProperties, FC, HTMLAttributes, ReactNode } from 'react'
import { defaultFontFamily } from '..'

// temp place to put core colors from design
export const TextColors = {
  base: '#000',
  light: '#fff',
  primary: '#00a0a8',
  secondary: '#003146',
  tertiary: '#f9f4ec',
  error: '#e22020',
  warning: '#fc9126',
  success: '#63be09',
  info: '#1677ff',
} as const

export type TypographyProps = {
  as?: 'a' | 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children?: string | ReactNode
  size?: number
  color?: CSSProperties['color'] | keyof typeof TextColors
  bold?: boolean
  unselectable?: boolean
  uppercase?: boolean
  style?: CSSProperties | undefined
  textAlign?: CSSProperties['textAlign']
} & Pick<
  HTMLAttributes<HTMLSpanElement>,
  'onClick' | 'onMouseEnter' | 'onMouseLeave' | 'onPointerEnter' | 'onPointerLeave' | 'onFocus'
>

const A = ({ children, onClick, ...rest }) => (
  <a
    href={'#'}
    {...rest}
    onClick={(e) => {
      e.preventDefault()
      onClick?.(e)
    }}
  >
    {children}
  </a>
)
const Span = ({ children, ...rest }) => <span {...rest}>{children}</span>
const P = ({ children, ...rest }) => <p {...rest}>{children}</p>
const H1 = ({ children, ...rest }) => <h1 {...rest}>{children}</h1>
const H2 = ({ children, ...rest }) => <h2 {...rest}>{children}</h2>
const H3 = ({ children, ...rest }) => <h3 {...rest}>{children}</h3>
const H4 = ({ children, ...rest }) => <h4 {...rest}>{children}</h4>
const H5 = ({ children, ...rest }) => <h5 {...rest}>{children}</h5>
const H6 = ({ children, ...rest }) => <h6 {...rest}>{children}</h6>

const TagMap = {
  a: A,
  span: Span,
  p: P,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
}

export const Typography: FC<TypographyProps> = ({
  children,
  as = 'span',
  size = 12,
  color,
  bold,
  unselectable = false,
  style,
  onClick,
  textAlign,
  uppercase,
  ...props
}) => {
  const Tag = TagMap[as]

  const textColor: CSSProperties['color'] =
    color && typeof TextColors[color] !== 'undefined' ? TextColors[color] : color

  return (
    <Tag
      onClick={onClick}
      style={{
        fontFamily: defaultFontFamily,
        fontSize: size,
        color: textColor,
        fontWeight: bold ? 'bold' : 'normal',
        userSelect: unselectable ? 'none' : 'auto',
        textAlign,
        textTransform: uppercase ? 'uppercase' : 'none',
        ...style,
      }}
      {...props}
    >
      {children}
    </Tag>
  )
}
