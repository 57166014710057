import { connect } from 'react-redux'
import { clearModal } from '@hixme/modal'

// route modules
import DeclineMedicareModal from 'components/DeclineMedicareModal/DeclineMedicareModal'

const mapDispatchToProps = (dispatch, { data }) => ({
  onCancelClick: () => dispatch(clearModal()),
  onDecline: () => {
    dispatch(clearModal())
    data.declineFn && data.declineFn(data.person)
  },
})

export default connect(
  null,
  mapDispatchToProps
)(DeclineMedicareModal)
