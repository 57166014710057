import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import Container from '@hixme-ui/container'
import Phone from '@hixme-ui/phone'
import Text from '@hixme-ui/text'

const ProviderTooltip = ({ tooltipId, confirmed, name, phone }) => (
  <ReactTooltip id={tooltipId} effect="solid">
    <Container flex direction="column" padding="5px" width="300px" style={{ borderRadius: '10px' }}>
      {confirmed ? (
        <Text p>
          Our provider network shows your provider <Text fontWeight={600}>accepts this plan.</Text>{' '}
          If you want to be sure, we recommend to call to confirm.
        </Text>
      ) : (
        <Text p>
          We <Text fontWeight={600}>could not verify</Text> if your Provider takes this plan. It is
          possible they accept this plan, play it safe and call to confirm.
        </Text>
      )}
      <Text p fontWeight={600}>
        <Text>{name}</Text> {phone && <Phone number={phone} color="#22D2AC" />}
      </Text>
    </Container>
  </ReactTooltip>
)

ProviderTooltip.propTypes = {
  tooltipId: PropTypes.string.isRequired,
  confirmed: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string,
}

ProviderTooltip.defaultProps = {
  phone: null,
}

ProviderTooltip.defaultProps = {}

export default ProviderTooltip
