import React from 'react'
import ReactTooltip from 'react-tooltip'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

type POSTooltipProps = {
  tooltipId: string | number
}

export default function POSTooltip({ tooltipId }: POSTooltipProps) {
  return (
    <ReactTooltip id={tooltipId} effect="solid">
      <Text primary large thin>
        What is a <Text bold>POS</Text>?
      </Text>
      <Container flex direction="column" padding="10px" width="350px">
        <Text p>
          A Point of Service plan, or POS, is a health plan that uses certain doctors and hospitals,
          called your POS provider network. A POS plan typically has a lower premium than a PPO
          plan, but still provides options for choosing health care providers. These added choices
          may give you more flexibility when you need care. Your primary care provider (PCP) from
          the POS network will manage your health care needs and you’ll have a lower deductible and
          coinsurance if you see a provider in the POS network. You can see an out-of-network
          provider, but you will pay more of the bill.
        </Text>
      </Container>
    </ReactTooltip>
  )
}
