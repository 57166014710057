import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Button from '@hixme-ui/button'
import Text from '@hixme-ui/text'
import Container from '@hixme-ui/container'

const EditCoverage = ({ handleEditClick, selectedPersons }) => {
  const editClick = (e) => {
    e.preventDefault()
    handleEditClick()
  }

  const nameOutput = selectedPersons
    .map((person) => {
      let name = person.FirstName
      name += person.Relationship === 'Employee' ? ' (me)' : ''
      return name
    })
    .join(', ')

  return (
    <div>
      <Container paddingBottom="10px" noPadding>
        <Text thin>Show prices for:</Text>
      </Container>
      <Text bold fontSize="16px">
        {nameOutput}
      </Text>{' '}
      <Button mini onClick={editClick}>
        Edit
      </Button>
    </div>
  )
}

EditCoverage.displayName = 'EditCoverage'
EditCoverage.propTypes = {
  handleEditClick: PropTypes.func.isRequired,
  selectedPersons: PropTypes.array.isRequired,
}

export default EditCoverage
