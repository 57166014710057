import { createSelector } from 'reselect'
import { NAME } from './constants'

// ======== Questionnaire ==========
export const getCurrentQuestion = createSelector(
  (state) => state[NAME].currentQuestion,
  (currentQuestion) => currentQuestion
)

export const getLastQuestion = createSelector(
  (state) => state[NAME].lastQuestion,
  (lastQuestion) => lastQuestion
)

export const getResponses = createSelector(
  (state) => state[NAME].responses,
  (responses) => responses
)

export const getQuestionAnswers = createSelector(
  (state) => state[NAME].questionAnswers,
  (qa) => qa
)

// ======== Person selector ==========
export const getAllPersons = createSelector(
  (state) => state[NAME].persons,
  (persons) => persons
)

export const getRespondersList = createSelector(
  (state) => state[NAME].respondersList,
  (list) => list
)

// Not being used
//
// export const getSelectedPersons = createSelector(
//   state => state[NAME].persons,
//   selection => {
//     const selectedPersons = selection.reduce((prev, curr) => {
//       if (curr.selected) {
//         prev.push(curr)
//       }
//       return prev
//     }, [])
//
//     return selectedPersons
//   }
// )
//
// export const getUnselectedPersons = createSelector(
//   state => state[NAME].persons,
//   selection => {
//     return selection.reduce((prev, curr) => {
//       if (!curr.selected) {
//         prev.push(curr)
//       }
//       return prev
//     }, [])
//   }
// )
//
//
