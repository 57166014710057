import React from 'react'

import Title from '@hixme-ui/title'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import Modal from 'modules/modal'

const GapFinancing = () => (
  <Container white rounded>
    <Title small>Gap Financing</Title>
    <hr />
    <Text p light>
      There are no insurance plans or bundles that cover all expenses, all the time. Hixme’s
      solution to the remaining expenses, once your gap insurance has paid out all possible
      benefits, is a custom financing option.
    </Text>
    <Text p light>
      If you are left with a medical bill over $500* for a health occurrence, you are eligible to
      borrow a portion of the remaining balance and repay the loan through manageable, monthly
      payroll deductions that are spread out over 12 months. This feature provides additional peace
      of mind.
    </Text>
    <Text p light>
      *The maximum amount you are allowed to finance is up to 4% of your annual salary.
    </Text>
  </Container>
)

GapFinancing.displayName = 'GapFinancing'

export default Modal.containers.Modal({
  content: {
    position: 'top',
    size: 'large',
    styles: {
      margin: '50px 0 0 0',
    },
  },
  overlay: {
    hideCloseButton: false,
    routeOnHide: false,
    dark: true,
  },
})(GapFinancing)
