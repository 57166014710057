export const LockScrollStyleSheetID = 'sureco-modal-lock-scroll'

export const injectLockScrollStyleSheet = (scrollBarWidth = 10) => {
  const existinglockScrollStyleNode = document.getElementById(LockScrollStyleSheetID)
  if (!existinglockScrollStyleNode) {
    const lockScrollStyle = document.createElement('style')
    lockScrollStyle.id = LockScrollStyleSheetID
    lockScrollStyle.textContent = `html body { overflow: hidden !important; width: calc(100% - ${scrollBarWidth}px); }`
    document.head.appendChild(lockScrollStyle)
  }
}

export const removeLockScrollStyleSheet = () => {
  const lockScrollStyleNode = document.getElementById(LockScrollStyleSheetID)
  if (lockScrollStyleNode) {
    document.head.removeChild(lockScrollStyleNode)
  }
}
