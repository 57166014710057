import { withReducer, withHandlers, compose } from 'recompose'
import CardTabs from '../components/CardTabs'

// TAB CONTROLLER
const tabReducer = withReducer(
  'tabIndex',
  'dispatch',
  (state, action) => {
    const actions = {
      SET_TAB: () => action.tabIndex,
    }
    return action && actions[action.type] ? actions[action.type]() : state
  },
  0
)

const tabHandlers = withHandlers({
  onTabClick: (props) => (e, tabIndex) => {
    props.dispatch({ type: 'SET_TAB', tabIndex })
    if (props.onTabClick) {
      props.onTabClick(props, tabIndex)
    }
  },
})

export default compose(
  tabReducer,
  tabHandlers
)(CardTabs)
