import React from 'react'
import PropTypes from 'prop-types'

import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'

import FileUploader from './FileUploader/FileUploader'
import Expense from '../../../models/Expense'
import { currencyFormatter } from '../../../utils/expenses'

const UploadForm = ({
  expense,
  coinsurance,
  employeePublicKey,
  totalAmount,
  groupKey,
  onPrevious,
}) => (
  <Container flex direction="column">
    <Container flex noPadding>
      <Text cursor="pointer" light onClick={onPrevious}>
        <Icon arrowLeft light size={0.7} /> Previous
      </Text>
    </Container>
    <FileUploader groupKey={groupKey} employeePublicKey={employeePublicKey} expense={expense} />
    <Container flex padding="10px 0" justifySpaceBetween>
      <Text large bold>
        Total Reimbursement Requested
      </Text>
      {totalAmount > 0 && (
        <Text large bold>
          {currencyFormatter(totalAmount)}*
        </Text>
      )}
    </Container>
    {coinsurance > 0 && (
      <Text light>
        {`*Reimbursable expenses are subject to a ${coinsurance * 100}% employee co-insurance
          obligation that will be applied to the final amount.`}
      </Text>
    )}
  </Container>
)

UploadForm.displayName = 'UploadForm'
UploadForm.propTypes = {
  expense: PropTypes.instanceOf(Expense),
  employeePublicKey: PropTypes.string.isRequired,
  onPrevious: PropTypes.func.isRequired,
  totalAmount: PropTypes.number.isRequired,
  coinsurance: PropTypes.number,
  groupKey: PropTypes.string.isRequired,
}

UploadForm.defaultProps = {
  expense: null,
  coinsurance: null,
}

export default UploadForm
