import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

const PresetFiltersTooltip = ({ tooltipId, currentGroupHasHiosMatch }) => (
  <ReactTooltip id={tooltipId}>
    <Container style={{ width: '400px' }} padding="10px">
      {currentGroupHasHiosMatch && (
        <div>
          <Text primary large thin>
            What is <Text bold>Find my Current Plan?</Text>
          </Text>
          <Text p>
            This filter searches for your current plan within the plans available to you.
          </Text>
        </div>
      )}
      <Text primary large thin>
        What is <Text bold>Similar to Current Plan?</Text>
      </Text>
      <Text p>
        This filter sets other filters to use the{' '}
        <Text primary bold>
          same Carrier
        </Text>
        , a similar{' '}
        <Text primary bold>
          Price and Actuarial Value
        </Text>{' '}
        <Text>based on your current plan.</Text>
      </Text>
      <Text p>
        Note: these filters will be disabled when there is no match between the people you are
        shopping for and the people in your most recent plan.
      </Text>
    </Container>
  </ReactTooltip>
)

PresetFiltersTooltip.propTypes = {
  tooltipId: PropTypes.string.isRequired,
  currentGroupHasHiosMatch: PropTypes.bool.isRequired,
}

PresetFiltersTooltip.defaultProps = {}

export default PresetFiltersTooltip
