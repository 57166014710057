import { createReducer } from 'modules/redux-utils'
import t from './actionTypes'

const initialState = {
  person: {},
  dependentsList: [],
  error: null,
  pendingEdit: [],
}

export default createReducer(initialState, {
  [t.LOAD_EMPLOYEE]: (state, payload) => ({ ...state, person: payload }),
  [t.UPDATE_EMPLOYEE]: (state, payload) => ({
    ...state,
    person: { ...state.person, ...payload },
  }),
  [t.LOAD_DEPENDENTS_LIST]: (state, payload) => ({
    ...state,
    dependentsList: payload,
  }),
  [t.ADD_DEPENDENT]: (state, payload) => ({
    ...state,
    dependentsList: state.dependentsList.concat([payload]),
  }),
  [t.UPDATE_DEPENDENT_BYINDEX]: (state, payload) => {
    const list = state.dependentsList
    const { dependent, index } = payload
    return {
      ...state,
      dependentsList: list
        .slice(0, index)
        .concat([{ ...list[index], ...dependent }])
        .concat(list.slice(index + 1)),
    }
  },
  [t.DELETE_DEPENDENT_BYINDEX]: (state, payload) => {
    const index = payload
    const list = state.dependentsList
    return {
      ...state,
      dependentsList: list.slice(0, index).concat(list.slice(index + 1)),
    }
  },
  [t.SET_ERROR]: (state, payload) => ({ ...state, error: payload }),
  [t.LOAD_PENDING_EDIT]: (state, payload) => ({ ...state, pendingEdit: payload }),
  [t.COMPLETE_PERSON_INFORMATION]: (state, payload) => ({
    ...state,
    pendingEdit: state.pendingEdit.filter((personId) => personId !== payload),
  }),
})
