export const NAME = 'expenseRequests'

// waiter names
// export const GET_BUNDLE_EVENT_NAME = 'GET_BUNDLE_EVENT_NAME'
export const GET_EXPENSE_REQUESTS_NAME = 'GET_EXPENSE_REQUESTS_NAME'
export const GET_EXPENSE_REQUEST_VALIDATE = 'GET_EXPENSE_REQUEST_VALIDATE'
export const SEND_EXPENSE_MESSAGE = 'SEND_EXPENSE_MESSAGE'
export const CREATE_REIMBURSEMENT = 'CREATE_REIMBURSEMENT'
export const UPDATE_RECEIPT = 'UPDATE_RECEIPT'
export const CREATE_RECEIPT = 'CREATE_RECEIPT'
export const GET_RECEIPTS = 'GET_RECEIPTS'
export const GET_LINE_ITEMS = 'GET_LINE_ITEMS'
// export const POST_BUNDLE_EVENT_NAME = 'POST_BUNDLE_EVENT_NAME'
// export const PUT_BUNDLE_EVENT_NAME = 'PUT_BUNDLE_EVENT_NAME'

// redux-form
export const EXPENSE_FORM = 'EXPENSE_FORM'
