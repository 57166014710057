import React from 'react'
import PropTypes from 'prop-types'

import { colorTheme } from '@hixme-ui/theme'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import styled from 'styled-components'

const HeaderTitle = styled(Text)`
  color: #3a4951;
  font-size: 18px;
  font-weight: 500;
`

const HeaderSubTitle = styled(Text)`
  color: #3a4951;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
`

const Badge = styled.span`
  align-items: center;
  background: ${(props) => (props.selected ? '#3A4951' : colorTheme.primary)};
  border-radius: 4px;
  color: white;
  font-size: 14px;
  padding: 2px 5px;
  &:hover {
    cursor: pointer;
  }
`

const GroupOption = ({ option, onSelect }) =>
  !option.currentSelected ? (
    <Container flex direction="column" noPadding>
      <Container flex flexColumn>
        <Container noPadding flex justifySpaceBetween>
          <HeaderTitle>Family member(s) in {option.area}</HeaderTitle>
          <Badge selected={option.isSelected} onClick={(e) => onSelect(option, e)}>
            {option.isSelected ? 'Selected' : 'Shop'}
          </Badge>
        </Container>
        <HeaderSubTitle>{option.name}</HeaderSubTitle>
        <Container noPadding>
          <Text primary>{option.model.getMemberForOption()}</Text>
        </Container>
      </Container>
    </Container>
  ) : null

GroupOption.displayName = 'GroupOption'

GroupOption.propTypes = {
  option: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default GroupOption
