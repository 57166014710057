import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CSSTransitionGroup } from 'react-transition-group'
import isEmpty from 'lodash/isEmpty'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Icon from '@hixme-ui/icon'
import Input from '@hixme-ui/input'
import FormGroup from '@hixme-ui/form-group'
import { colorTheme } from '@hixme-ui/theme'

import CurrencyInput from 'components/CurrencyInput/CurrencyInput'
import formatBytes from 'modules/ultimate-uploader/utils'
import ProgressBar from 'components/ProgressBar'

import classes from '../FileList/FileList.scss'
import Expense from '../../../../models/Expense'

const sanitizeAmount = (amount) =>
  amount ? amount.replace(/^\d{0,3}(\.\d{1,2})?$/g, '').replace('$', '') : amount

const isInvalidAmount = (amount) => isEmpty(amount) || amount === '0' || amount.endsWith('.')

class File extends Component {
  constructor() {
    super()
    this.state = {
      errors: {},
    }
  }

  render() {
    return (
      <div style={{ overflowY: 'hidden' }}>
        <Container
          flex
          direction="column"
          padding="10px 20px"
          background="WhiteSmoke"
          rounded
          margin="0 0 10px 0"
          border="1px solid Gainsboro"
        >
          <Container
            flex
            noPadding
            justifyContent="space-between"
            alignItems="flex-end"
            margin="0 0 10px 0"
          >
            <Text
              medium
              primary
              bold
              cursor="pointer"
              onClick={() => window.open(this.props.wrapper.file.preview, '_blank')}
            >
              {this.props.file.name}
            </Text>
            <CSSTransitionGroup
              transitionName={{
                enter: classes.enter,
                enterActive: classes.enterActive,
                leave: classes.leave,
                leaveActive: classes.leaveActive,
              }}
              transitionEnterTimeout={300}
              transitionLeaveTimeout={300}
            >
              {!this.props.wrapper.uploading &&
                (this.props.expense.isDraft() ||
                  this.props.expense.status === 'Need More Info') && (
                  <span
                    tabIndex="-1"
                    role="button"
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.handleRemoveFile(this.props.file.name, this.props.wrapper.id)
                    }
                  >
                    {!this.props.wrapper.id && <Icon x black />}
                  </span>
                )}
            </CSSTransitionGroup>
          </Container>

          <Container flex noPadding height="110px">
            <Container noPadding flex justifyContent="space-between" width="100%">
              <FormGroup label="Description">
                <Input
                  error={this.state.errors.note}
                  value={this.props.wrapper.note}
                  disabled={
                    this.props.wrapper.uploading || this.props.wrapper.id || this.props.isUploading
                  }
                  onChange={({ target }) => {
                    const { value, maxLength } = target
                    this.props.handleNoteChange(value.slice(0, maxLength), this.props.file.name)
                  }}
                  onFocus={() => {
                    this.setState({
                      errors: {
                        ...this.state.errors,
                        note: false,
                      },
                    })
                  }}
                  onBlur={(e) => {
                    const value = e.target.value
                    if (isEmpty(value)) {
                      this.setState({
                        errors: {
                          ...this.state.errors,
                          note: true,
                        },
                      })
                    }
                  }}
                  maxLength={75}
                />
                <Text
                  error
                  style={{
                    visibility: this.state.errors.note ? 'visible' : 'hidden',
                  }}
                >
                  Required
                </Text>
              </FormGroup>
              <FormGroup label="Amount">
                <CurrencyInput
                  value={this.props.wrapper.amount}
                  error={this.state.errors.amount && this.state.errors.amount.invalid}
                  disabled={this.props.isUploading || this.props.wrapper.id}
                  onChange={({ target }) => {
                    const { value, validity } = target
                    const sanitizedValue = sanitizeAmount(value)
                    this.props.handleUpdateAmount(
                      {
                        amount: sanitizedValue,
                        validAmount: isInvalidAmount(sanitizedValue) ? false : validity.valid,
                      },
                      this.props.file.name
                    )
                  }}
                  onFocus={() => {
                    this.setState({
                      errors: {
                        ...this.state.errors,
                        amount: false,
                      },
                    })
                  }}
                  onBlur={(_, { value, valid }) => {
                    if (!valid) {
                      this.setState({
                        errors: {
                          ...this.state.errors,
                          amount: {
                            invalid: true,
                            message: isEmpty(value) ? 'Amount Required' : 'Invalid Amount',
                          },
                        },
                      })
                      return
                    }
                    this.props.handleUpdateAmount(
                      {
                        amount: value,
                        validAmount: true,
                      },
                      this.props.file.name
                    )
                  }}
                />
                <Container flex noPadding justifyContent="flex-end">
                  <Text
                    error
                    style={{
                      visibility:
                        this.state.errors.amount && this.state.errors.amount.invalid
                          ? 'visible'
                          : 'hidden',
                    }}
                    justifyContent="flex-end"
                  >
                    {this.state.errors.amount && this.state.errors.amount.message}
                  </Text>
                </Container>
              </FormGroup>
            </Container>
          </Container>

          <ProgressBar
            value={this.props.wrapper.error ? 100 : this.props.wrapper.progress}
            error={this.props.wrapper.error}
          />
          <Container noPadding flex justifyContent="space-between" alignItems="flex-end">
            <Container noPadding flex height="30px">
              {this.props.wrapper.complete && (
                <Text style={{ margin: '10px 0 0 0', color: colorTheme.green }}>
                  File uploaded successfully.
                </Text>
              )}
              {this.props.wrapper.uploading && (
                <Text style={{ margin: '10px 10px 0 0', color: colorTheme.green }}>
                  {this.props.wrapper.progress.toFixed(0)}% complete
                </Text>
              )}
              {this.props.wrapper.error && (
                <Text style={{ margin: '10px 0 0 0' }} danger>
                  {this.props.wrapper.errorMessage}
                </Text>
              )}
            </Container>
            <Text small light>
              {this.props.wrapper.uploading && `${formatBytes(this.props.wrapper.uploaded)} of `}
              {formatBytes(this.props.wrapper.file.size)}
            </Text>
          </Container>
        </Container>
      </div>
    )
  }
}

File.propTypes = {
  wrapper: PropTypes.object.isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
  handleUpdateAmount: PropTypes.func.isRequired,
  handleNoteChange: PropTypes.func.isRequired,
  expense: PropTypes.instanceOf(Expense),
  file: PropTypes.object,
  isUploading: PropTypes.bool,
}

File.defaultProps = {
  isUploading: false,
  file: {},
  expense: null,
}

export default File
