import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import { Row, Col } from 'react-flexbox-grid'
import RadioButton from '@hixme-ui/radio-button'

// project
import FieldError from './FieldError'

const propTypes = {
  displayError: PropTypes.bool,
  errorBottom: PropTypes.bool,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  numCols: PropTypes.number,
  options: PropTypes.array,
  style: PropTypes.object,
}

const defaultProps = {
  displayError: true,
  errorBottom: false,
  numCols: 4,
  options: [],
  style: {},
}

const FieldRadioGroup = ({ input, meta, displayError, errorBottom, options, style, numCols }) => {
  const radioButtons = options.map((option) => (
    <Col xs={numCols} key={option.text.concat(option.value)}>
      <RadioButton
        {...input}
        text={option.text}
        value={option.value}
        checked={input.value === option.value}
      />
    </Col>
  ))
  return (
    <div style={style}>
      {!errorBottom && displayError && <FieldError meta={meta} />}
      <Row>{radioButtons}</Row>
      {errorBottom && <FieldError meta={meta} />}
    </div>
  )
}

FieldRadioGroup.propTypes = propTypes
FieldRadioGroup.defaultProps = defaultProps

export default FieldRadioGroup
