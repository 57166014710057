import { connect } from 'react-redux'

import { BundleDetails } from '../../../Benefits/HealthBundles/modules/health-bundles/components'

import prepairMedicalProductForViewDetails from './helpers'

const mapStateToProps = (state, props) => {
  const bundle = prepairMedicalProductForViewDetails(props.data.product)
  return {
    showFunctionality: false,
    bundle,
  }
}

const BundleDetailsRequest = connect(mapStateToProps)(BundleDetails)

export default BundleDetailsRequest
