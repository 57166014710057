import dayjs, { Dayjs } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import UTCPlugin from 'dayjs/plugin/utc'

dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(UTCPlugin);

export const DateTimeFormats = {
  a: 'YYYY-MM-DD',
  b: 'YYYY/MM/DD',
  c: 'ddd, YYYY/MM/DD HH:mm:ss'
} as const

export const determineAge = (dateOfBirth: string, parseFormat: string = DateTimeFormats.a) => {
  const now = dayjs()
  const dob = dayjs(dateOfBirth, parseFormat)
  if (!dob.isValid()) {
    console.error('Invalid Date of Birth provided!')
    return -1
  }

  return now.diff(dob, 'years')
}

export const toUTC = (date: Dayjs): Dayjs => date.utc()
