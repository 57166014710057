import React from 'react'
import PropTypes from 'prop-types'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import LoadingBundleCard from './components/LoadingBundleCard/LoadingBundleCard'

const EmptyStackCard = ({ isProcessing }) => (
  <Container flex noPadding direction="column">
    {isProcessing ? (
      <LoadingBundleCard />
    ) : (
      <Container
        flex
        padding="10px"
        justifyContent="center"
        alignItems="center"
        height="250px"
        lightest
        shadow
      >
        <Text center larger light thin>
          Your filter settings may have hidden all plans for this level.
        </Text>
      </Container>
    )}
  </Container>
)

EmptyStackCard.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
}

EmptyStackCard.displayName = 'EmptyStackCard'

export default EmptyStackCard
