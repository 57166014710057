import { createReducer } from 'modules/redux-utils'

import type from './actionTypes'

const initialState = {
  fileGroups: {},
  messages: {},
  isUploading: {},
}

export default createReducer(initialState, {
  [type.ADD_FILES]: (state, payload) => {
    if (state.fileGroups[payload.groupKey]) {
      return {
        ...state,
        fileGroups: {
          ...state.fileGroups,
          [payload.groupKey]: [...state.fileGroups[payload.groupKey], ...payload.files],
        },
      }
    }
    return {
      ...state,
      fileGroups: {
        ...state.fileGroups,
        [payload.groupKey]: [...payload.files],
      },
    }
  },
  [type.CLEAR_FILES]: (state, payload) => ({
    ...state,
    fileGroups: {
      ...state.fileGroups,
      [payload.groupKey]: [...state.fileGroups[payload.groupKey].filter((file) => file.uploading)],
    },
  }),
  [type.REMOVE_FILE]: (state, payload) => ({
    ...state,
    fileGroups: {
      ...state.fileGroups,
      [payload.groupKey]: [
        ...state.fileGroups[payload.groupKey].filter(
          (wrapper) => wrapper.file.name !== payload.fileName
        ),
      ],
    },
  }),
  [type.UPLOADING]: (state, payload) => ({
    ...state,
    isUploading: {
      [payload.groupKey]: payload.bool,
    },
  }),
  [type.UPLOAD_COMPLETE]: (state, payload) => ({
    ...state,
    fileGroups: {
      ...state.fileGroups,
      [payload.groupKey]: [
        ...state.fileGroups[payload.groupKey].map((wrapper) => {
          if (wrapper.file.name === payload.fileName) {
            return {
              ...wrapper,
              uploading: false,
              complete: true,
            }
          }
          return wrapper
        }),
      ],
    },
  }),
  [type.UPDATE_GENERAL_MESSAGE]: (state, payload) => ({
    ...state,
    messages: {
      ...state.messages,
      [payload.groupKey]: payload.message,
    },
  }),
  [type.UPLOAD_ERROR]: (state, payload) => ({
    ...state,
    fileGroups: {
      ...state.fileGroups,
      [payload.groupKey]: [
        ...state.fileGroups[payload.groupKey].map((wrapper) => {
          if (wrapper.file.name === payload.fileName) {
            return {
              ...wrapper,
              uploading: false,
              error: true,
              errorMessage: payload.message,
            }
          }
          return wrapper
        }),
      ],
    },
  }),
  [type.FILE_UPLOAD_PROGESS]: (state, payload) => ({
    ...state,
    fileGroups: {
      ...state.fileGroups,
      [payload.groupKey]: [
        ...state.fileGroups[payload.groupKey].map((wrapper) => {
          if (wrapper.file.name === payload.fileName) {
            return {
              ...wrapper,
              error: false,
              uploading: true,
              progress: payload.progress,
            }
          }
          return wrapper
        }),
      ],
    },
  }),
  [type.FILE_UPLOADED_AMOUNT]: (state, payload) => ({
    ...state,
    fileGroups: {
      ...state.fileGroups,
      [payload.groupKey]: [
        ...state.fileGroups[payload.groupKey].map((wrapper) => {
          if (wrapper.file.name === payload.fileName) {
            return {
              ...wrapper,
              error: false,
              uploading: true,
              uploaded: payload.uploaded,
            }
          }
          return wrapper
        }),
      ],
    },
  }),
  [type.FILE_UPDATE_TYPE]: (state, payload) => ({
    ...state,
    fileGroups: {
      ...state.fileGroups,
      [payload.groupKey]: [
        ...state.fileGroups[payload.groupKey].map((wrapper) => {
          if (wrapper.file.name === payload.fileName) {
            return {
              ...wrapper,
              uploading: false,
              fileType: payload.value,
              errorMessage:
                payload.value === '-1' ? 'Please select an option before uploading' : '',
            }
          }
          return wrapper
        }),
      ],
    },
  }),
  [type.FILE_UPDATE_NOTE]: (state, payload) => ({
    ...state,
    fileGroups: {
      ...state.fileGroups,
      [payload.groupKey]: [
        ...state.fileGroups[payload.groupKey].map((wrapper) => {
          if (wrapper.file.name === payload.fileName) {
            return {
              ...wrapper,
              touched: true,
              note: payload.value,
            }
          }
          return wrapper
        }),
      ],
    },
  }),
  [type.FILE_UPDATE_AMOUNT]: (state, payload) => ({
    ...state,
    fileGroups: {
      ...state.fileGroups,
      [payload.groupKey]: [
        ...state.fileGroups[payload.groupKey].map((wrapper) => {
          if (wrapper.file.name === payload.fileName) {
            return {
              ...wrapper,
              ...payload.data,
              touched: true,
              complete: wrapper.complete ? false : wrapper.complete,
              progress: wrapper.complete ? 0 : wrapper.progress,
            }
          }
          return wrapper
        }),
      ],
    },
  }),
  [type.FILE_UPDATE_ID]: (state, payload) => ({
    ...state,
    fileGroups: {
      ...state.fileGroups,
      [payload.groupKey]: [
        ...state.fileGroups[payload.groupKey].map((wrapper) => {
          if (wrapper.file.name === payload.fileName) {
            return {
              ...wrapper,
              id: payload.id,
            }
          }
          return wrapper
        }),
      ],
    },
  }),
})
