import { formValueSelector } from 'redux-form'

export const getValues = (form, state, values) => {
  const selector = formValueSelector(form)
  const selectors = {}
  values.forEach((value) => {
    selectors[value] = () => selector(state, value)
  })
  return selectors
}

export const hasError = (props) => props.touched && props.invalid
