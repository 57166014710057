import React from 'react'
import PropTypes from 'prop-types'
import ClickOutside from 'react-click-outside'

import Button from '@hixme-ui/button'
import Container from '@hixme-ui/container'
import { Checkbox, FormGroup } from '@hixme-ui/forms'

import * as FamilyProviderStyle from './style'
import classes from './ProviderSelector.module.scss'

const ProviderSelector = ({ onClickOutside, providers, onProviderSearch, handleSubmit }) => {
  const onSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.target)
    handleSubmit(data)
    onClickOutside()
  }
  const isEmpty = providers.length === 0
  return (
    <FamilyProviderStyle.ProviderContainer visible>
      <ClickOutside
        onClickOutside={onClickOutside}
        style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}
      >
        <form onSubmit={onSubmit}>
          <FamilyProviderStyle.Title>Select from My providers</FamilyProviderStyle.Title>
          <FamilyProviderStyle.MembersContainer>
            {!isEmpty ? (
              <FormGroup>
                {providers.map((provider) => (
                  <div
                    key={provider.npi}
                    style={{ marginBottom: '10px', textAlign: 'left' }}
                    className={classes.CustomCheckbox}
                  >
                    <Checkbox
                      name={provider.npi}
                      id={provider.npi}
                      text={provider.fullName}
                      defaultChecked={provider.isSelected}
                    />
                  </div>
                ))}
              </FormGroup>
            ) : (
              <Container flex justifyStart padding="20px 10px">
                <FamilyProviderStyle.NoProvidersMessage>
                  You have no providers.
                </FamilyProviderStyle.NoProvidersMessage>
              </Container>
            )}
          </FamilyProviderStyle.MembersContainer>

          <FamilyProviderStyle.ButtonContainer>
            <Button outline block onClick={onProviderSearch}>
              Provider Search
            </Button>
            <Button primary block type="submit">
              Done
            </Button>
          </FamilyProviderStyle.ButtonContainer>
        </form>
      </ClickOutside>
    </FamilyProviderStyle.ProviderContainer>
  )
}

ProviderSelector.propTypes = {
  onClickOutside: PropTypes.func.isRequired,
  onProviderSearch: PropTypes.func.isRequired,
  providers: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
}

ProviderSelector.defaultProps = {
  providers: [],
}

export default ProviderSelector
