import isEmpty from 'lodash/isEmpty'
import { currencyFormatter } from '../../routes/Expenses/utils/expenses'

export const sanitizeCurrencyValue = (value) => value.replace('$', '').replace(/[^0-9.]+/g, '')

export const thousandSaparatorValue = (value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const isInvalidValue = (value) => isEmpty(value) || value === '0' || value.endsWith('.')

export const format = (value) => {
  const dotPosition = value.indexOf('.')
  const hasFullFormating =
    dotPosition !== -1 ? value.slice(dotPosition + 1, value.length).length === 2 : false
  const needsFormating = value.length < 4 || !hasFullFormating
  const formatted = needsFormating ? currencyFormatter(Number(value)) : value
  return formatted
}
