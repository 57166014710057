import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { MrModal, getModalData } from '@hixme/modal'

// hixme-ui
import Currency from '@hixme-ui/currency'
import Separator from '@hixme-ui/separator'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'

// The data/EmployeeContributionMax is passed through from the following components
// -> FSAProductContainer, FSAInformationContainer, LearnMoreLink
const FSAInformation = ({ data: { EmployeeContributionMax, EmployeeContributionMin, Year } }) => {
  const textDefaultStyles = {
    p: true,
    light: true,
    thin: true,
    medium: true,
  }

  return (
    <MrModal medium contentStart contentPadding="40px 60px">
      <Title small>Important information about your FSA</Title>
      <Separator light />
      <Text {...textDefaultStyles} style={{ margin: '2em 0 1em' }}>
        A Flexible Spending Account is a great option when there are planned medical expenses that
        you’re certain you will have during a covered plan year, such as Lasik surgery or
        prescriptions.
      </Text>
      <Text {...textDefaultStyles}>
        1) There are limits to how much you can contribute towards your FSA per year. The Maximum
        limit allowed by the IRS for {Year} for an individual is{' '}
        <Currency hideCents value={EmployeeContributionMax} />.
      </Text>
      {EmployeeContributionMin && (
        <Text {...textDefaultStyles}>
          Your employer may have a monthly minimum requirement of{' '}
          <Currency hideCents value={EmployeeContributionMin} />.
        </Text>
      )}
      <Text {...textDefaultStyles}>
        2) The contributions made by employees are pre-tax, so you {"don't"} pay taxes on this
        money. This means {"you'll"} save an amount equal to the taxes you would have paid on the
        money you set aside. Each employee elects how much to contribute per pay period.
      </Text>
      <Text {...textDefaultStyles}>
        3) Your entire annual FSA election amount is available as of the 1st of the year.
      </Text>
      <Text {...textDefaultStyles}>
        4) The entire amount must be used during the plan year. Any remaining amounts not used will
        be forfeited, unless:
      </Text>
      <Text {...textDefaultStyles} style={{ textIndent: '1.5em' }}>
        <em>
          a) Your FSA benefit includes a grace period. In this case, the date shown represents the
          extent of the grace period into the following year, during which you can spend any
          remaining funds from the year prior. After that date, any unspent funds are forfeited.
        </em>
      </Text>
      <Text {...textDefaultStyles} style={{ textIndent: '1.5em' }}>
        <em>
          b) Your FSA benefit includes a carryover amount. In this case, you can roll over up to
          that amount of unused FSA dollars to the next year, but will forfeit any excess over that
          amount at year-end.
        </em>
      </Text>
      <Text {...textDefaultStyles}>
        5) Subject to certain requirements, the FSA can be used for medical expenses for a spouse,
        legal dependents, even if they are not covered by your medical plan. However, the expense
        must be incurred during the plan year in which your FSA election occurred.
      </Text>
      <Text {...textDefaultStyles}>
        6) You will be issued a debit card, which you can use at the time of the expense or you may
        file a claim that you can submit for reimbursement.
      </Text>
    </MrModal>
  )
}

FSAInformation.displayName = 'FSAInformation'
FSAInformation.propTypes = {
  // modal data props should include EmployeeContributionMax
  data: PropTypes.object.isRequired,
}

export default connect((state) => ({ data: getModalData(state) }))(FSAInformation)
