import _uniq from 'lodash/uniq'
import { capitalize } from 'lodash'

import bundleReducer from './bundle-reducer'
import t from './actionTypes'

// initialState
const groupModel = {
  id: null,
  bundles: {},
  persons: [],
  providers: {},
  carriers: [],
  planTypes: [],
  hsa: null,
  filters: {
    carriers: [],
    hios: false,
    planTypes: [],
    maxPriceLimit: 0,
    maxPrice: 0,
    matchBundle: false,
    hsa: false,
    valueScore: 0,
  },
  previousBundle: null,
  valueScore: 0,
  valueScoreMin: 0,
  valueScoreMax: 0,
  index: null,
}

const groupReducerMap = {
  [t.LOAD_LIST]: (state, { Bundles = [], Persons = [], Recommended, Providers, Id, Index }) => {
    const bundles = [...Bundles].reduce((acc, bundle, index) => {
      acc[bundle.BundlePublicKey] = {
        ...bundle,
        order: index,
      }
      return acc
    }, {})

    const providerMapper = (provider) => ({
      ...provider,
      fullName: provider.name
        .split(' ')
        .map(capitalize)
        .join(' '),
      count: 0,
      isSelected: true,
    })

    const providersHash =
      (Providers &&
        Providers.reduce((prev, curr) => ({ ...prev, [curr.npi]: providerMapper(curr) }), {})) ||
      {}

    const {
      carriers,
      hios,
      planTypes,
      maxPrice,
      maxPriceLimit,
      minPrice,
      hsa,
      valueScore,
      valueScoreMin,
      valueScoreMax,
      providers,
    } = Object.values(bundles).reduce(
      (acc, bundle) => {
        acc.carriers.push(bundle.CarrierName)
        acc.planTypes.push(bundle.PlanType)

        if (acc.maxPriceLimit < bundle.Price) {
          acc.maxPriceLimit = bundle.Price
        }

        if (acc.minPrice > bundle.Price) {
          acc.minPrice = bundle.Price
        }

        if (acc.valueScore > bundle.HixmeValue) {
          acc.valueScore = bundle.HixmeValue
        }

        if (acc.valueScoreMin > bundle.HixmeValue) {
          acc.valueScoreMin = bundle.HixmeValue
        }

        if (acc.valueScoreMax < bundle.HixmeValue) {
          acc.valueScoreMax = bundle.HixmeValue
        }

        if (bundle.IsHsa) {
          acc.hsa = true
        }

        // get count for each provider
        // selected by default
        bundle.Providers &&
          bundle.Providers.forEach((provider) => {
            if (acc.providers[provider.npi]) {
              acc.providers[provider.npi].count += 1
            }
          })

        return acc
      },
      {
        carriers: [],
        hios: false,
        planTypes: [],
        matchBundle: false,
        maxPrice: 0,
        maxPriceLimit: 0,
        minPrice: Infinity,
        hsa: false,
        valueScore: Infinity,
        valueScoreMin: Infinity,
        valueScoreMax: 0,
        providers: providersHash,
        id: Id,
        index: Index,
      }
    )

    return {
      ...groupModel,
      bundles,
      persons: Persons,
      recommended: Recommended,
      providers,
      carriers: _uniq(carriers).sort(),
      hios,
      planTypes: _uniq(planTypes).sort(),
      maxPrice,
      maxPriceLimit,
      minPrice,
      hsa,
      valueScore,
      valueScoreMin,
      valueScoreMax,
      filters: {
        carriers: [],
        hios: false,
        planTypes: [],
        hsa: false,
        matchBundle: false,
        maxPrice,
        maxPriceLimit,
        minPrice,
        valueScore,
      },
      id: Id,
      index: Index,
    }
  },
  [t.UPDATE_SELECTED_PROVIDERS]: (state, payload) => ({
    ...state,
    providers: Object.keys(state.providers).reduce(
      (acc, npi) => ({
        ...acc,
        [npi]: {
          ...state.providers[npi],
          isSelected: payload.includes(npi),
        },
      }),
      {}
    ),
  }),
  [t.TOGGLE_FAVORITE]: (state, payload) => {
    const bundle = state.bundles[payload]
    if (bundle) {
      return {
        ...state,
        bundles: {
          ...state.bundles,
          [payload]: bundleReducer(bundle, {
            type: t.TOGGLE_FAVORITE,
          }),
        },
      }
    }
    return state
  },
  [t.ADD_FAVORITE]: (state, payload) => {
    const bundle = state.bundles[payload]
    if (bundle) {
      return {
        ...state,
        bundles: {
          ...state.bundles,
          [payload]: bundleReducer(bundle, {
            type: t.ADD_FAVORITE,
          }),
        },
      }
    }
    return state
  },
  [t.REMOVE_USER_FAVORITES]: (state) => {
    const bundles = state.bundles
    return {
      ...state,
      bundles: Object.keys(bundles).reduce((acc, key) => {
        acc[key] = bundleReducer(bundles[key], {
          type: t.REMOVE_FAVORITE,
        })
        return acc
      }, {}),
    }
  },
  [t.BUNDLE_ADDED]: (state, payload) => {
    const bundle = state.bundles[payload]
    if (bundle) {
      return {
        ...state,
        bundles: {
          ...state.bundles,
          [payload]: bundleReducer(bundle, {
            type: t.BUNDLE_ADDED,
          }),
        },
      }
    }
    return state
  },
  [t.BUNDLE_REMOVED]: (state, payload) => {
    const bundle = state.bundles[payload]
    if (bundle) {
      return {
        ...state,
        bundles: {
          ...state.bundles,
          [payload]: bundleReducer(bundle, {
            type: t.BUNDLE_REMOVED,
          }),
        },
      }
    }
    return state
  },
  [t.TOGGLE_FILTER]: (state, { filterName, value }) => {
    const filters = state.filters
    let filterOption = filters[filterName]

    if (filterName === 'hsa') {
      filterOption = !filters.hsa
    }

    if (filterOption && Array.isArray(filterOption)) {
      if (filterOption.includes(value)) {
        filterOption = filterOption.filter((f) => f !== value)
      } else {
        filterOption = [...filterOption, value]
      }
    }

    return {
      ...state,
      filters: {
        ...state.filters,
        [filterName]: filterOption,
      },
    }
  },
  [t.UPDATE_MATCH_BUNDLE]: (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      matchBundle: payload,
    },
  }),
  [t.UPDATE_MATCH_BUNDLE_BY_HIOS]: (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      hios: payload,
    },
  }),
  [t.UPDATE_MAX_COST_FILTER]: (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      maxPrice: payload,
    },
  }),
  [t.UPDATE_VALUE_SCORE]: (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      valueScore: payload,
    },
  }),
  [t.RESET_FILTERS]: (state) => ({
    ...state,
    filters: {
      carriers: [],
      planTypes: [],
      maxPrice: 0,
      matchBundle: false,
      maxPriceLimit: state.maxPriceLimit,
      minPrice: state.minPrice,
      hsa: false,
      hospital: 'Select a hospital to filter by',
      valueScore: state.valueScore,
    },
  }),
  [t.SET_PREVIOUS_BUNDLE_FOR_GROUP]: (state, payload) => ({
    ...state,
    previousBundle: payload.bundle,
  }),
}

export default (state = groupModel, action) => {
  const reducer = groupReducerMap[action.type]

  return reducer ? reducer(state, action.payload) : state
}
