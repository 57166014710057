import React, { CSSProperties, FC, ReactNode } from 'react'
import { Property } from 'csstype'

export type StackProps = {
  style?: CSSProperties | undefined
  children: ReactNode
  space?: number
  direction?: 'col' | 'row'
  borderStyle?: Property.BorderStyle | undefined
  borderColor?: Property.BorderColor | undefined
  padding?: number
  borderRadius?: number
} & React.HTMLAttributes<HTMLDivElement>

export const Stack: FC<StackProps> = ({
  style,
  children,
  space = 0,
  direction = 'col',
  borderStyle = undefined,
  borderColor = undefined,
  padding = 0,
  borderRadius = 0,
  onClick,
  ...props
}) => {
  const isColumn = direction === 'col'
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        flexDirection: isColumn ? 'column' : 'row',
        borderStyle,
        borderColor,
        padding,
        borderRadius,
        gap: space,
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  )
}
