import React from 'react'

import Button from '@hixme-ui/button'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Select from '@hixme-ui/select'

const AflacInfo = () => (
  <Container
    margin="0 0 10px 0"
    flex
    padding="10px"
    background="white"
    rounded
    border="1px solid Gainsboro"
    alignItems="center"
    justifyContent="center"
    flexWrap
  >
    <Container flex noPadding minWidth="50%" direction="column">
      <Text primary large bold center>
        <Text bold>Raleigh Employees</Text> please visit Everwell Benefits (powered by Aflac) for
        Critical Illness and Short Term Disability.
      </Text>
      <Text light center>
        Make a note of your company Group Number which you will need when signing in.
      </Text>
    </Container>
    <Container flex noPadding marginTop="10px" flexWrap>
      <Select mini style={{ width: '270px' }}>
        <option>Company Group Numbers</option>
        <option>Raleigh Studios: LOW04</option>
        <option>Raleigh Property (corporate): LOW06</option>
        <option>Entourage (vineyard): LOW08</option>
        <option>Castle Creek (winery): LOW09</option>
        <option>Sunset Marquis: LOW10</option>
        <option>File Keepers: LOW13</option>
      </Select>

      <a
        href="https://www.everwellbenefits.com/EB/MP/auth/login"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button mini outline primary width="100px" style={{ marginLeft: '10px' }}>
          Visit Site
        </Button>
      </a>
    </Container>
  </Container>
)

AflacInfo.displayName = 'AflacInfo'

export default AflacInfo
