import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import DetailsCardContainer from '../../../../components/DetailsCard/DetailsCardContainer'
import classes from '../CompareTable/CompareTable.scss'

import { getBaseObject, getCategories, getBenefitList } from '../../utils/compareTable'

const DetailsTable = ({ benefit, showFunctionality, groupFavoriteCount }) => {
  const baseObject = getBaseObject(getCategories([benefit]))
  const benefitKeys = getBenefitList(baseObject)

  return (
    <Container flex noPadding>
      <div style={{ display: 'flex', flexBasis: '50%', minWidth: '230px' }}>
        <Container flex noPadding width="100%" direction="column">
          <Container
            flex
            noPadding
            width="100%"
            height="170px"
            justifyContent="center"
            alignItems="center"
            direction="column"
          />
          <div className={classes.titleKeyRow}>
            <Text uppercase>Plan</Text>
          </div>
          <div className={classes.expandedKeyRow}>
            <Text>Health Plan</Text>
          </div>
          <div className={classes.basicKeyRow}>
            <Text>Style of Network</Text>
          </div>
          <div className={classes.basicKeyRow}>
            <Text>Carrier</Text>
          </div>
          {benefitKeys.map((item) => (
            <div key={item.key} title={item.title} className={classes.basicKeyRow}>
              {item.text}
            </div>
          ))}
        </Container>
      </div>
      <Container flex noPadding direction="column">
        <Container flex noPadding width="320px">
          <DetailsCardContainer
            baseObject={baseObject}
            benefit={benefit}
            groupFavoriteCount={groupFavoriteCount}
            showFunctionality={showFunctionality}
          />
        </Container>
      </Container>
    </Container>
  )
}

DetailsTable.propTypes = {
  benefit: PropTypes.object.isRequired,
  groupFavoriteCount: PropTypes.number,
  showFunctionality: PropTypes.bool,
}

DetailsTable.defaultProps = {
  groupFavoriteCount: 0,
  showFunctionality: true,
}

export default DetailsTable
