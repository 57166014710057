/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const FooterSection = styled.section`
  background: white;
  width: 100%;
  height: 100px;
  position: fixed;
  bottom: 0;
  left: ${({ noLeft }) => (noLeft ? 'auto' : 0)};
`
