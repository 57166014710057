import startCase from 'lodash/startCase'
import isEmpty from 'lodash/isEmpty'

const visibleFields = ['approved', 'inProgress']

export const getHeaderAllowanceFromExpenses = (selectedExpense = {}) => {
  if (isEmpty(selectedExpense)) return []
  const entries = Object.entries(selectedExpense.sums)
  const header = [
    {
      title: `${selectedExpense.benefitYear} Allowance`,
      value: selectedExpense.benefitAmount,
    },
  ]
  const formattedEntries = entries.reduce((prev, curr) => {
    const [key, value] = curr
    if (!visibleFields.includes(key)) return prev
    prev.push({
      title: startCase(key),
      value,
    })
    return prev
  }, header)
  const remaining =
    selectedExpense.benefitAmount -
    (selectedExpense.sums.inProgress || 0) -
    (selectedExpense.sums.approved || 0)
  return [
    ...formattedEntries,
    {
      title: 'Remaining',
      value: remaining < 0 ? 0 : remaining,
    },
  ]
}

export const getBodyAllowanceFromExpenses = (expenses, selectedLifeEvent) => {
  if (isEmpty(expenses) || isEmpty(selectedLifeEvent)) return []
  return expenses.filter((e) => e.benefitPublicKey !== selectedLifeEvent.benefitPublicKey)
}

export const getExpenseGeneralInformation = (expense, members) => {
  const key = expense.persons.length > 1 ? 'patients' : 'patient'
  return {
    ...(!isEmpty(expense.persons) && {
      [key]: expense.persons.map((person) => members[person]).join(', '),
    }),
    notes: expense.notes,
  }
}

export const getAllowanceRowFromExpense = (expense) => {
  const { benefitEffectiveDate, benefitAmount } = expense
  return Object.keys(expense.sums).reduce(
    (arr, key) => {
      if (!visibleFields.includes(key)) return arr
      arr.push({
        title: startCase(key),
        value: expense.sums[key],
      })
      return arr
    },
    [
      {
        title: benefitEffectiveDate,
        value: benefitAmount || 0,
      },
    ]
  )
}

export const currencyFormatter = (amount, config) => {
  const defaultConfig = {
    style: 'currency',
    currency: 'USD',
    ...config,
  }
  return new Intl.NumberFormat('en-US', defaultConfig).format(amount)
}
