import { EnrollmeLayoutContainer } from 'layouts/EnrollmeLayout'
import HomeView from './Home'

const enrollme = (store) => ({
  component: EnrollmeLayoutContainer,
  path: '/',
  indexRoute: HomeView,
  getChildRoutes(location, next) {
    require.ensure([], (require) => {
      const routes = [require('./Home').default].concat(
        require('./Admin').default(store),
        require('./Benefits').default(store),
        require('./BundleEvents').default(store),
        require('./Checkout').default(store),
        require('./Cart/routes').default(store),
        require('./DigitalMailRoom').default(store),
        require('./FSAMarketing').default(store),
        require('./FeatureGate').default(store),
        require('./Doctor').default(store),
        require('./Summary').default(store),
        require('./Expenses').default(store),
        require('./OpenEnrollment/routes').default(store),
        require('./Profile').default(store),
        require('./Provider').default(store),
        require('./Settings').default(store),
        require('./ThankYou').default(store),
        require('./Signature').default(store),
        require('./NotAvailable').default(store),
        require('./LifeEvents').default(store)
      )
      next(null, routes)
    })
  },
})

export const createRoutes = (store) => {
  const routes = {
    getChildRoutes(location, next) {
      require.ensure([], (require) => {
        const childRoutes = [enrollme(store)].concat(
          require('./Auth/routes').default(store),
          require('./NotFound').default(store)
        )
        next(null, childRoutes)
      })
    },
  }
  return routes
}

export default createRoutes
