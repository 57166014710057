import React, { useState, useEffect } from 'react'
import { AnimatePresence, motion, MotionProps, AnimatePresenceProps } from 'framer-motion'
import { Stack, StackProps } from '../stack/Stack'
import { Typography } from '../typography/Typography'
import ArrowDown from './chevron-down.svg'
import styles from './Expandable.module.scss'

export type ExpandableProps = {
  /**
   * Optional. Sets initial expanded state
   */
  defaultExpanded?: boolean
  /**
   * Optional. Use when you want direct control over expanded state
   */
  expanded?: boolean
  /**
   * Additional props to be passed to the wrapper `Stack` component
   */
  wrapper?: Omit<StackProps, 'children'>
  /**
   * Event handler fired when expanded state changes
   */
  onChange?: (expanded: boolean) => void
  children: React.ReactNode | React.ReactNode[]
  /**
   * Title of the expandable. Disregarded if a custom `toggler` is defined
   */
  title?: string
  /**
   * Style for the default toggler. Disregarded if a custom `toggler` is defined
   */
  defaultTogglerStyle?: ((expanded: boolean) => React.CSSProperties) | React.CSSProperties
  /**
   * Function used to define custom toggle handle
   */
  toggler?: (expanded: boolean, onToggleHandler: () => void) => React.ReactNode
  /**
   * Framer-motion `motion.div` props. Override properties like `transition` here
   */
  motionContainer?: MotionProps
  /**
   * Props for `AnimatePresence` from `framer-motion`
   */
  animatePresence?: AnimatePresenceProps
}

export const ExpandableArrowIcon = ({ expanded }: Pick<ExpandableProps, 'expanded'>) => (
  <ArrowDown className={[styles.expandableIcon, expanded ? styles.active : ''].join(' ')} />
)

export type ExpandableTogglerProps = {
  onToggle: () => void
} & Pick<ExpandableProps, 'expanded' | 'title' | 'defaultTogglerStyle'>

export const ExpandableToggler = ({
  title,
  expanded,
  defaultTogglerStyle,
  onToggle,
}: ExpandableTogglerProps) => (
  <Stack
    direction="row"
    style={{
      padding: '8px 0px',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      background: '#fff',
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      borderBottomLeftRadius: expanded ? 0 : 6,
      borderBottomRightRadius: expanded ? 0 : 6,
      ...(typeof defaultTogglerStyle === 'function'
        ? defaultTogglerStyle(expanded)
        : defaultTogglerStyle),
    }}
    tabIndex={0}
    role="button"
    onClick={onToggle}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onToggle()
      }
    }}
  >
    <Typography size={14} bold>
      {title ?? ''}
    </Typography>
    <ExpandableArrowIcon expanded={expanded} />
  </Stack>
)

export const Expandable = ({
  defaultExpanded = false,
  title,
  defaultTogglerStyle,
  expanded,
  onChange,
  wrapper,
  toggler,
  children,
  motionContainer,
  animatePresence,
}: ExpandableProps) => {
  const { style, ...motionProps } = motionContainer ?? {}
  const [expand, setExpand] = useState(typeof expanded === 'boolean' ? expanded : defaultExpanded)

  useEffect(() => {
    if (typeof expanded !== 'undefined') {
      onChange?.(expanded)
      setExpand(expanded)
    }
  }, [expanded, onChange])

  const onToggleExpandable = () => {
    const status = !expand
    onChange?.(status)
    setExpand(status)
  }

  return (
    <Stack direction="col" {...wrapper}>
      {toggler?.(expand, onToggleExpandable) || (
        <ExpandableToggler
          title={title}
          expanded={expand}
          onToggle={onToggleExpandable}
          defaultTogglerStyle={defaultTogglerStyle}
        />
      )}
      <AnimatePresence {...animatePresence}>
        {expand && children && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ type: 'tween' }}
            style={{ overflow: 'hidden', ...(style ?? {}) }}
            {...motionProps}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </Stack>
  )
}
