import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

import BulletList from '@hixme-ui/bullet-list'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import { Checkbox, FormGroup, Input } from '@hixme-ui/forms'
import Icon from '@hixme-ui/icon'
import newExpensesTexts from '../NewExpensesRequestsTexts'

import ExpenseDateTooltip from './ExpenseDateTooltip/ExpenseDateTooltip'
import AllowanceLoader from './AllowanceLoader/AllowanceLoader'

const ExpenseForm = ({
  expense,
  members,
  onChange,
  onBlur,
  onFocus,
  onAgreementChange,
  onFamilyMemberChange,
  isEditing,
  isLoading,
  allowanceInformation,
}) => (
  <Container
    direction="column"
    flex
    noPadding
    margin="50px 0 0 0"
    rounded
    border="1px solid Gainsboro"
  >
    <Row middle="sm">
      <Col xs={12} sm={7}>
        <Container padding="0 0 0 20px">
          <FormGroup error={expense.nameError} label="Name this expense">
            <Input
              placeholder="Ex: Hearing Aid, Eyeglasses etc"
              name="name"
              error={expense.nameError}
              onChange={(e) => onChange(e, expense)}
              value={expense.name}
              maxLength={75}
            />
          </FormGroup>
        </Container>
      </Col>
      <Col xs={12} sm={5}>
        <Container flex padding="0 20px 0 20px">
          <FormGroup label="Date of expense">
            <Input
              name="date"
              date
              value={expense.date}
              onChange={(e) => onChange(e, expense)}
              error={expense.dateError}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            {expense.dateError && <Text error>{expense.dateError}</Text>}
          </FormGroup>
          <Container flex alignCenter padding="0 10px">
            <span data-tip data-for="expense-date" style={{ position: 'relative', top: '15px' }}>
              <Icon size={1.5} blue info />
            </span>
            <ExpenseDateTooltip tooltipId="expense-date" />
          </Container>
        </Container>
      </Col>
    </Row>
    {isLoading && <AllowanceLoader />}
    {allowanceInformation && (
      <Row>
        <Col xs={12}>
          <Container margin="0 10px" style={{ border: '1px dashed #845EA7', borderRadius: '6px' }}>
            <Text danger={!allowanceInformation.valid} green={allowanceInformation.valid}>
              {allowanceInformation.message}
            </Text>
          </Container>
        </Col>
      </Row>
    )}
    <Container width="100%" noPadding flex>
      <Container width="100%">
        <FormGroup label={newExpensesTexts.familyServices}>
          <Container
            flex
            noPadding
            justifyContent={members.length > 2 ? 'space-between' : 'flex-start'}
            flexWrap
            style={{ gap: members.length <= 2 ? '20px' : 0 }}
          >
            {members.map((person) => (
              <div key={person.id}>
                <Checkbox
                  name="familyGroup"
                  text={person.name}
                  disabled={isEditing}
                  value={person.id}
                  checked={expense.hasPerson(person.id)}
                  onChange={() => onFamilyMemberChange(person.id, expense)}
                />
              </div>
            ))}
          </Container>
        </FormGroup>
      </Container>
    </Container>
    <Container width="100%" noPadding flex>
      <Container padding="0 20px" width="100%">
        <FormGroup label="Add a note - optional">
          <Input
            name="notes"
            textarea
            maxLength={266}
            onChange={(e) => onChange(e, expense)}
            value={expense.notes}
          />
        </FormGroup>
      </Container>
    </Container>
    <Container width="100%" padding="10px" flex>
      <Container width="100%" style={{ backgroundColor: 'white' }}>
        <Checkbox
          className="confirmation_requirements"
          name="confirmation_requirements"
          disabled={isEditing}
          text={newExpensesTexts.checkboxConditions}
          value={expense.isCertified}
          onChange={(e) => onAgreementChange(e, expense)}
        />
        <Container noPadding margin="20px">
          <BulletList bulletArray={newExpensesTexts.conditions} />
        </Container>
        <Text>{newExpensesTexts.conditionsNote}</Text>
      </Container>
    </Container>
  </Container>
)

ExpenseForm.displayName = 'ExpenseForm'
ExpenseForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onAgreementChange: PropTypes.func.isRequired,
  members: PropTypes.array,
  onFamilyMemberChange: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  expense: PropTypes.shape({
    expenseDate: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  allowanceInformation: PropTypes.shape({
    valid: PropTypes.bool,
    message: PropTypes.string,
  }),
}
ExpenseForm.defaultProps = {
  members: [],
  allowanceInformation: null,
  isLoading: false,
}
export default ExpenseForm
