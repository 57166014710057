import { connect } from 'react-redux'
import { selectors as userSessionSelectors, actions } from 'store/modules/user-session'
import * as cartSelectors from 'store/modules/cart/selectors'
import * as personSelectors from 'store/modules/persons/selectors'
import { Home } from '../components'
import { isImpersonating } from 'store/modules/user-session/selectors'

const mapStateToProps = (state) => {
  const isQLEAvailable = isImpersonating(state) && userSessionSelectors.isQLEAvailable(state)

  // This is used for opt-out banner to determine who to additionally call
  // declineMedicare mutation since this mutation is not idempotent.
  // It will error trying to decline an already declined member
  const medicareBenefits = cartSelectors.getAllMedicareBenefits(state)
  const personsEnrolledInMedicare = medicareBenefits.map((benefit) => benefit.Persons).flat()

  const enrollmentDurationMessage = userSessionSelectors.getEnrollmentDurationMessageSelector(state)

  return {
    enrollmentDurationMessage,
    hasNeverSawProviderQuestion:
      userSessionSelectors.hasSeenProviderLookupQuestion(state) === undefined,
    benefits: userSessionSelectors.getClientBenefitsSummary(state),
    enrollmentIsActive: userSessionSelectors.getEnrollmentIsActiveSelector(state),
    isCartCommitted: userSessionSelectors.isCartCommitted(state),
    person: personSelectors.getPersonSelector(state),
    enrollmentResponses: userSessionSelectors.getEnrollmentResponses(state),
    dentalComplete: cartSelectors.dentalBenefitComplete(state),
    visionComplete: cartSelectors.visionBenefitComplete(state),
    healthComplete: cartSelectors.healthBenefitComplete(state),
    groupComplete: cartSelectors.groupHealthBenefitComplete(state),
    additionalBenefitsComplete: cartSelectors.additionalBenefitsComplete(state),
    needsToSignHealthBundles: cartSelectors.needsToSignHealthBundles(state),
    isGuestUser: userSessionSelectors.isGuestUser(state),
    hasHealthBundleBenefit: userSessionSelectors.healthBundleBenefitIsAvailable(state),
    isExistingMember: userSessionSelectors.getPreviousEnrollment(state) !== undefined,
    isICHRAAvailable: userSessionSelectors.getICHRAAvailablity(state) || false,
    isEnrolling: userSessionSelectors.isEnrollingExcludeQLE(state),
    isQLEAvailable: isQLEAvailable,
    isQleEnrollment: userSessionSelectors.isQleEnrollment(state),
    enrollmentSession: userSessionSelectors.getEnrollmentSession(state),
    prevEnrollment: userSessionSelectors.getPreviousEnrollment(state),
    isPendingInformation: personSelectors.getIsPendingEdit(state),
    personsEnrolledInMedicare,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCutoffTime: (cutoffTime) => {
      dispatch(actions.getEnrollmentCutoffTime(cutoffTime))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)
