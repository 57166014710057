/* eslint-disable */
// TODO: Lint cleanup. If you are editing this file, it is your duty to fix all linting errors.
import React from 'react'
import PropTypes from 'prop-types'
import { Select } from '@hixme-ui/forms'

const LocationsDropdown = ({ list, ...props }) => {
  const locations = list.map((loc) => ({
    id: loc.County,
    name: `${loc.County}, ${loc.StateProvince}`,
  }))
  locations.unshift({ id: '', name: 'Select' })

  return <Select list={locations} {...props} />
}
LocationsDropdown.propTypes = {
  list: PropTypes.array,
}

export default LocationsDropdown
