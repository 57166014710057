import { createReducer } from 'modules/redux-utils'
import { BENEFIT_TYPE_DENTAL, BENEFIT_TYPE_HSA, BENEFIT_TYPE_HEALTH } from '@hixme/benefit-types'
import * as t from './actionTypes'

const initialState = {
  cart: {},
  error: null,
}

export default createReducer(initialState, {
  [t.LOAD_CART]: (state, payload) =>
    Object.assign({}, state, {
      cart: payload,
    }),
  [t.RESET_CART]: (state) => Object.assign({}, state, initialState),

  [t.ADD_PRIMARY_CARE_PHYSICIAN]: (state, payload) => {
    const cart = state.cart.map((product) => {
      if (product.BenefitType === BENEFIT_TYPE_HEALTH) {
        return Object.assign({}, product, {
          Benefits: product.Benefits.map((benefit) => {
            // eslint-disable-line
            if (benefit.HealthPlanId === payload.HealthPlanId) {
              return Object.assign({}, benefit, {
                PrimaryCareProvider: payload.PrimaryCareProvider,
              })
            }
            return Object.assign({}, benefit)
          }),
        })
      }
      return product
    })
    return Object.assign({}, state, { cart })
  },
  [t.ADD_DENTAL_PRIMARY_CARE_PROVIDER]: (state, payload) => {
    const cart = state.cart.map((product) => {
      if (product.BenefitType === BENEFIT_TYPE_DENTAL) {
        return Object.assign({}, product, {
          Benefits: product.Benefits.map((benefit) =>
            Object.assign({}, benefit, { PrimaryCareProvider: payload })
          ),
        })
      }
      return product
    })
    return Object.assign({}, state, { cart })
  },

  [t.ADD_HEALTH_BUNDLE]: (state, payload) => {
    const cart = state.cart.map((oldProduct) => {
      const product = { ...oldProduct }
      const isEmployeeBundle = payload.bundle.Persons.some(
        (person) => person.Relationship === 'Employee'
      )

      if (
        product.BenefitType === BENEFIT_TYPE_HSA &&
        product.Benefits.length > 0 &&
        !payload.bundle.IsHsa &&
        isEmployeeBundle
      ) {
        product.Benefits = []
      }

      if (product.BenefitType === BENEFIT_TYPE_HEALTH) {
        if (payload.index !== -1) {
          product.Benefits.splice(payload.index, 1)
        }
        product.Benefits.push(payload.bundle)
        product.NotIncluded = payload.declinedPersons
      }
      return product
    })
    return Object.assign({}, state, {
      cart,
    })
  },
  [t.REMOVE_HEALTH_BUNDLE]: (state, payload) => {
    const cart = state.cart.map((oldProduct) => {
      const product = { ...oldProduct }
      if (product.BenefitType === BENEFIT_TYPE_HEALTH) {
        product.Benefits = product.Benefits.filter(
          (benefit) => benefit.HealthPlanId !== payload.bundle.HealthPlanId
        )
        product.NotIncluded = payload.declinedPersons
      }
      const isEmployeeBundle = payload.bundle.Persons.some(
        (person) => person.Relationship === 'Employee'
      )

      if (isEmployeeBundle && payload.bundle.IsHsa && product.BenefitType === BENEFIT_TYPE_HSA) {
        product.Benefits = []
      }

      return product
    })
    return Object.assign({}, state, {
      cart,
    })
  },
  [t.REMOVE_ALL_HEALTH_BUNDLES]: (state) => {
    const cart = state.cart.map((oldProduct) => {
      const product = { ...oldProduct }
      if (product.BenefitType === BENEFIT_TYPE_HEALTH) {
        product.Benefits = []
        product.NotIncluded = []
      }

      if (product.BenefitType === BENEFIT_TYPE_HSA) {
        product.Benefits = []
      }
      return product
    })

    return Object.assign({}, state, {
      cart,
    })
  },
  [t.DECLINE_HEALTH_BUNDLE]: (state, payload) => {
    const cart = state.cart.map((oldProduct) => {
      const product = { ...oldProduct }
      if (product.BenefitType === BENEFIT_TYPE_HEALTH) {
        if (payload.removeBenefitIndex !== -1) {
          product.Benefits.splice(payload.removeBenefitIndex, 1)
        }
        product.NotIncluded = payload.declinedPersons
      }
      return product
    })
    return Object.assign({}, state, {
      cart,
    })
  },

  [t.MARK_PDF_APPLICATIONS_AS_MANUAL]: (state) => {
    const cart = state.cart.map((product) => {
      if (product.BenefitType === BENEFIT_TYPE_HEALTH) {
        return Object.assign({}, product, {
          Benefits: product.Benefits.map((benefit) =>
            Object.assign({}, benefit, { PdfApplicationIsManual: true })
          ),
        })
      }
      return product
    })
    return Object.assign({}, state, {
      cart,
    })
  },
  [t.MARK_PDF_APPLICATION_AS_COMPLETE]: (state, { personPublicKey, envelopeId }) => {
    const cart = state.cart.map((product) => {
      if (product.BenefitType === BENEFIT_TYPE_HEALTH) {
        return {
          ...product,
          Benefits: product.Benefits.map((benefit) => {
            if (benefit.PdfSignatures && benefit.DocuSignEnvelopeId === envelopeId) {
              return {
                ...benefit,
                PdfSignatures: benefit.PdfSignatures.map((signature) => {
                  if (signature.Id === personPublicKey) {
                    return { ...signature, Signed: true }
                  }
                  return signature
                }),
              }
            }
            return benefit
          }),
        }
      }
      return product
    })
    return { ...state, cart }
  },
})
