import { compose, withState } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
// import { getUserPayroll } from 'store/modules/user-session/selectors'
import { getEmployee } from 'store/modules/persons/selectors'

import { monthlyCostByPayrollCycle, payrollCycleCostToMonthly } from 'modules/benefit-utils'

import { PROVIDER_LOOKUP_PATH } from 'routes/path-names'

import { sanitizeCurrencyValue } from 'components/CurrencyInput/CurrencyInputUtils'

import {
  getCurrentGroupFilterOptions,
  getCurrentGroupFilters,
  getCurrentGroupBundleFilteredCount,
  getCurrentGroupBundleCount,
  getCurrentGroupHasPreviousBundle,
  getCurrentGroupHasHiosMatch,
  getCurrentGroupProviders,
  getPersonsInCurrentGroup,
  getSomeGroupPreviousBundle,
} from '../selectors'

import {
  toggleFilter,
  resetFilters,
  setMatchBundle,
  setMatchBundleByHios,
  updateMatchBundle,
  updateMatchBundleByHios,
  updateMaxCostFilter,
  updateSelectedProviders,
} from '../actions'
import FilterPanelBody from '../components/FilterOptions/components/FilterPanelBody/FilterPanelBody'

// let payroll = null
let payrollCycle = null
const costValueForView = (amount) => {
  const viewValue = monthlyCostByPayrollCycle(amount, payrollCycle)
  return Math.ceil(viewValue)
}
const costValueForFilter = (amount) => payrollCycleCostToMonthly(amount, payrollCycle)

const mapStateToProps = (state) => {
  payrollCycle = getEmployee(state).PayrollCycle
  // payroll = getUserPayroll(state)

  const filters = getCurrentGroupFilters(state)
  const viewMinCost = filters && costValueForView(filters.minPrice)
  const viewMaxCostLimit = filters && costValueForView(filters.maxPriceLimit)
  const maxPrice = filters && costValueForView(filters.maxPrice)

  const hasPreviousBundles = getSomeGroupPreviousBundle(state)
  const currentGroupHasPreviousBundle = getCurrentGroupHasPreviousBundle(state)
  const currentGroupHasHiosMatch = getCurrentGroupHasHiosMatch(state)

  const providers = getCurrentGroupProviders(state)
  const selectedProviders = providers
    .filter((provider) => provider.isSelected)
    .sort((a, b) => b.count - a.count)

  return {
    ...getCurrentGroupFilterOptions(state),
    currentGroupBundleCount: getCurrentGroupBundleCount(state),
    currentGroupBundleFilteredCount: getCurrentGroupBundleFilteredCount(state),
    filters,
    persons: getPersonsInCurrentGroup(state),
    viewMaxCostLimit,
    viewMinCost,
    maxPrice,
    hasPreviousBundles,
    currentGroupHasPreviousBundle,
    currentGroupHasHiosMatch,
    providers,
    selectedProviders,
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  matchBundleFilter: (checked) => {
    dispatch(resetFilters())
    dispatch(updateMatchBundle(checked))
  },
  matchHiosFilter: (checked) => {
    dispatch(resetFilters())
    dispatch(updateMatchBundleByHios(checked))
  },
  filterClick: (filterName) => (e) => {
    dispatch(setMatchBundle(false))
    dispatch(setMatchBundleByHios(false))
    dispatch(toggleFilter(filterName, e.target.value, e.target.checked))
  },
  maxCostFilterChange: ({ target }) => {
    const value = sanitizeCurrencyValue(target.value)
    dispatch(setMatchBundle(false))
    dispatch(setMatchBundleByHios(false))
    const filterValue = costValueForFilter(value)
    dispatch(updateMaxCostFilter(filterValue))
  },
  onProviderSearch: () => {
    props.router.push(PROVIDER_LOOKUP_PATH)
  },
  handleSubmit: (data) => {
    const providers = []
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of data.entries()) {
      if (value === 'on') {
        providers.push(key)
      }
    }
    dispatch(updateSelectedProviders(providers))
  },
})

export default compose(
  withRouter,
  withState('isProviderSelectorVisible', 'setIsProviderSelectorVisible', false),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FilterPanelBody)
