import { connect } from 'react-redux'

import CartSummary from 'components/Cart/CartSummary'
import * as cartSelectors from 'store/modules/cart/selectors'
import actions from 'store/modules/cart'

const mapStateToProps = (state) => ({
  cartSummary: cartSelectors.getCartSummary(state),
})

const mapDispatchToProps = (dispatch) => ({
  toggleCartModal: () => {
    dispatch(actions.toggleCartModal())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartSummary)
