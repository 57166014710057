import React from 'react'
import { MrModal } from '@hixme/modal'

// hixme-ui
import Separator from '@hixme-ui/separator'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'

const FTAInformation = () => (
  <MrModal medium contentStart contentPadding="40px 60px">
    <Title small>Important information about your FTA</Title>
    <Separator light />
    <Text p light thin fontSize="16px" style={{ margin: '2em 0 1em' }}>
      An FTA is a great way to put extra money in your pocket each month and make your commute more
      convenient and affordable.
    </Text>
    <Text p light thin fontSize="16px">
      1) Public transit can include train, subway, bus, ferry or vanpool.
    </Text>
    <Text p light thin fontSize="16px">
      2) There are limits to how much can be saved in one year. The maximum limit allowed for 2019
      by the IRS is $255 per month.
    </Text>
    <Text p light thin fontSize="16px">
      3) The contributions you make are pre-tax, so you don’t pay taxes on this money.
    </Text>
  </MrModal>
)

FTAInformation.displayName = 'FTAInformation'

export default FTAInformation
