import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import BulletList from '@hixme-ui/bullet-list'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

export const BulletItems = [
  `The date of your encounter with a health care
provider (received a treatment, visited a doctor, had a procedure, checked into a hospital, etc.)`,
  `The date you purchased eligible medical equipment or supplies.`,
  `The date you picked up a prescription drug.`,
]

const ExpenseDateTooltip = ({ tooltipId }) => (
  <ReactTooltip id={tooltipId} effect="solid" place="left">
    <Container flex direction="column" padding="10px" width="350px">
      <Text p>Please enter the date that you originally incurred this expense. This could be:</Text>
      <BulletList bulletArray={BulletItems} />
    </Container>
  </ReactTooltip>
)

ExpenseDateTooltip.propTypes = {
  tooltipId: PropTypes.string.isRequired,
}

ExpenseDateTooltip.defaultProps = {}

export default ExpenseDateTooltip
