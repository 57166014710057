import React from 'react'
import BaseIcon from './BaseIcon'

/*eslint-disable */
export default class PhoneCircle extends BaseIcon {
  static defaultProps = {
    width: 27,
    height: 26,
    viewBox: '-1 -1 27 26'
  }
  render () {
    const color = this.getStrokeColor()
    return (
      <svg {...this.getSvgProps()}>
          <g fill='none' fillRule='evenodd' transform='translate(.25)'>
            <path d='M10.3907472 6.02841817c.1894335-.08922488.4143163-.00707525.5028888.18478407L12.0947642 8.814999c.0883042.1912783.0076745.4180344-.1831108.50789593l-.6873032.32372592c-.1894335.08922487-.4143163.00707525-.5028888-.18478407L9.52033324 6.86004002c-.08830427-.1912783-.0076745-.4180344.18311073-.50789593l.68730323-.32372593zm3.6021464 7.80270813c.1894335-.0892249.4143162-.0070753.5028887.1847841l1.2011282 2.6017967c.0883043.1912783.0076745.4180344-.1831107.5078959l-.6873032.323726c-.1894335.0892248-.4143163.0070752-.5028888-.1847841l-1.2011282-2.6017968c-.0883043-.1912783-.0076745-.4180344.1831107-.5078959l.6873033-.3237259zm-3.7146913-3.94995805l.0093463-.0044022c.187903-.088504.2704104-.31639814.1819874-.5079335L9.42893297 7.1147537c-.08885677-.19247508-.31420544-.27422224-.50429935-.18468633l-.00958458.00451443c-.08884385.04184624-.76621082.3608921-.9459848 1.3018247-.28801176 2.592317.78301686 4.8825232.94913712 5.2423609.16612025.3598376 1.21456514 2.660681 3.36570454 4.1041361.8291583.4657165 1.5065253.1466707 1.5953691.1048244l.0095846-.0045144c.1900939-.0895359.2729483-.3164495.1840916-.5089245l-1.0406031-2.2540789c-.0884229-.1915353-.3142922-.2741814-.5021952-.1856774l-.0093463.0044022c-.6043674.2846624-1.8055209-1.5610732-2.0123881-2.0091739-.2068672-.4481007-.8345836-2.5639303-.2302162-2.84859275z'
              fill={color}
              />
            <ellipse
              stroke={color}
              cx='12.109375'
              cy='12'
              rx='12.109375'
              ry='12'
              />
          </g>
      </svg>
    )
  }
}
/*eslint-enable */
