/* eslint-disable */
// TODO: Lint cleanup. If you are editing this file, it is your duty to fix all linting errors.
import React from 'react'
import PropTypes from 'prop-types'

const VideoThumb = ({ videoId, height, width }) => (
  <span
    className={`wistia_async_${videoId} wistia_embed popover=true popoverAnimateThumbnail=true`}
    style={{ display: 'inline-block', height, width }}
  />
)

VideoThumb.displayName = 'VideoThumb'
VideoThumb.propTypes = {
  videoId: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

VideoThumb.defaultProps = {
  height: '112px',
  width: '200px',
}

export default VideoThumb
