import { namespaceActions } from 'modules/redux-utils'
import { NAME } from './constants'

export const LOAD_CART = 'LOAD_CART'
export const RESET_CART = 'RESET_CART'

export const ADD_PRIMARY_CARE_PHYSICIAN = 'ADD_PRIMARY_CARE_PHYSICIAN'
export const ADD_DENTAL_PRIMARY_CARE_PROVIDER = 'ADD_DENTAL_PRIMARY_CARE_PROVIDER'

export const ADD_HEALTH_BUNDLE = 'ADD_HEALTH_BUNDLE'
export const REMOVE_HEALTH_BUNDLE = 'REMOVE_HEALTH_BUNDLE'
export const REMOVE_ALL_HEALTH_BUNDLES = 'REMOVE_ALL_HEALTH_BUNDLES'
export const DECLINE_HEALTH_BUNDLE = 'DECLINE_HEALTH_BUNDLE'

export const MARK_PDF_APPLICATIONS_AS_MANUAL = 'MARK_PDF_APPLICATIONS_AS_MANUAL'
export const MARK_PDF_APPLICATION_AS_COMPLETE = 'MARK_PDF_APPLICATION_AS_COMPLETE'

export const SEND_BENEFIT_ENROLLMENT_EVENT = 'SEND_BENEFIT_ENROLLMENT_EVENT'
export const UPDATE_MEDICARE = 'UPDATE_MEDICARE'

export default namespaceActions(NAME)([
  LOAD_CART,
  RESET_CART,

  ADD_PRIMARY_CARE_PHYSICIAN,
  ADD_DENTAL_PRIMARY_CARE_PROVIDER,

  ADD_HEALTH_BUNDLE,
  REMOVE_HEALTH_BUNDLE,
  REMOVE_ALL_HEALTH_BUNDLES,
  DECLINE_HEALTH_BUNDLE,

  MARK_PDF_APPLICATIONS_AS_MANUAL,
  MARK_PDF_APPLICATION_AS_COMPLETE,

  SEND_BENEFIT_ENROLLMENT_EVENT,
  UPDATE_MEDICARE,
])
