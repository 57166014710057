export const getFileExtension = (file) => {
  if (!(file instanceof File)) {
    throw new Error('Parameter is not a file instance')
  }
  const dotIdx = file.name.lastIndexOf('.')
  return file.name.slice(dotIdx + 1, file.name.length)
}

export const getFilename = (file) => {
  if (!(file instanceof File)) {
    throw new Error('Parameter is not a file instance')
  }
  const dotIdx = file.name.lastIndexOf('.')
  return file.name.slice(0, dotIdx).replace(/\s/g, '')
}

export const rename = (receipt, name) => {
  const file = new File([receipt], name, { type: receipt.type })
  file.base64 = receipt.base64
  return file
}

export const sanitizeFile = (receipt) => {
  const name = `${getFilename(receipt)}.${getFileExtension(receipt)}`
  const file = new File([receipt], name, { type: receipt.type })
  return file
}

export const buildBase64 = (file) => {
  if (!(file instanceof File)) {
    throw new Error('Parameter is not a file instance')
  }
  const reader = new FileReader()
  reader.readAsDataURL(file)
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
    reader.onerror = (err) => {
      reject(err)
    }
  })
}

export const buildFileName = (receipt, includeTimeStamp = false) => {
  const name = getFilename(receipt)
  const extension = getFileExtension(receipt)
  const fileName = includeTimeStamp ? `${name}-${Date.now()}.${extension}` : `${name}.${extension}`
  return fileName
}
