import React, { createContext, Dispatch, useContext, useReducer } from 'react'
import { LifeEventsSharedActions, lifeEventsSharedReducer } from './LifeEventsSharedReducer'
import { type LifeEventsState } from './LifeEventsContextTypes'
import { QLEType } from './constants'

export const initialQleState: LifeEventsState = {
  shared: {
    dirty: false,
    saving: false,
    qleType: QLEType.relocation,
    record: undefined,
    location: undefined,
  },
}

export const LifeEventsContext = createContext<{
  state: LifeEventsState
  dispatch: Dispatch<LifeEventsSharedActions>
} | null>(null)

export const lifeEventsReducer = ({ shared }, action) => ({
  shared: lifeEventsSharedReducer(shared, action),
})

/**
 * A React Context Provider with Reducer
 * Built using tutorial found here: https://dev.to/elisealcala/react-context-with-usereducer-and-typescript-4obm
 */
export const LifeEventsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(lifeEventsReducer, initialQleState)
  return (
      <LifeEventsContext.Provider value={{ state, dispatch }}>{children}</LifeEventsContext.Provider>
  )
}

export const useLifeEventContext = () => useContext(LifeEventsContext)
