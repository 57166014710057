import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'

import ProviderTooltip from './ProviderTooltip'

export const ProviderStatus = ({ name, isConfirmed }) => (
  <Container flex noPadding alignCenter style={{ gap: '10px' }}>
    <Container noPadding>
      <Icon
        check={isConfirmed}
        alert={!isConfirmed}
        color={isConfirmed ? '#2EAE8F' : '#FDBD16'}
        size={1.05}
      />
    </Container>
    <Text
      style={{
        borderBottom: '1px dashed',
      }}
      small
    >
      <Text fontWeight={600}>{name} </Text>
      <Text>{isConfirmed ? 'accepts this plan.' : '- not verified.'}</Text>
    </Text>
  </Container>
)

const ProviderNote = ({ visible, provider, bundleId }) => {
  if (!visible) {
    return null
  }
  return (
    <Container flex noPadding alignCenter>
      <span data-tip data-for={`provider-${provider.npi}-${bundleId}`}>
        <ProviderStatus name={provider.fullName} isConfirmed={provider.confirmed} />
        <ProviderTooltip
          tooltipId={`provider-${provider.npi}-${bundleId}`}
          confirmed={provider.confirmed}
          name={provider.fullName}
          bundleId={bundleId}
          phone={provider.phone}
        />
      </span>
    </Container>
  )
}

ProviderStatus.propTypes = {
  name: PropTypes.string.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
}

ProviderNote.propTypes = {
  visible: PropTypes.bool.isRequired,
  provider: PropTypes.object.isRequired,
  bundleId: PropTypes.string.isRequired,
}

export default ProviderNote
