import { LifeEventsActions, LifeEventsSharedState } from './LifeEventsContextTypes'
import { ActionMap } from 'context/ContextTypes'
import { QLEType, QLETypeMapper } from './constants'
import { QLEDocument, QLEPerson, QLERecordData, QLEResult, QLEStatus } from './types'
import { initialQleState } from './LifeEventsContext'

type SharedPayload = {
  [LifeEventsActions.UpdateQLEType]: {
    qleType: QLEType
  }
  [LifeEventsActions.Save]: undefined
  [LifeEventsActions.SaveSuccess]:
    | {
        record?: QLERecordData
        temp?: Record<string, any>
      }
    | undefined
  [LifeEventsActions.SaveError]: undefined
  [LifeEventsActions.Load]: {
    record: QLERecordData
  }
  [LifeEventsActions.UpdateMembers]: {
    members: QLEPerson[]
  }
  [LifeEventsActions.UpdateDocuments]: {
    documents: QLEDocument[]
  }
  [LifeEventsActions.UpdateResults]: {
    results: QLEResult[]
  }
  [LifeEventsActions.UpdateStatus]: QLEStatus
  [LifeEventsActions.UpdateRecord]: Partial<QLERecordData>
  [LifeEventsActions.UpdateSelectedHealthBundle]: string | undefined
  [LifeEventsActions.Clear]: void
}

export type LifeEventsSharedActions = ActionMap<SharedPayload>[keyof ActionMap<SharedPayload>]

export const lifeEventsSharedReducer = (
  state: LifeEventsSharedState,
  action: LifeEventsSharedActions
) => {
  switch (action.type) {
    /**
     * Sets the QLE Type to a new value.
     */
    case LifeEventsActions.UpdateQLEType: {
      return {
        ...state,
        dirty: true,
        qleType: action.payload.qleType,
      }
    }
    /**
     * Saves all progress on the current page and navigates back to the root Life Events page.
     */
    case LifeEventsActions.Save: {
      return { ...state, saving: true } // not updating `record` here since that should be after API update finished successfully
    }
    case LifeEventsActions.SaveSuccess: {
      return {
        ...state,
        dirty: false,
        saving: false,
        record: { ...state.record, ...action.payload?.record },
        temp: { ...state.temp, ...action.payload?.temp },
      }
    }
    case LifeEventsActions.SaveError: {
      return { ...state, saving: false }
    }
    case LifeEventsActions.Load: {
      return {
        ...state,
        record: { ...state.record, ...action.payload?.record },
        qleType: action.payload?.record?.qleType
          ? QLETypeMapper[action.payload?.record?.qleType.qleName] ?? state.qleType
          : state.qleType,
      }
    }
    case LifeEventsActions.UpdateMembers: {
      return {
        ...state,
        record: {
          ...state.record,
          qlePerson: action.payload.members,
        },
      }
    }
    case LifeEventsActions.UpdateDocuments: {
      return {
        ...state,
        record: {
          ...state.record,
          qleDocument: action.payload.documents,
        },
      }
    }
    case LifeEventsActions.UpdateResults: {
      return {
        ...state,
        record: {
          ...state.record,
          qleResult: action.payload.results,
        },
      }
    }
    case LifeEventsActions.UpdateStatus: {
      return {
        ...state,
        record: {
          ...state.record,
          status: action.payload,
        },
      }
    }
    case LifeEventsActions.UpdateSelectedHealthBundle: {
      return {
        ...state,
        selectedHealthBundlePublicKey: action.payload,
      }
    }
    case LifeEventsActions.Clear: {
      return {
        ...initialQleState.shared,
      }
    }
    case LifeEventsActions.UpdateRecord: {
      return {
        ...state,
        record: {
          ...state.record,
          ...action.payload
        }
      }
    }
    default:
      return state
  }
}
