import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@surecompanies/core_components'
import { Typography } from '@sureco/design-system'

const DeclineMedicalButton = ({ isProcessing, handleClick }) => (
  <Button
    submittingText="Declining..."
    loading={isProcessing}
    onClick={handleClick}
    size="large"
    style={{ width: '190px' }}
    danger
  >
    <Typography size={14}>Decline medical</Typography>
  </Button>
)

DeclineMedicalButton.propTypes = {
  handleClick: PropTypes.func,
  isProcessing: PropTypes.bool,
}

DeclineMedicalButton.defaultProps = {
  handleClick: () => null,
  isProcessing: false,
}

export default DeclineMedicalButton
