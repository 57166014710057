import React from 'react'
import PropTypes from 'prop-types'

import classes from './ScrollDetector.scss'

const ScrollDetector = ({ handleScroll, children }) => (
  <div onScroll={handleScroll} className={classes.scrollDetector}>
    {children}
  </div>
)

ScrollDetector.propTypes = {
  handleScroll: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default ScrollDetector
