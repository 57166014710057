/* eslint-disable import/no-named-as-default */
// modals
import AccidentDeclineCoverageModalContainer from './routes/Benefits/Accident/containers/DeclineCoverageModalContainer'
import AccidentCoverageModalContainer from './routes/Benefits/Accident/containers/AccidentCoverageModalContainer'
import BundleDetailsContainer from './routes/Benefits/HealthBundles/modules/health-bundles/containers/BundleDetailsContainer'
import BundleCompareContainer from './routes/Benefits/HealthBundles/modules/health-bundles/containers/BundleCompareContainer'
import DCFSAInformation from './components/Modals/DCFSAInformation'
import MedicareModal from './components/MedicareModal/MedicareModal'
import DentalCoverageModalContainer from './routes/Benefits/Dental/containers/DentalCoverageModalContainer'
import DentalDeclineCoverageModalContainer from './routes/Benefits/Dental/containers/DeclineCoverageModalContainer'
import HospitalIndemnityDeclineCoverageModalContainer from './routes/Benefits/HospitalIndemnity/containers/DeclineCoverageModalContainer'
import HospitalIndemnityCoverageModalContainer from './routes/Benefits/HospitalIndemnity/containers/HospitalIndemnityCoverageModalContainer'
import GroupDeclineCoverageModalContainer from './routes/Benefits/GroupHealth/containers/DeclineCoverageModalContainer'
import GroupHealthCoverageModalContainer from './routes/Benefits/GroupHealth/containers/GroupHealthCoverageModalContainer'
import EditQuestionsContainer from './components/EditQuestions/EditQuestionsContainer.js'
import FSAInformation from './components/Modals/FSAInformation'
import FTAInformation from './components/Modals/FTAInformation'
import HelpModal from './components/HelpModal'
import LearnMoreModal from './routes/Benefits/HealthBundles/components/LearnMoreModal/LearnMoreModal'
import LegalDeclineCoverageModalContainer from './routes/Benefits/Legal/containers/DeclineCoverageModalContainer'
import TermsAndConditions from './components/Modals/TermsAndConditions'
import Uploader from './modules/ultimate-uploader/components/Uploader'
import ViewMedicalDetails from './routes/Summary/components/ViewMedicalDetails/ViewMedicalDetails'
import VisionCoverageModalContainer from './routes/Benefits/Vision/containers/VisionCoverageModalContainer'
import VisionDeclineCoverageModalContainer from './routes/Benefits/Vision/containers/DeclineCoverageModalContainer'
import BasicLifeDeclineCoverageModalContainer from './routes/Benefits/BasicLife/containers/DeclineCoverageModalContainer'
import LongTermDisabilityDeclineCoverageModalContainer from './routes/Benefits/LongTermDisability/containers/DeclineCoverageModalContainer'
import SplitPreviewContainer from './routes/Benefits/HealthBundles/components/GroupSplit/components/SplitPreview/SplitPreviewContainer'
import ClearCartModal from './components/ClearCartModal'
import TransactionHistoryModal from './routes/Expenses/components/TransactionHistory/TransactionHistoryModal'
import ReimbursementMessageModal from './routes/Expenses/components/ReimbursementMessage/ReimbursementeMessageModal'
import ViewReimbursementModal from './routes/Expenses/components/ViewReimbursement/ViewReimbursementModal'
import ExpenseRequestModalContainer from './routes/Expenses/containers/ExpenseRequestModalContainer'
import HealthDeclineCoverageModalContainer from './routes/Benefits/HealthBundles/modules/health-bundles/containers/HealthDeclineCoverageModalContainer'
import DeclineMedicareCoverageModalContainer from './routes/Profile/routes/Groups/containers/DeclineMedicareCoverageModalContainer'

// modal string constants
export const BUNDLE_COMPARE = 'BUNDLE_COMPARE'
export const BUNDLE_DETAILS = 'BUNDLE_DETAILS'
export const CLEAR_CART = 'CLEAR_CART'
export const DCFSA_INFO = 'DCFSA_INFO'
export const DECLINE_ACCIDENT_COVERAGE = 'DECLINE_ACCIDENT_COVERAGE'
export const DECLINE_DENTAL_COVERAGE = 'DECLINE_DENTAL_COVERAGE'
export const DECLINE_HEALTH_COVERAGE = 'DECLINE_HEALTH_COVERAGE'
export const DECLINE_MEDICARE_COVERAGE = 'DECLINE_MEDICARE_COVERAGE'
export const DECLINE_HOSPITAL_INDEMNITY_COVERAGE = 'DECLINE_HOSPITAL_INDEMNITY_COVERAGE'
export const DECLINE_GROUP_COVERAGE = 'DECLINE_GROUP_COVERAGE'
export const DECLINE_LEGAL_COVERAGE = 'DECLINE_LEGAL_COVERAGE'
export const DECLINE_VISION_COVERAGE = 'DECLINE_VISION_COVERAGE'
export const DECLINE_SUPPLEMENTAL_COVERAGE = 'DECLINE_SUPPLEMENTAL_COVERAGE'
export const DECLINE_LONG_TERM_DISABILITY_COVERAGE = 'DECLINE_LONG_TERM_DISABILITY_COVERAGE'
export const EDIT_ACCIDENT_COVERAGE = 'EDIT_ACCIDENT_COVERAGE'
export const EDIT_CRITICAL_ILLNESS_COVERAGE = 'EDIT_CRITICAL_ILLNESS_COVERAGE'
export const EDIT_DENTAL_COVERAGE = 'EDIT_DENTAL_COVERAGE'
export const EDIT_HOSPITAL_INDEMNITY_COVERAGE = 'EDIT_HOSPITAL_INDEMNITY_COVERAGE'
export const EDIT_GROUP_COVERAGE = 'EDIT_GROUP_COVERAGE'
export const EDIT_HEALTH_NEEDS = 'EDIT_HEALTH_NEEDS'
export const EDIT_VISION_COVERAGE = 'EDIT_VISION_COVERAGE'
export const FSA_INFO = 'FSA_INFO'
export const FTA_INFO = 'FTA_INFO'
export const HELP = 'HELP'
export const LEARN_MORE = 'LEARN_MORE'
export const NEW_EXPENSE_REQUEST = 'NEW_EXPENSE_REQUEST'
export const EXPENSE_TRANSACTION_HISTORY = 'EXPENSE_TRANSACTION_HISTORY'
export const EXPENSE_SEND_MESSAGE = 'EXPENSE_SEND_MESSAGE'
export const EXPENSE_VIEW = 'EXPENSE_VIEW'
export const SPLIT_PREVIEW = 'SPLIT_PREVIEW'
export const TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS'
export const UPLOADER = 'UPLOADER'
export const VIEW_MEDICAL_DETAILS = 'VIEW_MEDICAL_DETAILS'
export const CONFIRM_ACCEPT_OFFER = 'CONFIRM_ACCEPT_OFFER'
export const CONFIRM_DECLINE_OFFER = 'CONFIRM_DECLINE_OFFER'
export const MEDICARE = 'MEDICARE'

export const modals = {
  [BUNDLE_COMPARE]: BundleCompareContainer,
  [BUNDLE_DETAILS]: BundleDetailsContainer,
  [DCFSA_INFO]: DCFSAInformation,
  [DECLINE_ACCIDENT_COVERAGE]: AccidentDeclineCoverageModalContainer,
  [DECLINE_MEDICARE_COVERAGE]: DeclineMedicareCoverageModalContainer,
  [DECLINE_HEALTH_COVERAGE]: HealthDeclineCoverageModalContainer,
  [DECLINE_DENTAL_COVERAGE]: DentalDeclineCoverageModalContainer,
  [DECLINE_HOSPITAL_INDEMNITY_COVERAGE]: HospitalIndemnityDeclineCoverageModalContainer,
  [DECLINE_GROUP_COVERAGE]: GroupDeclineCoverageModalContainer,
  [DECLINE_LEGAL_COVERAGE]: LegalDeclineCoverageModalContainer,
  [DECLINE_VISION_COVERAGE]: VisionDeclineCoverageModalContainer,
  [DECLINE_SUPPLEMENTAL_COVERAGE]: BasicLifeDeclineCoverageModalContainer,
  [DECLINE_LONG_TERM_DISABILITY_COVERAGE]: LongTermDisabilityDeclineCoverageModalContainer,
  [EDIT_ACCIDENT_COVERAGE]: AccidentCoverageModalContainer,
  [EDIT_DENTAL_COVERAGE]: DentalCoverageModalContainer,
  [EDIT_HOSPITAL_INDEMNITY_COVERAGE]: HospitalIndemnityCoverageModalContainer,
  [EDIT_GROUP_COVERAGE]: GroupHealthCoverageModalContainer,
  [EDIT_HEALTH_NEEDS]: EditQuestionsContainer,
  [EDIT_VISION_COVERAGE]: VisionCoverageModalContainer,
  [FSA_INFO]: FSAInformation,
  [FTA_INFO]: FTAInformation,
  [HELP]: HelpModal,
  [LEARN_MORE]: LearnMoreModal,
  [NEW_EXPENSE_REQUEST]: ExpenseRequestModalContainer,
  [EXPENSE_TRANSACTION_HISTORY]: TransactionHistoryModal,
  [EXPENSE_SEND_MESSAGE]: ReimbursementMessageModal,
  [EXPENSE_VIEW]: ViewReimbursementModal,
  [SPLIT_PREVIEW]: SplitPreviewContainer,
  [TERMS_AND_CONDITIONS]: TermsAndConditions,
  [UPLOADER]: Uploader,
  [VIEW_MEDICAL_DETAILS]: ViewMedicalDetails,
  [CLEAR_CART]: ClearCartModal,
  [MEDICARE]: MedicareModal,
}
