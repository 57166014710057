import { connect } from 'react-redux'
import { connectWaiter, getWaiter } from 'redux-waiter'
import { compose } from 'recompose'
import { clearModal } from '@hixme/modal'
import { enrollmentService } from '@hixme/api'
import { BENEFIT_TYPE_HOSPITAL_INDEMNITY } from '@hixme/benefit-types'

// project
import DeclineCoverageModal from 'components/DeclineCoverageModal'
import { createErrorNotification } from 'modules/notification-manager/actions'

// store
import cart from 'store/modules/cart'
import person from 'store/modules/persons'
import userSession from 'store/modules/user-session'

// module
import { constants } from '../modules/hospital-indemnity-coverage'
import { datadog } from 'helpers/datadog'

const mapStateToProps = (state) => {
  const waiter = getWaiter(state, constants.GET_HOSPITAL_INDEMNITY)
  return {
    benefitProducts: (waiter.response && waiter.response.ClientBenefits) || [],
    enrollmentKey: userSession.selectors.getEnrollmentPublicKey(state),
    coveredPersons: [
      person.selectors.getPersonSelector(state),
      ...person.selectors.getDependentsEligibleForBenefits(state),
    ],
    isProcessing: getWaiter(state, cart.constants.CART_HOSPITAL_INDEMNITY_POST).isPending || false,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onDeclineClick: (coveredPersons, benefitProducts) => {
    dispatch(cart.actions.declineHospitalIndemnityProduct(coveredPersons, benefitProducts)).then(
      () => {
        datadog.declineBenefits(BENEFIT_TYPE_HOSPITAL_INDEMNITY, coveredPersons, benefitProducts)
        dispatch(userSession.actions.pushNextBenefitRoute())
      },
      () => {
        dispatch(clearModal())
        dispatch(
          createErrorNotification(
            'There was an error declining hospital indemnity. Please try again.'
          )
        )
      }
    )
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  connectWaiter({
    name: constants.GET_HOSPITAL_INDEMNITY,
    requestCreator: ({ EnrollmentPublicKey, PersonPublicKeys }) =>
      enrollmentService({
        route: '{EnrollmentPublicKey}/client/benefits/{BenefitType}',
        params: {
          EnrollmentPublicKey,
          BenefitType: BENEFIT_TYPE_HOSPITAL_INDEMNITY,
          PersonPublicKeys,
        },
      }),
    requestOnMountParams: ({ enrollmentKey, coveredPersons }) => {
      const keys = coveredPersons.map((i) => i.Id)
      return {
        EnrollmentPublicKey: enrollmentKey,
        PersonPublicKeys: keys,
      }
    },
  })
)(DeclineCoverageModal)
