import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { MrModal } from '@hixme/modal'
import { Row, Col } from 'react-flexbox-grid'

// hixme-ui
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'

import Download from 'components/Download'

// Importing AddBenefitButtonContainer directly to avoid circular dependencies problem
import AddBenefitButtonContainer from '../../containers/AddBenefitButtonContainer'
import DetailsTable from '../../components/DetailsTable/DetailsTable'
import { MAX_FAVORITES } from '../../../../modules/health-bundles/constants'

const DownloadContainer = styled.span`
  margin: 0 0 0 5px;
`

const BundleDetails = ({
  bundle,
  currentGroupIndex,
  groupFavoriteCount,
  handleContinue,
  handleNextGroup,
  onToggleFavorite,
  showFunctionality,
  totalGroupCount,
}) => {
  if (!bundle || Object.keys(bundle) === 0) {
    return null
  }
  const hasNextGroup = currentGroupIndex + 1 !== totalGroupCount
  const handleSaveAndContinue = hasNextGroup ? handleNextGroup : handleContinue

  return (
    <MrModal title="Full Health Plan Details" contentCenter>
      <Container flex direction="column">
        <Container flex padding="20px 0 100px 0" justifyCenter noPadding>
          <DetailsTable
            benefit={bundle}
            onToggleFavorite={onToggleFavorite}
            groupFavoriteCount={groupFavoriteCount}
            showFunctionality={showFunctionality}
          />
          <Container
            noPadding
            height="100%"
            margin="0 20px 0 40px"
            width="auto"
            maxWidth="450px"
            minWidth="395px"
            style={{
              position: 'sticky',
              top: '80px',
            }}
          >
            <Container
              height="60px"
              style={{
                backgroundColor: '#6E8CA4',
                borderRadius: '8px 8px 0 0',
              }}
            >
              <Container flex alignCenter justifyCenter noPadding>
                <Text white>{`${bundle.PlanName}`.toUpperCase()}</Text>
              </Container>
            </Container>
            <Container
              noPadding
              style={{
                border: '2px solid rgba(110, 140, 164, 0.2)',
                backgroundColor: '#F8FAFC',
                borderRadius: '0 0 8px 8px',
              }}
            >
              <Container
                style={{
                  borderBottom: '2px solid rgba(110, 140, 164, 0.2)',
                  backgroundColor: '#F8FAFC',
                }}
              >
                <Row center="xs">
                  <Col sm={6} xs={12}>
                    {bundle.SbcLinks && bundle.SbcLinks[0] && (
                      <Download
                        url={bundle.SbcLinks[0].Url}
                        error={
                          <Text fontSize="11px" blue>
                            Please contact Hixme for more information about this plan
                          </Text>
                        }
                      >
                        {() => (
                          <Text
                            span
                            href={bundle.SbcLinks[0].Url}
                            download
                            cursor="pointer"
                            target="_blank"
                            thin
                          >
                            <Icon size={1.2} blue download />
                            <DownloadContainer>
                              <Text blue>
                                Download <Text light>full details</Text>
                              </Text>
                            </DownloadContainer>
                          </Text>
                        )}
                      </Download>
                    )}
                  </Col>
                  {showFunctionality && (
                    <Col sm={6} xs={12}>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => onToggleFavorite(bundle.BundlePublicKey)}
                      >
                        {groupFavoriteCount === MAX_FAVORITES && (
                          <ReactTooltip id="maxReached" effect="solid">
                            <span>Compare maximum of {MAX_FAVORITES} reached</span>
                          </ReactTooltip>
                        )}
                        <div data-tip data-for="maxReached">
                          <Container flex noPadding>
                            <Icon size={1.5} blue heartOutline />
                            <Container flex margin="0 0 0 5px" noPadding>
                              <Text blue>
                                Save <Text light>current plan</Text>
                              </Text>
                            </Container>
                          </Container>
                        </div>
                      </div>
                    </Col>
                  )}
                  {bundle.SbcLinks && bundle.SbcLinks.find((l) => l.BenefitType === 'Accident') && (
                    <Col sm={6} xs={12}>
                      <Download
                        url={bundle.SbcLinks.find((l) => l.BenefitType === 'Accident').Url}
                        error={
                          <Text fontSize="11px" blue>
                            Please contact Hixme for more information about this plan
                          </Text>
                        }
                      >
                        {() => (
                          <Text
                            span
                            href={bundle.SbcLinks.find((l) => l.BenefitType === 'Accident').Url}
                            download
                            cursor="pointer"
                            target="_blank"
                            thin
                          >
                            <Icon size={1.2} blue download />
                            <DownloadContainer>
                              <Text blue>
                                Download <Text light>accident details</Text>
                              </Text>
                            </DownloadContainer>
                          </Text>
                        )}
                      </Download>
                    </Col>
                  )}
                </Row>
                {bundle.added ? (
                  <Row middle="xs" center="xs" style={{ margin: '16px 0' }}>
                    <Col xs={8}>
                      {showFunctionality && (
                        <AddBenefitButtonContainer bundleId={bundle.BundlePublicKey} />
                      )}
                    </Col>
                    <Col xs={4}>
                      <Text onClick={handleSaveAndContinue} primary cursor="pointer" center>
                        {hasNextGroup ? 'Shop for next group' : 'Save & Continue'}
                      </Text>
                    </Col>
                  </Row>
                ) : (
                  <Row center="xs" style={{ margin: '16px 0' }}>
                    <Col xs={12}>
                      {showFunctionality && (
                        <AddBenefitButtonContainer bundleId={bundle.BundlePublicKey} />
                      )}
                    </Col>
                  </Row>
                )}
                {totalGroupCount > 1 && (
                  <Row center="xs">
                    <Col xs={12}>
                      <Text small light center>
                        Group {currentGroupIndex + 1} of {totalGroupCount}
                      </Text>
                    </Col>
                  </Row>
                )}
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </MrModal>
  )
}

BundleDetails.propTypes = {
  bundle: PropTypes.object,
  currentGroupIndex: PropTypes.number,
  groupFavoriteCount: PropTypes.number,
  handleContinue: PropTypes.func,
  handleNextGroup: PropTypes.func,
  onToggleFavorite: PropTypes.func,
  showFunctionality: PropTypes.bool,
  totalGroupCount: PropTypes.number,
}

BundleDetails.defaultProps = {
  bundle: {},
  currentGroupIndex: 0,
  groupFavoriteCount: 0,
  handleContinue: () => null,
  handleNextGroup: () => null,
  isProcessing: false,
  onToggleFavorite: () => null,
  showFunctionality: true,
  totalGroupCount: 0,
}

BundleDetails.displayName = 'BundleDetails'

export default BundleDetails
