// Disabled for React component arrow method
/* eslint-disable no-undef */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-flexbox-grid'

import { MrModal, clearModal } from '@hixme/modal'
import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'

import { selectors as authSelectors } from '@hixme/auth-ui'
import { ROLE_PLATFORM_HIXME_ADMIN, ROLE_BROKER } from '@hixme/role-policy'

import Container from '@hixme-ui/container'
import Title from '@hixme-ui/title'
import Text from '@hixme-ui/text'
import { Button, Input } from '@hixme-ui/forms'
import Select from '@hixme-ui/select'

import { createSuccessNotification } from '../../../../modules/notification-manager/actions'
import { selectors } from '../../modules'
import { sendExpenseMessage } from '../../modules/actions'
import { SEND_EXPENSE_MESSAGE } from '../../modules/constants'
import classes from './ReimbursementMessageModal.scss'

class ReimbursementMessageModal extends Component {
  constructor() {
    super()
    this.state = {
      message: '',
      reimbursementId: '',
    }
  }

  handleChange = (e) => {
    const { value, name } = e.target
    this.setState({ [name]: value })
  }

  render() {
    return (
      <MrModal>
        <Container flex justifyContent="center">
          <Title>Send Reimbursement Message</Title>
        </Container>
        <Container alignItems="center" flex noPadding margin="50px 0 0 0">
          <Container margin="0 auto" className={classes.FormContainer}>
            <Row>
              <Col xs>
                <Text bold>Which Reimbursement Request?</Text>
              </Col>
              <Col xs>
                <Select
                  placeholder="Select the expense"
                  name="reimbursementId"
                  list={this.props.reimbursements.map((reimbursement) => ({
                    id: reimbursement.id,
                    name: reimbursement.name,
                  }))}
                  value={this.state.reimbursementId}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs>
                <Text bold>Message to Administrator</Text>
              </Col>
              <Col xs>
                <Input
                  textarea
                  value={this.state.message}
                  name="message"
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
          </Container>
        </Container>
        <Container
          flex
          padding="20px 0 0 0"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Button outline onClick={this.props.closeModal}>
            Cancel
          </Button>
          <Button
            primary
            disabled={!this.state.reimbursementId || !this.state.message}
            submittingText="Sending..."
            submitting={this.props.isProcessing}
            onClick={() => {
              this.props.submitMessage(
                {
                  message: this.state.message,
                  reimbursementPublicKey: this.state.reimbursementId,
                },
                this.state.reimbursementId
              )
            }}
            style={{ marginLeft: '15px', padding: '.25em 4em' }}
          >
            Send and Close
          </Button>
        </Container>
      </MrModal>
    )
  }
}

ReimbursementMessageModal.displayName = 'ReimbursementMessageModal'

const mapStateToProps = (state) => {
  const isHixmeAdmin = [ROLE_PLATFORM_HIXME_ADMIN, ROLE_BROKER].indexOf(authSelectors.getRole(state)) > -1
  const isProcessing = getWaiter(state, SEND_EXPENSE_MESSAGE).isPending
  const selectedLifeEvent = selectors.getSelectedLifeEvent(state)
  const reimbursements = selectedLifeEvent.reimbursements
  return {
    isHixmeAdmin,
    isProcessing,
    reimbursements,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(clearModal())
  },
  createSuccessNotification: (message) => {
    dispatch(createSuccessNotification(message))
  },
  submitMessage: (data, id) => {
    dispatch(sendExpenseMessage(data, id))
  },
})

ReimbursementMessageModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  submitMessage: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  reimbursements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReimbursementMessageModal)
