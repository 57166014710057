import React, { useContext, createContext, useState, useEffect } from 'react'
import { motion, MotionProps, AnimatePresence, AnimatePresenceProps } from 'framer-motion'

import { createClasses } from 'design-system/helper'
import { Stack, StackProps } from '../stack/Stack'
import { Button, ButtonProps } from '../button/Button'
import { injectLockScrollStyleSheet, removeLockScrollStyleSheet } from './helper'
import styles from './Modal.module.scss'
import CloseIcon from './close.svg'
import { createPortal } from 'react-dom'

export type ModalAPI = {
  open: () => void
  close: () => void
  toggle: () => void
}

export type ModalProps = {
  /**
   * Modal content. Use `useModal` hook or pass in a fn of the form
   * `({open, close, toggle}) => ReactNode` to access modal control fns.
   */
  children: ((api: ModalAPI) => React.ReactNode) | React.ReactNode
  /**
   * When defined, changes the modal into a controlled component.
   * Must also define `onOpen` and `onClose` prop when in controlled mode.
   */
  visible?: boolean
  /**
   * Sets initial modal visibilty. This does not make the modal into a controlled component.
   */
  defaultVisible?: boolean
  /**
   * Optional React node that serves as the trigger for the modal.
   * Use `useModal` hook or pass in a fn of the form `({open, close, toggle}) => ReactNode` to access
   * modal control fns.
   */
  trigger?: ((api: ModalAPI) => React.ReactNode) | React.ReactNode

  /**
   * Event handler called when modal closes. Must be defined if `visible` is defined.
   */
  onClose?: () => void
  /**
   * Event handler called when modal opens. Must be defined if `visible` is defined.
   */
  onOpen?: () => void
  /**
   * Props passed down to the mask overlay element
   */
  mask?: Omit<StackProps, 'children' | 'onClick'>
  /**
   * Props passed down to the wrapper element around the modal content (`children` prop)
   */
  wrapper?: Omit<StackProps, 'children' | 'onClick'>
  /**
   * Determines if clicking on backdrop will close the modal. Defaults to `true`
   */
  closeOnBackdrop?: boolean
  /**
   * By default, the modal is injected directly underneath the current component in the DOM tree.
   * Passing in a DOM node will set the modal to be appended after the targeted node.
   *
   * I.E. Passing in `document.body` will append the modal as a child to the body tag
   */
  parentNode?: Element | DocumentFragment
  /**
   * Set to `true` to disable scrolling on body when modal is open. Default `true`
   */
  lockScroll?: boolean
  /**
   * The width of the scroll bar in px.
   * This is to offset the body width when scrolling is disabled to prevent layout shifting.
   * Only applicable when `lockScroll` is `true`. Defaults to `10` (I.E. 10px)
   */
  lockScrollWidth?: number
  /**
   * Framer-motion `motion.div` props. Override properties like `transition` here
   */
  motionProps?: MotionProps
  /**
   * Props for `AnimatePresence` from `framer-motion`
   */
  animatePresence?: AnimatePresenceProps
}

const ModalContext = createContext({
  open: () => {},
  close: () => {},
  toggle: () => {},
})

export const useModal = () => useContext(ModalContext)

export const CloseModalButton = (props: Omit<ButtonProps, 'children' | 'onClick'>) => {
  const { close } = useModal()

  return (
    <Button
      variant="ghost"
      onClick={close}
      aria-label="Close modal"
      {...props}
      className={createClasses(styles.modalCloseBtn, props.className)}
    >
      <CloseIcon />
    </Button>
  )
}

/**
 * Just a modal
 */
export const Modal = ({
  visible,
  defaultVisible = false,
  closeOnBackdrop = true,
  children,
  trigger,
  onClose,
  onOpen,
  mask,
  wrapper,
  motionProps,
  animatePresence,
  lockScroll = true,
  lockScrollWidth = 10,
  parentNode,
}: ModalProps) => {
  const [show, setShow] = useState(defaultVisible)

  useEffect(() => {
    if (lockScroll) {
      if (defaultVisible || visible) {
        injectLockScrollStyleSheet(lockScrollWidth)
      } else {
        removeLockScrollStyleSheet()
      }
    }
  }, [lockScroll, defaultVisible, visible])

  const onOpenHandler = () => {
    if (lockScroll) {
      injectLockScrollStyleSheet(lockScrollWidth)
    }
    setShow(true)
    onOpen?.()
  }

  const onCloseHandler = () => {
    if (lockScroll) {
      removeLockScrollStyleSheet()
    }
    setShow(false)
    onClose?.()
  }

  const modalState = typeof visible === 'boolean' ? visible : show

  const api: ModalAPI = {
    open: () => {
      onOpenHandler()
    },
    close: () => {
      onCloseHandler()
    },
    toggle: () => {
      if (modalState) {
        onCloseHandler()
      } else {
        onOpenHandler()
      }
    },
  }

  const ModalElement = (
    <AnimatePresence {...animatePresence}>
      {modalState && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'tween' }}
          style={{ position: 'fixed', zIndex: 99, ...(motionProps?.style ?? {}) }}
          {...motionProps}
        >
          <Stack
            {...mask}
            direction="row"
            onClick={closeOnBackdrop ? api.close : undefined}
            className={createClasses(
              styles.mask,
              closeOnBackdrop ? styles.closableMask : '',
              mask?.className ?? ''
            )}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              ...(mask?.style ?? {}),
            }}
            role="presentation"
          >
            <Stack
              role="dialog"
              direction="col"
              {...wrapper}
              className={createClasses(styles.wrapper, wrapper?.className ?? '')}
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              {typeof children === 'function' ? children(api) : children}
            </Stack>
          </Stack>
        </motion.div>
      )}
    </AnimatePresence>
  )

  return (
    <ModalContext.Provider value={api}>
      {typeof trigger === 'function' ? trigger(api) : trigger ?? null}
      {parentNode ? createPortal(ModalElement, parentNode) : ModalElement}
    </ModalContext.Provider>
  )
}
