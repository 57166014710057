import { createSelector } from 'reselect'
import { getEnrollmentSession } from 'store/modules/user-session/selectors'
import { getBundlePrice, getBundleBenefits, groupAndOrderBundleBenefits } from '@hixme/bundle-utils'
import sortBy from 'lodash/sortBy'
import { NAME } from './constants'
import { getPrescriptions, getBenefitNotes, getNotes } from './helper'

export const getIntentToElectHSA = createSelector(
  getEnrollmentSession,
  (enrollmentSession) => enrollmentSession.IntentToElectHSA || false
)

export const getCurrentGroup = createSelector(
  (state) => state[NAME],
  (healthBundles) => {
    if (healthBundles.currentGroupId) {
      const currentGroup = healthBundles.groups.find(
        (group) => group.id === healthBundles.currentGroupId
      )
      return currentGroup || healthBundles.groups[healthBundles.currentGroupIndex] || {}
    }
    return healthBundles.groups[healthBundles.currentGroupIndex] || {}
  }
)

export const getCurrentGroupIndex = createSelector(
  getCurrentGroup,
  (currentGroup) => currentGroup.index
)

export const getUserEligibility = createSelector(
  (state) => state[NAME],
  (bundles) => {
    return bundles.renewalEligibleGroups
  }
)

export const getCurrentGroupId = createSelector(
  (state) => state[NAME],
  (healthBundles) => healthBundles.currentGroupId
)

export const getTotalGroupCount = createSelector(
  (state) => state[NAME],
  (healthBundles) => healthBundles.groups.length
)

export const getBundleBenefitsCategoryOrder = createSelector(
  (state) => state[NAME],
  (healthBundles) => healthBundles.bundleBenefitsCategoryOrder
)

export const getCurrentGroupBundles = createSelector(
  getCurrentGroup,
  getIntentToElectHSA,
  (currentGroup, intentToElectHSA) => {
    return currentGroup && currentGroup.bundles
      ? Object.keys(currentGroup.bundles).reduce((acc, key) => {
          const bundle = currentGroup.bundles[key]

          const benefits = getBundleBenefits(bundle, intentToElectHSA, false)
          const bundleBenefits = groupAndOrderBundleBenefits(benefits)

          const bundleCoreBenefits = getPrescriptions(
            getBundleBenefits(bundle, intentToElectHSA),
            false
          )
          const bundlePrice = getBundlePrice(bundle, intentToElectHSA)
          const groupPersons = currentGroup.persons
          const prescriptions = getPrescriptions(getBundleBenefits(bundle, intentToElectHSA))

          acc[bundle.BundlePublicKey] = {
            ...bundle,
            bundleBenefits,
            bundleCoreBenefits,
            bundlePrice,
            groupPersons,
            sections: [
              {
                title: 'Benefit Highlights',
                list: getBenefitNotes(bundleCoreBenefits, getNotes(bundleCoreBenefits)),
                label: 'Benefit Highlights',
                key: `${bundle.BundlePublicKey}-Benefit Highlights`,
                showPlanId: false,
                showProofOfResidenceRequireMessage: bundle.ProofOfResidenceRequired,
                notes: getNotes(bundleCoreBenefits),
              },
              {
                title: 'Prescription Drugs',
                list: getBenefitNotes(prescriptions),
                label: 'Prescription Drugs',
                key: `${bundle.BundlePublicKey}-Prescription Drugs`,
                showPlanId: false,
                showProofOfResidenceRequireMessage: false,
                notes: getNotes(prescriptions),
              },
            ],
          }
          return acc
        }, {})
      : {}
  }
)

export const getCurrentGroupBundlesFiltered = createSelector(
  getCurrentGroup,
  ({ filters, bundles, previousBundle }) => {
    const filteredBundles = Object.keys(bundles || []).reduce((acc, key) => {
      const bundle = bundles[key]
      // Carrier filter
      if (filters.carriers.length && !filters.carriers.includes(bundle.CarrierName)) {
        return acc
      }

      // PlanType filter
      if (filters.planTypes.length && !filters.planTypes.includes(bundle.PlanType)) {
        return acc
      }

      // IsHsa filter
      if (filters.hsa === true && !bundle.IsHsa) {
        return acc
      }

      // maxPrice filter
      if (filters.maxPrice && bundle.Price > filters.maxPrice) {
        return acc
      }

      // HixmeValue filter
      if (bundle.HixmeValue > 0 && filters.valueScore > bundle.HixmeValue) {
        return acc
      }

      // Hios filter
      if (filters.hios && bundle.HealthPlanId !== previousBundle.HealthPlanId) {
        return acc
      }

      acc[key] = bundle
      return acc
    }, {})

    return filteredBundles
  }
)

export const getCurrentGroupBundleFilteredCount = createSelector(
  getCurrentGroupBundlesFiltered,
  (currentGroupBundlesFiltered) => Object.keys(currentGroupBundlesFiltered).length
)

export const getCurrentGroupRecommendedStack = createSelector(
  getCurrentGroup,
  (currentGroup) => currentGroup.recommended || ''
)

export const getCurrentGroupPreviousBundle = createSelector(
  getCurrentGroup,
  (currentGroup) => (currentGroup && currentGroup.previousBundle) || null
)

export const getSomeGroupPreviousBundle = createSelector(
  (state) => state[NAME],
  (healthBundles) => healthBundles.groups.some((g) => g.previousBundle)
)

export const getCurrentGroupHasPreviousBundle = createSelector(
  getCurrentGroup,
  (currentGroup) => !!currentGroup.previousBundle
)

export const getCurrentGroupHasHiosMatch = createSelector(
  getCurrentGroupPreviousBundle,
  getCurrentGroupBundles,
  (previousBundle, groupBundles) => {
    if (previousBundle) {
      const hasMatchingHios = Object.values(groupBundles).some(
        (bundle) => bundle.HealthPlanId === previousBundle.HealthPlanId
      )
      return hasMatchingHios
    }
    return false
  }
)

export const getPersonsInCurrentGroup = createSelector(
  getCurrentGroup,
  (currentGroup) => currentGroup.persons || []
)

export const getPersonsInAllGroups = createSelector(
  (state) => state[NAME],
  (healthBundles) => healthBundles.groups.map((g) => g.persons)
)

export const getCurrentGroupBundleCount = createSelector(
  getCurrentGroup,
  (currentGroup) => Object.values(currentGroup.bundles || []).length
)

export const getCurrentGroupFavoriteCount = createSelector(
  getCurrentGroup,
  (currentGroup) => Object.values(currentGroup.bundles || []).filter((b) => b.favorite).length
)

export const getCurrentGroupFavoriteBundles = createSelector(
  getCurrentGroupBundles,
  getIntentToElectHSA,
  (groupBundles) => {
    const faves = Object.values(groupBundles || []).filter((b) => b.favorite)
    return sortBy(faves, 'Price')
  }
)

export const getCurrentGroupFilterOptions = createSelector(
  getCurrentGroup,
  ({ carriers, planTypes = [], maxPrice, hsa, valueScoreMin, valueScoreMax }) => ({
    carriers,
    planTypes: [...planTypes, ...(hsa ? ['HSA'] : [])],
    maxPrice,
    hsa,
    valueScoreMin,
    valueScoreMax,
  })
)

export const getCurrentGroupFilters = createSelector(
  getCurrentGroup,
  ({ filters }) => filters
)

export const getCurrentGroupProviders = createSelector(
  getCurrentGroup,
  ({ providers }) =>
    providers ? Object.keys(providers).map((npi) => ({ ...providers[npi], npi })) : []
)
