import React from 'react'

import Text from '@hixme-ui/text'

const SharedText = () => (
  <Text
    blue
    target="_blank"
    href="http://help.hixme.com/customer/portal/articles/2934792-what-is-an-actuarial-value-score"
  >
    Read more about AV scores in our Help Center
  </Text>
)

SharedText.defaultProps = {}

export default SharedText
