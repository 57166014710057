import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getAllPersons = createSelector(
  (state) => state[NAME],
  (coverage) => coverage
)

export const getSelectedPersons = createSelector(
  (state) => state[NAME],
  (coverage) => coverage.filter((p) => p.selected)
)

export const getUnselectedPersons = createSelector(
  (state) => state[NAME],
  (coverage) => coverage.filter((p) => !p.selected)
)
