/* eslint-disable */
// TODO: Lint cleanup. If you are editing this file, it is your duty to fix all linting errors.
import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import thunk from 'redux-thunk'
import throttle from 'lodash/throttle'
import session from 'modules/session'

import makeRootReducer from './reducers'

export let storeInstance = null

export default (initialState = {}, extras = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [thunk.withExtraArgument(extras)]

  // ======================================================
  // Store Enhancers
  // ======================================================

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    makeRootReducer(),
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  )
  store.asyncReducers = {}

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  store.subscribe(
    throttle(() => {
      session.utils.saveState({
        session: store.getState().session,
      })
    }, 1000)
  )

  storeInstance = store
  return store
}
