import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'
import notification from 'modules/notification-manager'

import {
  getPersonSelector,
  getDependentsEligibleForMedicalBenefits,
} from 'store/modules/persons/selectors'

import { getHealthGroups } from 'store/modules/user-session/selectors'
import {
  getCurrentGroupBundles,
  getCurrentGroupIndex,
  getCurrentGroupProviders,
} from '../selectors'
import { addToCart, removeFromCart, tryToggleFavorite } from '../actions'

const isConfirmedProvider = (npi, allBundleProviders) =>
  allBundleProviders.some((provider) => provider.npi === npi)

const mapStateToProps = (state, { carrierNoticeConfig = {}, ...props }) => {
  const bundle = getCurrentGroupBundles(state)[props.bundleId]
  const person = getPersonSelector(state)
  const dependents = getDependentsEligibleForMedicalBenefits(state)

  const currGroupIndx = getCurrentGroupIndex(state)
  const groups = getHealthGroups(state) || []

  const groupState = groups[currGroupIndx]?.StateProvince || ''
  const isNoticeVisible =
    carrierNoticeConfig[bundle.CarrierName] &&
    carrierNoticeConfig[bundle.CarrierName]?.State?.includes(groupState)
  const bundleProviders = bundle.Providers || []
  const providers = getCurrentGroupProviders(state).map((provider) => ({
    ...provider,
    confirmed: isNoticeVisible ? false : isConfirmedProvider(provider.npi, bundleProviders),
  }))
  const coveredPeopleCount = bundle.groupPersons
    ? bundle.groupPersons.length
    : [person, ...dependents].length
  const isProcessing =
    getWaiter(state, `ADD_TO_CART_${props.bundleId}`).isPending ||
    getWaiter(state, `REMOVE_FROM_CART_${props.bundleId}`).isPending

  return {
    bundle,
    coveredPeopleLabel: `Per paycheck (for ${coveredPeopleCount} ${
      coveredPeopleCount !== 1 ? 'people' : 'person'
    })`,
    isProcessing,
    isNoticeVisible,
    ...(isNoticeVisible && { website: carrierNoticeConfig[bundle.CarrierName]?.Website }),
    providers,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onAddToCart: (BundlePublicKey, qleEnrollmentKey) =>
    dispatch(addToCart(BundlePublicKey, qleEnrollmentKey)).catch((error) => {
      dispatch(
        notification.actions.createNotification({
          type: 'error',
          message: `There was an error adding to Cart: ${error.message}`,
        })
      )
    }),

  onRemoveFromCart: (BundlePublicKey, qleEnrollmentKey) => {
    dispatch(removeFromCart(BundlePublicKey, qleEnrollmentKey)).then(
      () => {},
      (error) => {
        dispatch(
          notification.actions.createNotification({
            type: 'error',
            message: `There was an error removing from Cart: ${error.message}`,
          })
        )
      }
    )
  },

  onToggleFavorite: (BundlePublicKey) => {
    dispatch(tryToggleFavorite(BundlePublicKey))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)
