import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransitionGroup } from 'react-transition-group'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Icon from '@hixme-ui/icon'
import Select from '@hixme-ui/select'
import Input from '@hixme-ui/input'
import FormGroup from '@hixme-ui/form-group'

import formatBytes from '../../../utils'

import classes from '../../styles/transitionStyles.scss'

const File = ({
  documentTypes,
  wrapper,
  handleRemoveFile,
  handleFileTypeChange,
  handleNoteChange,
}) => (
  <div style={{ overflowY: 'hidden' }}>
    <Container
      flex
      direction="column"
      padding="8px"
      background="WhiteSmoke"
      rounded
      margin="0 0 10px 0"
      border="1px solid Gainsboro"
    >
      <Container
        flex
        noPadding
        justifyContent="space-between"
        alignItems="flex-end"
        margin="0 0 10px 0"
      >
        <Text large blue thin>
          {wrapper.file.name}
        </Text>
        <CSSTransitionGroup
          transitionName={{
            enter: classes.enter,
            enterActive: classes.enterActive,
            leave: classes.leave,
            leaveActive: classes.leaveActive,
          }}
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {!wrapper.uploading && (
            <span
              tabIndex="-1"
              role="button"
              style={{ cursor: 'pointer' }}
              onClick={() => handleRemoveFile(wrapper.file.name)}
            >
              <Icon x />
            </span>
          )}
        </CSSTransitionGroup>
      </Container>

      <Container flex noPadding justifyContent="space-between" height="110px">
        <Container flex noPadding width="350px">
          <FormGroup label="What type of file or document is this?" error={wrapper.errorMessage}>
            <Select
              disabled={wrapper.uploading || wrapper.complete}
              list={documentTypes}
              onChange={(event) => handleFileTypeChange(event.target.value, wrapper.file.name)}
              placeholder="Please select type"
              value={wrapper.fileType}
            />
          </FormGroup>
        </Container>
        <Container noPadding flex width="350px">
          <FormGroup label="Add an optional note for this document">
            <Input
              value={wrapper.note}
              onChange={(event) => handleNoteChange(event.target.value, wrapper.file.name)}
              disabled={wrapper.uploading || wrapper.complete}
              textarea
            />
          </FormGroup>
        </Container>
      </Container>

      <progress style={{ width: '100%' }} value={wrapper.progress} max="100" />

      <Container noPadding flex justifyContent="space-between" alignItems="flex-end">
        <Container noPadding flex height="30px">
          {wrapper.uploading && (
            <Text style={{ margin: '10px 10px 0 0' }} primary>
              {wrapper.progress.toFixed(0)}% complete
            </Text>
          )}
          {wrapper.complete && (
            <Text style={{ margin: '10px 0 0 0' }} primary>
              File uploaded successfully.
            </Text>
          )}
          {wrapper.error && (
            <Text style={{ margin: '10px 0 0 0' }} danger>
              File failed to upload. Please check your internet connection try again.
            </Text>
          )}
        </Container>
        <Text small light>
          {formatBytes(wrapper.file.size)}
        </Text>
      </Container>
    </Container>
  </div>
)

File.propTypes = {
  documentTypes: PropTypes.array,
  wrapper: PropTypes.object.isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
  handleFileTypeChange: PropTypes.func.isRequired,
  handleNoteChange: PropTypes.func.isRequired,
}

File.defaultProps = {
  documentTypes: [],
}

export default File
