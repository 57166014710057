import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getAllPersons = createSelector(
  (state) => state[NAME],
  (coverage) => coverage
)

export const getSelectedPersons = createSelector(
  (state) => state[NAME],
  (coverage) =>
    coverage.reduce((prev, curr) => {
      if (curr.selected) {
        prev.push(curr)
      }
      return prev
    }, [])
)

export const getUnselectedPersons = createSelector(
  (state) => state[NAME],
  (coverage) =>
    coverage.reduce((prev, curr) => {
      if (!curr.selected) {
        prev.push(curr)
      }
      return prev
    }, [])
)
