import React from 'react'
import PropTypes from 'prop-types'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Icon from '@hixme-ui/icon'

import HelpNumber from 'components/HelpNumber/HelpNumber'

const Doctors = ({ DoctorInstructionsUrl }) => (
  <Container
    flex
    noPadding
    justifyContent="center"
    alignItems="center"
    width="100%"
    rounded
    padding="20px"
    background="#F8FAFC"
  >
    {DoctorInstructionsUrl.length > 0 && (
      <Container flex noPadding direction="column" alignItems="center" justifyContent="center">
        <div>
          <Icon doctor size={6} light />
        </div>

        <a href={DoctorInstructionsUrl} target="_blank" rel="noopener noreferrer">
          <Text div center blue>
            Doctor Lookup Instructions
          </Text>
        </a>
      </Container>
    )}
    {DoctorInstructionsUrl.length === 0 && (
      <Container flex noPadding direction="column" alignItems="center" justifyContent="center">
        <div>
          <Icon doctor size={3} light />
        </div>
        <Text div center light>
          Contact us if you need help finding plans with a specific in-network doctor
        </Text>
        <Text primary large>
          <HelpNumber />
        </Text>
      </Container>
    )}
  </Container>
)

Doctors.displayName = 'Doctors'

Doctors.propTypes = {
  DoctorInstructionsUrl: PropTypes.string,
}

Doctors.defaultProps = {
  DoctorInstructionsUrl: '',
}

export default Doctors
