import { createReducer } from 'modules/redux-utils'
import t from './actionTypes'
import groupReducer from './group-reducer'
import { STACK_VIEW } from './constants'

// initialState
const initialState = {
  groups: [],
  bundleBenefitsCategoryOrder: [],
  currentGroupIndex: 0,
  currentGroupId: null,
  currentView: STACK_VIEW,
  renewalEligibleGroups: [],
}

export default createReducer(initialState, {
  [t.LOAD_LIST]: (state, payload) => ({
    ...state,
    bundleBenefitsCategoryOrder: payload[0]?.BenefitCategoryOrder || [],
    currentGroupIndex: state.currentGroupIndex ?? initialState.currentGroupIndex,
    groups: payload.map((group) => groupReducer(null, { type: t.LOAD_LIST, payload: group })),
  }),
  [t.LOAD_GROUPS]: (state, { __typename, ...rest }) => {
    const { familyGroups, ...data } = rest
    return {
      ...state,
      familyGroups: {
        ...data,
        groups: familyGroups.filter((group) => group.familyMembers.length > 0),
      },
    }
  },
  [t.UNLOAD_LIST]: (state) => ({
    ...state,
    groups: initialState.groups,
    currentGroupIndex: state.currentGroupIndex ?? initialState.currentGroupIndex,
  }),
  [t.NEXT_GROUP]: (state) => {
    if (state.groups.length === state.currentGroupIndex + 1) return state
    const nextIndexForward = state.currentGroupIndex + 1
    const currentGroupIndex = state.groups[nextIndexForward] ? nextIndexForward : 0
    const currentGroupId = state.groups[currentGroupIndex] && state.groups[currentGroupIndex].id
    return {
      ...state,
      currentGroupIndex,
      currentGroupId,
    }
  },
  [t.PREVIOUS_GROUP]: (state) => {
    if (state.groups.length === state.currentGroupIndex - 1) return state
    const nextIndexBackward = state.currentGroupIndex - 1
    return {
      ...state,
      currentGroupIndex: state.groups[nextIndexBackward] ? nextIndexBackward : 0,
    }
  },
  [t.SET_GROUP_INDEX]: (state, payload) => {
    return { ...state, currentGroupIndex: payload }
  },
  [t.SET_GROUP_ID]: (state, payload) => {
    return {
      ...state,
      currentGroupId: payload,
    }
  },
  [t.SET_GROUPS_RENEWAL_ELIGIBILITY]: (state, payload) => {
    return {
      ...state,
      renewalEligibleGroups: payload,
    }
  },
  [t.BUNDLE_ADDED]: (state, payload) => ({
    ...state,
    groups: state.groups.map((group, idx) =>
      state.currentGroupId === group.id || state.currentGroupIndex === idx
        ? groupReducer(group, { type: t.BUNDLE_ADDED, payload })
        : group
    ),
  }),

  [t.SET_AS_ADDED_BY_GROUP]: (state, { groupId, bundleId }) =>
    Object.assign({}, state, {
      groups: state.groups.map((g, index) =>
        index === groupId ? groupReducer(g, { type: t.BUNDLE_ADDED, payload: bundleId }) : g
      ),
    }),
  [t.BUNDLE_REMOVED]: (state, payload) => ({
    ...state,
    groups: state.groups.map((group, idx) =>
      state.currentGroupId === group.id || state.currentGroupIndex === idx
        ? groupReducer(group, { type: t.BUNDLE_REMOVED, payload })
        : group
    ),
  }),
  [t.TOGGLE_FAVORITE]: (state, payload) =>
    Object.assign({}, state, {
      groups: state.groups.map((g, index) =>
        index === state.currentGroupIndex
          ? groupReducer(g, { type: t.TOGGLE_FAVORITE, payload })
          : g
      ),
    }),
  [t.ADD_FAVORITE]: (state, payload) =>
    Object.assign({}, state, {
      groups: state.groups.map((g, index) =>
        index === state.currentGroupIndex ? groupReducer(g, { type: t.ADD_FAVORITE, payload }) : g
      ),
    }),
  [t.UPDATE_SELECTED_PROVIDERS]: (state, payload) =>
    Object.assign({}, state, {
      groups: state.groups.map((g, index) =>
        index === state.currentGroupIndex
          ? groupReducer(g, { type: t.UPDATE_SELECTED_PROVIDERS, payload })
          : g
      ),
    }),
  [t.REMOVE_USER_FAVORITES]: (state) =>
    Object.assign({}, state, {
      groups: state.groups.map((g, index) =>
        index === state.currentGroupIndex ? groupReducer(g, { type: t.REMOVE_USER_FAVORITES }) : g
      ),
    }),
  [t.TOGGLE_FILTER]: (state, payload) =>
    Object.assign({}, state, {
      groups: state.groups.map((g, index) => {
        return index === state.currentGroupIndex
          ? groupReducer(g, { type: t.TOGGLE_FILTER, payload })
          : g
      }),
    }),
  [t.UPDATE_MATCH_BUNDLE]: (state, payload) =>
    Object.assign({}, state, {
      groups: state.groups.map((g, index) =>
        index === state.currentGroupIndex
          ? groupReducer(g, { type: t.UPDATE_MATCH_BUNDLE, payload })
          : g
      ),
    }),
  [t.UPDATE_MATCH_BUNDLE_BY_HIOS]: (state, payload) =>
    Object.assign({}, state, {
      groups: state.groups.map((g, index) =>
        index === state.currentGroupIndex
          ? groupReducer(g, { type: t.UPDATE_MATCH_BUNDLE_BY_HIOS, payload })
          : g
      ),
    }),
  [t.UPDATE_MAX_COST_FILTER]: (state, payload) =>
    Object.assign({}, state, {
      groups: state.groups.map((g, index) =>
        index === state.currentGroupIndex
          ? groupReducer(g, { type: t.UPDATE_MAX_COST_FILTER, payload })
          : g
      ),
    }),
  [t.RESET_FILTERS]: (state) =>
    Object.assign({}, state, {
      zipCode: initialState.zipCode,
      radius: initialState.radius,
      hospitalList: initialState.hospitalList,
      groups: state.groups.map((g, index) =>
        index === state.currentGroupIndex ? groupReducer(g, { type: t.RESET_FILTERS }) : g
      ),
    }),
  [t.UPDATE_BUNDLES]: (state, payload) =>
    Object.assign({}, state, {
      groups: payload,
    }),
  [t.UPDATE_VALUE_SCORE]: (state, payload) =>
    Object.assign({}, state, {
      groups: state.groups.map((g) => groupReducer(g, { type: t.UPDATE_VALUE_SCORE, payload })),
    }),
  [t.SET_PREVIOUS_BUNDLE_FOR_GROUP]: (state, payload) =>
    Object.assign({}, state, {
      groups: state.groups.map((g, index) =>
        index === payload.groupIndex
          ? groupReducer(g, {
              type: t.SET_PREVIOUS_BUNDLE_FOR_GROUP,
              payload,
            })
          : g
      ),
    }),
})
