import React from 'react'
import PropTypes from 'prop-types'
import { MrModal } from '@hixme/modal'

// Importing CompareTableContainer directly to avoid circular dependencies problem
import CompareTableContainer from '../../containers/CompareTableContainer'

const BundleCompare = ({ currentGroupFavorites }) => (
  <MrModal contentCenter title="Compare favorite plans">
    <CompareTableContainer plans={currentGroupFavorites} />
  </MrModal>
)

BundleCompare.propTypes = {
  currentGroupFavorites: PropTypes.array.isRequired,
}

BundleCompare.displayName = 'BundleCompare'

export default BundleCompare
