import React from 'react'
import PropTypes from 'prop-types'

import Container from '@hixme-ui/container'
import Card from '@hixme-ui/card'
import Icon from '@hixme-ui/icon'
import Button, { OutlineButton } from '@hixme-ui/button'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'
import BasePrice from '@hixme-ui/price'

import CardTabsContainer from 'containers/CardTabsContainer'

import { BundleHighlights, Doctors, Hospitals } from './components/TabContent'

const BundleListItem = ({
  bundle: {
    BundleBenefits,
    BundlePublicKey,
    CarrierName,
    CoveredHospitals,
    DoctorInstructionsUrl,
    IsHsa,
    PlanType,
    Price,

    added,
    // bundleLevel,
    metalLevel,
    favorite,
  },

  onAddToCart,
  onRemoveFromCart,
  onToggleFavorite,
  onViewDetails,

  isProcessing,
  ...restProps
}) => {
  const tabs = [
    {
      name: 'Bundle Highlights',
      content: <BundleHighlights BundleBenefits={BundleBenefits} />,
    },
    {
      name: 'Doctors In-Network',
      content: <Doctors DoctorInstructionsUrl={DoctorInstructionsUrl} />,
    },
    {
      name: 'Participating Hospitals',
      content: <Hospitals CoveredHospitals={CoveredHospitals} />,
    },
  ]

  return (
    <Card heavyShadow rounded width="100%" margin="0 0 20px 0" background="white">
      <Container flex noPadding direction="column">
        <Container flex noPadding justifyContent="space-between">
          <Container flex noPadding>
            <div>
              <Title light small bold>
                {metalLevel}
              </Title>{' '}
              {}
              <Text light larger>
                {CarrierName} Bundle
              </Text>{' '}
              {}
              <br />
              <Text light>{PlanType}</Text> {}
              {IsHsa && <Text light>(HSA Eligible)</Text>}
            </div>
          </Container>

          <Container flex noPadding>
            <Container flex noPadding margin="0 20px 0 0">
              <BasePrice value={Price} />
            </Container>
            <div
              role="button"
              style={{ cursor: 'pointer' }}
              onClick={() => onToggleFavorite(BundlePublicKey)}
            >
              <Icon heartOutline light={!favorite} red={favorite} size={1.5} />
            </div>
          </Container>
        </Container>

        <Container flex noPadding justifyContent="space-between" margin="30px 0 20px 0">
          <Container flex noPadding alignItems="center">
            <Container flex noPadding alignItems="center">
              <Icon document light size={1.25} />
              <div style={{ margin: '0 30px 0 10px' }}>
                <Text onClick={onViewDetails} blue cursor="pointer">
                  View Details
                </Text>{' '}
                {}
              </div>
            </Container>

            <Container flex noPadding alignItems="center">
              <Icon heartOutline light size={1.1} />
              <div style={{ marginLeft: '10px' }}>
                <Text onClick={() => onToggleFavorite(BundlePublicKey)} blue cursor="pointer">
                  {favorite ? 'Compare' : 'Save'}
                </Text>{' '}
                {}
                <Text light>this bundle</Text>
              </div>
            </Container>
          </Container>

          <Container flex noPadding justifyContent="flex-end">
            <div style={{ marginRight: '10px' }}>
              <OutlineButton
                red={favorite}
                blue={!favorite}
                onClick={() => onToggleFavorite(BundlePublicKey)}
              >
                <Container flex noPadding alignItems="center">
                  <Icon heartOutline blue={!favorite} red={favorite} size={1.2} />
                  <div style={{ marginLeft: '10px' }}>
                    {favorite ? 'Remove from Compare List' : 'Add to Compare List'}
                  </div>
                </Container>
              </OutlineButton>
            </div>
            {added ? (
              <Button
                submitting={isProcessing}
                submittingText="Removing from Benefits..."
                danger
                onClick={() => onRemoveFromCart(BundlePublicKey)}
              >
                Remove from My Benefits
              </Button>
            ) : (
              <Button
                submitting={isProcessing}
                submitingText="Adding to Benefits..."
                blue
                onClick={() => onAddToCart(BundlePublicKey)}
              >
                Add to My Benefits
              </Button>
            )}
          </Container>
        </Container>

        <CardTabsContainer list={tabs} {...restProps} />
      </Container>
    </Card>
  )
}

BundleListItem.propTypes = {
  bundle: PropTypes.object,
  onAddToCart: PropTypes.func,
  onRemoveFromCart: PropTypes.func,
  onToggleFavorite: PropTypes.func,
  onViewDetails: PropTypes.func,
  isProcessing: PropTypes.bool,
}

BundleListItem.defaultProps = {
  bundle: {
    BundlePublicKey: '1',
    CarrierName: 'Blue Shield',
    PlanName: 'EPO',
    IsHsa: true,
    Price: 123,
    favorite: true,
    added: false,
    CopayCaps: [],
    DoctorInstructionsUrl: '',
    CoveredHospitals: [],
  },

  onAddToCart: () => {},
  onRemoveFromCart: () => {},
  onToggleFavorite: () => {},
  onViewDetails: () => {},

  isProcessing: false,
}

BundleListItem.displayName = 'BundleListItem'

export default BundleListItem
