import React from 'react'
import BaseIcon from './BaseIcon'

/*eslint-disable */
export default class User extends BaseIcon {
  static defaultProps = {
    width: 18,
    height: 16,
    viewBox: '3 4 18 16'
  }
  render () {
    return (
      <svg {...this.getSvgProps()}>
        <path d='M18.5315342 15.5435102c-1.072356-.4733878-2.6635068-1.6927347-5.0192876-2.111755.6023835-.6404083 1.058548-1.642041 1.5275342-2.8288164.271726-.6874286.2251233-1.2737143.2251233-2.1083266 0-.616653.1171234-1.6060408-.0369862-2.149959C14.708137 4.5071836 13.3951233 4 11.8567398 4c-1.5396165 0-2.8541097.5093878-3.3729042 2.3502857-.1528767.542204-.0355068 1.5293878-.0355068 2.1443265 0 .8365715-.045863 1.4245715.2266027 2.1134694.472685 1.192653.9221918 2.1928164 1.5223562 2.8305306-2.337041.4266123-3.9163562 1.637143-4.9818083 2.1090613C3.0108495 16.5240815 3 17.5930612 3 17.5930612v1.8159184l17.7551507-.002204V17.593061s-.0103562-1.072653-2.2236165-2.049551'
          {...this.getPathProps()} />
      </svg>
    )
  }
}
/*eslint-enable */
