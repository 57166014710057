// Home
export const HOME_PATH = '/'

// Root paths
export const BENEFITS_NAME = 'benefits'
export const BUNDLE_EVENTS_NAME = 'bundle-events'
export const CHECKOUT_NAME = 'checkout'
export const PROFILE_NAME = 'profile'
export const EXPENSES_NAME = 'expenses'
export const PROVIDER_NAME = 'provider'
export const MAIL_ROOM_NAME = 'messages'

// Benefits
export const ACCIDENT_NAME = 'accident'
export const ADDITIONAL_NAME = 'additional'
export const BASIC_LIFE_NAME = 'basic-life'
export const CHIROPRACTIC_NAME = 'chiropractic'
export const CRITICAL_ILLNESS_NAME = 'critical-illness'
export const DCFSA_NAME = 'dcfsa'
export const DENTAL_NAME = 'dental'
export const EMPLOYEE_ASSISTANCE_NAME = 'eap'
export const FEATURE_GATE_NAME = 'feature-gate'
export const FSAMARKETING_NAME = 'fsa-marketing'
export const FSA_NAME = 'fsa'
export const FTA_NAME = 'fta'
export const GROUP_HEALTH_NAME = 'group-health'
export const GROUP_SPLIT_NAME = 'group-split'
export const HEALTH_NAME = 'health-bundles'
export const HEALTH_NAME_GROUP_SPLIT = `${HEALTH_NAME}/${GROUP_SPLIT_NAME}`
export const HOSPITAL_INDEMNITY = 'hospital-indemnity'
export const LEGAL_SHIELD_NAME = 'legal-shield'
export const LEGAL_PLAN_NAME = 'legal'
export const LONG_TERM_DISABILITY_NAME = 'long-term-disability'
export const LPFSA_NAME = 'lpfsa'
export const RENEWAL_NAME = 'renewal'
export const SHORT_TERM_DISABILITY_NAME = 'short-term-disability'
export const SUMMARY_NAME = 'summary'
export const VISION_NAME = 'vision'
export const VOLUNTARY_ACCIDENTAL_DEATH_DISMEMBERMENT_NAME = 'voluntary-accidental-dd'
export const VOLUNTARY_LIFE_NAME = 'voluntary-life'
export const VOLUNTARY_LIFE_SIMPLE_NAME = 'voluntary-life-s'
export const VOLUNTARY_LONG_TERM_DISABILITY_NAME = 'voluntary-long-term-disability'
export const VOLUNTARY_SHORT_TERM_DISABILITY_NAME = 'voluntary-short-term-disability'

// benefits sub-paths
export const NO_BUNDLES_NAME = 'no-bundles'

// profile
export const OVERVIEW_NAME = 'overview'
export const QUESTIONS_NAME = 'questions'
export const VIEW_NAME = 'view'
export const DEPENDENTS_NAME = 'dependents'
export const GROUPS = 'groups'

// Checkout
export const APPLICATION_SIGNATURE_NAME = 'application'
export const ATTESTATIONS_NAME = 'attestations'
export const CONFIRM_NAME = 'confirm'
export const DECLINE_COVERAGE_NAME = 'decline-coverage'
export const DHMO_NAME = 'dhmo'
export const PCP_DISCLOSURE_NAME = 'pcp-disclosure'
export const TERMS_AND_CONDITIONS_NAME = 'terms-and-conditions'
export const THANK_YOU_NAME = 'thank-you'

const setupParentRoute = (route) => (childRoute) => ['', route, childRoute].join('/')

// BENEFIT PATHS - /benefits/*
const benefitRoute = setupParentRoute(BENEFITS_NAME)

export const ACCIDENT_PRODUCTS_PATH = benefitRoute(ACCIDENT_NAME) // accident
export const ADDITIONAL_PRODUCTS_PATH = benefitRoute(ADDITIONAL_NAME) // additional
export const BASIC_LIFE_PRODUCTS_PATH = benefitRoute(BASIC_LIFE_NAME) // basic-life
export const CHIROPRACTIC_PRODUCTS_PATH = benefitRoute(CHIROPRACTIC_NAME) // chiropractic
export const CRITICAL_ILLNESS_PRODUCTS_PATH = benefitRoute(CRITICAL_ILLNESS_NAME) // critical-illness
export const DCFSA_PRODUCTS_PATH = benefitRoute(DCFSA_NAME) // dcfsa
export const DENTAL_PRODUCTS_PATH = benefitRoute(DENTAL_NAME) // dental
export const EMPLOYEE_ASSISTANCE_PRODUCTS_PATH = benefitRoute(EMPLOYEE_ASSISTANCE_NAME) // eap
export const HEALTH_PRODUCTS_PATH = benefitRoute(HEALTH_NAME) // health-bundles
export const HEALTH_PRODUCTS_GROUP_SPLIT_PATH = benefitRoute(HEALTH_NAME_GROUP_SPLIT) // health-bundles/group-split
export const GROUP_HEALTH_PATH = benefitRoute(GROUP_HEALTH_NAME) // group-health
export const BENEFITS_SUMMARY_PATH = benefitRoute(SUMMARY_NAME) // summary

export const FSA_PRODUCTS_PATH = benefitRoute(FSA_NAME) // fsa
export const FTA_PRODUCTS_PATH = benefitRoute(FTA_NAME) // fta
export const HOSPITAL_INDEMNITY_PRODUCTS_PATH = benefitRoute(HOSPITAL_INDEMNITY) // hospital-indemnity
export const LEGAL_SHIELD_PRODUCTS_PATH = benefitRoute(LEGAL_SHIELD_NAME) // legal-shield
export const LEGAL_PLAN_PRODUCTS_PATH = benefitRoute(LEGAL_PLAN_NAME) // legal
export const LPFSA_PRODUCTS_PATH = benefitRoute(LPFSA_NAME) // lpfsa
export const LONG_TERM_DISABILITY_PRODUCTS_PATH = benefitRoute(LONG_TERM_DISABILITY_NAME)
export const SHORT_TERM_DISABILITY_PRODUCTS_PATH = benefitRoute(SHORT_TERM_DISABILITY_NAME)
export const NO_BUNDLES_PATH = benefitRoute(`${HEALTH_NAME}/${NO_BUNDLES_NAME}`)
export const VISION_PRODUCTS_PATH = benefitRoute(VISION_NAME) // vision
export const VOLUNTARY_AD_D_PRODUCTS_PATH = benefitRoute(
  VOLUNTARY_ACCIDENTAL_DEATH_DISMEMBERMENT_NAME
)
export const VOLUNTARY_LIFE_PRODUCTS_PATH = benefitRoute(VOLUNTARY_LIFE_NAME)
export const VOLUNTARY_LIFE_SIMPLE_PRODUCTS_PATH = benefitRoute(VOLUNTARY_LIFE_SIMPLE_NAME)
export const VOLUNTARY_LONG_TERM_DISABILITY_PRODUCTS_PATH = benefitRoute(
  VOLUNTARY_LONG_TERM_DISABILITY_NAME
)
export const VOLUNTARY_SHORT_TERM_DISABILITY_PRODUCTS_PATH = benefitRoute(
  VOLUNTARY_SHORT_TERM_DISABILITY_NAME
)

// PROFILE PATHS - /profile/*
const profileRoute = setupParentRoute(PROFILE_NAME)

export const OVERVIEW_PATH = profileRoute(OVERVIEW_NAME)
export const VIEW_PATH = profileRoute(VIEW_NAME)
export const QUESTIONS_PATH = profileRoute(QUESTIONS_NAME)
export const DEPENDENTS_PATH = profileRoute(DEPENDENTS_NAME)
export const GROUPS_PATH = profileRoute(GROUPS)

// PROVIDER PATHS - /provider/*
const providerRoute = setupParentRoute(PROVIDER_NAME)
export const PROVIDER_LOOKUP_PATH = providerRoute()

// CHECKOUT PATHS - /checkout/*
const checkoutRoute = setupParentRoute(CHECKOUT_NAME)

export const CONFIRM_PATH = checkoutRoute(CONFIRM_NAME) // confirm
export const PCP_DISCLOSURE_PATH = checkoutRoute(PCP_DISCLOSURE_NAME) // pcp-disclosure
export const DHMO_PATH = checkoutRoute(DHMO_NAME) // dhmo
export const DECLINE_COVERAGE_PATH = checkoutRoute(DECLINE_COVERAGE_NAME) // decline-coverage
export const ATTESTATIONS_PATH = checkoutRoute(ATTESTATIONS_NAME) // decline-coverage
export const TERMS_AND_CONDITIONS_PATH = checkoutRoute(TERMS_AND_CONDITIONS_NAME) // terms-and-conditions
export const APPLICATION_SIGNATURE_PATH = checkoutRoute(APPLICATION_SIGNATURE_NAME) // application

// EXPENSES PATHS - /expenses/*
const expenseRoute = setupParentRoute(EXPENSES_NAME)

export const EXPENSES_PATH = expenseRoute()
