import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import uuid from 'uuid/v4'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

const BundlePersonsTooltip = ({ tooltipId, persons }) => (
  <ReactTooltip id={tooltipId} place="bottom" effect="solid">
    <Container flexColumn alignCenter padding="10px">
      {persons.map((person) => (
        <Text key={uuid()}>{person.FullName}</Text>
      ))}
    </Container>
  </ReactTooltip>
)

BundlePersonsTooltip.propTypes = {
  tooltipId: PropTypes.string.isRequired,
  persons: PropTypes.array,
}

BundlePersonsTooltip.defaultProps = {
  persons: [],
}

export default BundlePersonsTooltip
