import React from 'react'

// hixme-ui
import Title from '@hixme-ui/title'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import Modal from 'modules/modal'

const StarRatingsInfo = () => (
  <Container white rounded>
    <Title small>Star Value Ratings Explained</Title>
    <hr />
    <Text p>
      Click on the “Star Value Ratings” section for each bundle to see Hixme’s value rating, which
      assesses how the bundle is optimized across 11 health and treatment scenarios.
    </Text>
    <Text>For example:</Text>
    <ul>
      <li>
        If you see 4 or 5 stars for “surgery in hospital,” this means you’re getting great “bang for
        your buck” with this bundle, in the event that you do have surgery and need to stay
        overnight.
      </li>
      <li>
        If you see only 1 or 2 stars for “having a baby,” there may be better bundles out there for
        someone planning on having a child in 2017.
      </li>
      <li>
        If you see 4 or 5 stars for “healthy,” this bundle is probably a great value for someone who
        doesn’t expect to see the doctor or use their health plan very often.
      </li>
    </ul>
    <Text p>
      NOTE: It’s best to evaluate bundles based on the health/treatment scenarios that most concern
      you. For example, if you’re not planning on having a baby next year, you shouldn’t worry about
      a plan that has a low rating for this scenario! This is a great tool for comparing health
      bundles side-by-side through the “View more bundles” option as well.
    </Text>
  </Container>
)

StarRatingsInfo.displayName = 'StarRatingsInfo'

export default Modal.containers.Modal({
  content: {
    position: 'top',
    size: 'small',
    styles: {
      margin: '50px 0 0 0',
    },
  },
  overlay: {
    hideCloseButton: false,
    routeOnHide: false,
    dark: true,
  },
})(StarRatingsInfo)
