import React from 'react'

import InfoCard from '../InfoCard/InfoCard'
import useFeatureFlags from '../../../../hooks/useFeatureFlags'
import QLEImage from '../assets/QLEImage.png'

/**
 * Home tile card for QLE. This is the dummy view whereas `QLECard` will decide to render this component
 * or not based on feature flag `qle-v-1`
 */

export type QLECardViewProps = {
  blockQleActions: boolean
}

export const QLECardView = ({ blockQleActions }: QLECardViewProps) => {
  return (
    <InfoCard
      Image={QLEImage}
      title="My Qualifying Life Events "
      message={
        blockQleActions
          ? 'My Qualifying Life Events is disabled because you have a current enrollment'
          : 'Life events happen. Tell us about them.'
      }
      url={'/life-events'}
      state={blockQleActions ? 'disabled' : 'completed'}
      badge={
        <span
          style={{
            position: 'absolute',
            right: 16,
            top: 16,
            background: '#1677FF',
            borderRadius: 20,
            color: '#fff',
            fontSize: 11,
            fontWeight: 700,
            padding: '4px 6px',
          }}
        >
          New
        </span>
      }
    />
  )
}

/**
 * Renders `QLECardView` based on feature flag `qle-v-1`
 */

export type QLECardProps = {
  blockQleActions: boolean
}

export const QLECard = ({ blockQleActions }: QLECardProps) => {
  const { flags } = useFeatureFlags()
  if (!flags['qle-v-1']) {
    return null
  }

  return <QLECardView blockQleActions={blockQleActions} />
}
