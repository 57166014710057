import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { setModal } from '@hixme/modal'

import Button from '@hixme-ui/button'

import { UPLOADER } from 'modal-constants'

const LaunchUploader = ({ launchModal, buttonLabel, onUploadsComplete, onUploadsError }) => (
  <Button
    onUploadsComplete={onUploadsComplete}
    onUploadsError={onUploadsError}
    width="250px"
    onClick={launchModal}
  >
    {buttonLabel}
  </Button>
)

const mapDispatchToProps = (dispatch, props) => ({
  launchModal: () => {
    dispatch(setModal(UPLOADER, { ...props }))
  },
})

LaunchUploader.propTypes = {
  launchModal: PropTypes.func.isRequired,
  onUploadsComplete: PropTypes.func.isRequired,
  onUploadsError: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
}

LaunchUploader.defaultProps = {
  buttonLabel: 'Upload files and documents',
}

LaunchUploader.displayName = 'LaunchUploader'

export default connect(
  null,
  mapDispatchToProps
)(LaunchUploader)
