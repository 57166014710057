import React from 'react'
import PropTypes from 'prop-types'
import ProductCard from 'components/ProductCard'

const GroupHealthProductCard = ({
  BenefitPublicKey,
  Name,
  Description,
  Carrier,
  Price,
  IsHsa,
  InNetworkMedicalDeductible,
  OutNetworkMedicalDeductible,
  InNetworkCoinsurance,
  OutOfNetworkCoinsurance,
  InNetworkPaymentLimit,
  OutOfNetworkPaymentLimit,
  SBCUrl,
  PlanType,
  Persons,
  coveredPersons,
  excludedPersons,
  added,
  groupHealthProductOnClick,
  handleViewDetails,
  viewOnly,
  Note,
  Math,
}) => {
  const hasMathWorkerPrice = Math !== undefined && Math.WorkerPrice !== undefined
  const benefitsList = [
    {
      label: 'Carrier',
      value: Carrier,
    },
    {
      label: 'Deductible (in-network)',
      value: InNetworkMedicalDeductible,
    },
    {
      label: 'Deductible (out-of-network)',
      value: OutNetworkMedicalDeductible,
    },
    {
      label: 'Coinsurance (in-network)',
      value: InNetworkCoinsurance,
    },
    {
      label: 'Coinsurance (out-of-network)',
      value: OutOfNetworkCoinsurance,
    },
    {
      label: 'Payment limit per calendar year (in-network)',
      value: InNetworkPaymentLimit,
    },
    {
      label: 'Payment limit per calendar year (out-of-network)',
      value: OutOfNetworkPaymentLimit,
    },
    {
      label: 'Office visit copay (in-network)',
      value: Math.OfficeVisitCopay,
    },
    {
      label: 'Prescription drug copays (tier 1 / tier 2 / tier 3)',
      value: Math.PerscriptionDrugCopays,
    },
  ]

  const handleProductOnClick = () => {
    const groupHealthProduct = {
      BenefitPublicKey,
      Name,
      Description,
      Price,
      IsHsa,
      InNetworkMedicalDeductible,
      OutNetworkMedicalDeductible,
      InNetworkCoinsurance,
      OutOfNetworkCoinsurance,
      InNetworkPaymentLimit,
      OutOfNetworkPaymentLimit,
      Carrier,
      SBCUrl,
      PlanType,
    }
    groupHealthProductOnClick({
      groupHealthProduct,
      coveredPersons,
      excludedPersons,
    })
  }

  const productProps = {
    BenefitPublicKey,
    name: Description,
    price: hasMathWorkerPrice ? Math.WorkerPrice : Price,
    productURL: SBCUrl,
    SBCUrl,
    PlanType,
    benefitsList,
    added,
    Note,
    handleProductOnClick,
    handleViewDetails,
    viewOnly,
    Persons,
  }

  return <ProductCard {...productProps} />
}

GroupHealthProductCard.displayName = 'GroupHealthProductCard'
GroupHealthProductCard.propTypes = {
  BenefitPublicKey: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  Description: PropTypes.string,
  Carrier: PropTypes.string.isRequired,
  Price: PropTypes.number.isRequired,
  IsHsa: PropTypes.bool.isRequired,
  InNetworkMedicalDeductible: PropTypes.string.isRequired,
  OutNetworkMedicalDeductible: PropTypes.string.isRequired,
  InNetworkCoinsurance: PropTypes.string.isRequired,
  OutOfNetworkCoinsurance: PropTypes.string.isRequired,
  InNetworkPaymentLimit: PropTypes.string.isRequired,
  OutOfNetworkPaymentLimit: PropTypes.string.isRequired,
  SBCUrl: PropTypes.string.isRequired,
  PlanType: PropTypes.string.isRequired,
  Persons: PropTypes.array,
  coveredPersons: PropTypes.array.isRequired,
  excludedPersons: PropTypes.array.isRequired,
  added: PropTypes.bool.isRequired,
  groupHealthProductOnClick: PropTypes.func.isRequired,
  handleViewDetails: PropTypes.func,
  viewOnly: PropTypes.bool.isRequired,
  Note: PropTypes.string.isRequired,
  Math: PropTypes.object.isRequired,
}

GroupHealthProductCard.defaultProps = {
  Description: 'My Medical Insurance',
  Note: '',
  Persons: [],
  handleViewDetails: () => null,
  viewOnly: false,
}

export default GroupHealthProductCard
