// TermsAndConditionsCopy - 'copy' as in words, not as in duplicate
import React from 'react'

// hixme-ui
import BulletList from '@hixme-ui/bullet-list'
import Container from '@hixme-ui/container'
import { Row, Col } from 'react-flexbox-grid'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'

const StateTable = () => {
  const stateData = [
    { stateName: 'Alabama', licenseNo: '3001083172' },
    { stateName: 'Alaska', licenseNo: '3001711050' },
    { stateName: 'Arizona', licenseNo: '3001083381' },
    { stateName: 'Arkansas', licenseNo: '3001083380' },
    { stateName: 'California', licenseNo: '6003562' },
    { stateName: 'Colorado', licenseNo: '668829' },
    { stateName: 'Connecticut', licenseNo: '2716517' },
    { stateName: 'D.C.', licenseNo: '3001083361' },
    { stateName: 'Delaware', licenseNo: '3001025204' },
    { stateName: 'Florida', licenseNo: 'L113560' },
    { stateName: 'Georgia', licenseNo: '214962' },
    { stateName: 'Hawaii', licenseNo: '523291' },
    { stateName: 'Idaho', licenseNo: '814992' },
    { stateName: 'Illinois', licenseNo: '3001083184' },
    { stateName: 'Indiana', licenseNo: '3611482' },
    { stateName: 'Iowa', licenseNo: '3001083107' },
    { stateName: 'Kansas', licenseNo: '852326973-000' },
    { stateName: 'Kentucky', licenseNo: '1110676' },
    { stateName: 'Louisiana', licenseNo: '891752' },
    { stateName: 'Maine', licenseNo: 'AGN369141' },
    { stateName: 'Maryland', licenseNo: '3001083426' },
    { stateName: 'Massachusetts', licenseNo: '2042037' },
    { stateName: 'Michigan', licenseNo: '130033' },
    { stateName: 'Minnesota', licenseNo: '40713803' },
    { stateName: 'Mississippi', licenseNo: '15039398' },
    { stateName: 'Missouri', licenseNo: '3001146927' },
  ]
  const stateData2 = [
    { stateName: 'Montana', licenseNo: '3001650161' },
    { stateName: 'Nebraska', licenseNo: '3001083261' },
    { stateName: 'Nevada', licenseNo: '3609755' },
    { stateName: 'New Hampshire', licenseNo: '3001083288' },
    { stateName: 'New Jersey', licenseNo: '3001083267' },
    { stateName: 'New Mexico', licenseNo: '3001084578' },
    { stateName: 'New York', licenseNo: 'LA-14282300' },
    { stateName: 'North Carolina', licenseNo: '3001099174' },
    { stateName: 'North Dakota', licenseNo: '3001083413' },
    { stateName: 'Ohio', licenseNo: '1332212' },
    { stateName: 'Oklahoma', licenseNo: '3001083244' },
    { stateName: 'Oregon', licenseNo: '3001099716' },
    { stateName: 'Pennsylvania', licenseNo: '977307' },
    { stateName: 'Rhode Island', licenseNo: '3001083105' },
    { stateName: 'South Carolina', licenseNo: '3001083284' },
    { stateName: 'South Dakota', licenseNo: '10027735' },
    { stateName: 'Tennessee', licenseNo: '3001083382' },
    { stateName: 'Texas', licenseNo: '2601635' },
    { stateName: 'Utah', licenseNo: '815417' },
    { stateName: 'Vermont', licenseNo: '3609596' },
    { stateName: 'Virginia', licenseNo: '149847' },
    { stateName: 'Washington', licenseNo: '1097604' },
    { stateName: 'West Virginia', licenseNo: '3001083875' },
    { stateName: 'Wisconsin', licenseNo: '3001083282' },
    { stateName: 'Wyoming', licenseNo: '448963' },
  ]

  const thStyles = {
    textAlign: 'left',
  }
  const tdStyles = {
    padding: '2px 20px 2px 0',
  }

  return (
    <Container flex noPadding margin="20px 0 0 0">
      <Row between="xs" style={{ width: '100%' }}>
        <Col xs={12} sm={6}>
          <table style={{ margin: '0 auto 20px auto' }}>
            <thead>
              <tr>
                <th style={thStyles}>STATE</th>
                <th style={thStyles}>LICENSE NO.</th>
              </tr>
            </thead>
            <tbody>
              {stateData.map((currState) => (
                <tr key={`stateData-${currState.stateName}-${currState.licenseNo}`}>
                  <td style={tdStyles}>
                    <Text light thin>
                      {currState.stateName}
                    </Text>
                  </td>
                  <td style={tdStyles}>
                    <Text light thin>
                      {currState.licenseNo}
                    </Text>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
        <Col xs={12} sm={6}>
          <table style={{ margin: '0 auto 20px auto' }}>
            <thead>
              <tr>
                <th style={thStyles}>STATE</th>
                <th style={thStyles}>LICENSE NO.</th>
              </tr>
            </thead>
            <tbody>
              {stateData2.map((currState2) => (
                <tr key={`stateData2-${currState2.stateName}-${currState2.licenseNo}`}>
                  <td style={tdStyles}>
                    <Text light thin>
                      {currState2.stateName}
                    </Text>
                  </td>
                  <td style={tdStyles}>
                    <Text light thin>
                      {currState2.licenseNo}
                    </Text>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  )
}

const TermsAndConditionsCopy = () => {
  const introBulletList = [
    `Your Individual Health Policy establishes a type of health benefits plan, such as HMO coverage or PPO coverage (called “Style of Coverage”).`,
    `The Individual Health Policy also establishes a Network of Hospitals, Physicians, Labs, Imaging Centers and other providers and sites of care.`,
    `Your coverage may require payment in advance and reimbursement later or may free you from some payments in advance.`,
    `For assistance regarding Styles of Coverage, networks, or any prepayment/reimbursement obligations, please contact us at employee.experience@sureco.com.`,
  ]

  const howDoesItWorkBulletList = [
    `Style of Coverage`,
    `Provider coverage`,
    `Price ranges`,
    `Coverage details`,
  ]

  const individualHealthPoliciesBulletList = [
    `Application submission`,
    `Account creation`,
    `Health plan premium payments and auto-pay`,
    `Membership updates`,
    `Healthcare and claims reimbursements`,
  ]

  return (
    <div>
      <Title smaller thin margin="20px 0">
        Introduction
      </Title>
      <Text p light thin>
        Welcome to SureCo’s Enrollment Platform, offered by SureCo Health and Life, Inc. (called
        “SureCo”) to help you learn about and select from a broad array of employer-sponsored health
        coverage options available to you and your eligible dependents (called “Dependents”).
        Through SureCo, which acts as an insurance agent/broker and transaction manager for various
        programs, more personalized health coverage is available to you, based on your individual
        needs and preferences and those of each of your Dependents.
      </Text>
      <Text p light thin>
        Those programs include (1) an individual health insurance policy (called the “Individual
        Health Policy”), which is provided by a licensed health insurance company or health plan and
        may include (2) expense reimbursement for certain qualified health expenses, if offered by
        your employer and in some instances, claims data that we utilize internally for reimbursement
        and coordination of benefits purposes. Keep in mind:
      </Text>
      <BulletList light thin bulletArray={introBulletList} />
      <Title smaller thin margin="20px 0">
        How does the program work?
      </Title>
      <Text p light thin>
        SureCo’s Enrollment Platform facilitates your exploration of health coverage options by
        allowing you to match your family’s needs and preferences with available coverage options
        through a set of filters. These filters enable you to identify preferences in:
      </Text>
      <BulletList light thin bulletArray={howDoesItWorkBulletList} />
      <Text p light thin>
        Then you make an individual insurance coverage plan selection that best works for you among
        those available in your area. It’s your choice to make for you and your family.
      </Text>
      <Text p light thin>
        By using this site to explore your health coverage options, you are agreeing to these Terms
        & Conditions. Please read them carefully.
      </Text>
      <Title smaller thin margin="20px 0">
        SureCo Platform Member Accounts
      </Title>
      <Text p light thin>
        To use SureCo’s services, you must establish a member account that gives you access to our
        services and any functionality that in our sole discretion we may establish and maintain as
        needed.
      </Text>
      <Text p light thin>
        You may never use another user’s account unless you have obtained their written permission.
        You must provide accurate and complete information when setting up a member account, and you
        must keep this information up to date. Your member account on SureCo’s Enrollment Platform
        belongs to you, and you are solely responsible for the activities that occur under your
        account. If you suspect unauthorized use or any breach of security, you are required to
        notify SureCo immediately.
      </Text>
      <Text p light thin>
        SureCo is not liable or responsible in any way for any losses or harm to you or any other
        person or entity caused by any unauthorized use of your account.
      </Text>
      <Text p thin light>
        Your email address listed in your account will be used to send you notices related to
        Enrollment Platform services, including any notices required by law. You consent to the
        electronic disclosure of any and all insurance or other notices, which may include
        compliance-related notices, enrollment information, or additional notifications by us to
        you, through that email address.
      </Text>
      <Title smaller thin margin="20px 0">
        SureCo Health Benefit Coverages (Individual Health Policy and Expense Reimbursement and Claims Data)
      </Title>
      <Text p thin light>
        The SureCo health benefit coverage options, provided as part of your Employer-Sponsored Health Plan,
        are structured with an Individual Health Policy and, if offered by your employer, expense reimbursement
        and claims reimbursement for certain qualified health expenses to help you to minimize, and better
        manage, any out-of-pocket costs for covered services that you may incur under your Individual Health
        Policy coverage (for example, in-network coinsurance, deductibles, etc.).
      </Text>
      <Title smaller thin margin="20px 0">
        Use of Personal Information
      </Title>
      <Text p thin light>
        To access SureCo’s Enrollment Platform website to shop and learn more about your health coverage plan
        options, we need your authorization to retrieve, store, and manage information about you provided by
        your employer, benefits consultant, insurance carriers and providers of care. Please note any
        transmission, storage, or use of your personal information is carried out in compliance with all
        applicable Federal and State laws, including the Health Insurance Portability and Accountability Act
        (commonly referred to as “HIPAA”). To utilize SureCo’s services, you also may be required to provide
        us with all relevant information, signatures, data, passwords, usernames, PINs, and other information,
        materials, and content necessary for us to provide SureCo’s services to you. Collectively, the
        information described in this paragraph is called “SureCo Member Account Information.”
      </Text>
      <Text p thin light>
        We may provide personal information to SureCo employees, consultants, affiliates or other businesses
        or persons for the purpose of processing such information on our behalf in or der to provide the
        services to you. In such circumstances, we require that these parties agree to protect the
        confidentiality of such information consistent with the terms and conditions. Third parties receiving
        personal information and the purposes for such sharing include:
      </Text>
      <BulletList light thin bulletArray={[
        <>
          <Text light thin style={{ textDecoration: 'underline' }}>Service Providers</Text>
          <div style={{ marginBlock: 8 }}>
            <BulletList light thin bulletArray={[
              <Text light thin>
                <Text light thin style={{ textDecoration: 'underline' }}>Payment Processors</Text>:
                Our payment processing partners collect payment card information necessary to process your payment
                insurance premium payments.
              </Text>,
              <Text light thin>
                <Text light thin style={{ textDecoration: 'underline' }}>Security and Fraud Prevention Consultants</Text>:
                Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and
                prosecuting those responsible for that activity.
              </Text>,
              <>
                <Text thin light style={{ textDecoration: 'underline' }}>
                  Hosting, Technology and Communications Providers; Fulfillment Providers; Data Storage Providers;
                  Analytics Providers; Insurance Verification Providers; Staff Augmentation Personnel
                </Text>:
                <div style={{ marginBlock: 4 }}>
                  <BulletList light thin bulletArray={[
                    'To perform operational services (such as hosting, billing, fulfillment, data storage, security, insurance verification, web service analytics) and/or make certain services, features or functionality available to our users.',
                    'Debugging to identify and repair errors that impair existing intended functionality.',
                    'Short-term, transient use of Personal information that is not used by another party to build a consumer profile or otherwise alter your consumer experience outside the current interaction.',
                    'Performing services on our behalf, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing financing, providing advertising or marketing services, providing analytic services, or providing similar services on behalf of the business or service provider.',
                    'Undertaking internal research for technological development and demonstration',
                    'Undertaking activities to verify or maintain the quality or safety of our services.',
                  ]} />
                </div>
              </>
            ]} />
          </div>
        </>,
        <>
          <Text light thin style={{ textDecoration: 'underline' }}>Other Select Recipients</Text>
          <div style={{ marginBlock: 8 }}>
            <BulletList light thin bulletArray={[
              <Text light thin>
                <Text light thin style={{ textDecoration: 'underline' }}>Health Information Exchanges</Text>:
                Health Information Exchanges and related organizations that collect and organize Consumer information
                (such as Regional Health Information Organizations) to make your information more securely and easily
                accessible to your Healthcare Providers. The goal of such organizations is to facilitate access to health
                information to improve the safety, quality, and efficiency of patient-centered care.
              </Text>,
              <Text light thin>
                <Text light thin style={{ textDecoration: 'underline' }}>Healthcare Providers</Text>:
                When applicable, to perform certain services such as medical management or chronic disease management to
                perform analyses on potential health issues or treatments. Your personal information may also be shared
                with Healthcare Providers in the event of an emergency.
              </Text>,
              <Text light thin>
                <Text light thin style={{ textDecoration: 'underline' }}>Insurance Providers</Text>:
                To determine eligibility and cost-sharing obligations, and to otherwise obtain benefit
                plan information on your behalf.
              </Text>,
              <Text light thin>
                <Text light thin style={{ textDecoration: 'underline' }}>Government agencies, courts, or other legal entities</Text>:
                In response to legal process or other requests, where permitted by law.
              </Text>
            ]} />
          </div>
        </>,
        <>
          <Text light thin style={{ textDecoration: 'underline' }}>Parties You Authorize, Access or Authenticate</Text>
          <div style={{ marginBlock: 8 }}>
            <BulletList light thin bulletArray={[
              <Text light thin>
                <Text light thin style={{ textDecoration: 'underline' }}>Third-Party Business Partners You Access Through the Services</Text>:
                We will share certain Personal information if you choose to use any service to log in to the Services, or to meet or fulfill the
                reason you provided the information to us.
              </Text>,
              <Text light thin>
                <Text light thin style={{ textDecoration: 'underline' }}>Other Users</Text>:
                Any information that you may reveal in a review posting or online discussion
                or forum is intentionally open to the public and is not in any way private. We
                recommend that you carefully consider whether to disclose any Personal information
                in any public posting or forum. What you have written may be seen and/or collected
                by third parties and may be used by others in ways we are unable to control or predict.
              </Text>,
            ]} />
          </div>
        </>,
      ]} />
      <Text p thin light>
        Except as described above, we will not share your personal information with other, third-party
        companies for their commercial or marketing use without your consent or except as part of a specific
        program or feature for which you have the ability to opt-in or opt-out.
      </Text>
      <Text p thin light>
        In addition, we may release personal information: (i) to the extent we have a good-faith belief
        that such action is necessary to comply with any applicable law; (ii) to enforce any provision
        of the Terms and Conditions , protect ourselves against any liability, defend ourselves against
        any claims, protect the rights, property and personal safety of any user, or protect the public
        welfare; (iii) when disclosure is required to maintain the security and integrity of the service,
        or to protect any user’s security or the security of other persons, consistent with applicable
        laws (iv) to respond to a court order, subpoena, search warrant, or other legal process, to the
        extent permitted and as restricted by law; (v) in the event that we go through a business transition,
        such as a merger, divestiture, acquisition, liquidation or sale of all or a portion of our assets;
        or (vi) for an additional purpose that is directly related to the original purpose for which the
        personal information was collected.
      </Text>
      <Text p thin light>
        You represent and warrant that the SureCo Member Account Information you provide us is accurate and
        complete, and that you are authorized to submit it to us without any obligations on SureCo, including
        but not limited to the payment of health or other insurance premiums. We have no liability or other
        responsibility for any inaccuracy or incompleteness in the SureCo Member Account Information you provide
        us, or your inability to use SureCo’s services to obtain health or other insurance. You are responsible
        for the consequences of any instructions you provide us and that we follow.
      </Text>
      <Title smaller thin margin="20px 0">
        Insurance Agent / Broker / Producer Services
      </Title>
      <Text p thin light>
        SureCo is licensed as an insurance agent, broker, or producer. If you choose to use SureCo’s
        services to obtain professional advice with respect to any line of insurance that SureCo is
        licensed to transact (called “Insurance Services”), you agree to:
      </Text>
      <Text p thin light>
        (i) Designate SureCo Health and Life, Inc. as your broker or agent of record; (ii) Authorize
        SureCo to communicate such designation as broker or agent of record to any insurance carrier
        and any other person or entity SureCo determines should be advised; (iii) Work exclusively
        with SureCo for such Insurance Services unless we have expressly agreed to collaborate with
        another insurance broker or agent; and (iv) Permit SureCo to receive any commission or other
        form of compensation that any insurance carrier agrees to pay to SureCo in connection with
        the provision of Insurance Services.
      </Text>
      <Text p thin light>
        You acknowledge that you are aware that if you do not choose to make SureCo your broker or
        agent of record for Insurance Services, certain functionality related to administering
        Insurance Services may not be available.
      </Text>
      <Text p thin light>
        SureCo is not an insurance company and does not provide any benefits covered under your
        Individual Health Insurance Policy. Please refer to 'Individual Health Policies' below for
        information regarding the agreement not to cancel or terminate coverage.
      </Text>
      <Text p thin light>
        SureCo Health and Life, Inc., is a licensed broker, agent, or producer of health insurance
        in the states listed below. Our address is: 201 Sandpointe Ave 6th Floor, Santa Ana, CA
        92707.
      </Text>
      <StateTable />
      <Title smaller thin margin="20px 0">
        Legal Jurisdictions Only
      </Title>
      <Text p thin light>
        The insurance and other products and services detailed in SureCo’s Enrollment Platform are
        only offered and available to persons in those states and jurisdictions where such products
        and services may be legally offered or sold. Nothing on the Enrollment Platform should be
        considered a solicitation to buy or an offer to sell any products to any person in any
        jurisdiction where such offer, solicitation, purchase, or sale would be unlawful.
      </Text>
      <Title smaller thin margin="20px 0">
        Federal and State Public Insurance Exchanges and ACA Premium Assistance
      </Title>
      <Text p thin light>
        Insurance options recommended through SureCo’s Enrollment Platform are provided by licensed
        health insurance companies or health plans with whom SureCo is contracted as a licensed
        insurance broker or agent. It does not represent all options available in the individual
        health insurance market, nor does it include options available on any Federal or State
        public insurance exchange. As such, you cannot apply for health insurance premium assistance,
        available under the Patient Protection and Affordable Care Act (“ACA”), through
        the Enrollment Platform and you represent and warrant that you have not and will not apply
        for such health insurance premium assistance in connection with the services made available
        by SureCo.
      </Text>
      <Title smaller thin margin="20px 0">
        Individual Health Policies
      </Title>
      <Text p thin light>
        When you purchase an Individual Health Policy through SureCo’s Enrollment Platform, you are
        entering into an individual agreement with a licensed health insurance company or health plan
        that has its own terms and conditions that you agree to when you enroll for the coverage under
        that Individual Health Policy. You agree that you will not cancel or terminate your Individual
        Health Policy during the time you participate in SureCo’s programs. The terms you agree to on
        this site are supplemented with the terms of your Individual Health Policy. If any term of the
        Individual Health Policy expressly conflicts with any of these Terms & Conditions, any term of
        the Individual Health Policy required by applicable law will take precedence and all remaining
        Terms & Conditions will remain in force.
      </Text>
      <Text p thin light>
        By selecting a plan through SureCo, you agree that SureCo may work with your insurers, and
        in certain circumstances your health insurance plan, on your behalf. This may include
        SureCo’s representatives acting on your behalf to do the following:
      </Text>
      <BulletList light thin bulletArray={individualHealthPoliciesBulletList} />
      <Text p thin light>
        Application submission and membership updates may require SureCo to upload necessary
        documents on your behalf.
      </Text>
      <Title smaller thin margin="20px 0">
        Requirement to Maintain Insurance Premium Payments
      </Title>
      <Text p thin light>
        Because you are agreeing as part of your SureCo health benefits to purchase health insurance
        or coverage directly from a health insurance company or health plan, you are ultimately
        responsible for making certain the requirements of your Individual Health Policy and any
        Individual Health Policies issued to your Dependents are satisfied, including timely payment
        of premium amounts for health insurance coverage. Such payment will be facilitated through
        your employer and SureCo’s Enrollment Platform while you are with your employer. When you
        leave your employer, one of the key values of individual health insurance coverage is that
        you can keep it for as long as you maintain your premium payments and otherwise comply with
        legal requirements. Because your premium payments under the Enrollment Platform arrangement
        are carried out through payroll deduction, if you leave your job and wish to maintain this
        health insurance coverage or the health insurance coverage of your Dependents, to the extent
        possible SureCo will assist you in coordinating the necessary transition to a personal payment
        method of your choice. However, it is ultimately your responsibility to maintain timely payment
        of the health insurance premiums under the Individual Health Policies covering you and your
        Dependents and to maintain those Individual Health Policies in effect.
      </Text>
      <Title smaller thin margin="20px 0">
        No Professional Advice
      </Title>
      <Text p thin light>
        Notwithstanding any other provision of these Terms & Conditions or your participation in the
        programs made available by SureCo, any information that SureCo may provide to you, including
        regarding your health insurance and coverage options, is for informational purposes only and
        should not be construed as professional advice. You first should obtain advice from an
        applicable independent professional, licensed and/or qualified in an applicable area before
        you take any actions related to the information provided to you by SureCo.
      </Text>
      <Text p thin light>
        You are solely responsible for: (i) Compliance with any and all applicable laws, rules, and
        regulations affecting you; and (ii) Any use you may make of SureCo’s services to assist in
        complying with any such laws, rules, or regulations. SureCo’s services do not include any legal,
        regulatory, accounting, or tax advice, and you will rely solely upon your own advisors with
        respect to such advice.
      </Text>
      <Title smaller thin margin="20px 0">
        Entire Agreement
      </Title>
      <Text p thin light>
        This Terms & Conditions agreement (called “Agreement”) together with any amendments and any
        additional agreements you may enter into with SureCo (including the Terms of Use on SureCo’s
        Enrollment Platform website), shall constitute the entire agreement between you and SureCo
        concerning SureCo’s services. If a court of competent jurisdiction deems any provision of
        this Agreement invalid, the invalidity of such provision shall not affect the validity of
        the remaining provisions of this Agreement.
      </Text>
      <Title smaller thin margin="20px 0">
        Changes to SureCo’s Enrollment Platform Terms & Conditions
      </Title>
      <Text p thin light>
        SureCo may, at its discretion, modify or update this Agreement. We will notify you of material
        changes and update the "last modified" date at the bottom of this page. Your continued use of
        SureCo’s services after any such change constitutes your acceptance of the new Terms & Conditions.
        If you do not agree to any of these terms or any future Terms of Use, you may not use or access
        SureCo’s services.
      </Text>
      <Text p thin light>
        This Agreement last modified on November 1, 2024.
      </Text>
    </div>
  )
}

TermsAndConditionsCopy.displayName = 'TermsAndConditionsCopy'

export default TermsAndConditionsCopy
