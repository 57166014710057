import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'

const SignOut = ({ logout }) => (
  <Text light cursor="pointer" onClick={logout}>
    <Icon arrowLeft light size={0.7} /> Sign Out
  </Text>
)

SignOut.propTypes = {
  logout: PropTypes.func.isRequired,
}

export default SignOut
