import { connect } from 'react-redux'
import { connectWaiter, getWaiter } from 'redux-waiter'
import { compose } from 'recompose'
import { clearModal } from '@hixme/modal'
import { enrollmentService } from '@hixme/api'
import { BENEFIT_TYPE_LEGAL_PLAN } from '@hixme/benefit-types'

// project
import DeclineCoverageModal from 'components/DeclineCoverageModal'
import { createErrorNotification } from 'modules/notification-manager/actions'

// store
import cart from 'store/modules/cart'
import person from 'store/modules/persons'
import userSession from 'store/modules/user-session'

import { constants } from '../modules/legal-benefit'

const mapStateToProps = (state) => {
  const waiter = getWaiter(state, constants.GET_LEGAL_ROUTE)
  return {
    enrollmentKey: userSession.selectors.getEnrollmentPublicKey(state),
    benefitProducts: (waiter.response && waiter.response.ClientBenefits) || [],
    coveredPersons: [person.selectors.getPersonSelector(state)],
    isProcessing: getWaiter(state, cart.constants.CART_LEGAL_POST).isPending || false,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onDeclineClick: (coveredPersons, benefitProducts) => {
    dispatch(cart.actions.declineLegalProduct(coveredPersons, benefitProducts)).then(
      () => {
        dispatch(userSession.actions.pushNextBenefitRoute())
      },
      () => {
        dispatch(clearModal())
        dispatch(createErrorNotification('There was an error declining legal. Please try again.'))
      }
    )
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  connectWaiter({
    name: constants.GET_LEGAL_ROUTE,
    requestCreator: ({ EnrollmentPublicKey }) =>
      enrollmentService({
        route: '{EnrollmentPublicKey}/client/benefits/{BenefitType}',
        params: {
          EnrollmentPublicKey,
          BenefitType: BENEFIT_TYPE_LEGAL_PLAN,
        },
      }),
    requestOnMountParams: ({ enrollmentKey }) => ({
      EnrollmentPublicKey: enrollmentKey,
    }),
    onFailure: (request, dispatch) => {
      dispatch(
        createErrorNotification(
          'There was an error getting your legal plan. Please reload the page.'
        )
      )
    },
  })
)(DeclineCoverageModal)
