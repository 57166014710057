import { connect } from 'react-redux'

import { setScrolledToBottom } from '../actions'
import { ScrollDetector } from '../components'

const mapDispatchToProps = (dispatch) => ({
  handleScroll: (event) => {
    const { scrollTop, scrollHeight, offsetHeight } = event.target
    const isScrolledToBottom = scrollTop >= scrollHeight - offsetHeight
    if (isScrolledToBottom) {
      dispatch(setScrolledToBottom())
    }
  },
})

export default connect(
  null,
  mapDispatchToProps
)(ScrollDetector)
