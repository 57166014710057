import React from 'react'

import Container from '@hixme-ui/container'

import Placeholder from 'components/Placeholder'

const ReceitpLineItemLoader = () => (
  <Container flex padding="10px 5px 5px" justifyContent="space-between" width="100%">
    <Placeholder width="200px" height="30px" />
    <Placeholder width="300px" height="30px" />
    <Placeholder width="120px" height="30px" />
  </Container>
)

export default ReceitpLineItemLoader
