/* eslint-disable */
// TODO: Lint cleanup. If you are editing this file, it is your duty to fix all linting errors.
import React from 'react'
import PropTypes from 'prop-types'

const Separator = (props) => {
  const {
    white,
    light,
    grey,

    margin,
    padding,
    style,
    ...restProps
  } = props

  // default styles with black background
  let styles = {
    background: 'black',
    height: '2px',
    margin: '10px 0 20px 0',
    border: 'none',
  }

  if (white) styles.background = 'white'
  if (light) styles.background = '#B9C5CD'
  if (grey) styles.background = '#8F9DA8'

  if (margin) styles.margin = margin
  if (padding) styles.padding = padding

  if (styles) styles = Object.assign(styles, style)

  return <hr style={styles} {...restProps} />
}

Separator.displayName = 'Separator'

Separator.propTypes = {
  white: PropTypes.bool,
  light: PropTypes.bool,
  grey: PropTypes.bool,

  margin: PropTypes.string,
  padding: PropTypes.string,

  style: PropTypes.object,
}

export default Separator
