import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import { FormGroup, Checkbox, Button } from '@hixme-ui/forms'
import Card from '@hixme-ui/card'

// project
import { FieldComponent } from 'components/ReduxFormComponents'

const TermsAndConditionsForm = ({ handleSubmit, submitting, disableForm }) => (
  <form onSubmit={handleSubmit}>
    <Card
      padding="20px 40px"
      margin="0 auto 30px"
      width="100%"
      style={{ opacity: disableForm ? 0.5 : 1 }}
    >
      <FormGroup style={{ marginBottom: '-7px' }}>
        <FieldComponent
          inputComponent={Checkbox}
          text="I agree to the Terms & Conditions."
          name="TermsAndConditionsAgreed"
          disabled={disableForm}
        />
      </FormGroup>
    </Card>
    <div style={{ margin: '0 auto 90px', textAlign: 'center' }}>
      <Button
        submitting={submitting}
        submittingText="Submitting..."
        style={{ paddingLeft: '6em', paddingRight: '6em' }}
        disabled={disableForm}
      >
        Save & continue
      </Button>
    </div>
  </form>
)

TermsAndConditionsForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  disableForm: PropTypes.bool.isRequired,
}

export default TermsAndConditionsForm
