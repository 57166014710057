import t from './actionTypes.js'

export const showModal = () => ({
  type: t.SHOW_MODAL,
})

export const hideModal = () => ({
  type: t.HIDE_MODAL,
})

export const setModalView = (View) => ({
  type: t.SET_MODAL_VIEW,
  payload: View,
})

export const setData = (data) => ({
  type: t.SET_MODAL_DATA,
  payload: data,
})

export const clearModalView = () => ({
  type: t.CLEAR_MODAL_VIEW,
})

export const setViewAndShowModal = (View, data) => (dispatch) => {
  dispatch(setModalView(View))
  dispatch(setData(data))
  dispatch(showModal())
}
