import * as _actions from './actions'
import * as _components from './components'
import * as _constants from './constants'
import _reducer from './reducer'

export const actions = _actions
export const components = _components
export const constants = _constants
export const reducer = _reducer

export default { actions, components, constants, reducer }
