import React from 'react'
import PropTypes from 'prop-types'

import Container from '@hixme-ui/container'
import Phone from '@hixme-ui/phone'
import Text from '@hixme-ui/text'

const CarrierInformationComponent = ({ CarrierWebsite, CarrierCSN, GroupNumber }) => {
  if (!CarrierWebsite && !CarrierCSN && !GroupNumber) return null
  return (
    <Container
      flex
      rounded
      padding="10px"
      margin="10px 0 0 0"
      border="1px solid Gainsboro"
      justifyContent="space-between"
    >
      {CarrierWebsite ? (
        <Text small light a target="_blank" href={CarrierWebsite}>
          Carrier website: <Text blue>{CarrierWebsite}</Text>
        </Text>
      ) : (
        <Text small light>
          No website listed
        </Text>
      )}
      {CarrierCSN ? (
        <Text small light>
          Carrier Customer Service: <Phone light number={CarrierCSN} />
        </Text>
      ) : (
        <Text>No phone number listed</Text>
      )}
      {GroupNumber ? (
        <Text small light>
          Group Number: {GroupNumber}
        </Text>
      ) : (
        <Text small light>
          No Group Number listed
        </Text>
      )}
    </Container>
  )
}

CarrierInformationComponent.displayName = 'CarrierInformationComponent'
CarrierInformationComponent.propTypes = {
  CarrierWebsite: PropTypes.string,
  CarrierCSN: PropTypes.string,
  GroupNumber: PropTypes.string,
}
CarrierInformationComponent.defaultProps = {
  CarrierWebsite: '',
  CarrierCSN: '',
  GroupNumber: '',
}
export default CarrierInformationComponent
