import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

// hixme-ui
import Button from '@hixme-ui/button'
import Container from '@hixme-ui/container'
import { Checkbox } from '@hixme-ui/forms'
import Text from '@hixme-ui/text'
import Separator from '@hixme-ui/separator'

import CurrencyInput from 'components/CurrencyInput/CurrencyInput'
import BundlePlanTypeTooltips from 'routes/Benefits/HealthBundles/components/BundlePlanTypeTooltips/BundlePlanTypeTooltips'
import PresetFiltersTooltip from 'routes/Benefits/HealthBundles/components/BundlePlanTypeTooltips/components/PresetFiltersTooltip'
import FilterOptionCheckbox from '../FilterOptionCheckbox/FilterOptionsCheckbox'
import ProviderBadge from '../ProviderBadge'

import classes from './FilterPanelBody.module.scss'
import ProviderSelector from './components/ProviderSelector/ProviderSelector'
import { useLifeEventContext } from 'routes/LifeEvents/LifeEventsContext'
import { ShopForHealthLog } from '../../../../../../shop-for-health-activity-log/shop-for-health-log'

const FilterPanelBody = ({
  carriers,
  providers,
  handleSubmit,
  handleToggleSelectedProvider,
  selectedProviders,
  hasPreviousBundles,
  currentGroupHasPreviousBundle,
  currentGroupHasHiosMatch,
  filters,
  maxPrice,
  planTypes,
  viewMaxCostLimit,
  viewMinCost,
  filterClick,
  matchBundleFilter,
  matchHiosFilter,
  maxCostFilterChange,
  onProviderSearch,
  isProviderSelectorVisible,
  setIsProviderSelectorVisible,
}) => {
  const logAddProviderClick = () => {
    ShopForHealthLog.addProviderClicked()
  }
  const qleContext = useLifeEventContext()
  return (
    <Container noPadding>
      <Separator size="1px" margin="0" padding="0" background="#DBE2E8" />
      <Container flex background="white" direction="column">
        <Container flex noPadding direction="column">
          {hasPreviousBundles && (
            <Container flex noPadding direction="column" margin="0 0 20px 0">
              <PresetFiltersTooltip
                tooltipId="presetFilters"
                currentGroupHasHiosMatch={currentGroupHasHiosMatch}
              />
              <div style={{ width: '150px' }} data-tip data-for="presetFilters">
                <Text fontWeight="600" medium>
                  Preset Filters
                </Text>
              </div>
              {currentGroupHasHiosMatch && (
                <div style={{ margin: '10px 0 10px 0' }}>
                  <Checkbox
                    checked={filters.hios}
                    disabled={!currentGroupHasPreviousBundle}
                    onChange={(e) => matchHiosFilter(e.target.checked)}
                    text="Find my Current Plan"
                    value={filters.hios}
                  />
                </div>
              )}

              <div style={{ marginTop: '10px' }}>
                <Checkbox
                  disabled={!currentGroupHasPreviousBundle}
                  checked={filters.matchBundle}
                  value={filters.matchBundle}
                  text="Similar to Current Plan"
                  onChange={(e) => matchBundleFilter(e.target.checked)}
                />
              </div>
            </Container>
          )}
        </Container>
        <Container flex noPadding justifyContent="space-between">
          <Row>
            <Col xs={12} md={6}>
              <FilterOptionCheckbox
                data={carriers.map((carrier) => ({
                  ...carrier,
                  checked: !!filters.carriers.includes(carrier),
                  text: carrier,
                  value: carrier,
                }))}
                label="Carriers"
                onChange={filterClick('carriers')}
              />
            </Col>
            <Col xs={6} md={3}>
              <Container flex noPadding direction="column">
                <Container flex noPadding direction="column">
                  <Text fontWeight="600" medium>
                    Plan types
                  </Text>
                  <Container flex noPadding flexWrap>
                    {planTypes.map((planType) => (
                      <div
                        key={`planType-${planType}`}
                        style={{
                          flexBasis: '50%',
                          maxWidth: '100px',
                        }}
                      >
                        <div
                          data-tip
                          data-for={planType}
                          style={{
                            margin: '10px 0 10px 0',
                          }}
                        >
                          <div className={classes.CustomCheckbox}>
                            {planType === 'HSA' ? (
                              <Checkbox
                                checked={!!filters.hsa}
                                onChange={filterClick('hsa')}
                                text="HSA"
                                value="hsa"
                              />
                            ) : (
                              <Checkbox
                                checked={!!filters.planTypes.includes(planType)}
                                onChange={filterClick('planTypes')}
                                text={planType}
                                value={planType}
                              />
                            )}
                          </div>
                        </div>
                        <BundlePlanTypeTooltips
                          IsHsa={planType === 'HSA'}
                          PlanType={planType}
                          tooltipId={planType}
                        />
                      </div>
                    ))}
                  </Container>
                </Container>
              </Container>
            </Col>
            <Col xs={6} md={3}>
              <Container flex noPadding direction="column">
                <Text fontWeight="600" medium>
                  Plan cost
                </Text>
                <Container alignCenter flex noPadding style={{ gap: '5px' }} margin="10px 0 0 0">
                  <Text>Up to</Text>
                  <CurrencyInput
                    style={{ width: '150px' }}
                    defaultValue={maxPrice}
                    max={viewMaxCostLimit}
                    min={viewMinCost}
                    value={maxPrice}
                    onChange={maxCostFilterChange}
                  />
                  <Text>per paycheck</Text>
                </Container>
              </Container>
            </Col>
          </Row>
        </Container>
        {qleContext ? null : (
          <Container flex noPadding marginTop="30px" direction="column" style={{ gap: '10px' }}>
            <Text fontWeight="600" medium>
              My Providers
            </Text>
            <Container flex flexWrap noPadding alignCenter>
              {selectedProviders.map((provider) => (
                <ProviderBadge key={provider.npi} name={provider.fullName} count={provider.count} />
              ))}
              <Button outline style={{ width: '104px', height: '31px', position: 'relative' }}>
                <Text small black onClick={() => {setIsProviderSelectorVisible(true); logAddProviderClick()}}>
                  Add provider
                </Text>
                {isProviderSelectorVisible && (
                  <ProviderSelector
                    handleSubmit={handleSubmit}
                    handleChange={handleToggleSelectedProvider}
                    providers={providers}
                    onProviderSearch={onProviderSearch}
                    onConfirm={() => setIsProviderSelectorVisible(false)}
                    onClickOutside={() => setIsProviderSelectorVisible(false)}
                  />
                )}
              </Button>
            </Container>
          </Container>
        )}
      </Container>
    </Container>
  )
}

FilterPanelBody.propTypes = {
  hasPreviousBundles: PropTypes.bool,
  currentGroupHasPreviousBundle: PropTypes.bool,
  currentGroupHasHiosMatch: PropTypes.bool,
  carriers: PropTypes.arrayOf(PropTypes.string),
  planTypes: PropTypes.arrayOf(PropTypes.string),
  filters: PropTypes.object,
  viewMinCost: PropTypes.number,
  maxPrice: PropTypes.number,
  viewMaxCostLimit: PropTypes.number,
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })
  ),
  selectedProviders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })
  ),
  handleSubmit: PropTypes.func.isRequired,
  handleToggleSelectedProvider: PropTypes.func,
  isProviderSelectorVisible: PropTypes.bool.isRequired,
  setIsProviderSelectorVisible: PropTypes.func.isRequired,
  onProviderSearch: PropTypes.func.isRequired,
  matchBundleFilter: PropTypes.func.isRequired,
  matchHiosFilter: PropTypes.func.isRequired,
  filterClick: PropTypes.func.isRequired,
  maxCostFilterChange: PropTypes.func.isRequired,
}

FilterPanelBody.defaultProps = {
  filters: {},
  hasPreviousBundles: null,
  viewMinCost: null,
  maxPrice: null,
  viewMaxCostLimit: null,
  currentGroupHasPreviousBundle: null,
  currentGroupHasHiosMatch: null,
  carriers: [],
  planTypes: [],
  providers: [],
  selectedProviders: [],
  handleToggleSelectedProvider: () => {},
}

export default FilterPanelBody
