import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

// hixme-ui
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'

import ArrowIcon from './style'

const FilterPanelHeader = ({ currentCount, totalCount, onResetFilters, onToggle, opened }) => (
  <Container background="#FFF" flex height="60px" alignItems="center" padding="0 20px 0 20px">
    <Container width="100%" noPadding>
      <Row middle="xs" between="sm">
        <Col xs={9} sm={6}>
          <Text medium thin>
            Viewing {currentCount} of {totalCount} plans
          </Text>
          <Text
            primary
            cursor="pointer"
            onClick={onResetFilters}
            style={{ marginLeft: '20px', fontSize: '14px' }}
          >
            Reset filters
          </Text>
        </Col>
        <Col xs={3} sm={6}>
          <Container flex noPadding justifyContent="flex-end" style={{ gap: '15px' }}>
            <Text primary cursor="pointer" fontWeight="600" onClick={onToggle}>
              {opened ? 'Hide Filters' : 'Show Filters'}
            </Text>
            <ArrowIcon open={opened}>
              <Icon primary arrowRight size={1} />
            </ArrowIcon>
          </Container>
        </Col>
      </Row>
    </Container>
  </Container>
)

FilterPanelHeader.propTypes = {
  opened: PropTypes.bool.isRequired,
  currentCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default FilterPanelHeader
