import { connect } from 'react-redux'
import DetailsCard from './DetailsCard'

import { actions } from '../../modules/health-bundles'

const mapDispatchToProps = (dispatch) => ({
  onToggleFavorite: (BundlePublicKey) => {
    dispatch(actions.tryToggleFavorite(BundlePublicKey))
  },
})

export default connect(
  () => ({}),
  mapDispatchToProps
)(DetailsCard)
