import React from 'react'

import Container from '@hixme-ui/container'
import Separator from '@hixme-ui/separator'

import Placeholder from 'components/Placeholder'

const LoadingBundleCard = () => (
  <Container noPadding height="710px" background="white">
    <Container flex padding="10px" justifyContent="flex-end">
      <Placeholder width="20px" height="20px" />
    </Container>

    <Container flex noPadding justifyContent="center" margin="10px 0 0 0">
      <Placeholder width="120px" height="50px" />
    </Container>

    <Container flex direction="column" justifyContent="center" padding="20px">
      <Placeholder width="100%" height="20px" margin="5px" />
      <Placeholder width="100%" height="20px" margin="5px" />
    </Container>

    <Separator size="1px" margin="20px 0 0 0" />

    <Container flex padding="20px">
      <Placeholder width="100%" height="20px" />
    </Container>

    <Container flex direction="column" padding="0 10px 0 10px">
      <Placeholder height="15px" margin="10px" />
      <Placeholder height="15px" margin="10px" />
      <Placeholder height="15px" margin="10px" />
      <Placeholder height="15px" margin="10px" />
      <Placeholder height="15px" margin="10px" />
    </Container>

    <Separator size="1px" margin="20px 0 0 0" />

    <Container flex padding="15px 20px 0px 20px">
      <Placeholder width="100%" height="20px" />
    </Container>

    <Separator size="1px" margin="15px 0 0 0" />

    <Container flex padding="15px 20px 0px 20px">
      <Placeholder width="100%" height="20px" />
    </Container>

    <Container flex direction="column" padding="0 20px" margin="40px 0 0 0">
      <Container flex noPadding margin="0 0 10px 0">
        <Placeholder width="40%" height="40px" />
        <Placeholder width="60%" height="40px" margin="0 0 0 10px" />
      </Container>
      <Placeholder width="100%" height="40px" />
    </Container>
  </Container>
)

export default LoadingBundleCard
