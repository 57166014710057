import React from 'react'
import dayjs from 'dayjs'
import { CTABanner } from '@sureco/design-system'
import BlockIcon from 'assets/icons/block.svg'
import { OptOutModal, OptOutModalProps } from './OptOutModal'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useMutation } from '@apollo/client'

import { DECLINE_HEALTH_MEMBER, DECLINE_MEDICARE } from 'apollo/queries'
import { BenefitPerson } from 'types/enrollment'
import { storeInstance } from 'store/createStore'
import { actions as userSessionActions } from 'store/modules/user-session'
import { browserHistory } from 'react-router'

const currentYear = dayjs().year()

export type OptOutEnrollmentProps = {
  enrollmentPublicKey: string
  members: Pick<BenefitPerson, 'Id' | 'FirstName' | 'LastName' | 'MedicareEligible'>[]
  membersEnrolledInMedicare?: Pick<
    BenefitPerson,
    'Id' | 'FirstName' | 'LastName' | 'MedicareEligible'
  >[]
} & OptOutModalProps

/**
 * This is the opt-out view without the feature flag control for storybook demo purposes.
 * `OptOutEnrollment` should be used in actual application as it has the feature flag
 */
export const OptOutEnrollmentView = ({
  enrollmentPublicKey,
  members,
  membersEnrolledInMedicare,
  ...props
}: OptOutEnrollmentProps) => {
  return (
    <OptOutModal
      {...props}
      trigger={({ toggle }) => (
        <CTABanner
          title={`Opt-out for ${currentYear + 1}`}
          description={`From our records we see you opted-out in ${currentYear}`}
          buttonText={`Opt-out ${currentYear + 1}`}
          indicatorIcon={<BlockIcon width="24" height="24" />}
          indicatorStyle={{ backgroundColor: '#F8C9C9' }}
          buttonIcon={<BlockIcon width="16" height="16" />}
          onClick={toggle}
          style={{ marginBottom: 8 }}
        />
      )}
    />
  )
}

export const OptOutEnrollment = (props: OptOutEnrollmentProps) => {
  const { flags } = useFeatureFlags()

  const [declineHealthPlan, { loading }] = useMutation(DECLINE_HEALTH_MEMBER)
  const [declineMedicare, { loading: isMedicareDeclining }] = useMutation(DECLINE_MEDICARE)

  const onConfirmOptOut = async (closeModal: () => void) => {
    // Uhh looks like this API is not uhh... atomic?
    // You can't do Promise.all and it has to be sequential
    // otherwise only the last group will decline
    for (const member of props.members) {
      // declineMedicare is not idempotent. Will error if called on a member that declined
      const isEnrolledInMedicare = props.membersEnrolledInMedicare.find(
        (enrolled) => enrolled.Id === member.Id
      )
      if (member.MedicareEligible && isEnrolledInMedicare) {
        await declineMedicare({
          variables: {
            input: {
              enrollmentPublicKey: props.enrollmentPublicKey,
              personPublicKey: member.Id,
            },
          },
        })
      }

      // no else clause here since you need to decline medicare first then decline health plan
      await declineHealthPlan({
        variables: {
          input: {
            enrollmentPublicKey: props.enrollmentPublicKey,
            personPublicKey: member.Id,
          },
        },
      })
    }

    // make sure skipShop is true, otherwise it will take user to S4H step
    const nextBenefitPath = storeInstance.dispatch(
      userSessionActions.getNextBenefitRoute(undefined, true)
    )

    // reload family groups
    storeInstance.dispatch(userSessionActions.getFamilyGroups())

    closeModal()
    // this should only be true when there are no dental, vision, etc benefits left
    // so instead of staying on the dashboard page, go to cart page
    if (nextBenefitPath === '/') {
      browserHistory.push('/cart')
    } else {
      browserHistory.push(nextBenefitPath)
    }
  }

  if (!flags['product.enrollme.renewals']) {
    return null
  }

  return (
    <OptOutEnrollmentView
      {...props}
      onConfirm={onConfirmOptOut}
      isLoading={isMedicareDeclining || loading}
    />
  )
}
