import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { isDevelopment } from 'modules/app-config/environment'
import pjson from '../../package.json'

const DEV_ENROLLME_GATEWAY_URI = 'https://enrollmegateway.dev.surecolabs.com/graphql'
const PROD_ENROLLME_GATEWAY_URI = 'https://enrollmegateway.docday.com/graphql'
const URI = isDevelopment() ? DEV_ENROLLME_GATEWAY_URI : PROD_ENROLLME_GATEWAY_URI

const httpLink = createHttpLink({
  uri: URI,
})

const authLink = setContext((_, { headers }) => {
  const fullToken = localStorage.getItem('admin-token')
  const { idToken } = JSON.parse(fullToken) || {}
  return {
    headers: {
      ...headers,
      authorization: idToken || '',
    },
  }
})

const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache(),
  name: 'enrollme-admin-web',
  version: pjson.version,
})

export default client
