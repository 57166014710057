import { createRequest } from '@hixme/redux-gateway'
import { callWaiter } from 'redux-waiter'
import difference from 'lodash/difference'
import { BENEFIT_TYPE_HSA } from '@hixme/benefit-types'
import { enrollmentService } from '@hixme/api'
import { clearModal } from '@hixme/modal'

import personModule from 'store/modules/persons'
import notification from 'modules/notification-manager'
import { selectors as userSessionSelectors } from 'store/modules/user-session'

import apolloClient from 'apollo/client'
import { SEND_BENEFIT_ENROLLMENT_EVENT, UPDATE_MEDICARE } from 'apollo/queries'

import * as constants from './constants'
import * as t from './actionTypes'
import * as selectors from './selectors'
import { buildDeclinedPerson, buildIncludedPerson } from './helpers'
import { datadog, CustomActionNames } from 'helpers/datadog'

export const loadCart = (cart) => ({
  type: t.LOAD_CART,
  payload: cart,
})

export const resetCart = () => ({
  type: t.RESET_CART,
})

const postBenefitToCart = (request, sendPersonPublicKeys = true, enrollmentPublicKey) => (
  dispatch,
  getState
) => {
  const { benefitType, benefitProduct, coveredPersons } = request.data
  const PersonPublicKeys = coveredPersons.map((person) => person.Id)
  const BenefitPublicKey = benefitProduct.BenefitPublicKey

  const body = {
    Action: request.action,
    BenefitPublicKey,
    PersonPublicKeys: sendPersonPublicKeys ? PersonPublicKeys : undefined,
  }

  const EnrollmentPublicKey =
    enrollmentPublicKey || userSessionSelectors.getEnrollmentPublicKey(getState())
  return dispatch(
    callWaiter(request.routeName, {
      requestCreator: () =>
        enrollmentService({
          route: request.routeName,
          method: 'PUT',
          params: { EnrollmentPublicKey },
          body,
        }),
    })
  ).then((response) => {
    datadog.addOrRemoveBenefitAction(request.action, benefitType, coveredPersons, benefitProduct)
    return dispatch(loadCart(response.Cart))
  })
}

export const recalculateHealthBenefitsAndGetCart = (enrollmentPublicKey) => async (dispatch, getState) => {
  const EnrollmentPublicKey =
    enrollmentPublicKey || userSessionSelectors.getEnrollmentPublicKey(getState())

  dispatch(
    callWaiter(constants.CART_RECALCULATE_POST, {
      requestCreator: () =>
        enrollmentService({
          route: '{EnrollmentPublicKey}/cart/recalculate',
          method: 'POST',
          params: { EnrollmentPublicKey },
        }),
    })
  )
    .then(({ Cart }) => dispatch(loadCart(Cart)))
    .then((response) => {
      datadog.customAction(CustomActionNames.CartRecalculate, {
        enrollmentPublicKey
      })
    })
    .catch(() =>
      dispatch(
        notification.actions.createNotification({
          type: 'error',
          message: 'Error recalculating and getting cart benefits',
        })
      )
    )
}

// ======== Vision ==========
export const addVisionProduct = ({ visionProduct, coveredPersons, excludedPersons }) =>
  postBenefitToCart({
    routeName: constants.CART_VISION_POST,
    data: { benefitProduct: visionProduct, coveredPersons, excludedPersons },
    action: 'add',
  })

export const removeVisionProduct = ({ visionProduct, coveredPersons, excludedPersons }) =>
  postBenefitToCart({
    routeName: constants.CART_VISION_POST,
    data: { benefitProduct: visionProduct, coveredPersons, excludedPersons },
    action: 'remove',
  })

export const declineVisionProduct = (coveredPersons, benefitProducts, enrollmentPublicKey) =>
  postBenefitToCart(
    {
      routeName: constants.CART_VISION_POST,
      data: { coveredPersons, benefitProduct: benefitProducts[0] },
      action: 'decline',
    },
    true,
    enrollmentPublicKey
  )

export const removeBenefit = (
  benefitType,
  coveredPersons,
  benefit,
  excludedPersons,
  enrollmentKey
) =>
  postBenefitToCart(
    {
      routeName: constants.getBenefitPath(benefitType),
      data: { benefitType, coveredPersons, benefitProduct: benefit, excludedPersons },
      action: constants.REMOVE,
    },
    true,
    enrollmentKey
  )

export const declineBenefit = (
  benefitType,
  coveredPersons,
  benefit,
  excludedPersons,
  enrollmentKey
) =>
  postBenefitToCart(
    {
      routeName: constants.getBenefitPath(benefitType),
      data: { coveredPersons, benefitProduct: benefit, excludedPersons },
      action: constants.DECLINE,
    },
    true,
    enrollmentKey
  )

export const addBenefit = (
  benefitType,
  coveredPersons,
  benefit,
  excludedPersons,
  enrollmentPublicKey
) =>
  postBenefitToCart(
    {
      routeName: constants.getBenefitPath(benefitType),
      data: { benefitType, coveredPersons, benefitProduct: benefit, excludedPersons },
      action: constants.ADD,
    },
    true,
    enrollmentPublicKey
  )

// ======== Legal ==========
export const addLegalProduct = ({ product, coveredPersons, excludedPersons }) =>
  postBenefitToCart(
    {
      routeName: constants.CART_LEGAL_POST,
      data: { benefitProduct: product, coveredPersons, excludedPersons },
      action: 'add',
    },
    false
  )

export const removeLegalProduct = ({ product, coveredPersons, excludedPersons }) =>
  postBenefitToCart(
    {
      routeName: constants.CART_LEGAL_POST,
      data: { benefitProduct: product, coveredPersons, excludedPersons },
      action: 'remove',
    },
    false
  )

export const declineLegalProduct = (coveredPersons, benefitProducts) =>
  postBenefitToCart(
    {
      routeName: constants.CART_LEGAL_POST,
      data: { coveredPersons, benefitProduct: benefitProducts[0] },
      action: 'decline',
    },
    false
  )

// ======== Critical Illness ==========

export const addCriticalIllnessProduct = ({
  criticalIllnessProduct,
  coveredPersons,
  excludedPersons,
}) =>
  postBenefitToCart({
    routeName: constants.CART_CRITICAL_ILLNESS_POST,
    data: {
      benefitProduct: criticalIllnessProduct,
      coveredPersons,
      excludedPersons,
    },
    action: 'add',
  })

export const removeCriticalIllnessProduct = ({
  criticalIllnessProduct,
  coveredPersons,
  excludedPersons,
}) =>
  postBenefitToCart({
    routeName: constants.CART_CRITICAL_ILLNESS_POST,
    data: {
      benefitProduct: criticalIllnessProduct,
      coveredPersons,
      excludedPersons,
    },
    action: 'remove',
  })

export const declineCriticalIllnessProduct = (coveredPersons, benefitProducts) =>
  postBenefitToCart({
    routeName: constants.CART_CRITICAL_ILLNESS_POST,
    data: { benefitProduct: benefitProducts[0], coveredPersons },
    action: 'decline',
  })

// ======== Accident ==========

export const addAccidentProduct = ({ accidentProduct, coveredPersons, excludedPersons }) =>
  postBenefitToCart({
    routeName: constants.CART_ACCIDENT_POST,
    data: {
      benefitProduct: accidentProduct,
      coveredPersons,
      excludedPersons,
    },
    action: 'add',
  })

export const removeAccidentProduct = ({ accidentProduct, coveredPersons, excludedPersons }) =>
  postBenefitToCart({
    routeName: constants.CART_ACCIDENT_POST,
    data: {
      benefitProduct: accidentProduct,
      coveredPersons,
      excludedPersons,
    },
    action: 'remove',
  })

export const declineAccidentProduct = (coveredPersons, benefitProducts) =>
  postBenefitToCart({
    routeName: constants.CART_ACCIDENT_POST,
    data: { benefitProduct: benefitProducts[0], coveredPersons },
    action: 'decline',
  })

// ======== Hospital Indemnity ==========

export const addHospitalIndemnityProduct = ({
  hospitalIndemnityProduct,
  coveredPersons,
  excludedPersons,
}) =>
  postBenefitToCart({
    routeName: constants.CART_HOSPITAL_INDEMNITY_POST,
    data: {
      benefitProduct: hospitalIndemnityProduct,
      coveredPersons,
      excludedPersons,
    },
    action: 'add',
  })

export const removeHospitalIndemnityProduct = ({
  hospitalIndemnityProduct,
  coveredPersons,
  excludedPersons,
}) =>
  postBenefitToCart({
    routeName: constants.CART_HOSPITAL_INDEMNITY_POST,
    data: {
      benefitProduct: hospitalIndemnityProduct,
      coveredPersons,
      excludedPersons,
    },
    action: 'remove',
  })

export const declineHospitalIndemnityProduct = (coveredPersons, benefitProducts) =>
  postBenefitToCart({
    routeName: constants.CART_HOSPITAL_INDEMNITY_POST,
    data: { benefitProduct: benefitProducts[0], coveredPersons },
    action: 'decline',
  })

// ======== HSA ==========

const removeHsaFromCart = () => (dispatch, getState) => {
  const state = getState()
  const hsa = state.Cart.cart.find((benefit) => benefit.BenefitType === BENEFIT_TYPE_HSA)
  const hsaBenefit = hsa.Benefits[0]
  const BenefitPublicKey = hsaBenefit.BenefitPublicKey
  const AnnualElection = hsaBenefit.AnnualElection
  const EnrollmentPublicKey = userSessionSelectors.getEnrollmentPublicKey(getState())
  return dispatch(
    callWaiter('cartHsaPost', {
      requestCreator: () =>
        enrollmentService({
          route: '{EnrollmentPublicKey}/cart/benefits/HealthSavingsAccount',
          method: 'PUT',
          params: { EnrollmentPublicKey },
          body: {
            BenefitPublicKey,
            Action: 'remove',
            AnnualElection,
          },
        }),
    })
  )
}

const addGroupHealth = ({ groupHealthProduct, coveredPersons, excludedPersons }) =>
  postBenefitToCart({
    routeName: constants.CART_GROUPHEALTH_POST,
    data: {
      benefitProduct: groupHealthProduct,
      coveredPersons,
      excludedPersons,
    },
    action: 'add',
  })

export const addGroupHealthProduct = ({ groupHealthProduct, coveredPersons, excludedPersons }) => (
  dispatch,
  getState
) =>
  dispatch(addGroupHealth({ groupHealthProduct, coveredPersons, excludedPersons })).then(() => {
    const hsaBenefit = selectors.getHSABenefitProduct(getState())
    if (hsaBenefit) dispatch(removeHsaFromCart())
  })

const removeGroupHealth = ({ groupHealthProduct, coveredPersons, excludedPersons }) =>
  postBenefitToCart({
    routeName: constants.CART_GROUPHEALTH_POST,
    data: {
      benefitProduct: groupHealthProduct,
      coveredPersons,
      excludedPersons,
    },
    action: 'remove',
  })

export const removeGroupHealthProduct = ({
  groupHealthProduct,
  coveredPersons,
  excludedPersons,
}) => (dispatch, getState) =>
  dispatch(removeGroupHealth({ groupHealthProduct, coveredPersons, excludedPersons })).then(() => {
    const hsaBenefit = selectors.getHSABenefitProduct(getState())
    if (hsaBenefit) dispatch(removeHsaFromCart())
  })

const declineGroupHealth = (coveredPersons, benefitProducts) =>
  postBenefitToCart({
    routeName: constants.CART_GROUPHEALTH_POST,
    data: { benefitProduct: benefitProducts[0], coveredPersons },
    action: 'decline',
  })

export const declineGroupHealthProduct = (coveredPersons, benefitProduct) => (dispatch, getState) =>
  dispatch(declineGroupHealth(coveredPersons, benefitProduct)).then(() => {
    const hsaBenefit = selectors.getHSABenefitProduct(getState())
    if (hsaBenefit) dispatch(removeHsaFromCart())
  })

export const addToCart = (bundle, declinedPersons, index) => ({
  type: t.ADD_HEALTH_BUNDLE,
  payload: { bundle, declinedPersons, index },
})

export const addHealthBundle = (bundle) => (dispatch, getState) => {
  const saveBundle = Object.assign({}, bundle)
  saveBundle.Name = saveBundle.PlanName

  const bundlePersonsIds = saveBundle.Persons.map((person) => person.Id)
  const cartPersonsIds = selectors.getPersonIdsInHealthProducts(getState())

  const employeeId = personModule.selectors.getEmployeeKey(getState())
  const dependentsIds = personModule.selectors.getDependentsKeys(getState())
  const familyIds = [employeeId, ...dependentsIds]
  const addedIds = [...bundlePersonsIds, ...cartPersonsIds]

  const excludedPersonsIds = difference(familyIds, addedIds)
  const excludedPersonsArray = excludedPersonsIds.map((id) =>
    personModule.selectors.getPersonById(id, getState())
  )

  const index = selectors.getCartIndex(bundlePersonsIds, getState())

  const includedPersonsArray = saveBundle.Persons.map(buildIncludedPerson)

  const declinedPersons = excludedPersonsArray.map(buildDeclinedPerson)

  saveBundle.Persons = includedPersonsArray
  dispatch(addToCart(saveBundle, declinedPersons, index))
}

export const removeBundle = (bundle, declinedPersons) => ({
  type: t.REMOVE_HEALTH_BUNDLE,
  payload: { bundle, declinedPersons },
})

export const removeHealthBundle = (bundle) => (dispatch, getState) => {
  const state = getState()
  const cartCount = selectors.getBundleCartCount(state)
  let declinedPersons = []
  if (cartCount === 1) {
    const allPersons = [
      personModule.selectors.getPersonSelector(state),
      ...personModule.selectors.getDependents(state),
    ]
    declinedPersons = allPersons.map(buildDeclinedPerson)
  } else {
    declinedPersons = bundle.Persons.map(buildDeclinedPerson)
  }

  dispatch(removeBundle(bundle, declinedPersons))
}

export const declineBundle = (persons, removeBenefitIndex) => {
  const declinedPersons = persons.map(buildDeclinedPerson)
  return {
    type: t.DECLINE_HEALTH_BUNDLE,
    payload: { declinedPersons, removeBenefitIndex },
  }
}

export const declineHealthBundle = (persons) => (dispatch, getState) => {
  const personIds = persons.map((person) => person.Id)
  const removeBenefitIndex = selectors.getHealthBenefitIndexByPersons(personIds, getState())
  dispatch(declineBundle(persons, removeBenefitIndex))
}

export const removeAllHealthBundles = () => ({
  type: t.REMOVE_ALL_HEALTH_BUNDLES,
})

export const getCartByEnrollmentPublicKey = (enrollmentPublicKey) => async (dispatch, getState) => {
  const EnrollmentPublicKey =
    enrollmentPublicKey || userSessionSelectors.getEnrollmentPublicKey(getState())
  dispatch(
    callWaiter(constants.CART_GET, {
      requestCreator: () =>
        enrollmentService({
          route: '{EnrollmentPublicKey}/cart',
          method: 'GET',
          params: {
            EnrollmentPublicKey,
          },
        }),
    })
  )
    .then(({ Cart }) => dispatch(loadCart(Cart)))
    .catch(() =>
      dispatch(
        notification.actions.createNotification({
          type: 'error',
          message: 'Error getting cart benefits',
        })
      )
    )
}
export const getCartByEmployeePublicKey = (key) => (dispatch, getState) => {
  const employeePublicKey = !key ? userSessionSelectors.getUserId(getState()) : key
  const req = dispatch(
    createRequest({
      route: constants.CART_GET_ROUTE,
      params: {
        employeePublicKey,
      },
    })
  )

  req.then((response) => {
    dispatch(loadCart(response))
  })

  return req
}

export const addPrimaryCarePhysician = ({ HealthPlanId, PrimaryCareProvider }) => ({
  type: t.ADD_PRIMARY_CARE_PHYSICIAN,
  payload: { HealthPlanId, PrimaryCareProvider },
})

export const addDentalPrimaryCareProvider = (providerNumber) => ({
  type: t.ADD_DENTAL_PRIMARY_CARE_PROVIDER,
  payload: providerNumber,
})

export const markPdfApplicationsAsManual = () => ({
  type: t.MARK_PDF_APPLICATIONS_AS_MANUAL,
})

export const markPdfApplicationAsComplete = ({ personPublicKey, envelopeId }) => ({
  type: t.MARK_PDF_APPLICATION_AS_COMPLETE,
  payload: { personPublicKey, envelopeId },
})

export const saveSignatureStatus = (ApplicationId) => (dispatch, getState) => {
  const EmployeePublicKey = userSessionSelectors.getUserId(getState())
  const req = dispatch(
    createRequest({
      route: constants.CART_SAVE_SIGNATURE_ROUTE,
      body: {
        EmployeePublicKey,
        ApplicationId,
      },
    })
  )

  req.then(
    () => {
      dispatch(getCartByEmployeePublicKey(EmployeePublicKey)).then((response) =>
        dispatch(loadCart(response))
      )
    },
    () => {
      dispatch(notification.actions.createErrorNotification('Error saving signature status'))
    }
  )

  return req
}

export const saveManualSignatureStatus = () => (dispatch, getState) => {
  const EmployeePublicKey = userSessionSelectors.getUserId(getState())
  const req = dispatch(
    createRequest({
      route: constants.CART_SAVE_MANUAL_SIGNATURE_ROUTE,
      body: {
        EmployeePublicKey,
      },
    })
  )

  req.then(
    () => {
      dispatch(getCartByEmployeePublicKey(EmployeePublicKey)).then((response) =>
        dispatch(loadCart(response))
      )
    },
    () => {
      dispatch(notification.actions.createErrorNotification('Error saving cart'))
    }
  )

  return req
}

export const saveCart = () => (dispatch, getState) => {
  const req = dispatch(
    createRequest({
      route: constants.CART_POST_ROUTE,
      body: getState()[constants.NAME].cart,
    })
  )

  req.then(
    (currentCart) => {
      dispatch(loadCart(currentCart))
    },
    () => {
      dispatch(notification.actions.createErrorNotification('Error saving cart'))
    }
  )

  return req
}

export const deleteCart = () => (dispatch, getState) => {
  const enrollmentKey = userSessionSelectors.getEnrollmentPublicKey(getState())

  return dispatch(
    callWaiter('DELETE_CART', {
      requestCreator: () =>
        enrollmentService({
          route: '{enrollmentKey}/cart',
          method: 'DELETE',
          params: {
            enrollmentKey,
          },
        }),
    })
  )
    .then(async (res) => {
      dispatch(loadCart(res.Cart))
    })
    .catch(() => dispatch(notification.actions.createErrorNotification('Error deleting cart')))
}

export const setEnrollmentDigitalMail = (enableDigitalMail) => (dispatch, getState) => {
  const enablePath = enableDigitalMail ? 'enable' : 'disable'
  const enrollmentKey = userSessionSelectors.getEnrollmentPublicKey(getState())

  return dispatch(
    callWaiter('DIGITAL_MAIL', {
      requestCreator: () =>
        enrollmentService({
          route: `{enrollmentKey}/digitalmail/${enablePath}`,
          method: 'POST',
          params: {
            enrollmentKey,
          },
        }),
    })
  )
    .then((res) => {
      dispatch(loadCart(res.Cart))
    })
    .catch(() =>
      dispatch(
        notification.actions.createErrorNotification(
          'Error updating enrollment digital mail setting'
        )
      )
    )
}
//
export const sendBenefitEnrollmentEvent = (enrollmeEventType) => async (dispatch, getState) => {
  const enrollmentKey = userSessionSelectors.getEnrollmentPublicKey(getState())

  apolloClient
    .mutate({
      mutation: SEND_BENEFIT_ENROLLMENT_EVENT,
      variables: {
        enrollMeBenefitEventHandlerInput: {
          enrollmentPublicKey: enrollmentKey,
          enrollmeEventType,
          eventDate: new Date(),
        },
      },
    })
    .then((data) => ({
      type: 'SEND_BENEFIT_ENROLLMENT_EVENT',
      payload: data,
    }))
    .catch(() => {
      dispatch(
        notification.actions.createErrorNotification('Error sending benefit enrollment event')
      )
    })
}

export const UpdateMedicare = (medicare) => async (dispatch, getState) => {
  apolloClient
    .mutate({
      mutation: UPDATE_MEDICARE,
      variables: {
        input: {
          personId: medicare.personId,
          enrollmentPublicKey: medicare.enrollmentPublicKey,
          medicareNumber: medicare.medicareNumber,
          documents: medicare.documents.map((item) => item.key),
          medicareCoverage: medicare.medicareCoverage.map((item) => {
            return {
              ...item,
              premium: +item.premium,
              insuredId: medicare.insuredId,
              insuredName: medicare.insuredName,
            }
          }),
        },
      },
    })
    .then((data) => {
      return {
        type: 'UPDATE_MEDICARE',
        payload: data,
      }
    })
    .finally(async () => {
      dispatch(
        notification.actions.createNotification({
          type: 'success',
          autoClose: true,
          message: `Medicare was successfully updated`,
        })
      )
      await apolloClient.refetchQueries({
        include: ['GetMedicareCoverage'],
      })
      dispatch(clearModal())
    })
}
