/* eslint-disable import/prefer-default-export */
import { personService } from '@hixme/api'

export function getFamilyByEmployeeId(id) {
  return personService({
    route: `persons/${id}/family`,
  })
}

export function getPersonProviderById(id) {
  return personService({
    route: `persons/${id}/providers`,
  })
}
