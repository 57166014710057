import styled from 'styled-components'

const Border = styled.div`
  height: 1px;
  background-image: linear-gradient(to right, #b9c5cd 40%, rgba(255, 255, 255, 0) 20%);
  background-position: top;
  background-size: 10px 1px;
  background-repeat: repeat-x;
`
export default Border
