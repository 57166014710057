import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getExpenseRequests = createSelector(
  (state) => state[NAME],
  (expenseRequests) => expenseRequests.expenses
)

export const getFamilyMembers = createSelector(
  (state) => state[NAME],
  (expenseRequest) => expenseRequest.familyBenefits
)

export const getSelectedLifeEvent = createSelector(
  (state) => state[NAME],
  (expenseRequests) => expenseRequests.selectedLifeEvent
)

export const getSelectedExpense = createSelector(
  (state) => state[NAME],
  (expenseRequests) => expenseRequests.selectedExpense
)

export const getLineItemsReceipts = createSelector(
  (state) => state[NAME],
  (expenseRequests) => expenseRequests.lineItems
)

export const getTotalAmountRequested = (id, state) =>
  createSelector(
    () => state[NAME],
    (expenseRequest) => {
      if (!id || !expenseRequest.receipts[id]) return 0
      const { data } = expenseRequest.receipts[id]
      return data.reduce((prev, curr) => {
        const total = prev + Number(curr.amount)
        return total
      }, 0)
    }
  )(state)

export const getSelectedExpenseId = createSelector(
  (state) => state[NAME],
  (expenseRequests) => expenseRequests.selectedExpenseId
)

export const getUiConfig = createSelector(
  (state) => state[NAME],
  (expenseRequests) => expenseRequests.ui
)

export const getExpenseReceipts = (id, state) =>
  createSelector(
    () => state[NAME],
    (expenseRequest) => expenseRequest.receipts[id]
  )(state)

export const getRecordPointer = createSelector(
  (state) => state[NAME],
  (temp) => temp.recordPointer
)

export const getSelectedYear = createSelector(
  (state) => state[NAME],
  (temp) => temp.year
)

export const hasMore = createSelector(
  getSelectedLifeEvent,
  getRecordPointer,
  (lifeEvent, recordPointer) => lifeEvent && lifeEvent.reimbursements.length > recordPointer
)

export const isExpenseAvailable = createSelector(
  (state) => state[NAME],
  (expenseRequests) => expenseRequests.available
)

export const getExpenseReimbursementData = createSelector(
  (state) => state[NAME],
  (expenseRequests) => ({
    selectedLifeEvent: expenseRequests.selectedLifeEvent,
    selectedExpense: expenseRequests.selectedExpense,
    ui: expenseRequests.ui,
    expenseId: expenseRequests.selectedExpenseId,
    year: expenseRequests.year,
    family: expenseRequests.familyBenefits,
  })
)
