import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

// hixme-ui
import Card from '@hixme-ui/card'
import Container from '@hixme-ui/container'
import Currency from '@hixme-ui/currency'
import Text from '@hixme-ui/text'
import { Button } from '@hixme-ui/forms'

// project
import { PersonBenefitPrice } from 'store/modules/persons/components'
import { ProductCardLeftColumn } from './ProductCardLeftColumn'
import CarrierInformationComponent from './CarrierInformationComponent'
import { EXPENSES_PATH } from '../../routes/path-names'

export const ProductCardOuter = ({ children, ...restProps }) => (
  <Card margin="20px 0" animate heavyShadow {...restProps}>
    {children}
  </Card>
)
ProductCardOuter.displayName = 'ProductCardOuter'
ProductCardOuter.propTypes = { children: PropTypes.node }
ProductCardOuter.defaultProps = { children: null }

const ProductCard = ({
  Note,
  Persons,
  added,
  benefitsList = [],
  description,
  remainingBalance,
  handleProductOnClick = () => {},
  isProcessing,
  name,
  price,
  ignorePayrollCycle,
  priceStyle,
  label,
  productURL,
  viewOnly,
  product,
}) => {
  const addButtonText = added ? 'Added' : 'Add to benefits'

  const addButtonProps = {
    outline: true,
    style: { width: '200px' },
    onClick: (e) => {
      e.preventDefault()
      handleProductOnClick()
    },
  }

  if (added) {
    Object.assign(addButtonProps, {
      outline: false,
    })
  }

  const PeopleCovered = () => {
    const names = Persons.map((person) => `${person.FirstName} ${person.LastName}`).join(', ')
    return (
      <Text light thin>
        People covered: <Text primary>{names}</Text>
      </Text>
    )
  }

  const RemainingBalance = () => (
    <Text light thin>
      Remaining Balance:{' '}
      <Link to={EXPENSES_PATH}>
        <Currency bold primary>
          {remainingBalance}
        </Currency>
      </Link>
    </Text>
  )
  return (
    <ProductCardOuter style={{ pageBreakInside: 'avoid' }}>
      <Container flex noPadding>
        <ProductCardLeftColumn
          Name={name}
          Description={description}
          benefitsList={benefitsList}
          productURL={productURL}
          Note={Note}
        />
        <Container flexGrow={1} justifyEnd margin="0px" noPadding textRight>
          <Container flex noPadding direction="column" justifyContent="space-between">
            <Container flex noPadding alignSelf="flex-end">
              <Container noPadding margin="0 0 20px">
                <PersonBenefitPrice
                  price={price}
                  label={label}
                  priceStyle={priceStyle}
                  ignorePayrollCycle={ignorePayrollCycle}
                />
              </Container>
            </Container>
            {!viewOnly && (
              <Container flex noPadding direction="column">
                <Button
                  {...addButtonProps}
                  submitting={isProcessing}
                  submittingText={added ? 'Removing...' : 'Adding...'}
                >
                  {addButtonText}
                </Button>
                <Container padding="5px 0 0" textCenter>
                  {added && (
                    <Text light thin>
                      (click again to remove)
                    </Text>
                  )}
                </Container>
              </Container>
            )}
            {viewOnly && Persons && Persons.length > 0 && <PeopleCovered />}
            {viewOnly && remainingBalance !== null && <RemainingBalance />}
          </Container>
        </Container>
      </Container>
      {viewOnly && <CarrierInformationComponent {...product} />}
      {Note && (
        <Text p light thin fontSize="13px" style={{ textAlign: 'right' }}>
          {Note}
        </Text>
      )}
    </ProductCardOuter>
  )
}

ProductCard.displayName = 'ProductCard'
ProductCard.propTypes = {
  Note: PropTypes.string,
  Persons: PropTypes.array,
  added: PropTypes.bool,
  benefitsList: PropTypes.array,
  description: PropTypes.string,
  label: PropTypes.string,
  handleProductOnClick: PropTypes.func,
  isProcessing: PropTypes.bool,
  name: PropTypes.string,
  price: PropTypes.number,
  remainingBalance: PropTypes.number,
  product: PropTypes.object.isRequired,
  productURL: PropTypes.string,
  viewOnly: PropTypes.bool,
  ignorePayrollCycle: PropTypes.bool,
  priceStyle: PropTypes.object,
}

ProductCard.defaultProps = {
  Note: '',
  Persons: [],
  added: false,
  benefitsList: [],
  description: '',
  handleProductOnClick: null,
  isProcessing: false,
  name: '',
  label: 'per paycheck',
  price: 0,
  productURL: '',
  viewOnly: false,
  ignorePayrollCycle: false,
  remainingBalance: null,
  priceStyle: {},
}

export default ProductCard
