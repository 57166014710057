import { createReducer } from 'modules/redux-utils'
import Expense from '../models/Expense'

import type from './actionTypes'

export const initialState = {
  expenses: [],
  familyBenefits: [],
  selectedLifeEvent: null,
  recordPointer: 5,
  ui: {
    isUploadingFiles: false,
    isDiscardConfirmationVisible: false,
    allowanceInformation: null,
  },
  isEditing: false,
  selectedExpense: new Expense(),
  receipts: {},
  lineItems: [],
  year: null,
  available: false,
}

export default createReducer(initialState, {
  [type.LOAD_EXPENSE_REQUESTS]: (state, payload) => ({
    ...state,
    expenses: payload,
  }),
  [type.LOAD_FAMILY_BENEFITS]: (state, payload) => ({
    ...state,
    familyBenefits: payload,
  }),
  [type.VIEW_MORE]: (state) => ({
    ...state,
    recordPointer: state.recordPointer + 5,
  }),
  [type.RESET_RECORD]: (state) => ({
    ...state,
    recordPointer: initialState.recordPointer,
  }),
  [type.LOAD_EXPENSE_REQUEST]: (state, payload) => ({
    ...state,
    expenseRequest: payload,
  }),
  [type.SELECT_LIFE_EVENT]: (state, payload) => ({
    ...state,
    selectedLifeEvent: payload,
  }),
  [type.SET_EXPENSE_ID]: (state, payload) => ({
    ...state,
    selectedExpenseId: payload,
  }),
  [type.SET_SELECTED_EXPENSE]: (state, payload) => ({
    ...state,
    selectedExpense: payload,
  }),
  [type.SET_BENEFIT_YEAR]: (state, payload) => ({
    ...state,
    year: Number(payload.year),
    familyBenefits: payload.family,
  }),
  [type.SET_UI_STATE]: (state, payload) => ({
    ...state,
    ui: {
      ...state.ui,
      ...payload,
    },
  }),
  [type.LOAD_RECEIPTS]: (state, payload) => ({
    ...state,
    receipts: {
      ...state.receipts,
      [payload.id]: {
        message: payload.message,
        data: payload.receipts,
      },
    },
  }),
  [type.LOAD_LINE_ITEMS]: (state, payload) => ({
    ...state,
    lineItems: payload,
  }),
  [type.SET_EXPENSE_AVAILABLE]: (state, payload) => ({
    ...state,
    available: payload,
  }),
})
