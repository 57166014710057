import { namespaceActions } from 'modules/redux-utils'
import { NAME } from './constants'

const SET_USER_ID = 'SET_USER_ID'
const LOAD_USER = 'LOAD_USER'
const LOAD_PAYROLL = 'LOAD_PAYROLL'
const LOAD_CLIENT_SETTINGS = 'LOAD_CLIENT_SETTINGS'
const LOAD_CLIENT_BENEFITS_ENROLLMENT = 'LOAD_CLIENT_BENEFITS_ENROLLMENT'
const LOAD_ENROLLMENT_RESPONSES = 'LOAD_ENROLLMENT_RESPONSES'
const LOAD_ENROLLMENT_SESSION = 'LOAD_ENROLLMENT_SESSION'
const LOAD_PREVIOUS_ENROLLMENT = 'LOAD_PREVIOUS_ENROLLMENT'
const LOAD_ICHRA_INFORMATION = 'LOAD_ICHRA_INFORMATION'
const LOAD_PROVIDERS = 'LOAD_PROVIDERS'
const ADD_PROVIDER = 'ADD_PROVIDER'
const REMOVE_PROVIDER = 'REMOVE_PROVIDER'
const USER_INITIALIZED = 'USER_INITIALIZED'
const USER_INITIALIZE_ERROR = 'USER_INITIALIZE_ERROR'
const UPDATING_USER = 'UPDATING_USER'
const SCROLLED_TO_BOTTOM = 'SCROLLED_TO_BOTTOM'
const SET_PROVIDER_QUESTION = 'SET_PROVIDER_QUESTION'
const SET_USER_LOCATION = 'SET_USER_LOCATION'
const SET_FAMILY_GROUPS = 'SET_FAMILY_GROUPS'
const DELETE_ALL_FROM_PROVIDER = 'DELETE_ALL_FROM_PROVIDER'
const DELETE_FAMILY_GROUPS = 'DELETE_FAMILY_GROUPS'
const LOAD_ENROLLMENT_CUTOFF_TIME = 'LOAD_ENROLLMENT_CUTOFF_TIME'

export default namespaceActions(NAME)([
  SET_USER_ID,
  LOAD_USER,
  LOAD_PAYROLL,
  LOAD_CLIENT_SETTINGS,
  LOAD_CLIENT_BENEFITS_ENROLLMENT,
  LOAD_ENROLLMENT_RESPONSES,
  LOAD_ENROLLMENT_SESSION,
  LOAD_PREVIOUS_ENROLLMENT,
  LOAD_ICHRA_INFORMATION,
  LOAD_PROVIDERS,
  ADD_PROVIDER,
  REMOVE_PROVIDER,
  USER_INITIALIZE_ERROR,
  USER_INITIALIZED,
  UPDATING_USER,
  SCROLLED_TO_BOTTOM,
  SET_PROVIDER_QUESTION,
  SET_USER_LOCATION,
  SET_FAMILY_GROUPS,
  DELETE_ALL_FROM_PROVIDER,
  DELETE_FAMILY_GROUPS,
  LOAD_ENROLLMENT_CUTOFF_TIME,
])
