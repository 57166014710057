import React from 'react'
import { MrModal } from '@hixme/modal'

// hixme-ui
import Separator from '@hixme-ui/separator'
import Title from '@hixme-ui/title'

// project
import TermsAndConditionsCopy from 'components/TermsAndConditionsCopy'

const TermsAndConditions = () => (
  <MrModal medium contentStart contentPadding="40px">
    <Title small>Terms &amp; Conditions</Title>
    <Separator light />
    <TermsAndConditionsCopy isModal />
  </MrModal>
)

TermsAndConditions.displayName = 'TermsAndConditionsModal'

export default TermsAndConditions
