import { isUndefined, isNull, isPlainObject } from 'lodash'

import BUNDLE_LEVELS, {
  BUNDLE_LEVEL_ESSENTIAL,
  BUNDLE_LEVEL_FLEXIBLE,
  BUNDLE_LEVEL_BALANCED,
  BUNDLE_LEVEL_SECURE,
} from 'modules/bundle-levels'

function getProperty(bundleArg, paramName) {
  if (isUndefined(bundleArg) || isNull(bundleArg)) {
    return null
  }

  if (isPlainObject(bundleArg)) {
    return bundleArg[paramName]
  }

  return bundleArg
}

export function getBundleLevelProperty(bundleArg) {
  const bundleLevel = getProperty(bundleArg, 'bundleLevel')

  if (!Object.values(BUNDLE_LEVELS).includes(bundleLevel)) {
    // eslint-disable-next-line no-console
    console.warn(
      `benefit-utils: bundleLevel of value ${bundleLevel} not recognized. This could be an error`
    )
  }

  return bundleLevel
}

export function isEssentialBundle(bundle) {
  return [BUNDLE_LEVEL_ESSENTIAL].includes(getBundleLevelProperty(bundle))
}

export function isFlexibleBundle(bundle) {
  return [BUNDLE_LEVEL_FLEXIBLE].includes(getBundleLevelProperty(bundle))
}

export function isBalancedBundle(bundle) {
  return [BUNDLE_LEVEL_BALANCED].includes(getBundleLevelProperty(bundle))
}

export function isSecureBundle(bundle) {
  return [BUNDLE_LEVEL_SECURE].includes(getBundleLevelProperty(bundle))
}

export const monthlyCostByPayrollCycle = (monthlyCost, payrollCycle) =>
  (monthlyCost * 12) / payrollCycle

export const payrollCycleCostToMonthly = (monthlyCost, payrollCycle) =>
  (monthlyCost / 12) * payrollCycle
