import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransitionGroup } from 'react-transition-group'

import Container from '@hixme-ui/container'

import classes from './FileList.scss'

import File from '../File/File'
import Expense from '../../../../models/Expense'

const FileList = ({
  expense,
  handleUpdateAmount,
  handleNoteChange,
  handleRemoveFile,
  files,
  isUploading,
}) => (
  <Container flex noPadding direction="column" margin="10px 0">
    <CSSTransitionGroup
      transitionName={{
        enter: classes.enter,
        enterActive: classes.enterActive,
        leave: classes.leave,
        leaveActive: classes.leaveActive,
      }}
      transitionEnterTimeout={300}
      transitionLeaveTimeout={300}
    >
      {files.map((wrapper) => (
        <File
          expense={expense}
          handleNoteChange={handleNoteChange}
          handleRemoveFile={handleRemoveFile}
          handleUpdateAmount={handleUpdateAmount}
          key={wrapper.file.name}
          file={wrapper.file}
          wrapper={wrapper}
          isUploading={isUploading}
        />
      ))}
    </CSSTransitionGroup>
  </Container>
)

FileList.propTypes = {
  expense: PropTypes.instanceOf(Expense).isRequired,
  isUploading: PropTypes.bool,
  files: PropTypes.array,
  handleNoteChange: PropTypes.func,
  handleRemoveFile: PropTypes.func,
  handleUpdateAmount: PropTypes.func,
}

FileList.defaultProps = {
  isUploading: false,
  files: [],
  handleFileTypeChange: () => null,
  handleNoteChange: () => null,
  handleRemoveFile: () => null,
  handleUpdateAmount: () => null,
}

export default FileList
