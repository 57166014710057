import { callWaiter } from 'redux-waiter'
import { createRequest } from '@hixme/redux-gateway'
import { getFamilyShape } from '@hixme/person-utils'
import { selectors as sessionSelectors } from 'store/modules/user-session'

import t from './actionTypes'
import * as personsAPI from './api'

import { POST_PERSON_GRAPH_ROUTE, GET_FAMILY_REQUEST, requiredFields } from './constants'
import * as selectors from './selectors'

const LOADING_ERROR_MESSAGE = 'There was an error retrieving your information.'

export const loadEmployee = (person) => ({
  type: t.LOAD_EMPLOYEE,
  payload: person,
})

export const updateEmployee = (person) => ({
  type: t.UPDATE_EMPLOYEE,
  payload: person,
})

export const loadDependentsList = (dependents) => ({
  type: t.LOAD_DEPENDENTS_LIST,
  payload: dependents,
})

export const addDependent = (dependent) => ({
  type: t.ADD_DEPENDENT,
  payload: dependent,
})

export const loadPendingEdit = (persons) => ({
  type: t.LOAD_PENDING_EDIT,
  payload: persons,
})

export const completePersonInformation = (id) => ({
  type: t.COMPLETE_PERSON_INFORMATION,
  payload: id,
})

export const updateDependentByIndex = (dependent, index) => ({
  type: t.UPDATE_DEPENDENT_BYINDEX,
  payload: { dependent, index },
})

export const removeDependentByIndex = (index) => ({
  type: t.DELETE_DEPENDENT_BYINDEX,
  payload: index,
})

export const loadingError = (error) => ({
  type: t.SET_ERROR,
  payload: error || LOADING_ERROR_MESSAGE,
})

export const getFamilyByEmployeeId = (id) => (dispatch) =>
  dispatch(
    callWaiter(`${GET_FAMILY_REQUEST}-${id}`, {
      requestCreator: () =>
        personsAPI
          .getFamilyByEmployeeId(id)
          .then((response) => {
            const family = getFamilyShape(response.Persons)
            const dependents = family.dependents ?? []
            const pendingEdit = [family.employee, ...dependents].reduce((prev, person) => {
              const hasRequiredFields = requiredFields.every((property) => property in person)
              if (hasRequiredFields) return prev
              return [...prev, person.Id]
            }, [])
            dispatch(loadEmployee(family.employee))
            dispatch(loadDependentsList(dependents))
            dispatch(loadPendingEdit(pendingEdit))
            return response
          })
          .catch((error) => {
            dispatch(loadingError(error))
            throw error
          }),
    })
  )

export const deleteDependentByIndex = (index) => (dispatch, getState) => {
  dispatch(removeDependentByIndex(index))
  const persons = selectors.getPersonsSelector(getState())
  const req = dispatch(
    createRequest({
      route: POST_PERSON_GRAPH_ROUTE,
      body: persons,
    })
  )
  // .then(
  //   (data) => { console.log('POST trimmed dependents list success: ', data) },
  //   (error) => { console.log('Failed to POST trimed dependents list', error) }
  // )
  // .catch(
  //   reason => { console.log('Person Graph POST rejected: ', reason) }
  // )
  return req
}

export const getPersons = () => (dispatch, getState) => {
  dispatch(getFamilyByEmployeeId(sessionSelectors.getUserId(getState())))
}
