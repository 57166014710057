import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

const UserSessionErrorView = ({ errors }) => (
  <Container>
    {errors &&
      errors.map(({ title, message, error }) => (
        <Container white key={title} marginBottom="20px">
          <Text bold large>
            {message}
          </Text>

          <Container error marginTop="20px">
            <Text white>{error.message ? error.message : JSON.stringify(error)}</Text>
          </Container>
        </Container>
      ))}
  </Container>
)

UserSessionErrorView.displayName = 'UserSessionErrorView'
UserSessionErrorView.propTypes = {
  errors: PropTypes.array.isRequired,
}
UserSessionErrorView.defaultProps = {}

export default UserSessionErrorView
