import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

// hixme-ui
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'

import { LinkOutlined, HeartOutlined } from '@ant-design/icons'
import { Button, Space, Tooltip } from '@surecompanies/core_components'
import { Typography } from '@sureco/design-system'

import { getBundleLetter } from '@hixme/bundle-utils'

import { PersonBenefitPrice } from 'store/modules/persons/components'

import { MAX_FAVORITES } from '../../constants'

import { BundleAccordion } from './components'

// Importing these containers directly to avoid circular dependencies problem
import AddBenefitButtonContainer from '../../containers/AddBenefitButtonContainer'
import CompareNavContainer from '../../../../containers/CompareNavContainer'
import { currencyFormatter } from '../../../../../../Expenses/utils/expenses'
import ICHRATooltip from '../../../../components/BundlePlanTypeTooltips/components/ICHRATooltip/ICHRATooltip'
import ProviderNote from './components/ProviderNote/ProviderNote'
import { datadog, CustomActionNames } from 'helpers/datadog'
import BenefitsSummaryButton from '../BenefitsSummaryButton/BenefitsSummaryButton'
import { ShopForHealthLog } from '../../../../shop-for-health-activity-log/shop-for-health-log'

const BundleCard = ({
  bundle,
  coveredPeopleLabel,
  onToggleFavorite,
  groupFavoriteCount,
  isNoticeVisible,
  website,
  providers,
}) => {
  const {
    BundlePublicKey,
    // BundleLevel,
    CarrierName,
    HealthPlanId,
    // HixmeValue,
    IsHsa,
    PlanType,
    PlanName,
    MetalLevel,
    sections,
    bundlePrice,
    ICHRAPrice,
    favorite,
  } = bundle

  const onToggle = (benefitHighlight, bundle, toggle ) => {
    ShopForHealthLog.toggleBundlePanelAccordion({
      bundle,
      benefitHighlight,
      toggle,
    })
  }

  return (
    <div>
      <Container background="white" noPadding shadow>
        <Container flex justifyContent="center" alignItems="center" noPadding height="30px">
          {MetalLevel === 'Catastrophic' && (
            <Text primary small>
              Only available for persons aged 30 and under
            </Text>
          )}
        </Container>

        <Container flex noPadding direction="column" alignItems="center">
          <PersonBenefitPrice price={bundlePrice} label={coveredPeopleLabel} />
        </Container>

        <Container
          flex
          direction="column"
          alignItems="center"
          margin="0 0 20px 0"
          padding="0 10px 0 10px"
        >
          <Container flex noPadding direction="column" alignItems="center">
            <Container flex noPadding height="60px">
              <Tooltip placement="top" title={bundle.Tooltip}>
                <Typography size={18} style={{ height: 'fit-content' }}>
                  {bundle.Tooltip && <Icon
                    info
                    color={'#3997e2'}
                  />} {CarrierName}
                </Typography>
              </Tooltip>
            </Container>
            {isNoticeVisible && (
              <Container flex noPadding>
                <Text p center thin small>
                  <a href={website} target={'_blank'}>
                    visit website
                  </a>
                </Text>
              </Container>
            )}

            <Container flex noPadding direction="column" style={{ gap: '15px' }}>
              <span
                data-tip
                data-for={`${PlanType}${BundlePublicKey}`}
                style={{ textAlign: 'center' }}
              >
                <Typography size={14} style={{ marginBottom: ICHRAPrice ? 0 : '14px' }}>
                  {PlanName} {IsHsa && '(HSA Eligible)'}
                </Typography>
              </span>

              {ICHRAPrice && (
                <span data-tip data-for={`ICHRA-${ICHRAPrice}`}>
                  <Container flex noPadding alignItems="center">
                    <Icon dollar primary />
                    <Text dashed p center>
                      ICHRA Expense allowance:{' '}
                      {currencyFormatter(ICHRAPrice, {
                        maximumSignificantDigits: 1,
                      })}
                    </Text>
                  </Container>
                  <ICHRATooltip tooltipId={`ICHRA-${ICHRAPrice}`} />
                </span>
              )}
              {providers &&
                providers.map((provider) => (
                  <ProviderNote
                    key={provider.npi}
                    provider={provider}
                    bundleId={BundlePublicKey}
                    visible={provider.isSelected}
                  />
                ))}
            </Container>
          </Container>
        </Container>

        <BundleAccordion onToggle={onToggle} Bundle={bundle} HealthPlanId={HealthPlanId} Sections={sections} />
        <div style={{ borderTop: '1px solid #DBE2E8' }}>
          <Container
            flex
            height="100%"
            direction="column"
            justifyContent="flex-end"
            padding="0 20px 20px 20px"
          >
            <Container noPadding margin="10px 0">
              <Typography color="#8F9DA8">
                {bundle.HealthPlanId} {getBundleLetter(bundle)}
              </Typography>
            </Container>
            {groupFavoriteCount === MAX_FAVORITES && (
              <ReactTooltip id="maxReached" effect="solid">
                <Typography>Compare maximum of {MAX_FAVORITES} reached</Typography>
              </ReactTooltip>
            )}
            <Container flex noPadding justifyContent="flex-end" height="40px" alignItems="center">
              {favorite && <CompareNavContainer />}
            </Container>

            <Container flex noPadding justifySpaceBetween>
              <BenefitsSummaryButton
                label="Benefits Summary"
                onItemSelected={(key) => onBenefitsSummaryOpen(key, BundlePublicKey)}
                items={bundle.SbcLinks.map((link) => ({
                  label: languageMapper[link.Language] || 'English',
                  icon: <LinkOutlined />,
                  key: link.Url,
                }))}
              />
              <div data-tip data-for={favorite ? null : 'maxReached'}>
                <Button
                  onClick={() => onToggleFavorite(BundlePublicKey)}
                  style={{ padding: '5px' }}
                  danger={favorite}
                  type={favorite ? 'primary' : 'default'}
                >
                  <Space>
                    <HeartOutlined />
                    Compare
                  </Space>
                </Button>
              </div>
            </Container>

            <Container flex noPadding margin="10px 0 0 0" justifyContent="center">
              <AddBenefitButtonContainer bundleId={BundlePublicKey} />
            </Container>
          </Container>
        </div>
      </Container>
    </div>
  )
}

BundleCard.propTypes = {
  bundle: PropTypes.object,
  isNoticeVisible: PropTypes.bool,
  groupFavoriteCount: PropTypes.number,
  coveredPeopleLabel: PropTypes.string,
  website: PropTypes.string,
  providers: PropTypes.array,
  onToggleFavorite: PropTypes.func,
}

BundleCard.defaultProps = {
  bundle: {
    BundleBenefits: [],
    BundlePublicKey: '0',
    CarrierName: '',
    CoveredHospitals: [],
    HsaPrice: 0,
    IsHsa: false,
    PlanName: '',
    Price: 0,
    ICHRAPrice: null,
    bundleCoreBenefits: [],
    bundlePrice: 0,
    favorite: false,
    groupPersons: [],
  },
  isNoticeVisible: false,
  website: '',
  coveredPeopleLabel: '',
  providers: [],
  isProcessing: false,
  stackLength: 0,
  currentBundleIndex: 0,
  groupFavoriteCount: 0,

  onAddToCart: () => { },
  onRemoveFromCart: () => { },
  onToggleFavorite: () => { },
}

BundleCard.displayName = 'BundleCard'

export default BundleCard

export const onBenefitsSummaryOpen = (url, BundlePublicKey) => {
  datadog.customAction(CustomActionNames.OpenBenefitsSummaryPDF, {
    pdf: url,
    bundle: datadog.getBundleAttributesByBundleId(BundlePublicKey),
  })
  ShopForHealthLog.openBenefitsSummary(BundlePublicKey)
  window.open(url)
}

export const languageMapper = {
  EN: 'English',
  SP: 'Spanish',
}
