import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { getWaiter } from 'redux-waiter'

import Button from '@hixme-ui/button'
import Container from '@hixme-ui/container'
import Currency from '@hixme-ui/currency'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'
import { MrModal, clearModal } from '@hixme/modal'
import { colorTheme } from '@hixme-ui/theme'

import DashedBorder from 'components/DashedBorder/DashedBorder'
import ReceiptItems from 'components/ReceiptItems'

import {
  getSelectedExpense,
  getSelectedLifeEvent,
  getLineItemsReceipts,
  getTotalAmountRequested,
} from '../../modules/selectors'
import { GET_LINE_ITEMS, GET_RECEIPTS } from '../../modules/constants'
import {
  getExpenseLineItems,
  getReceitpsByReimbursementId,
  updateSelectedExpenseData,
} from '../../modules/actions'
import Expense from '../../models/Expense'
import ViewReimbursementLoader from './components/ViewReimbursementLoader'
import ExpenseFactory from '../../models/ExpenseFactory'

export const ViewReimbursementModal = ({
  expense,
  isProcessing,
  lineItems,
  closeModal,
  coinsurance,
  totalRequested,
}) => (
  <MrModal medium backgroundColor="#f1f1f1" cancelHandler={closeModal}>
    <Container flex justifyContent="center">
      <Title>Expense Reimbursement</Title>
    </Container>
    {isProcessing ? (
      <ViewReimbursementLoader />
    ) : (
      <Container>
        <Container flex noPadding width="100%" justifyContent="space-between" alignItems="center">
          <Container flex direction="column" padding="10px 0">
            <Text large bold>
              Amount Requested
            </Text>
            <Text light>
              Date of Expense: {expense.date} / Requested:
              {expense.createdDate || ''}
            </Text>
          </Container>
          <Currency large bold>
            {totalRequested}
          </Currency>
        </Container>
        <DashedBorder />
        <Container flex padding="20px 0">
          <Container noPadding flex direction="column" width="100%">
            {!isEmpty(lineItems) &&
              lineItems.map((receipt) => (
                <Container key={receipt.id} padding="10px 0">
                  <ReceiptItems receipt={receipt} />
                </Container>
              ))}
          </Container>
        </Container>
        <DashedBorder />
        <Container flex padding="20px 0" justifyContent="space-between">
          <Text large bold>
            Net Reimbursement
          </Text>
          <Container flex noPadding direction="column" alignItems="flex-end">
            <Currency large primary bold>
              {expense.amount}
            </Currency>
            <Text
              style={{
                color: expense.isApproved() ? colorTheme.green : colorTheme.red,
              }}
            >
              {expense.status} - {expense.updatedDate}
            </Text>
          </Container>
        </Container>
        {coinsurance > 0 && (
          <Container flex noPadding direction="column">
            <Text light bold>
              Reimbursement payments usually will appear within 1-2 paycheck cycles.
            </Text>
            <Text light fontWeight="400">
              * ICHRA Reimbursement expenses are subject to a {coinsurance * 100}% employee
              co-insurance obligation.
            </Text>
          </Container>
        )}
        <Container
          flex
          padding="20px 0 0 0"
          width="100%"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button primary onClick={closeModal} style={{ padding: '.25em 4em' }}>
            Close
          </Button>
        </Container>
      </Container>
    )}
  </MrModal>
)

ViewReimbursementModal.displayName = 'ViewReimbursementModal'

const mapStateToProps = (state) => {
  const selectedExpense = getSelectedExpense(state)
  const selectedLifeEvent = getSelectedLifeEvent(state)
  const lineItems = getLineItemsReceipts(state)
  const totalRequested = getTotalAmountRequested(selectedExpense && selectedExpense.id, state)
  const isProcessingGetLineItems = getWaiter(state, GET_LINE_ITEMS).isPending
  const isProcessingGetReceipts = getWaiter(state, GET_RECEIPTS).isPending
  return {
    totalRequested,
    isProcessing: isProcessingGetLineItems || isProcessingGetReceipts,
    expense: selectedExpense || {},
    lineItems,
    reimbursementId: selectedExpense && selectedExpense.id,
    coinsurance: selectedLifeEvent && selectedLifeEvent.coinsurance,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(updateSelectedExpenseData(ExpenseFactory.create()))
    dispatch(clearModal())
  },

  getLineItems: (reimbursementId) => {
    dispatch(getExpenseLineItems(reimbursementId))
  },

  getReceipts: (reimbursementId) => {
    dispatch(getReceitpsByReimbursementId(reimbursementId))
  },
})

ViewReimbursementModal.propTypes = {
  expense: PropTypes.instanceOf(Expense),
  isProcessing: PropTypes.bool,
  lineItems: PropTypes.array,
  coinsurance: PropTypes.number,
  totalRequested: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
}

ViewReimbursementModal.defaultProps = {
  expense: new Expense(),
  lineItems: [],
  coinsurance: null,
  isProcessing: false,
  totalRequested: 0,
}

const withLineItemsRequest = lifecycle({
  componentDidMount() {
    this.props.getLineItems(this.props.reimbursementId)
    this.props.getReceipts(this.props.reimbursementId)
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withLineItemsRequest
)(ViewReimbursementModal)
