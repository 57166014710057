import React from 'react'

// hixme-ui
import Container from '@hixme-ui/container'
import Title from '@hixme-ui/title'

import classes from './AppLoader.scss'

const AppLoader = () => (
  <div className={classes.container}>
    <Container
      flex
      noPadding
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <div className={classes.loader} />
      <Title small thinnest white>
        Loading your data...
      </Title>
    </Container>
  </div>
)

AppLoader.displayName = 'AppLoader'
export default AppLoader
