import { connect } from 'react-redux'
import { clearModal } from '@hixme/modal'
import { getWaiter } from 'redux-waiter'

// route modules
import DeclineCoverageModal from 'components/DeclineCoverageModal/DeclineCoverageModal'
import { declineBundles } from '../actions'

const mapStateToProps = (state) => ({
  isProcessing: getWaiter(state, 'DECLINE_BUNDLES').isPending,
})

const mapDispatchToProps = (dispatch, props) => ({
  onCancelClick: () => dispatch(clearModal()),
  onDeclineClick: () => dispatch(declineBundles(props.declineForAll)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeclineCoverageModal)
