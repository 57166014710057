import React from 'react'
import PropTypes from 'prop-types'

import Text from '@hixme-ui/text'

const FootNote = ({ text, id }) => (
  <Text smaller light>
    <sup>{id}</sup>
    {text}
  </Text>
)

FootNote.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default FootNote
