import React from 'react'
import PropTypes from 'prop-types'
import uuidV1 from 'uuid/v1'

// hixme-ui
import Text from '@hixme-ui/text'

// project
import GroupHealthProductCard from './GroupHealthProductCard'

const GroupHealthDetails = ({
  currentProduct,
  isUpdating,
  groupHealthProducts,
  addGroupHealthProduct,
  removeGroupHealthProduct,
  coveredPersons,
  excludedPersons,
}) => (
  <div>
    {isUpdating && (
      <Text primary p center>
        Retrieving group health products...
      </Text>
    )}
    {groupHealthProducts.map((product) => {
      const data = { ...product }
      data.coveredPersons = coveredPersons
      data.excludedPersons = excludedPersons
      data.added = currentProduct && currentProduct.BenefitPublicKey === data.BenefitPublicKey
      data.groupHealthProductOnClick = data.added ? removeGroupHealthProduct : addGroupHealthProduct
      return <GroupHealthProductCard key={uuidV1()} {...data} />
    })}
  </div>
)

GroupHealthDetails.propTypes = {
  currentProduct: PropTypes.object,
  isUpdating: PropTypes.bool,
  groupHealthProducts: PropTypes.array,
  addGroupHealthProduct: PropTypes.func,
  removeGroupHealthProduct: PropTypes.func,
  coveredPersons: PropTypes.array.isRequired,
  excludedPersons: PropTypes.array.isRequired,
}

GroupHealthDetails.defaultProps = {
  currentProduct: {},
  isUpdating: false,
  groupHealthProducts: [],
  addGroupHealthProduct: null,
  removeGroupHealthProduct: null,
}

export default GroupHealthDetails
