import React from 'react'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// hixme-ui
import Container from '@hixme-ui/container'
import Title from '@hixme-ui/title'

import { Button } from '@hixme-ui/forms'
import { MrModal } from '@hixme/modal'

// project
import ExpenseForm from './components/ExpenseForm'
import UploadFormContainer from '../../containers/UploadFormContainer'
import UploadButton from './components/UploadButton/UploadButton'
import Expense from '../../models/Expense'
import DiscardExpense from '../DiscardExpense/DiscardExpense'
import { OutlineButton } from './styles/style'

const MySwal = withReactContent(Swal)

const NewExpenseRequest = ({
  isEditing,
  isCreatingOrUpdating,
  selectedExpense,
  clientId,
  familyNames,
  employeePublicKey,
  isUploadingFiles,
  isUploading,
  isLoading,
  onFieldChange,
  closeModal,
  onFamilyMemberChange,
  onAgreementChange,
  createOrUpdate,
  handleDone,
  hasUploadedFiles,
  setDateError,
  handleBlur,
  handleFocus,
  allowanceInformation,
}) => (
  <MrModal medium hideCloseButton backgroundColor="#f1f1f1" shouldCloseOnOverlayClick={false}>
    <Container flex noPadding justifyContent="center">
      <Title>{!isUploadingFiles ? 'Expense Reimbursement' : 'Upload Receipts'}</Title>
    </Container>
    {isUploadingFiles ? (
      <UploadFormContainer />
    ) : (
      <ExpenseForm
        isEditing={isEditing}
        expense={selectedExpense}
        members={familyNames}
        onChange={onFieldChange}
        onBlur={() =>
          handleBlur({
            expense: selectedExpense,
            setDateError,
          })
        }
        onFocus={() => handleFocus(selectedExpense, setDateError)}
        onFamilyMemberChange={onFamilyMemberChange}
        onAgreementChange={onAgreementChange}
        allowanceInformation={allowanceInformation}
        isLoading={isLoading}
      />
    )}

    <Container
      flex
      padding="20px 0 0 0"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <Container flex noPadding width="100%">
        {isUploadingFiles ? (
          <Container flex noPadding justifyContent="space-between" width="100%">
            <Container>
              {hasUploadedFiles || !selectedExpense.isDraft() ? (
                <OutlineButton
                  onClick={() => handleDone(selectedExpense, clientId)}
                  submitting={isCreatingOrUpdating}
                  submittingText="Creating..."
                >
                  Done
                </OutlineButton>
              ) : (
                <OutlineButton
                  disabled={isCreatingOrUpdating || isUploading}
                  onClick={() => {
                    if (!selectedExpense.isDraft()) {
                      closeModal()
                      return
                    }
                    MySwal.fire({
                      title: (
                        <DiscardExpense
                          onLeave={() => {
                            MySwal.close()
                            closeModal(clientId, employeePublicKey, selectedExpense)
                          }}
                          onStay={() => MySwal.close()}
                        />
                      ),
                      showConfirmButton: false,
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    })
                  }}
                >
                  Cancel
                </OutlineButton>
              )}
            </Container>
            <Container>
              <UploadButton />
            </Container>
          </Container>
        ) : (
          <Container flex noPadding alignItems="center" justifyContent="space-between" width="100%">
            <OutlineButton
              disabled={isCreatingOrUpdating}
              onClick={() => {
                if (!selectedExpense.isDraft()) {
                  closeModal()
                  return
                }
                MySwal.fire({
                  title: (
                    <DiscardExpense
                      onLeave={() => {
                        MySwal.close()
                        closeModal(clientId, employeePublicKey, selectedExpense)
                      }}
                      onStay={() => MySwal.close()}
                    />
                  ),
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                })
              }}
            >
              Cancel
            </OutlineButton>
            <Button
              submitting={isCreatingOrUpdating}
              disabled={
                !selectedExpense.isValid() ||
                (allowanceInformation && !allowanceInformation.valid) ||
                isLoading
              }
              style={{ padding: '0 60px' }}
              onClick={() =>
                createOrUpdate({
                  expense: selectedExpense,
                  clientId,
                })
              }
            >
              {selectedExpense.isDraft() ? 'Continue' : 'Update'}
            </Button>
          </Container>
        )}
      </Container>
    </Container>
  </MrModal>
)

NewExpenseRequest.displayName = 'NewExpenseRequest'

NewExpenseRequest.propTypes = {
  isUploadingFiles: PropTypes.bool.isRequired,
  isCreatingOrUpdating: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  clientId: PropTypes.string.isRequired,
  employeePublicKey: PropTypes.string.isRequired,
  familyNames: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleDone: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onAgreementChange: PropTypes.func.isRequired,
  onFamilyMemberChange: PropTypes.func.isRequired,
  createOrUpdate: PropTypes.func.isRequired,
  selectedExpense: PropTypes.instanceOf(Expense).isRequired,
  isEditing: PropTypes.bool,
  isUploading: PropTypes.bool,
  hasUploadedFiles: PropTypes.bool,
  setDateError: PropTypes.func.isRequired,
  allowanceInformation: PropTypes.shape({
    valid: PropTypes.bool,
    message: PropTypes.string,
  }),
}

NewExpenseRequest.defaultProps = {
  isEditing: false,
  isUploading: false,
  isLoading: false,
  hasUploadedFiles: false,
  files: [],
  allowanceInformation: null,
}

export default NewExpenseRequest
