import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'

import ProductCardBenefitsList from './ProductCardBenefitsList' // eslint-disable-line

export const ProductCardLeftColumn = ({
  Name,
  description,
  benefitsList,
  productURL,

  columnProps,
  nameProps,
  descriptionProps,
}) => (
  <Container flexGrow={1} flexColumn noPadding {...columnProps}>
    {Name && (
      <Title smaller {...nameProps}>
        {Name}
      </Title>
    )}
    {description && description.length && (
      <Container noPadding margin="20px 0" {...descriptionProps}>
        <Text light thin fontSize="16px">
          {description}
        </Text>
      </Container>
    )}
    <Container noPadding margin="10px 0 0">
      <ProductCardBenefitsList list={benefitsList} />
    </Container>
    {productURL && (
      <a
        href={productURL}
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: 'inline-block', marginTop: '20px' }}
      >
        View details
      </a>
    )}
  </Container>
)

ProductCardLeftColumn.displayName = 'ProductCardLeftColumn'

ProductCardLeftColumn.propTypes = {
  Name: PropTypes.string,
  description: PropTypes.string,
  benefitsList: PropTypes.array,
  productURL: PropTypes.string,

  columnProps: PropTypes.object,
  nameProps: PropTypes.object,
  descriptionProps: PropTypes.object,
}

ProductCardLeftColumn.defaultProps = {
  Name: '',
  description: '',
  benefitsList: [],
  productURL: '',

  columnProps: {},
  nameProps: {},
  descriptionProps: {},
}

export default ProductCardLeftColumn
