export default [
  {
    header: {
      index: 1,
      question: 'Is someone covered by your medical plan likely to have a baby in 2019?',
      tooltipText: `Pregnancies sometimes are a surprise, but many are planned. If your plans include a pregnancy
        where the baby will be delivered during 2019, we will match you to the optimal level of coverage for your
        prenatal care, delivery, and hospital stay.`,
    },
    number: '1',
    fragment: 'Having a baby',
  },
  {
    header: {
      index: 2,
      question:
        'Is someone covered by your medical plan likely to have a surgery and/or hospital stay in 2019?',
    },
    number: '2',
    fragment: 'Surgery with overnight hospital stay',
  },
  {
    header: {
      index: 3,
      question:
        'Is someone covered by your medical plan likely to fill 10 or more prescriptions in 2019?',
      tooltipText: `
        "10 or more prescriptions" can include, for example, a 3-month supply fill (which counts as 1 fill).
      `,
    },
    number: '3',
    fragment: '10 or more prescriptions filled',
  },
  {
    header: {
      index: 4,
      question: `Is someone covered by your medical plan likely to have ongoing treatment for a minor condition in
        2019?`,
      tooltipText: `Ongoing treatment for a minor condition can entail regularly scheduled laboratory tests, radiology
        exams/MRI, physical therapy, or behavioral health services.`,
    },
    number: '4',
    fragment: 'Ongoing treatment for minor condition(s)',
  },
]
