import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'

import { setModal } from '@hixme/modal'

import { DECLINE_HEALTH_COVERAGE } from 'modal-constants'

import DeclineMedicalButton from './DeclineMedicalButton'

const mapStateToProps = (state) => ({
  isProcessing: getWaiter(state, 'DECLINE_BUNDLES').isPending || false,
})

const mapDispatchToProps = (dispatch) => ({
  handleClick: () => dispatch(setModal(DECLINE_HEALTH_COVERAGE)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeclineMedicalButton)
