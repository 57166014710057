import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@sureco/design-system'
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'
import { ActionButton, CardHeader, CardHeaderControls } from './styles/style'

const StackNav = ({
  onPreviousBundle,
  onNextBundle,
  stackName,
  bundleCount,
  isProcessing,
  currentBundleIndex,
}) => (
  <div>
    <Container
      flex
      noPadding
      margin="0 0 10px 0"
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Typography bold size={18} style={{ color: '#677786' }}>
        {stackName}
      </Typography>

      <Typography size={12} style={{ color: '#677786' }}>
        ({bundleCount} available)
      </Typography>
    </Container>

    <CardHeader metal={stackName} />
    <CardHeaderControls>
      <ActionButton
        metal={stackName}
        onClick={onPreviousBundle}
        disabled={currentBundleIndex === 1 || isProcessing}
      >
        <Text>
          <Icon arrowLeft white size={0.7} /> Prev
        </Text>
      </ActionButton>

      <Container flex noPadding>
        {bundleCount > 0 && (
          <Text medium>
            {currentBundleIndex} of {bundleCount}
          </Text>
        )}
      </Container>

      <Container flex noPadding alignItems="center">
        <ActionButton
          metal={stackName}
          onClick={onNextBundle}
          disabled={bundleCount === 0 || currentBundleIndex === bundleCount || isProcessing}
        >
          <Text>
            Next <Icon arrowRight white size={0.7} />
          </Text>
        </ActionButton>
      </Container>
    </CardHeaderControls>
  </div>
)

StackNav.propTypes = {
  stackName: PropTypes.string,
  onNextBundle: PropTypes.func,
  onPreviousBundle: PropTypes.func,
  bundleCount: PropTypes.number,
  isProcessing: PropTypes.bool.isRequired,
  currentBundleIndex: PropTypes.number,
}

StackNav.defaultProps = {
  stackName: '',
  onNextBundle: () => {},
  onPreviousBundle: () => {},
  bundleCount: 0,
  currentBundleIndex: 0,
  recommended: '',
}

StackNav.displayName = 'StackNav'

export default StackNav
