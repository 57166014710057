import React from 'react'
import PropTypes from 'prop-types'

const FieldError = ({ meta: { touched, invalid, error }, children }) => (
  <div>{touched && invalid && children(error)}</div>
)

FieldError.propTypes = {
  meta: PropTypes.object,
  children: PropTypes.func.isRequired,
}

FieldError.defaultProps = {
  meta: {},
}

export default FieldError
