import { connect } from 'react-redux'
import { getModalData, clearModal } from '@hixme/modal'
import { compose } from 'recompose'

import { actions as userSessionActions } from 'store/modules/user-session'

import BundleProvider from '../providers/BundleProvider'
import { actions, selectors } from '../../../modules/health-bundles'
import BundleDetails from '../components/BundleDetails/BundleDetails'

const mapStateToProps = (state) => ({
  bundleId: getModalData(state) || '',
  currentGroupIndex: selectors.getCurrentGroupIndex(state),
  groupFavoriteCount: selectors.getCurrentGroupFavoriteCount(state),
  totalGroupCount: selectors.getTotalGroupCount(state),
})

const mapDispatchToProps = (dispatch) => ({
  handleContinue: () => {
    dispatch(clearModal())
    dispatch(userSessionActions.pushNextBenefitRoute())
  },
  handleNextGroup: () => {
    dispatch(clearModal())
    dispatch(actions.nextGroup())
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  BundleProvider
)(BundleDetails)
