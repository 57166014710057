import { DrugsForIllnessCondition } from './constants'
import { BundleBenefitDetail } from 'types/enrollment'

// These are pulled out of getCurrentGroupBundles in selector.js
// Since Renewal modal will run the same logic to categorize benefits into
// prescription vs highlights

// For some reason, some accounts' previous enrollment does not have "section" data
// so Renewal runs the categorize logic to manually create the "section" data
// Note: "section" data is just BundleBenefits separated into "highlights" vs "prescription" benefits

export const getPrescriptions = (benefits: BundleBenefitDetail[], includes = true) =>
  benefits &&
  benefits.filter((benefit) => {
    const shouldExclude =
      benefit.BenefitDisplayName.includes('Drug Deductible') &&
      (benefit.DisplayValue === 'See Plan Details' || benefit.DisplayValue === '$0')

    return includes
      ? benefit.SectionDisplayLabel === DrugsForIllnessCondition && !shouldExclude
      : benefit.SectionDisplayLabel !== DrugsForIllnessCondition
  })

export const getNotes = (list: BundleBenefitDetail[]) =>
  list
    .map((benefit) => benefit.Notes || [])
    .flat()
    .reduce((prev, curr) => {
      const note = { ...prev, [curr.Id]: curr.Text }
      return note
    }, {})

export const getBenefitNotes = (list: BundleBenefitDetail[], notes = {}) => {
  const firstXVisitNoteID = 2 // 2 is always going to be the "Fist X visits, ..." note
  const NOTE_VALUE = notes[firstXVisitNoteID]
  const index = NOTE_VALUE && NOTE_VALUE.indexOf(',')
  return list.map((bundleBenefit) => {
    const Notes = Array.from(new Set(bundleBenefit.Notes.map((note) => note.Id)))
    // need to check if the benefit itself has note id 2 before adding the "First X visits" msg
    const shouldShowFirstXVisits = Notes.includes(firstXVisitNoteID) && NOTE_VALUE
    return {
      ...bundleBenefit,
      DisplayValue: shouldShowFirstXVisits
        ? `${bundleBenefit.DisplayValue} (${NOTE_VALUE.slice(0, index)})`
        : bundleBenefit.DisplayValue,
      Notes,
    }
  })
}
