import React from 'react'
import PropTypes from 'prop-types'

import Title from '@hixme-ui/title'

const ProductCardTitle = ({ children, ...restProps }) => (
  <Title smaller {...restProps}>
    {children}
  </Title>
)

ProductCardTitle.displayName = 'ProductCardTitle'

ProductCardTitle.propTypes = {
  children: PropTypes.node,
}

ProductCardTitle.defaultProps = {
  children: <div />,
}

export default ProductCardTitle
