import React from 'react'

import Container from '@hixme-ui/container'

import Placeholder from 'components/Placeholder'

const ViewReimbursementLoader = () => (
  <Container flex padding="10px 5px 5px" direction="column">
    <Container flex noPadding justifyContent="space-between" width="100%">
      <Container flex noPadding direction="column" style={{ gap: '10px' }}>
        <Placeholder width="200px" height="20px" />
        <Placeholder width="200px" height="20px" />
      </Container>
      <Placeholder width="150px" height="20px" />
    </Container>
    <Container flex padding="20px 0">
      <Placeholder width="800px" height="5px" />
    </Container>
    <Container flex padding="20px 0" direction="column" style={{ gap: '10px' }}>
      <Container flex noPadding>
        <Placeholder width="150px" height="25px" />
      </Container>
      <Container flex noPadding justifyContent="space-between">
        <Placeholder width="200px" height="25px" />
        <Placeholder width="200px" height="25px" />
        <Placeholder width="200px" height="25px" />
      </Container>
    </Container>
    <Container flex padding="20px 0">
      <Placeholder width="800px" height="5px" />
    </Container>
    <Container flex noPadding justifyContent="space-between" width="100%">
      <Placeholder width="250px" height="30px" />
      <Container flex noPadding direction="column" style={{ gap: '10px' }}>
        <Placeholder width="200px" height="20px" />
        <Placeholder width="200px" height="20px" />
      </Container>
    </Container>
    <Container flex padding="10px 0" direction="column" style={{ gap: '10px' }}>
      <Placeholder width="300px" height="20px" />
      <Placeholder width="400px" height="20px" />
    </Container>
  </Container>
)

export default ViewReimbursementLoader
